export const traductionsPausePodcast = {
  en : {
    "restart" : "Start from the beginning",
    "continue" : "Continue",
    "text" : "It would appear that you have already participated in the adventure."
  },
  fr : {
    "restart" : "Recommencer du début",
    "continue" : "Continuer",
    "text" : "Il semblerait que vous ayez déjà participé à l'aventure."
  },
  it : {
    "restart" : "Partire dall'inizio",
    "continue" : "Continua",
    "text" : "Sembrerebbe che tu abbia già partecipato all'avventura."
  },
  de : {
    "restart" : "Von Anfang an beginnen",
    "continue" : "Weiter",
    "text" : "Es scheint, als hätten Sie bereits an dem Abenteuer teilgenommen."
  }
}
