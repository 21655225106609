export const traductionsIntro = {
  en : {
    "start" : "Enter the park",
    "label" : "Make yourself comfortable.",
    "text" : `
      <p>
        You are about to participate in a prospective and interactive sound experience in which your choices will influence the story.</br>
        A dive into the near future, in a unique amusement park called Fantasia. The decisions you make will participate, anonymously, in a statistical study.</br>
        At the end of the experiment, you can find out more about your positioning, and about the results of the study, in relation to the use of the technological fields presented in FantasIA.</br>
        Duration about 20 minutes.
      <p/>
    `,
    "label-2" : "Have a nice trip!"
  },
  fr : {
    "start" : "Entrer dans le parc",
    "label" : "Installez-vous confortablement.",
    "text" : `
      <p>
        Vous êtes sur le point de participer à une expérience sonore prospective et interactive, dans laquelle vos choix influeront sur l’histoire.</br>
        Une plongée dans un futur proche, au sein d’un parc d’attractions unique en son genre, appelé Fantasia. Les décisions que vous prendrez participeront, de manière anonyme, à une étude statistique.</br>
        À l’issue de l’expérience, vous pourrez en savoir plus sur votre positionnement, et sur les résultats de l’étude, par rapport à l’usage des domaines technologiques présentés dans FantasIA.</br>
        Durée : environ 20 minutes.
      <p/>
    `,
    "label-2" : "Bon voyage !"
  },
  it : {
    "start" : "Entrare nel parco",
    "label" : "Mettetevi comodi.",
    "text" : `
      <p>
        State per partecipare a un'esperienza sonora prospettica e interattiva in cui le vostre scelte influenzeranno la storia.</br>
        Un tuffo nel prossimo futuro in un parco divertimenti unico nel suo genere chiamato Fantasia. Le decisioni che prenderai parteciperanno, in forma anonima, ad uno studio statistico.</br>
        Al termine dell'esperimento, potrete conoscere meglio il vostro posizionamento, e i risultati dello studio, in relazione all'utilizzo dei campi tecnologici presentati in FantasIA.</br>
        Durata : circa 20 minuti.
      <p/>
    `,
    "label-2" : "Buon viaggio!"
  },
  de : {
    "start" : "Betreten des Parks",
    "label" : "Machen Sie es sich bequem.",
    "text" : `
      <p>
        Sie sind dabei, an einem vorausschauenden und interaktiven Klangerlebnis teilzunehmen, bei dem Ihre Entscheidungen die Geschichte beeinflussen werden.</br>
        Ein Eintauchen in die nahe Zukunft in einem einzigartigen Vergnügungspark namens Fantasia. Die Entscheidungen, die Sie treffen, nehmen anonym an einer statistischen Studie teil.</br>
        Am Ende des Experiments werden Sie in der Lage sein, mehr über Ihre Positionierung und die Ergebnisse der Studie in Bezug auf die Nutzung der in FantasIA vorgestellten Technologiefelder zu erfahren.</br>
        Dauer : ca. 20 Minuten
      <p/>
    `,
    "label-2" : "Wir wünschen Ihnen eine gute Fahrt!"
  }
}
