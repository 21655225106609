export const traductionsGeneralConditions = {
  en : {
    "title": "General conditions",
    "text": `
      <p>
        Terms of use<br/>
        Responsibility<br/>
        Although every care has been taken by the federal authorities to ensure the accuracy of the information published on this website, no warranty can be given in respect of the accuracy, correctness, up-to-dateness, reliability or completeness of this information.
      </p>
      <p>
        The federal authorities expressly reserve the right to modify in part or in whole the content of this site, to delete it or to temporarily suspend its distribution, at any time and without prior notice.
      </p>
      <p>
        The federal authorities shall not be held liable for any material or immaterial damage that may be caused by access to the information disseminated or by its use or non-use, by misuse of the connection or by technical problems.
      </p>
      <p>
        References and links<br/>
        References and links to other websites are not the responsibility of the federal authorities. Access to and use of these sites is at the user's own risk. The federal authorities expressly declare that they have no influence whatsoever on the form, content and offers of the linked sites. The information and services offered on these sites are the sole responsibility of their authors.
      </p>
      <p>
        The federal authorities disclaim all responsibility for such websites.
      </p>
      <p>
        Data protection<br/>
        Art. 13 of the Federal Constitution and the legal provisions of the Swiss Confederation on data protection stipulate that everyone has the right to protection of their private sphere and to protection against the misuse of their personal data. The federal authorities strictly observe these provisions. Personal data is treated in the strictest confidence and is neither sold nor passed on to third parties.
      </p>
      <p>
        We strive, in close cooperation with our hosting providers, to protect our databases as well as possible from external intrusion, loss, misuse and falsification.
      </p>
      <p>
        When accessing our website, the following data is recorded: IP address, date, time, browser numbering and other information that is generally transmitted to the operating system or browser software.
      </p>
      <p>
        This data enables the federal authorities to carry out anonymized statistical evaluations in order to identify trends that can be used to improve the supply. According to the Federal Law on the Surveillance of Postal and Telecommunications Correspondence (LSCPT), there is a legal obligation to store connection data for at least six months.
      </p>
      <p>
        However, these rules do not apply to voluntary contact. When you register your e-mail address, it is stored in a separate database, which is not linked to the anonymous log files. You can cancel your registration at any time.
      </p>
      <p>
        Copyrights<br/>
        Copyright: Authorities of the Swiss Confederation.
      </p>
      <p>
        The information contained on the websites of the federal authorities is made available to the public. Downloading or copying texts, illustrations, photographs or other data does not entail any transfer of rights to the contents.
      </p>
      <p>
        The copyright and all other rights related to the texts, illustrations, photographs and other data placed on the websites of the federal authorities are their exclusive property or that of the expressly mentioned holders. Any reproduction is subject to the prior written permission of the rights holder.
      </p>
    `
  },
  fr : {
    "title" : "Mentions légales",
    "text" : `
      <p>
        Conditions d'utilisation<br/>
        Responsabilité<br/>
        Malgré la grande attention qu’elles portent à la justesse des informations diffusées sur ce site, les autorités fédérales ne peuvent endosser aucune responsabilité quant à la fidélité, à l’exactitude, à l’actualité, à la fiabilité et à l’intégralité de ces informations.
      </p>
      <p>
        Les autorités fédérales se réservent expressément le droit de modifier en partie ou en totalité le contenu de ce site, de le supprimer ou d’en suspendre temporairement la diffusion, et ce à tout moment et sans avertissement préalable.
      </p>
      <p>
        Les autorités fédérales ne sauraient être tenues pour responsables des dommages matériels ou immatériels qui pourraient être causés par l’accès aux informations diffusées ou par leur utilisation ou non-utilisation, par le mauvais usage de la connexion ou par des problèmes techniques.
      </p>
      <p>
        Renvois et liens<br/>
        Les renvois et liens vers d’autres sites Internet ne sont pas de la responsabilité des autorités fédérales. L’accès à ces sites et leur utilisation se font aux risques des utilisateurs. Les autorités fédérales déclarent expressément qu’elles n’ont aucune influence sur la forme, le contenu et les offres des sites auxquels elles renvoient. Les informations et services offerts par ces sites sont entièrement de la responsabilité de leurs auteurs.
      </p>
      <p>
        Les autorités fédérales rejettent toute responsabilité pour de tels sites Internet.
      </p>
      <p>
        Protection des données<br/>
        L’art. 13 de la Constitution fédérale et les dispositions légales de la Confédération suisse sur la protection des données disposent que toute personne a droit à la protection de sa sphère privée ainsi qu’à la protection contre l'emploi abusif des données qui la concernent. Les autorités fédérales observent strictement ces dispositions. Les données personnelles sont traitées de façon parfaitement confidentielle et ne sont ni vendues, ni transmises à des tiers.
      </p>
      <p>
        Nous nous efforçons, en collaboration étroite avec nos hébergeurs, de protéger au mieux possible nos banques de données des intrusions externes, des pertes, du mauvais usage et de la falsification.
      </p>
      <p>
        Lors de l’accès à notre site web les données suivantes sont enregistrées: l’adresse IP, la date, l’heure, la numérotation du logiciel de navigation ainsi des informations généralement transmises sur le système d’exploitation resp. sur le logiciel de navigation.
      </p>
      <p>
        Ces données permettent aux autorités fédérales de procéder à des évaluations statistiques anonymisées, afin de reconnaître des tendances servant à améliorer l’offre. Selon la loi fédérale sur la surveillance de la correspondance par poste et télécommunication (LSCPT) il existe une obligation légale de conserver durant au moins six mois les données de connexion.
      </p>
      <p>
        Ces règles ne sont toutefois pas valables pour la prise de contact volontaire. Lors de l’enregistrement de votre adresse électronique, celle-ci est stockée dans une banque de données séparée, qui ne possède aucun lien avec les fichiers journaux anonymes. Vous avez à tout moment la possibilité d’annuler votre enregistrement.
      </p>
      <p>
        Droits d'auteur<br/>
        Droits d'auteur: autorités de la Confédération suisse.
      </p>
      <p>
        Les informations que recèlent les sites Web des autorités fédérales sont mises à la disposition du public. Le téléchargement ou la copie de textes, d'illustrations, de photographies ou d'autres données n'entraîne aucun transfert de droits sur les contenus.
      </p>
      <p>
        Les droits d'auteur et tous les autres droits liés aux textes, illustrations, photographies et autres données placés sur les sites Web des autorités fédérales sont leur propriété exclusive ou celle des détenteurs expressément cités. Toute reproduction est subordonnée à l'autorisation écrite préalable du détenteur des droits.
      </p>
    `
  },
  it : {
    "titolo": "Condizioni generali",
    "testo": `
      <p>
        Condizioni di utilizzo<br/>
        Responsabilità<br/>
        Sebbene le autorità federali abbiano prestato la massima attenzione alla correttezza delle informazioni pubblicate su questo sito web, non è possibile garantire l'esattezza, la correttezza, l'attualità, l'aggiornamento, l'affidabilità e la completezza di tali informazioni.
      </p>
      <p>
        Le autorità federali si riservano espressamente il diritto di modificare, cancellare o sospendere temporaneamente la diffusione di tutto o parte del contenuto di questo sito web in qualsiasi momento e senza preavviso.
      </p>
      <p>
        Le autorità federali non possono essere ritenute responsabili per eventuali danni materiali o immateriali che possono essere causati dall'accesso, dall'uso o dal mancato uso delle informazioni diffuse, dall'uso improprio della connessione o da problemi tecnici.
      </p>
      <p>
        Riferimenti e link<br/>
        I riferimenti e i link ad altri siti web non sono di competenza delle autorità federali. L'accesso e l'utilizzo di questi siti è a rischio e pericolo dell'utente. Le autorità federali dichiarano espressamente di non avere alcuna influenza sulla struttura, sul contenuto e sulla disponibilità dei siti collegati. Le informazioni e i servizi offerti su questi siti sono di esclusiva responsabilità dei loro autori.
      </p>
      <p>
        Le autorità federali declinano ogni responsabilità per tali siti web.
      </p>
      <p>
        Protezione dei dati<br/>
        L'articolo 13 della Costituzione federale e le disposizioni legali della Confederazione svizzera sulla protezione dei dati stabiliscono che ogni persona ha il diritto alla protezione della sua sfera privata e alla protezione contro l'abuso dei dati che la riguardano. Le autorità federali si attengono rigorosamente a queste disposizioni. I dati personali sono trattati con la massima riservatezza e non vengono venduti né ceduti a terzi.
      </p>
      <p>
        In stretta collaborazione con i nostri fornitori di hosting, ci sforziamo di proteggere al meglio le nostre banche dati da intrusioni esterne, perdite, uso improprio e falsificazioni.
      </p>
      <p>
        Quando si accede al nostro sito web, vengono registrati i seguenti dati: indirizzo IP, data, ora, numerazione del browser e altre informazioni che vengono generalmente trasmesse al sistema operativo o al software del browser.
      </p>
      <p>
        Questi dati consentono alle autorità federali di effettuare valutazioni statistiche anonime per individuare le tendenze che possono essere utilizzate per migliorare l'offerta. La legge federale sulla sorveglianza della corrispondenza postale e della telecomunicazione (LSCPT) prevede l'obbligo legale di conservare i dati di connessione per almeno sei mesi.
      </p>
      <p>
        Tuttavia, queste regole non si applicano ai contatti volontari. Quando si registra il proprio indirizzo e-mail, questo viene memorizzato in un database separato, che non è collegato ai file di log anonimi. È possibile cancellare la registrazione in qualsiasi momento.
      </p>
      <p>
        Diritti d'autore<br/>
        Copyright: Autorità della Confederazione Svizzera.
      </p>
      <p>
        Le informazioni sui siti web delle autorità federali sono messe a disposizione del pubblico. Il download o la copia di testi, illustrazioni, fotografie o altri dati non comporta alcun trasferimento dei diritti sui contenuti.
      </p>
      <p>
        I diritti d'autore e tutti gli altri diritti relativi a testi, illustrazioni, fotografie e altri dati inseriti sui siti web delle autorità federali sono di loro esclusiva proprietà o dei titolari espressamente nominati. Qualsiasi riproduzione è soggetta alla previa autorizzazione scritta del titolare dei diritti.
      </p>
    `
  },
  de : {
    "Titel": "Allgemeine Bedingungen",
    "text": `
      <p>
        Einsatzbedingungen<br/>
        Verantwortung<br/>
        Obwohl die Bundesbehörden mit aller Sorgfalt auf die Richtigkeit der veröffentlichten Informationen achten, kann hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit dieser Informationen keine Gewährleistung übernommen werden.
      </p>
      <p>
        Die Bundesbehörden behalten sich ausdrücklich vor, den Inhalt dieser Website jederzeit und ohne vorherige Ankündigung ganz oder teilweise zu ändern, zu löschen oder die Verbreitung zeitweise einzustellen.
      </p>
      <p>
        Haftungsansprüche gegen die Bundesbehörden, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch den Zugriff oder die Nutzung bzw. Nichtnutzung der dargebotenen Informationen, durch Missbrauch der Verbindung oder durch technische Störungen verursacht wurden, sind grundsätzlich ausgeschlossen.
      </p>
      <p>
        Referenzen und Links<br/>
        Verweise und Links auf andere Websites liegen nicht in der Verantwortung von Bundesbehörden. Der Zugang zu und die Nutzung dieser Seiten erfolgt auf eigene Gefahr des Nutzers. Die Bundesbehörden erklären ausdrücklich, dass sie keinerlei Einfluss auf die Gestaltung, den Inhalt und die Verfügbarkeit der verlinkten Seiten haben. Die auf diesen Seiten angebotenen Informationen und Dienstleistungen liegen in der alleinigen Verantwortung der jeweiligen Autoren.
      </p>
      <p>
        Die Bundesbehörden lehnen jede Verantwortung für solche Websites ab.
      </p>
      <p>
        Datenschutz<br/>
        Artikel 13 der Bundesverfassung und die gesetzlichen Bestimmungen der Schweizerischen Eidgenossenschaft über den Datenschutz legen fest, dass jede Person das Recht auf Schutz ihrer Privatsphäre und auf Schutz vor Missbrauch der sie betreffenden Daten hat. Die Bundesbehörden halten diese Bestimmungen strikt ein. Persönliche Daten werden streng vertraulich behandelt und weder verkauft noch an Dritte weitergegeben.
      </p>
      <p>
        In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, unsere Datenbanken so gut wie möglich vor externem Eindringen, Verlust, Missbrauch und Verfälschung zu schützen.
      </p>
      <p>
        Beim Zugriff auf unsere Website werden folgende Daten erfasst: IP-Adresse, Datum, Uhrzeit, Browsernummerierung und weitere Informationen, die in der Regel an das Betriebssystem oder die Browsersoftware übermittelt werden.
      </p>
      <p>
        Diese Daten ermöglichen es den Bundesbehörden, anonymisierte statistische Auswertungen vorzunehmen, um Trends zu erkennen, die zur Verbesserung der Versorgung genutzt werden können. Nach dem Bundesgesetz über die Überwachung des Post- und Fernmeldeverkehrs (BÜPF) besteht eine gesetzliche Verpflichtung zur Speicherung der Verbindungsdaten für mindestens sechs Monate.
      </p>
      <p>
        Diese Regeln gelten jedoch nicht für den freiwilligen Kontakt. Wenn Sie Ihre E-Mail-Adresse registrieren, wird diese in einer separaten Datenbank gespeichert, die nicht mit den anonymen Protokolldateien verknüpft ist. Sie können Ihre Anmeldung jederzeit stornieren.
      </p>
      <p>
        Urheberrechte<br/>
        Copyright: Behörden der Schweizerischen Eidgenossenschaft.
      </p>
      <p>
        Die Informationen auf den Webseiten der Bundesbehörden werden der Öffentlichkeit zugänglich gemacht. Mit dem Herunterladen oder der Vervielfältigung von Texten, Abbildungen, Fotos oder anderen Daten ist keine Übertragung von Rechten an den Inhalten verbunden.
      </p>
      <p>
        Das Urheberrecht und alle anderen Rechte an Texten, Abbildungen, Fotos und anderen Daten, die auf den Webseiten der Bundesbehörden zur Verfügung gestellt werden, liegen ausschließlich bei ihnen oder den ausdrücklich genannten Inhabern. Jede Vervielfältigung bedarf der vorherigen schriftlichen Genehmigung des Rechteinhabers.
      </p>
    `
  }
}
