import Button from '../../../components/Button'
import { MdVolumeOff, MdVolumeUp } from "react-icons/md"
import styles from './navigation-annexes.module.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { traductionsNavigationAnnexes } from '../../../config/traductions-navigation-annexes.js'

export default function NavigationAnnexes({isMuted, playSound, pauseSound}){
  const podcastReducer = useSelector(state => state.podcastReducer)

  return (
    <div className={styles["navigation-annexes"]}>
      <div className={styles["container"]}>
        <p>
          {traductionsNavigationAnnexes[podcastReducer.lang]["text"]}
        </p>
        <Button to="/fantas-ia/pages/resultat" text={traductionsNavigationAnnexes[podcastReducer.lang]["see-result"]} />
        <Button to="/fantas-ia/pages/sources" text={traductionsNavigationAnnexes[podcastReducer.lang]["see-sources"]} />
        <Link to="/fantas-ia/pages/credits">{traductionsNavigationAnnexes[podcastReducer.lang]["see-credits"]}</Link>
      </div>
      <div className={styles["navigation-annexes-controls"]}>
        { isMuted ?
          <MdVolumeUp onClick={()=> playSound()} /> :
          <MdVolumeOff onClick={()=> pauseSound()} />
        }
      </div>
    </div>
  )
}
