import { useEffect } from 'react';
import styles from './subtitles.module.scss';
import { animated, useSpring, config } from 'react-spring'

export default function Subtitles({text, centered ,hide}){

  const spring = useSpring({
    x : hide ? 160 : 0,
    opacity: hide ? 0 : 1,
    config : config.gentle
  })

  return (
    <animated.div
      className={centered ? styles["subtitles-centered"] : styles["subtitles"]}
      style={{
        opacity : spring.opacity.interpolate(opacity => {
          return opacity
        }),
        transform : spring.x.interpolate(x => {
          return `translate(0,${x}%)`
        })
      }}
    >
      <div className={styles["container-scroll"]}>
        <div className={styles["content"]} >
          <span dangerouslySetInnerHTML={{ __html : text}} />
        </div>
      </div>
    </animated.div>
  )
}
