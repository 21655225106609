import { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import styles from './input-rate.module.scss'

const boxShadow = '0px 0px 10px 0px rgba(0, 255, 224,0.3)'

const StyledSlider = withStyles({
  mark : {
    display : "none"
  },
  markLabel : {
    color : "rgba(255,255,255,.3)"
  },
  rail: {
    backgroundColor : "rgba(255,255,255,.3)",
  },
  track: {
    display : "none"
  },
  thumb: {
    backgroundColor: '#00FFE0',
    boxShadow: boxShadow,
    transition : 'box-shadow .3s ease-in-out',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 0px 15px 0px rgba(0, 255, 224,0.7)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: boxShadow,
      },
    },
  },
  valueLabel: {
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#00FFE0',
    },
  },
})(Slider);

const InputRate = ({name, defaultValue = 0, step = 0.01, min = 0, max = 10, marks = false, unit = ""}, ref) => {
  const [ value, setValue ] = useState( 0 )

  useImperativeHandle(ref,()=>({
    getValue : () => value,
    getObject : () => ({ [name] : value})
  }))

  function handleChange(value){
    setValue(value)
  }

  function valuetext(value) {
    return `${value}%`;
  }

  return (
    <div className={styles["container-input-rate"]}>
      <StyledSlider
        defaultValue={defaultValue}
        valueLabelFormat={value => `${value}${unit}`}
        valueLabelDisplay="on"
        onChange={(e, value) => handleChange(value)}
        step={step}
        min={min}
        max={max}
        marks={marks}
      />
    </div>
  )
}

export default forwardRef(InputRate)
