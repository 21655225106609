import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styles from './resultat.module.scss'
import arrow from '../../../../assets/images/arrow.svg'
import axios from 'axios'
import _ from 'lodash'

import story from '../../../../config/story'
import { traductionsResultat } from '../../../../config/traductions-resultat'
import { traductions } from '../../../../config/traductions'

import ArrowBack from '../../../../assets/images/arrow-back.svg'
import Facebook from '../../../../assets/images/facebook.png'
import Twitter from '../../../../assets/images/twitter.png'
import Linkedin from '../../../../assets/images/linkedin.png'


const matchAnswers = {
  48 : { letter : "A", 0 : "positive", 1 : "negative" },
  53 : { letter : "B", 1 : "positive", 0 : "negative" },
  185 : { letter : "C", 0 : "positive", 1 : "negative" },
  75 : { letter : "D", 0 : "positive", 1 : "negative" },
  199 : { letter : "E", 0 : "positive", 1 : "negative" },
  83 : { letter : "F", 0 : "positive", 1 : "negative" },
  138 : { letter : "G", 0 : "positive", 1 : "negative" },
  206 : { letter : "H", 0 : "positive", 1 : "negative" },
  100 : { letter : "I", 1 : "positive", 0 : "negative" },
  160 : { letter : "J", 0 : "positive", 1 : "positive", 2 : "negative", 3 : "negative" },
  212 : { letter : "K", 0 : "positive", 1 : "negative" }
}

export default function Resultat(){
  const [ mapAnswers , setMapAnswers ] = useState()

  useEffect(()=>{
    let answers = localStorage.getItem('answers')
    if(answers){
      let map = new Map()
      let arrAnswers = JSON.parse(answers);
      arrAnswers.forEach((answer)=>{
        map.set(answer.id, answer)
      })
      setMapAnswers(map)
    }
  },[])

  function getKeyChoice(id){
    let found = story.find(bloc=> bloc.id === id)
    return found
  }

  function renderKeyChoices(){
    let arr = []
    mapAnswers.forEach((value,key)=>{
      matchAnswers[value.id] && arr.push({...value, letter : matchAnswers[value.id].letter})
    })

    function compare( a, b ) {
      if ( a.letter < b.letter ){
        return -1;
      }
      if ( a.letter > b.letter ){
        return 1;
      }
      return 0;
    }

    arr = arr.sort( compare );

    let elts = arr.map((answer)=>{
      return <ComponentKeyChoice key={`keychoice-${answer.id}`} answer={answer} content={getKeyChoice(answer.id)}/>
    })

    return elts
  }


  return (
    <div className={styles["resultat"]}>
      <div className={`${styles["container"]} container`}>

        {mapAnswers &&
          <React.Fragment>
            <Three answers={mapAnswers}/>
            <div className={styles["col"]}>
              {renderKeyChoices()}
            </div>
          </React.Fragment>
      }

      </div>
    </div>
  )
}

const ComponentKeyChoice = ({content,answer}) => {
  const podcastReducer = useSelector(state => state.podcastReducer)
  const [ state, setState ] = useState()
  const [ traductionsKC, setTraductionsKC ] = useState()
  const [ choices, setChoices ] = useState()
  const [ distribution, setDistribution ] = useState()
  const [ textAnswer, setTextAnswer ] = useState()

  content = _.cloneDeep(content)

  useEffect(()=>{
    let trad = traductionsResultat[podcastReducer.lang]["key-choices"]
    setTraductionsKC(trad[answer.id])
  },[podcastReducer.lang])

  useEffect(()=>{
    if(!content.choices[answer.answer]){
      return
    }
    let itemAnswer = content.choices[answer.answer].answer
    setState(matchAnswers[answer.id])
    setTextAnswer(itemAnswer)

    let otherChoices = content.choices
    otherChoices.splice(answer.answer,1);

    /// WIP % PROGRESS
    axios.get('https://api.technocast.org/api/response/distribution')
    .then(response => {
      var obj = _.cloneDeep(response.data.distribution)
      _.each(obj, function(d,i){
        var total = 0
        if(d[2]){
          total = d[0] + d[1] + d[2] + d[3]
          d[0] = Math.round(d[0]*100/total*100)/100
          d[1] = Math.round(d[1]*100/total*100)/100
          d[2] = Math.round(d[2]*100/total*100)/100
          d[3] = Math.round(d[3]*100/total*100)/100
        }else{
          total = d[0] + d[1]
          d[0] = Math.round(d[0]*100/total*100)/100
          d[1] = Math.round(d[1]*100/total*100)/100
        }
      })
      setDistribution(obj)
    }).catch(error => {
      console.log(error)
    })

    setChoices([ { answer : itemAnswer } ,...otherChoices])

  },[])

  return (
    <div className={styles["key-choice"]}>
      {(choices && state) &&
        <>
          <label>{traductionsResultat[podcastReducer.lang]["key-choice"]} {answer.letter} :</label>
          {/*<span dangerouslySetInnerHTML={{ __html : content.text[podcastReducer.lang]}} />*/}
          {(traductionsKC && traductionsKC["title"]) &&
            <p>
              {traductionsKC["title"]}
            </p>
          }
          {(traductionsKC && state[answer.answer] === "positive") &&
            <p className={`${styles["text-answer"]} ${styles['positive']}`}>
              {traductionsKC["positive"]}
            </p>
          }
          {(traductionsKC && state[answer.answer] === "negative") &&
            <p className={`${styles["text-answer"]} ${styles['negative']}`}>
            {traductionsKC["negative"]}
            </p>
          }
          <div className={styles["answers"]}>
            <p className={`${styles[state[answer.answer]]}`}>
              <img src={arrow} alt="arrow" />
              {traductionsResultat[podcastReducer.lang]["your-choice"]} : {<span dangerouslySetInnerHTML={{ __html : textAnswer[podcastReducer.lang]}} />}
            </p>
            <p className={`${styles[state[answer.answer]]}`}>
              <img src={arrow} alt="arrow" />
              {traductionsResultat[podcastReducer.lang]["distribution"]} :
            </p>
            <ul className={`${styles[state[answer.answer]]}`}>
              {distribution && choices.map((choice,i)=>{
                return (
                  <li key={`choice-${i}`}><span style={{display: 'inline-block'}} dangerouslySetInnerHTML={{ __html : `${choice.answer[podcastReducer.lang]}`}} /> : {distribution[content.id][i]}%</li>
                )
              })}
            </ul>
          </div>
        </>
      }
    </div>
  )


  // return (
  //   <div className={styles["key-choice"]}>
  //     { state &&
  //       <>
  //         <label>{traductionsResultat[podcastReducer.lang]["key-choice"]} {answer.letter} :</label>
  //         <span dangerouslySetInnerHTML={{ __html : content.text[podcastReducer.lang]}} />
  //         {(traductionsKC && state[answer.answer] === "positive") &&
  //           <p className={`${styles["text-answer"]} ${styles['positive']}`}>
  //             {traductionsKC["positive"]}
  //           </p>
  //         }
  //         {(traductionsKC && state[answer.answer] === "negative") &&
  //           <p className={`${styles["text-answer"]} ${styles['negative']}`}>
  //           {traductionsKC["negative"]}
  //           </p>
  //         }
  //         <div className={styles["answers"]}>
  //           <p className={`${styles[state[answer.answer]]}`}><img src={arrow} alt="arrow" />{traductionsResultat[podcastReducer.lang]["your-choice"]} : {state[answer.answer] === "positive" ? <span>{traductionsResultat[podcastReducer.lang]["yes"]}</span> : <span>{traductionsResultat[podcastReducer.lang]["no"]}</span>}</p>
  //           <p className={`${styles[state[answer.answer]]}`}><img src={arrow} alt="arrow" />{traductionsResultat[podcastReducer.lang]["distribution"]} : <span>{traductionsResultat[podcastReducer.lang]["yes"]} (54%)</span> / <span>{traductionsResultat[podcastReducer.lang]["no"]} (46%)</span> </p>
  //         </div>
  //       </>
  //     }
  //   </div>
  // )
}

const Three = ({answers}) => {
  const podcastReducer = useSelector(state => state.podcastReducer)
  const history = useHistory();


  function getClassName(blocID){
    let obj = answers.get(blocID)
    if(obj){
      return `${styles["node"]} ${styles[matchAnswers[blocID][obj.answer]]}`
    }else{
      return `${styles["node"]}`
    }
  }

  function openShareModal(e, href){
    window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=700');
    return false;
  }

  return (
    <div className={styles["container-three"]}>
      <div className={styles["back-navigation"]} onClick={() => { history.push("/fantas-ia/pages/navigation-annexes") }}>
        <img src={ArrowBack} alt="retour" />
        <label>{traductions[podcastReducer.lang]["backward"]}</label>
      </div>
      <div className={styles["share"]}>
        {/*<a
          rel="nofollow"
          onClick={ (e) => openShareModal(e, "https://twitter.com/share?url=https://technocast.org&text=test twitter&hashtags=Fantasia&via=Bakasable") }
          alt="Share on Twitter">
          <img src={Twitter} alt="Twitter" />
        </a>*/}
        <a
          rel="nofollow"
          onClick={ (e) => openShareModal(e, "https://www.facebook.com/sharer/sharer.php?u=https://technocast.org&title=FantasIA&description="+traductionsResultat[podcastReducer.lang]["rs"]) }
          alt="Share on Facebook">
          <img src={Facebook} alt="Facebook" />
        </a>
        <a
          rel="nofollow"
          onClick={ (e) => openShareModal(e, "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Ftechnocast.org&title=FantasIA"/*+traductionsResultat[podcastReducer.lang]["rs"]*/) }
          alt="Share on Linkedin">
          <img src={Linkedin} alt="Linkedin" />
        </a>
      </div>
      <div className={styles["group"]}>
        <div>
          <div className={styles["line"]}>
            <div className={getClassName(48)}><span>A</span></div>
          </div>
          <div className={styles["line"]}>
            <div className={getClassName(53)}><span>B</span></div>
            <div className={getClassName(185)}><span>C</span></div>
          </div>
        </div>
        <div className={styles["container-label"]}><label>{traductionsResultat[podcastReducer.lang]["three-part-1"]}</label></div>
      </div>
      <div className={styles["group"]}>
        <div>
          <div className={styles["line"]}>
            <div className={getClassName(75)}><span>D</span></div>
            <div className={getClassName(199)}><span>E</span></div>
          </div>
          <div className={styles["line"]}>
            <div className={getClassName(83)}><span>F</span></div>
            <div className={getClassName(138)}><span>G</span></div>
            <div className={getClassName(206)}><span>H</span></div>
          </div>
        </div>
        <div className={styles["container-label"]}><label>{traductionsResultat[podcastReducer.lang]["three-part-2"]}</label></div>
      </div>
      <div className={styles["group"]}>
        <div>
          <div className={styles["line"]}>
            <div className={getClassName(100)}><span>I</span></div>
            <div className={getClassName(160)}><span>J</span></div>
            <div className={getClassName(212)}><span>K</span></div>
          </div>
        </div>
        <div className={styles["container-label"]}><label>{traductionsResultat[podcastReducer.lang]["three-part-3"]}</label></div>
      </div>
    </div>
  )
}
