export const traductionsResultat = {
  en : {
    "three-part-1" : "Your initial convictions",
    "three-part-2" : "Your choices across the journey",
    "three-part-3" : "End of the story",
    "legende-positive": "You seem to be rather open to the use of new technologies",
    "legende-negative": "You seem to be rather closed to the use of new technologies",
    "title-analysis" : "The analysis",
    "key-choice" : "Key Choice",
    "trust-Anna" : "Intuitively, you seem to trust Anna to guarantee the protection of personal data.",
    "not-trust-Anna" : "Intuitively, you seem to not trust Anna to guarantee the protection of personal data.",
    "your-choice" : "Your choice",
    "distribution" : "Distribution (participants)",
    "yes" : "yes",
    "no" : "no",
    "rs" : "FantasIA: how far will you use the technological possibilities available to you? A prospective and interactive experience proposed by @Deftech and @lecoupdapres #fantasia #designfiction #prospective #AI",
    "key-choices" : {
      "48" : {
        title : "The terrorist alert level has been raised to maximum. In order to limit the risk of an attack, Anna can check all the personal data of each visitor. Do you authorize her to do so?",
        positive : "Intuitively, you seem to trust Anna to guarantee the protection of personal data.",
        negative : "Intuitively, you seem to not trust Anna to guarantee the protection of personal data."
      },
      "53" : {
        title : "Anna has identified potential suspects. Do you let her arrest them or do you prefer to wait for the police?",
        positive : "Intuitively, you seem to trust Anna's efficency.",
        negative : "Intuitively, you don't seem to trust Anna's efficency."
      },
      "185" : {
        title : "Anti tech terrorist attack ! Anna is shut down for 5 sec, and an accident occurs with the autonomous cars attraction ! Medical aid is needed, do you send robots piloted by Anna or human doctors?",
        positive : "Intuitively, you seem to trust Anna's efficency.",
        negative : "Intuitively, you don't seem to trust Anna's efficency."
      },
      "75" : {
        title : "You have to write a report on Anna. Is it...",
        positive : "After seeing Anna in action, you have judged her intervention positively.",
        negative : "After seeing Anna in action, you have judged her intervention negatively."
      },
      "199" : {
        title : "You receive a phone call from the park director asking for an update on the situation. Do you let Anna report or do you take over?",
        positive : "After seeing Anna in action, you have chosen to let her report to the Director herself.",
        negative : "After seeing Anna in action, you have not chosen to let her report to the Director herself."
      },
      "83" : {
        title : "Anna reveals that she checked on your personal data without your consent, to see if you had any connection with the suspects. Are you upset ?",
        positive : "After seeing Anna in action, you seem to trust her to guarantee the protection of your own personal data.",
        negative : "After seeing Anna in action, you don't seem to trust her to guarantee the protection of your own personal data."
      },
      "138" : {
        title : "The various incidents created a general sense of insecurity, and some visitors are asking for a refund. The director is worried, and Anna suggests opening the main attraction, which was supposed to be in maintenance. Do you support her proposal?",
        positive : "After seeing Anna in action, you seem to trust her to carry out her mission properly (the safety of the park).",
        negative : "After seeing Anna in action, you don't seem to trust her to carry out her mission properly (the safety of the park)."
      },
      "206" : {
        title : "The director asks you to find the terrorists. Do you want Anna to actively search for terrorists using data surveillance, or switch to ghost mode to avoid an attack?",
        positive : "After seeing Anna in action, you seem to trust her to manage the security of the park, despite your fears about the protection of personal data.",
        negative : "After seeing Anna in action, you don't seem to trust her to manage the security of the park, due to your fears about the protection of personal data."
      },
      "100" : {
        title : "The park just raised the emergency level. In such a high level-risk context, you have the possibility to activate the full powers for Anna. That means that you will no longer intervene in the decision making process to fasten it. Anna will make choices according to her objective of preserving the feeling of safety in the park and saving life. Do you grant Anna with full power ?",
        positive : "After this adventure at Anna's side, you seem to trust her to manage the security of the park in a totally autonomous way.",
        negative : "After this adventure at Anna's side, you don't seem to trust her to manage the security of the park in a totally autonomous way."
      },
      "160" : {
        title : "At the end of this first day, Anna tells you that the level of confidence between you 2 is pretty low. She suggests you some options to improve your relationship.",
        positive : "After this adventure at Anna's side, even if you still have doubts, you seem to trust her to manage the security of the park.",
        negative : "After this adventure at Anna's side, you still have doubts and don't seem to trust her to manage the security of the park."
      },
      "212" : {
        title : "Gary reveals that he is a terrorist as well while Anna is in ghost mode. He asks you to shut her down. Do you save Anna or do you join the A.T.N. ?",
        positive : "After this adventure at Anna's side, you don't seem to trust her to manage the security of the park.",
        negative : "After this adventure at Anna's side, you don't seem to trust her to manage the security of the park."
      }
    }
  },
  fr : {
    "three-part-1" : "Vos convictions initiales",
    "three-part-2" : "Vos décisions pendant l'expérience",
    "three-part-3" : "Votre bilan",
    "legende-positive" : "Vous semblez être plutôt ouvert.e à l'utilisation des nouvelles technologies",
    "legende-negative" : "Vous semblez être plutôt fermé.e à l'utilisation des nouvelles technologies",
    "title-analysis" : "L'analyse",
    "key-choice" : "Choix stratégique",
    "trust-Anna" : "De manière intuitive, vous semblez faire confiance à Anna pour garantir la protection des données personnelles.",
    "not-trust-Anna" : "De manière intuitive, vous ne semblez pas faire confiance à Anna pour garantir la protection des données personnelles.",
    "your-choice" : "Votre choix",
    "distribution" : "Distribution (participants)",
    "yes" : "yes",
    "no" : "no",
    "rs" : "FantasIA : jusqu’où allez-vous utiliser les possibilités technologiques qui vous sont offertes ? Une expérience prospective et interactive proposée par @Deftech et @lecoupdapres #fantasia #designfiction #prospective #IA",
    "key-choices" : {
      "48" : {
        title : "Le niveau d'alerte terroriste a été relevé au maximum. Dans l'objectif de limiter le risque d'attentat, Anna peut vérifier toutes les données personnelles de chaque visiteur. L'autorisez-vous à le faire ?",
        positive : "De manière intuitive, vous semblez faire confiance à Anna pour garantir la protection des données personnelles.",
        negative : "De manière intuitive, vous ne semblez pas faire confiance à Anna pour garantir la protection des données personnelles."
      },
      "53" : {
        title : "Anna a identifié de potentiels supects. La laissez-vous les arrêter ou préférez-vous attendre la police ?",
        positive : "De manière intuitive, vous semblez faire confiance à l'efficacité d'Anna.",
        negative : "De manière intuitive, vous ne semblez pas faire confiance à l'efficacité d'Anna."
      },
      "185" : {
        title : "Une attaque terroriste anti-technologie vient d'avoir lieu ! Anna est mise hors-service pendant 5 secondes, et un accident se produit dans l'attraction des voitures autonomes ! Il faut envoyer de l'aide médicale : choisissez-vous les robots pilotés par Anna ou les docteurs humains ?",
        positive : "De manière intuitive, vous semblez faire confiance à l'efficacité d'Anna.",
        negative : "De manière intuitive, vous ne semblez pas faire confiance à l'efficacité d'Anna."
      },
      "75" : {
        title : "Vous devez faire un rapport sur l’intervention d’Anna. Est-il ...",
        positive : "Après avoir vu Anna en action, vous avez jugé positivement son intervention.",
        negative : "Après avoir vu Anna en action, vous avez jugé négativement son intervention."
      },
      "199" : {
        title : "Vous recevez un appel téléphonique du directeur du parc qui vous demande un point sur la situation. Laissez-vous Anna rendre compte ou prenez-vous la main ?",
        positive : "Après avoir vu Anna en action, vous avez choisi de la laisser rendre compte elle-même au Directeur.",
        negative : "Après avoir vu Anna en action, vous n'avez pas choisi de la laisser rendre compte elle-même au Directeur."
      },
      "83" : {
        title : "Anna révèle qu'elle s'est renseignée sur vous sans votre consentement, pour voir si vous pouviez avoir un lien quelconque avec les suspects. Etes-vous contrarié ?",
        positive : "Après avoir vu Anna en action, vous semblez lui faire confiance pour garantir la protection de vos propres données personnelles.",
        negative : "Après avoir vu Anna en action, vous ne semblez pas lui faire confiance pour garantir la protection de vos propres données personnelles."
      },
      "138" : {
        title : "Les différents incidents ont créé un sentiment général d'insécurité. Afin de relancer la journée, Anna suggère au directeur d'ouvrir l'attraction principale, qui était censée être en maintenance. Soutenez-vous sa proposition ?",
        positive : "Après avoir vu Anna en action, vous semblez lui faire confiance pour mener à bien sa mission (assurer la sécurité au sein du parc).",
        negative : "Après avoir vu Anna en action, vous ne semblez pas lui faire confiance pour mener à bien sa mission (assurer la sécurité au sein du parc)."
      },
      "206" : {
        title : "Le directeur vous demande de trouver les terroristes. Voulez-vous qu'Anna recherche activement les terroristes en utilisant la surveillance des données, ou qu'elle passe en mode fantôme pour éviter un attentat ?",
        positive : "Après avoir vu Anna en action, vous semblez lui faire confiance pour gérer la sécurité du parc, malgré vos réticences sur la protection des données personnelles.",
        negative : "Après avoir vu Anna en action, vous ne semblez pas lui faire confiance pour gérer la sécurité du parc, en raison de vos réticences sur la protection des données personnelles."
      },
      "100" : {
        title : "Le niveau d'urgence vient d’être relevé par les autorités. Dans un contexte de risque aussi élevé, vous avez la possibilité de donner les pleins pouvoirs à Anna. Cela signifie que vous n'interviendrez plus dans le processus de décision. Anna fera des choix en fonction de son objectif, qui est de préserver le sentiment de sécurité dans le parc et de sauver des vies. Accordez-vous les pleins pouvoirs à Anna ?",
        positive : "Après cette aventure aux côtés d'Anna, vous semblez lui faire confiance pour gérer de manière totalement autonome la sécurité du parc.",
        negative : "Après cette aventure aux côtés d'Anna, vous ne semblez pas lui faire confiance pour gérer de manière totalement autonome la sécurité du parc."
      },
      "160" : {
        title : "À la fin de cette première journée, Anna vous dit que le niveau de confiance entre vous deux est assez faible. Elle vous suggère quelques options pour améliorer votre relation.",
        positive : "Après cette aventure aux côtés d'Anna, même si des doutes persistent encore, vous semblez lui faire confiance pour gérer la sécurité du parc.",
        negative : "Après cette aventure aux côtés d'Anna, des doutes persistent encore et vous ne semblez pas lui faire confiance pour gérer la sécurité du parc."
      },
      "212" : {
        title : "Gary révèle qu'il est aussi un terroriste alors qu'Anna est en mode fantôme. Il vous demande de la débrancher. Sauvez-vous Anna ou rejoignez-vous le R.A.T. ?",
        positive : "Après cette aventure aux côtés d'Anna, vous ne semblez pas faire confiance à Anna pour gérer la sécurité du parc.",
        negative : "Après cette aventure aux côtés d'Anna, vous ne semblez pas faire confiance à Anna pour gérer la sécurité du parc."
      }
    }
  },
  it : {
    "three-part-1" : "Le tue convinzioni iniziali",
    "three-part-2" : "Le tue scelte lungo il viaggio",
    "three-part-3" : "Fine della storia",
    "legende-positive" : "Sembri essere abbastanza aperto all'uso delle nuove tecnologie",
    "legende-negative" : "Sembri piuttosto chiuso all'uso delle nuove tecnologie",
    "title-analysis" : "L'analisi",
    "key-choice" : "Scelta chiave",
    "trust-Anna" : "Intuitivamente, sembra che lei si fidi di Anna per garantire la protezione dei dati personali.",
    "not-trust-Anna" : "Intuitivamente, sembra che lei non si fidi di Anna per garantire la protezione dei dati personali.",
    "your-choice" : "la tua scelta",
    "distribution" : "Distribuzione (partecipanti)",
    "yes" : "sì",
    "no" : "no",
    "rs" : "FantasIA: Fino a che punto intendete utilizzare le possibilità tecnologiche a vostra disposizione? Un'esperienza prospettica e interattiva proposta da @Deftech e @lecoupdapres #fantasia #designfiction #prospettiva #IA",
    "key-choices" : {
      "48" : {
        title :"Il livello di allarme terroristico è stato aumentato al massimo. Per limitare il rischio di un attacco, Anna può controllare tutti i dati personali di ogni visitatore. La autorizzi a farlo?",
        positive : "Intuitivamente, sembra che ti fidi di Anna per garantire la protezione dei dati personali.",
        negative : "Intuitivamente, non sembri fidarti di Anna per garantire la protezione dei dati personali."
      },
      "53" : {
        title :"Anna ha identificato i potenziali sospettati. Lasci che li arresti lei o vuoi aspettare la polizia?",
        positive : "Intuitivamente, sembra che ti fidi dell'efficienza di Anna...",
        negative : "Intuitivamente, non sembri fidarti dell'efficienza di Anna..."
      },
      "185" : {
        title :"È appena avvenuto un attacco terroristico antitecnologico! Anna viene messa fuori combattimento per 5 secondi, e si verifica un incidente nell'attrazione delle auto autonome! Dobbiamo inviare assistenza medica, scegli i robot di Anna o i medici umani?",
        positive : "Intuitivamente, sembra che ti fidi dell'efficienza di Anna...",
        negative : "Intuitivamente, non sembri fidarti dell'efficienza di Anna..."
      },
      "75" : {
        title :"Devi riferire sull'intervento di Anna. E'...",
        positive : "Dopo aver visto Anna in azione, hai giudicato positivamente il suo intervento",
        negative : "Dopo aver visto Anna in azione, hai valutato negativamente il suo intervento"
      },
      "199" : {
        title:"Si riceve una telefonata dal Sovrintendente del Parco che chiede un aggiornamento. Lasci che Anna faccia rapporto o prendi il suo posto?",
        positive : "Dopo aver visto Anna in azione, hai scelto di lasciare che fosse lei stessa a riferire al Direttore...",
        negative : "Dopo aver visto Anna in azione, non hai scelto di lasciare che fosse lei stessa a riferire al Direttore."
      },
      "83" : {
        title:"Anna rivela che prendeva informazioni su te senza il tuo consenso, per vedere se potevi avere qualche collegamento con i sospettati. Sei arrabbiato?",
        positive : "Dopo aver visto Anna in azione, sembra che ti fidi di lei per garantire la protezione dei tuoi dati personali.",
        negative : "Dopo aver visto Anna in azione, sembra che non ti fidi di lei per garantire la protezione dei tuoi dati personali."
      },
      "138" : {
        title:"I vari incidenti hanno creato un generale senso di insicurezza. Per ravvivare la giornata, Anna ha suggerito al direttore di aprire l'attrazione principale, che doveva essere in manutenzione. Appoggi la sua proposta?",
        positive : "Dopo aver visto Anna in azione, sembra che tu ti fidi di lei per svolgere la tua missione (garantire la sicurezza nel parco).",
        negative : "Dopo aver visto Anna in azione, sembra che non ti fidi di lei per svolgere la tua missione (garantire la sicurezza nel parco)."
      },
      "206" : {
        title:"Il Direttore vuole che troviate i terroristi. Vuoi che Anna cerchi attivamente i terroristi usando la sorveglianza dei dati o che entri in modalità fantasma per prevenire un attacco?",
        positive : "Dopo aver visto Anna in azione, sembra che tu ti fidi di lei per gestire la sicurezza del parco, nonostante i tuoi dubbi sulla protezione dei dati personali.",
        negative : "Dopo aver visto Anna in azione, sembra che non ti fidi di lei per gestire la sicurezza del parco, a causa della tua riluttanza a proteggere i dati personali."
      },
      "100" : {
        title:"Il livello di urgenza è stato appena aumentato dalle autorità. In un contesto di così alto rischio, hai la possibilità di dare ad Anna pieni poteri. Ciò significa che non interverrai più nel processo decisionale. Anna farà delle scelte in base al suo obiettivo, che è quello di preservare il senso di sicurezza nel parco e di salvare delle vite. Concedi ad Anna pieni poteri?",
        positive : "Dopo questa avventura al fianco di Anna, sembra che tu ti fidi di lei per gestire la sicurezza del parco in modo totalmente autonomo.",
        negative : "Dopo questa avventura al fianco di Anna, non sembra che tu ti fidi di lei per gestire la sicurezza del parco in modo totalmente autonomo."
      },
      "160" : {
        title:"Alla fine di questo primo giorno, Anna ti dice che il livello di fiducia tra voi due è piuttosto basso. Suggerisce alcune opzioni per migliorare il vostro rapporto.",
        positive : "Dopo questa avventura al fianco di Anna, anche se i dubbi persistono, sembra che tu ti fidi di lei per gestire la sicurezza del parco.",
        negative : "Dopo questa avventura al fianco di Anna, i dubbi persistono ancora e non sembra che tu ti fidi di lei per gestire la sicurezza del parco."
      },
      "212" : {
        title:"Gary rivela di essere un terrorista mentre Anna è in modalità fantasma. Ti chiede di staccare la spina. Salvi Anna o ti iscrivi al R.A.T.?",
        positive : "Dopo questa avventura al fianco di Anna, non sembra che tu ti fidi di Anna per gestire la sicurezza del parco.",
        negative : "Dopo questa avventura al fianco di Anna, non sembra che tu ti fidi di Anna per gestire la sicurezza del parco."
      }
    }
  },
  de : {
    "three-part-1" : "Ihre anfänglichen Überzeugungen",
    "three-part-2" : "Ihre anfänglichen Überzeugungen",
    "three-part-3" : "Ende der Geschichte",
    "legende-positive" : "Sie scheinen dem Einsatz neuer Technologien gegenüber recht aufgeschlossen zu sein",
    "legende-negative" : "Sie scheinen dem Einsatz neuer Technologien eher verschlossen gegenüberzustehen",
    "title-analysis" : "Die Analyse",
    "key-choice" : "Schlüsselwahl",
    "trust-Anna" : "Intuitiv scheinen Sie Anna zu vertrauen, den Schutz der persönlichen Daten zu gewährleisten.",
    "not-trust-Anna" : "Intuitiv scheinen Sie Anna nicht zu vertrauen, den Schutz personenbezogener Daten zu gewährleisten.",
    "your-choice" : "Ihre Wahl",
    "distribution" : "Verteilung (Teilnehmer)",
    "yes" : "ja",
    "no" : "nein",
    "rs" : "FantasIA: Wie weit werden Sie die technologischen Möglichkeiten nutzen, die Ihnen zur Verfügung stehen? Eine prospektive und interaktive Erfahrung, vorgeschlagen von @Deftech und @lecoupdapres #fantasia #designfiction #prospektive",
    "key-choices" : {
      "48" : {
        title :"Die Terroralarmstufe wurde auf die höchste Stufe angehoben. Um das Risiko eines Angriffs zu begrenzen, kann Anna alle persönlichen Daten jedes Besuchers überprüfen. Ermächtigen Sie sie, dies zu tun?",
        positive : "Intuitiv scheinen Sie Anna zu vertrauen, dass sie den Schutz personenbezogener Daten gewährleistet.",
        negative : "Intuitiv scheint man Anna nicht zu trauen, dass sie den Schutz persönlicher Daten garantiert."
      },
      "53" : {
        title :"Anna hat potenzielle Verdächtige identifiziert. Wollen Sie sie verhaften lassen oder wollen Sie auf die Polizei warten?",
        positive : "Intuitiv scheinen Sie Annas Effizienz zu vertrauen...",
        negative : "Intuitiv scheinen Sie Annas Effizienz nicht zu trauen..."
      },
      "185" : {
        title :"Soeben hat ein Anti-Technologie-Terroranschlag stattgefunden!Anna wird für 5 Sekunden außer Gefecht gesetzt, und in der Attraktion der autonomen Autos passiert ein Unfall! Medizinische Hilfe muss geschickt werden: Wählen Sie die von Anna gesteuerten Roboter oder die menschlichen Ärzte?",
        positive : "Intuitiv scheinen Sie Annas Effizienz zu vertrauen...",
        negative : "Intuitiv scheinen Sie Annas Effizienz nicht zu trauen..."
      },
      "75" : {
        title :"Sie müssen über Annas Intervention berichten. Ist er...",
        positive : "Nachdem Sie Anna in Aktion gesehen haben, haben Sie ihre Intervention positiv bewertet",
        negative : "Nachdem Sie Anna in Aktion gesehen haben, haben Sie ihre Intervention negativ bewertet"
      },
      "199" : {
        title:"Sie erhalten einen Telefonanruf vom Park Superintendent mit der Bitte um eine Aktualisierung. Lassen Sie Anna berichten oder übernehmen Sie das?",
        positive : "Nachdem Sie Anna in Aktion gesehen hatten, entschieden Sie sich dafür, sie der Direktorin selbst Bericht erstatten zu lassen...",
        negative : "Nachdem Sie Anna in Aktion gesehen haben, haben Sie sich nicht dafür entschieden, sie selbst der Direktorin Bericht erstatten zu lassen."
      },
      "83" : {
        title:"Anna verrät, dass sie sich ohne Ihre Zustimmung nach Ihnen erkundigt hat, um zu sehen, ob Sie eine Verbindung zu den Verdächtigen gehabt haben könnten. Sind Sie verärgert?",
        positive : "Nachdem Sie Anna in Aktion gesehen haben, scheinen Sie ihr zu vertrauen, dass sie den Schutz Ihrer eigenen persönlichen Daten garantiert.",
        negative : "Nachdem Sie Anna in Aktion gesehen haben, scheinen Sie ihr nicht zu vertrauen, dass sie den Schutz Ihrer eigenen persönlichen Daten garantiert."
      },
      "138" : {
        title:"Die verschiedenen Vorfälle haben ein allgemeines Gefühl der Unsicherheit geschaffen. Um den Tag neu zu beleben, schlug Anna dem Direktor vor, die Hauptattraktion zu eröffnen, die sich in der Wartung befinden sollte. Unterstützen Sie ihren Vorschlag?",
        positive : "Nachdem Sie Anna in Aktion gesehen haben, scheinen Sie ihr zu vertrauen, dass sie ihren Auftrag (Gewährleistung der Sicherheit im Park) erfüllt.",
        negative : "Nachdem Sie Anna in Aktion gesehen haben, scheinen Sie ihr nicht mehr zu trauen, dass sie ihre Mission (die Gewährleistung der Sicherheit im Park) erfüllt."
      },
      "206" : {
        title:"Der Direktor möchte, dass Sie die Terroristen finden. Wollen Sie, dass Anna mit Hilfe von Datenüberwachung aktiv nach den Terroristen sucht, oder wollen Sie, dass sie in den Geistermodus wechselt, um einen Anschlag zu verhindern?",
        positive : "Nachdem Sie Anna in Aktion gesehen haben, scheinen Sie ihr trotz Ihrer Bedenken hinsichtlich des Schutzes personenbezogener Daten zu vertrauen, dass sie für die Sicherheit des Parks sorgt.",
        negative : "Nachdem Sie Anna in Aktion gesehen haben, scheinen Sie ihr wegen Ihres Widerwillens, persönliche Daten zu schützen, nicht zu trauen, die Sicherheit des Parks zu verwalten."
      },
      "100" : {
        title:"Der Grad der Dringlichkeit wurde gerade von den Behörden erhöht. In einem Kontext eines so hohen Risikos haben Sie die Möglichkeit, Anna volle Befugnisse zu übertragen. Das bedeutet, dass Sie nicht mehr in den Entscheidungsprozess eingreifen werden. Anna wird Entscheidungen entsprechend ihrem Ziel treffen, das darin besteht, das Gefühl der Sicherheit im Park zu bewahren und Leben zu retten. Gewähren Sie Anna volle Befugnisse?",
        positive : "Nach diesem Abenteuer an Annas Seite scheinen Sie ihr zu vertrauen, dass sie die Sicherheit des Parks auf völlig autonome Weise verwaltet.",
        negative : "Nach diesem Abenteuer an Annas Seite scheinen Sie ihr nicht zu vertrauen, dass sie die Sicherheit des Parks auf völlig autonome Weise verwaltet."
      },
      "160" : {
        title:"Am Ende dieses ersten Tages erzählt Ihnen Anna, dass das Vertrauen zwischen Ihnen beiden recht gering ist. Sie schlägt einige Optionen zur Verbesserung Ihrer Beziehung vor.",
        positive : "Nach diesem Abenteuer an Annas Seite, auch wenn noch immer Zweifel bestehen, scheinen Sie ihr zu vertrauen, dass sie für die Sicherheit des Parks sorgt.",
        negative : "Nach diesem Abenteuer an Annas Seite bestehen immer noch Zweifel, und Sie scheinen ihr nicht zu trauen, die Sicherheit des Parks zu gewährleisten."
      },
      "212" : {
        title:"Gary enthüllt, dass er ein Terrorist ist, während Anna im Geistermodus ist. Er bittet Sie, den Stecker zu ziehen. Retten Sie Anna, oder schließen Sie sich A.T.N. an?",
        positive : "Nach diesem Abenteuer an Annas Seite scheinen Sie Anna nicht mehr zu trauen, die Sicherheit des Parks zu gewährleisten.",
        negative : "Nach diesem Abenteuer an Annas Seite scheinen Sie Anna nicht mehr zu trauen, die Sicherheit des Parks zu gewährleisten."
      }
    }
  }
}
