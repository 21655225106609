export const traductionsEnd = {
  en : {
    "title" : "Thank you for participating in this experience.",
    "age" : "How old are you?",
    "where" : "Where do you live (country)?",
    "studiesLevel" : "Number of years of university studies?",
    "score" : "How much did you enjoy the experience (1 is awful / 10 is terrific)?",
    "text" : "<p>Before having access to your restitution and the results of the study, we would need to know some additional information about you. All this data is anonymized and will not be kept beyond the end of the study.<br/>This is optional, you are not obliged to answer it, but these elements will allow us to go further in our study.</p>",
    "techno-acceptance" : "Do you think you have made open or closed choices to the use of new technologies?",
    "free-text" : "Please let us know more about your experience.",
    "majority" : "In your opinion, are your choices close to the majority of the other participants?",
    "emailIntro" : "I would like to be informed when the results of the study are available.",
    "email" : "Your email :",
    "closed" : 'Closed',
    "open" : 'Open',
    "yes" : "Yes",
    "no" : "No",
    "emailConsent" : "Thank you to send me the recommended sources by email.",
    "thanks" : "<p>Thank you for your participation.<br/>You will now be able to access the restitution of your experience.</p>"
  },
  fr : {
    "title" : "Merci d’avoir participé à cette expérience.",
    "age" : "Quel âge avez-vous ?",
    "where" : "Où habitez-vous (pays) ?",
    "studiesLevel" : "Nombre d'années d'études universitaires",
    "score" : "Dans quelle mesure avez-vous apprécié l’expérience (1 - catastrophique / 10 - merveilleux) ?",
    "text" : "<p>Avant d’avoir accès à votre restitution et aux résultats de l’étude, nous aurions besoin de connaître quelques infos supplémentaires vous concernant. Ces données seront anonymisées et ne seront pas conservées à la fin de l’étude.<br/>Ces éléments nous permettront d’aller plus loin dans notre étude, mais cette étape est facultative, vous pouvez la sauter et passer directement à la suite si vous ne souhaitez pas répondre.</p>",
    "techno-acceptance" : "Pensez-vous avoir globalement fait des choix ouverts ou fermés à l’usage des nouvelles technologies ?",
    "free-text" : "Dites-nous en plus sur ce que vous avez pensé de l’expérience.",
    "majority" : "D'après vous, vos choix sont-ils proches de la majorité des autres participants ?",
    "emailIntro" : "J’aimerais être informé·e lorsque les résultats de l’étude seront disponibles",
    "email" : "Votre email :",
    "closed" : 'Fermé',
    "open" : 'Ouvert',
    "yes" : "Oui",
    "no" : "Non",
    "emailConsent" : "Merci de m’envoyer les sources conseillées par mail",
    "thanks" : "<p>Merci pour votre participation.<br/>Vous allez maintenant avoir accès à la restitution de votre expérience.</p>"
  },
  it : {
    "title" : "Grazie per aver partecipato a questa esperienza.",
    "age" : "Quanti anni hai?",
    "where" : "Dove vivi (paese)?",
    "studiesLevel" : "Numero di anni di studi universitari?",
    "score" : "Quanto ti è piaciuta l'esperienza (1 - catastrofica / 10 - meravigliosa)?",
    "text" : "<p>Prima di avere accesso al tuo feedback e ai risultati dello studio, avremmo bisogno di conoscere alcune informazioni aggiuntive su di te. Questi dati saranno resi anonimi e non saranno conservati dopo la fine dello studio.<br/>Questi elementi ci permetteranno di andare oltre nel nostro studio,  in ogni caso questo passo è facoltativo, se non vuoi rispondere puoi saltarlo e andare direttamente al successivo.</p>",
    "techno-acceptance" : "In generale credi di aver fatto scelte a favore o a sfavore delle nuove tecnologie ?",
    "free-text" : "Raccontaci di più su quello che hai pensato dell'esperienza",
    "majority" : "Secondo te, le sue scelte sono vicine alla maggioranza degli altri partecipanti?",
    "emailIntro" : "Vorrei essere informato quando saranno disponibili i risultati dello studio.",
    "email" : "La tua e-mail :",
    "closed" : 'Aperto',
    "open" : 'Chiuso',
    "yes" : "Si",
    "no" : "No",
    "emailConsent" : "Grazie per avermi inviato le fonti raccomandate via e-mail",
    "thanks" : "<p>Grazie per la tua partecipazione.<br/>Avrai adesso accesso al resoconto della tua esperienza.</p>"
  },
  de : {
    "title" : "Danke, dass Sie an dieser Erfahrung teilhaben.",
    "age" : "Wie alt sind Sie?",
    "where" : "Wo wohnen Sie (Land)?",
    "studiesLevel" : "Anzahl der Jahre des Universitätsstudiums?",
    "score" : "Wie sehr haben Sie diese Erfahrung genossen (1 - katastrophal / 10 - wunderbar)?",
    "text" : "<p>Bevor wir Zugang zu Ihrem Feedback und den Ergebnissen der Studie erhalten, benötigen wir einige zusätzliche Informationen über Sie. Diese Daten werden anonymisiert und am Ende der Studie nicht aufbewahrt.<br/>Diese Elemente werden es uns ermöglichen, in unserer Studie weiter zu gehen, aber dieser Schritt ist optional, Sie können ihn überspringen und direkt zum nächsten Schritt übergehen, wenn Sie nicht antworten möchten.</p>",
    "techno-acceptance" : "Glauben Sie, dass Sie im Allgemeinen offene oder geschlossene Entscheidungen in Bezug auf den Einsatz neuer Technologien getroffen haben?",
    "free-text" : "Erzählen Sie uns mehr darüber, was Sie von dieser Erfahrung gehalten haben",
    "majority" : "Liegt Ihre Wahl Ihrer Meinung nach nahe an der Mehrheit der anderen Teilnehmer?",
    "emailIntro" : "Ich möchte informiert werden, wenn die Ergebnisse der Studie vorliegen.",
    "email" : "Ihre E-Mail :",
    "closed" : 'Offen',
    "open" : 'Geschlossen',
    "yes" : "Ja",
    "no" : "Nein",
    "emailConsent" : "Danke, dass Sie mir die empfohlenen Quellen per E-Mail zusenden",
    "thanks" : "<p>Danke für Ihre Teilnahme.<br/>Sie haben nun Zugang zur Rückgabe Ihrer Erfahrung.</p>"

  }
}
