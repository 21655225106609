// background : {
//   src : null,
//   type : 'video'
// },

const story = [
  {
    id : 1,
    audio : {
      fr : "/blocs/bloc01.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bonjour. Bienvenue au Poste de sécurité du parc Fantasia !</p>",
      en : "<p>ANNA : Hello. Welcome to the Fantasia Park Security Station !</p>",
      it : "<p>ANNA : Buongiorno. Benvenuti alla Centrale di Sicurezza del Parco Fantasia !</p>",
      de : "<p>ANNA : Guten Tag. Willkommen in der Kontrollstation des Fantasia Parks !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 2
  },
  {
    id : 2,
    audio : {
      fr : "/blocs/bloc02.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Avant toute chose, est-ce que tu m'entends bien ?</p>",
      en : "<p>ANNA : How are you today ?</p>",
      it : "<p>ANNA : Come stai oggi ?</p>",
      de : "<p>ANNA : Wie geht's Dir heute ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, 5/5.</p>",
          en : "<p>Fine, thank you.</p>",
          it : "<p>Bene, grazie.</p>",
          de : "<p>Gut, danke.</p>"
        },
        next : 3,
      },
      {
        answer : {
          fr : "<p>J’ai du mal à t’entendre.</p>",
          en : "<p>I've known better.</p>",
          it : "<p>Ho conosciuto meglio.</p>",
          de : "<p>Es gab schon bessere Tage.</p>"
        },
        next : 4,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : null
  },
  {
    id : 3,
    audio : {
      fr : "/blocs/bloc03.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Parfait. On ne peut pas se voir, alors autant s’entendre bien !</p>",
      en : "<p>ANNA : Good, let's get started !</p>",
      it : "<p>ANNA : Bene, cominciamo !</p>",
      de : "<p>ANNA : Gut, dann fangen wir mal an !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 5
  },
  {
    id : 4,
    audio : {
      fr : "/blocs/bloc04.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Alors prends le temps de vérifier la connexion de tes écouteurs ou de ton casque, et ajuste peut-être le volume. Il est important que tu m’entendes parfaitement pour qu’on coopère correctement.</p>",
      en : "<p>ANNA : Sorry to hear that! I hope you are ready for the day though...</p>",
      it : "<p>ANNA : Mi dispiace! Spero che tu sia pronto per la giornata, però...</p>",
      de : "<p>ANNA : Tut mir leid, das zu hören! Ich hoffe, du bist trotzdem bereit für den Tag...</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je suis pret !</p>",
          en : "<p>I'm ready !</p>",
          it : "<p>Sono pronto !</p>",
          de : "<p>Ich bin bereit !</p>"
        },
        next : 5,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : null
  },
  {
    id : 5,
    audio : {
      fr : "/blocs/bloc05.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Je suis Anna, la responsable sécurité du parc. C’est moi qui vais te guider pour cette première journée. Alors installe-toi tranquillement. Je suis avec Gary, notre chef des agents sur le terrain, qui est dans le parc actuellement. On fait notre tour d’inspection matinal des attractions avant l’ouverture. On a repéré une légère anomalie sur des attractions, mais on s’en occupera après. Rien de grave !</p>",
      en : "<p>ANNA : I'm Anna, the park's security manager. I'm going to guide you through this first day. Make yourself comfortable. I'm with Gary, our chief field officer, who is in the park right now. We're doing our morning tour of inspection of the attractions before the opening. We've spotted a slight anomaly on some of the attractions, but we'll take care of it afterwards. Nothing serious!</p>",
      it : "<p>ANNA : Sono Anna, l'addetta alla sicurezza del parco. Ti guiderò nel tuo primo giorno. Sistemati tranquillamente. Sono qui con Gary, il nostro agente capo sul campo, che in questo momento è nel parco. Faremo il nostro tour d'ispezione mattutino delle giostre prima della loro apertura. Abbiamo notato una leggera anomalia su alcune delle giostre, ma ce ne occuperemo dopo. Non è niente di grave.</p>",
      de : "<p>ANNA : Ich bin Anna, die Sicherheitsbeauftragte des Parks. Ich werde dich durch diesen ersten Tag führen. Richte Dich erstmal ein! Ich bin mit Gary, unserem Einsatzleiter, der sich gerade im Park befindet. Wir machen unsere morgendliche Runde um die Attraktionen zu besichtigen, bevor sie öffnen. Wir haben bei einigen Attraktionen eine kleine Anomalie festgestellt, aber wir werden uns danach darum kümmern. Es ist nicht schlimm.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 6
  },
  {
    id : 6,
    audio : {
      fr : "/blocs/bloc06.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : A toi ! Dis m’en un peu plus...</p>",
      en : "<p>ANNA : Your turn ! Tell me a little more...</p>",
      it : "<p>ANNA : Tocca a te ! Dimmi un po' di più...</p>",
      de : "<p>ANNA : Du bist dran ! Erzähle mir ein bisschen mehr...</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je suis un homme.</p>",
          en : "<p>I am a man.</p>",
          it : "<p>Sono un uomo.</p>",
          de : "<p>Ich bin ein Mann.</p>"
        },
        next : 7,
      },
      {
        answer : {
          fr : "<p>Je suis une femme.</p>",
          en : "<p>I am a woman.</p>",
          it : "<p>Sono una donna.</p>",
          de : "<p>Ich bin eine Frau.</p>"
        },
        next : 8,
      },
      {
        answer : {
          fr : "<p>Autre</p>",
          en : "<p>Other</p>",
          it : "<p>Altro</p>",
          de : "<p>Andere</p>"
        },
        next : 9,
      },
      {
        answer : {
          fr : "<p>Je ne préfère pas te donner cette info</p>",
          en : "<p>I prefer not to give you this info</p>",
          it : "<p>Preferirei non darti questa informazione</p>",
          de : "<p>Diese Information möchte ich lieber nicht teilen.</p>"
        },
        next : 9,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : null
  },
  {
    id : 7,
    audio : {
      fr : "/blocs/bloc07.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Super ! Ma dernière équipière était une femme, j’aime bien alterner. Rassure-toi, je fais autant confiance aux hommes qu’aux femmes !</p>",
      en : "<p>ANNA : Great ! My last partner was a woman, I like to alternate. Don't worry, I trust men as much as women !</p>",
      it : "<p>ANNA : Grande ! Il mio ultimo partner era una donna, mi piace alternare. Non ti preoccupare, mi fido sia degli uomini che delle donne !</p>",
      de : "<p>ANNA : Grossartig ! Mein letzter Partner war eine Frau, ich wechsle gerne ab. Keine Sorge, ich vertraue Männern genauso viel wie Frauen !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 10
  },
  {
    id : 8,
    audio : {
      fr : "/blocs/bloc08.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Super ! On va faire une équipe de choc.</p>",
      en : "<p>ANNA : Awesome! We're going to make a great team.</p>",
      it : "<p>ANNA : Grande! Saremo una grande squadra.</p>",
      de : "<p>ANNA : Grossartig! Wir werden ein grossartiges Team sein.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 10
  },
  {
    id : 9,
    audio : {
      fr : "/blocs/bloc09.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : OK, j’en prends note.</p>",
      en : "<p>ANNA : Okay, I'll make a note of that.</p>",
      it : "<p>ANNA : Ok, ne prendo nota.</p>",
      de : "<p>ANNA : Okay, das werde ich mir notieren.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 10
  },
  {
    id : 10,
    audio : {
      fr : "/blocs/bloc10.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Ha, une dernière chose importante…</p>",
      en : "<p>ANNA : Ha, one last important thing...</p>",
      it : "<p>ANNA : Ah, un'ultima cosa importante ...</p>",
      de : "<p>ANNA : Ha, eine letzte wichtige Frage...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 11
  },
  {
    id : 11,
    audio : {
      fr : "/blocs/bloc11.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Es-tu ou as-tu été militaire de profession ?</p>",
      en : "<p>ANNA : Are you or have you ever been a professional soldier ?</p>",
      it : "<p>ANNA : Sei o sei stato un soldato professionista ?</p>",
      de : "<p>ANNA : Bist du oder warst du Berufsmilitär ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui.</p>",
          en : "<p>Yes.</p>",
          it : "<p>Sì.</p>",
          de : "<p>Ja.</p>"
        },
        next : 12,
      },
      {
        answer : {
          fr : "<p>Non.</p>",
          en : "<p>No.</p>",
          it : "<p>No.</p>",
          de : "<p>Nein.</p>"
        },
        next : 13,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : null
  },
  {
    id : 12,
    audio : {
      fr : "/blocs/bloc12.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Parfait, on est entre pros !</p>",
      en : "<p>ANNA : Perfect, we're among professionals!</p>",
      it : "<p>ANNA : Perfetto, siamo tra professionisti!</p>",
      de : "<p>ANNA : Perfekt, wir sind also alle Profis!</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 14
  },
  {
    id : 13,
    audio : {
      fr : "/blocs/bloc13.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Alors je vais te rassurer tout de suite : si tu n’es pas familier des protocoles de sécurité, ou même si tu n’as jamais mis les pieds dans un parc d’attractions, ce n’est pas grave.</p>",
      en : "<p>ANNA : So I'll reassure you right away: if you're not familiar with safety protocols, or even if you've never been to an amusement park, it's okay.</p>",
      it : "<p>ANNA : Quindi ti rassicuro subito: se non hai familiarità con i protocolli di sicurezza, o se non sei mai stato·a in un parco di giostre, non è grave.</p>",
      de : "<p>ANNA : Ich kann dich also gleich beruhigen. Wenn du mit den Sicherheitsprotokollen nicht vertraut bist, oder auch wenn du noch nie in einem Vergnügungspark warst, macht das nichts.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 14
  },
  {
    id : 14,
    audio : {
      fr : "/blocs/bloc14.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Voilà, on apprend à se connaître ! On va former une super équipe, je suis contente !</p>",
      en : "<p>ANNA : That's it, we get to know each other ! We're going to form a great team, I'm happy !</p>",
      it : "<p>ANNA : Ora ci stiamo conoscendo ! Faremo una grande squadra, sono felice !</p>",
      de : "<p>ANNA : Jetzt lernen wir uns etwas kennen ! Wir werden ein tolles Team sein, ich bin zufrieden !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 15
  },
  {
    id : 15,
    audio : {
      fr : "/blocs/bloc15.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et toi, comment te sens-tu ? Pas trop stressé.e ?</p>",
      en : "<p>ANNA : How do you feel ? Not too stressed ?</p>",
      it : "<p>ANNA : Come ti senti ? Non sei troppo stressato·a ?</p>",
      de : "<p>ANNA : Wie fühlst du dich ? Nicht zu gestresst ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>J’appréhende un peu.</p>",
          en : "<p>I'm a little apprehensive.</p>",
          it : "<p>Sono un po' preoccupato·a.</p>",
          de : "<p>Ich bin ein wenig beunruhigt.</p>"
        },
        next : 16,
      },
      {
        answer : {
          fr : "<p>Je me sens bien.</p>",
          en : "<p>I feel good.</p>",
          it : "<p>Mi sento bene.</p>",
          de : "<p>Ich fühle mich gut.</p>"
        },
        next : 17,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : null
  },
  {
    id : 16,
    audio : {
      fr : "/blocs/bloc16.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Rassure-toi. La grande majorité des personnes ayant occupé le fauteuil dans lequel tu as pris place n’avait même aucune connaissance sur le sujet.</p>",
      en : "<p>ANNA : Don't worry. The vast majority of the people who sat in the chair in which you took place did not even know anything about the topic.</p>",
      it : "<p>ANNA : Non preoccuparti. La grande maggioranza delle persone che si sono sedute al posto dove ti trovi adesso non ne sapeva nulla.</p>",
      de : "<p>ANNA : Mach dir keine Sorgen. Die überwiegende Mehrheit der Menschen auf dem Stuhl, auf dem du nun sitzt, wusste auch nichts darüber.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 18
  },
  {
    id : 17,
    audio : {
      fr : "/blocs/bloc17.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Parfait ! Je l’avais senti, mais je suis contente de l’entendre.</p>",
      en : "<p>ANNA : Perfect! I felt it, but I'm glad to hear it.</p>",
      it : "<p>ANNA : Perfetto! L'avevo percepito, ma sono contenta di sentirlo.</p>",
      de : "<p>ANNA : Perfekt! Ich habe es gespürt, aber es freut mich zu hören.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 18
  },
  {
    id : 18,
    audio : {
      fr : "/blocs/bloc18.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Comme je te le disais, on va travailler en équipe à partir d’aujourd’hui. Notre mission, c’est d’assurer la sécurité du parc Fantasia à tout moment de la journée.</p>",
      en : "<p>ANNA : As I was telling you, starting today, we're going to work as a team. Our mission is to ensure the safety of Fantasia Park at all time.</p>",
      it : "<p>ANNA : Come ti ho detto, a partire da oggi lavoreremo in squadra. La nostra missione è quella di mantenere il Parco Fantasia al sicuro in ogni momento della giornata.</p>",
      de : "<p>ANNA : Wie bereits erwähnt, werden wir ab heute als Team arbeiten. Unsere Aufgabe ist die Sicherheit des Fantasia Parks zu jeder Tageszeit zu gewährleisten.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 19
  },
  {
    id : 19,
    audio : {
      fr : "/blocs/bloc19.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Au fait, connaissais-tu le parc Fantasia avant de venir ce matin ?</p>",
      en : "<p>ANNA : By the way, did you know Fantasia Park before coming this morning?</p>",
      it : "<p>ANNA : A proposito, sapevi del Fantasia Park prima di venire stamattina?</p>",
      de : "<p>ANNA : Übrigens, kanntest du den Fantasia Park bereits, bevor du heute Morgen gekommen bist?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Non, je n’en avais jamais entendu parler. Dis m’en un peu plus.</p>",
          en : "<p>No, I had never heard of it. Tell me a bit more.</p>",
          it : "<p>No, non ne avevo mai sentito parlare. Raccontami un po' di più.</p>",
          de : "<p>Nein, davon hatte ich noch nie gehört. Erzähle mir ein wenig mehr.</p>"
        },
        next : 20,
      },
      {
        answer : {
          fr : "<p>Oui, je connais Fantasia.</p>",
          en : "<p>Yes, I know Fantasia.</p>",
          it : "<p>Si', conosco Fantasia.</p>",
          de : "<p>Ja, ich kenne Fantasia.</p>"
        },
        next : 21,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : null
  },
  {
    id : 20,
    audio : {
      fr : "/blocs/bloc20.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu vas voir, Fantasia c’est un lieu unique en son genre, qui s’inspire de l’âge d’or des parcs d’attractions qui existaient au 20ème siècle : montagnes russes, tirs à la carabine, auto-tamponneuses,... Bon évidemment, tout ça surveillé par les technologies de notre époque, histoire de nous simplifier un peu la tâche.</p>",
      en : "<p>ANNA : You'll see, Fantasia is a unique place, inspired by the golden age of amusement parks that existed in the 20th century: roller coasters, rifle shooting, bumper cars, ... Of course, all of this monitored by the technologies of our time, just to simplify our task a little.</p>",
      it : "<p>ANNA : Vedrai, Fantasia è un luogo unico, ispirato all'epoca d'oro dei parchi giochi che esistevano nel XX secolo: montagne russe, tiro con il fucile, autoscontri, ... Beh, ovviamente, tutto questo è monitorato dalla tecnologia del nostro tempo, giusto per rendere il nostro lavoro un po' più facile.</p>",
      de : "<p>ANNA : Fantasia ist ein einzigartiger Ort, der von der Blütezeit der Freizeitparks im 20. Jahrhundert inspiriert wurde: Achterbahnen, Schiessbuden, Autoscooter, ... Natürlich werden alle Attraktionen heute durch die Technologien unserer Zeit überwacht, um unsere Aufgabe zu vereinfachen.</p>"
    },
    background : {
      src : "Video AA - ambiance_generale_1.mp4",
      type : 'video'
    },
    next : 22
  },
  {
    id : 21,
    audio : {
      fr : "/blocs/bloc21.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Parfait ! On va gagner du temps. Comme ça je n’ai pas à te rappeler que Fantasia est un lieu unique en son genre, qui s’inspire de l’âge d’or des parcs d’attractions qui existaient au 20ème siècle : montagnes russes, tirs à la carabine, auto-tamponneuses, ... tout ça surveillé par les technologies en sécurité de notre époque, histoire de nous simplifier un peu la tâche.</p>",
      en : "<p>ANNA : Perfect! We'll save time. That way I don't have to remind you that Fantasia is a unique place, inspired by the golden age of amusement parks that existed in the 20th century: roller coasters, rifle shooting, bumper cars, ... all monitored by the security technologies of our time, just to make our job a little easier.</p>",
      it : "<p>ANNA : Perfetto! Risparmieremo tempo. Quindi non c'è bisogno di ricordarti che Fantasia è un luogo unico, ispirato all'epoca d'oro dei parchi di divertimento che esistevano nel XX secolo: montagne russe, tiro con il fucile, autoscontri, ... tutto supervisionato dalle tecnologie di sicurezza del nostro tempo, solo per rendere il nostro lavoro un po' più facile.</p>",
      de : "<p> (ANNA): Perfekt! Also weisst du schon, dass Fantasia ein einzigartiger Ort ist, der von der Blütezeit der Freizeitparks im 20. Jahrhundert inspiriert wurde: Achterbahnen, Schiessbuden, Autoscooter, ... Natürlich werden alle Attraktionen heute durch die Technologien unserer Zeit überwacht, um unsere Aufgabe zu vereinfachen.</p>"
    },
    background : {
      src : "Video AA - ambiance_generale_1.mp4",
      type : 'video'
    },
    next : 22
  },
  {
    id : 22,
    audio : {
      fr : "/blocs/bloc22.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v3.mp3"
    },
    text : {
      fr : "<p>ANNA : Donc toi et moi, on doit veiller à ce que tous les aspects de la sécurité du parc soient optimaux : mécanique, sanitaire, etc. C’est pour cela que tu as été engagé.e.</p>",
      en : "<p>ANNA : So you and I have to make sure that all aspects of the park's safety are optimal: mechanical, sanitary, etc. That's why you were hired.</p>",
      it : "<p>ANNA : Quindi io e te dobbiamo assicurarci che tutti gli aspetti della sicurezza del parco siano ottimali: meccanici, sanitari, ecc. Ecco perché sei stato assunto·a.</p>",
      de : "<p>ANNA : Wir müssen also dafür sorgen, dass alle Aspekte der Sicherheit des Parks optimal sind: Mechanik, sanitäre Einrichtungen, usw. Dafür wurdest du eingestellt.</p>"
    },
    background : {
      src : "Video AA - ambiance_generale_1.mp4",
      type : 'video'
    },
    next : 23
  },
  {
    id : 23,
    audio : {
      fr : "/blocs/bloc23.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et c’est pour cela que j’ai été programmée.</p>",
      en : "<p>ANNA : And that’s what I was programmed for.</p>",
      it : "<p>ANNA : Ed è per questo che sono stata programmata.</p>",
      de : "<p>ANNA : Und dafür wurde ich programmiert.</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Programmée ? Comment ça ?</p>",
          en : "<p>Programmed? What do you mean, programmed?</p>",
          it : "<p>Programmata? Cosa intendi con \"programmata\"?</p>",
          de : "<p>Programmiert? Was meinst du mit \"programmiert\"?</p>"
        },
        next : 24,
      },
      {
        answer : {
          fr : "<p>Je me disais bien qu’il y avait un truc étrange...</p>",
          en : "<p>I thought there was something strange...</p>",
          it : "<p>Pensavo ci fosse qualcosa di strano…</p>",
          de : "<p>Ich dachte schon, dass da etwas seltsam war...</p>"
        },
        next : 25,
      }
    ],
    background : {
      src : "Video AA - ambiance_generale_1.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 24,
    audio : {
      fr : "/blocs/bloc24.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et oui, je ne suis pas humaine contrairement à toi. Je suis une Intelligence Artificielle, développée pour assurer la sécurité des établissements recevant du public, comme le Parc Fantasia.</p>",
      en : "<p>ANNA : And yes, I'm not human, unlike you. I'm an Artificial Intelligence, developed to ensure the safety of establishments that receive the public, such as Fantasia Park.</p>",
      it : "<p>ANNA : E sì, non sono umana a differenza di te. Sono un'intelligenza artificiale, sviluppata per garantire la sicurezza di strutture pubbliche come il Parco Fantasia.</p>",
      de : "<p>ANNA : Ja. Im Gegensatz zu dir bin ich kein Mensch. Ich bin eine künstliche Intelligenz, die entwickelt wurde, um in öffentlichen Einrichtungen wie dem Fantasia Park für Sicherheit zu sorgen.</p>"
    },
    background : {
      src : "Video AA - ambiance_generale_1.mp4",
      type : 'video'
    },
    next : 26
  },
  {
    id : 25,
    audio : {
      fr : "/blocs/bloc25.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et oui, bien vu, je suis une Intelligence Artificielle ! J'ai été développée pour assurer la sécurité des établissements recevant du public, comme le Parc Fantasia.</p>",
      en : "<p>ANNA : And yes, good point, I'm an Artificial Intelligence! I was developed to ensure the security of establishments open to the public, such as Fantasia Park.</p>",
      it : "<p>ANNA : E sì, buona osservazione, sono un'Intelligenza Artificiale! Sono stata sviluppata per garantire la sicurezza degli stabilimenti aperti al pubblico, come il Parco Fantasia.</p>",
      de : "<p>ANNA : Und ja, gut erkannt, ich bin eine künstliche Intelligenz! Ich wurde entwickelt, um die Sicherheit von Einrichtungen zu gewährleisten, die der Öffentlichkeit zugänglich sind, wie zum Beispiel der Fantasia Park.</p>"
    },
    background : {
      src : "Video AA - ambiance_generale_1.mp4",
      type : 'video'
    },
    next : 26
  },
  {
    id : 26,
    audio : {
      fr : "/blocs/bloc26.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’espère que travailler avec une intelligence artificielle ne te fait pas peur… c’est quoi ton rapport à la technologie ?</p>",
      en : "<p>ANNA : I hope that working with an artificial intelligence doesn't scare you... what is your relationship to technology ?</p>",
      it : "<p>ANNA : Spero che lavorare con una intelligenza artificiale non ti spaventi... qual è il tuo rapporto con la tecnologia ?</p>",
      de : "<p>ANNA : Ich hoffe, die Arbeit mit einer künstlicher Intelligenz macht dir keine Angst... wie ist deine Beziehung zu Technologie ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je ferais planter un ordinateur rien qu’en le regardant.</p>",
          en : "<p>I would make a computer crash just by looking at it.</p>",
          it : "<p>Appena sfioro il computer si impalla</p>",
          de : "<p>Ich kann einen Computer zum Absturz bringen, indem ich ihn nur ansehe.</p>"
        },
        next : 27,
      },
      {
        answer : {
          fr : "<p>Je sais me servir de mon smartphone et de mon ordi, mais sans plus.</p>",
          en : "<p>I know how to use my smartphone and my computer, but nothing more.</p>",
          it : "<p>So come usare il mio smartphone e il mio computer, ma niente di più.</p>",
          de : "<p>Ich kann mein Smartphone und Computer bedienen, aber mehr nicht.</p>"
        },
        next : 28,
      },
      {
        answer : {
          fr : "<p>Je suis complètement geek.</p>",
          en : "<p>I'm a total geek.</p>",
          it : "<p>Sono un vero e proprio nerd.</p>",
          de : "<p>Ich bin ein totaler Computerfreak.</p>"
        },
        next : 29,
      }
    ],
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 27,
    audio : {
      fr : "/blocs/bloc27.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Super nouvelle ! Si j’ai bien fait mon travail, tu n'avais peut-être même pas remarqué que j’étais artificielle.</p>",
      en : "<p>ANNA : Great news ! If I I've done my job well, you may not even have noticed that I'm artificial.</p>",
      it : "<p>ANNA : Grandi notizie ! Se ho fatto bene il mio lavoro, forse non ti sarai nemmeno accorto·a che sono artificiale.</p>",
      de : "<p>ANNA : Grossartige Neuigkeiten ! Wenn ich meine Arbeit gut gemacht habe, hast du vielleicht nicht bemerkt, dass ich künstlich bin.</p>"
    },
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : 30
  },
  {
    id : 28,
    audio : {
      fr : "/blocs/bloc28.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Il ne t’en faudra pas plus. Si tu sais appuyer sur un bouton, tu sais interagir avec moi.</p>",
      en : "<p>ANNA : You won't need much more. If you know how to press a button, you know how to interact with me.</p>",
      it : "<p>ANNA : Non avrai bisogno di più spiegazioni. Se sai come premere un pulsante, sai come interagire con me.</p>",
      de : "<p>ANNA : Mehr brauchst du nicht. Wenn du weisst, wie man einen Knopf drückt, weisst du, wie du mit mir interagieren kannst.</p>"
    },
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : 30
  },
  {
    id : 29,
    audio : {
      fr : "/blocs/bloc29.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Parfait. Nous étions faits pour nous entendre !</p>",
      en : "<p>ANNA : Perfect. We were meant to get along !</p>",
      it : "<p>ANNA : Perfetto. Eravamo destinati ad andare d'accordo !</p>",
      de : "<p>ANNA : Perfekt. Wir sind für einander bestimmt !</p>"
    },
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : 30
  },
  {
    id : 30,
    audio : {
      fr : "/blocs/bloc30.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ta mission consiste à me donner ton avis quand mon taux de confiance est trop bas pour prendre une décision. Pour résumer, il te suffit d’être… humain. Quand je ferai appel à toi pour m’éclairer, il n’y aura jamais de bonne ou de mauvaise réponse. Il te faudra juste prendre la décision que te dicte ta conscience.</p>",
      en : "<p>ANNA : Your job is to give me your opinion when my confidence level is too low to make a decision. In short, you just have to be... human. When I call on you for advice, there will never be a right or wrong answer. You will just have to make the decision that your conscience dictates.</p>",
      it : "<p>ANNA : Il tuo compito è quello di darmi i tuoi consigli quando il mio livello di fiducia è troppo basso per prendere una decisione. In breve, tutto quello che devi fare è essere... umano. Quando ti chiedo una consulenza, non ci sarà mai una risposta giusta o sbagliata. Dovrai solo prendere una decisione basata sulla tua coscienza.</p>",
      de : "<p>ANNA : Deine Aufgabe ist es, mir deinen Rat zu geben, wenn mein Konfidenzintervall zu gering ist, um eine Entscheidung zu treffen. Kurz gesagt, alles, was du tun musst, ist... menschlich zu sein. Wenn ich dich um Rat frage, wird es nie eine richtige oder falsche Antwort geben. Du musst einfach eine Entscheidung treffen, die auf deinem Gewissen beruht.</p>"
    },
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : 31
  },
  {
    id : 31,
    audio : {
      fr : "/blocs/bloc31.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Est-ce que tout est clair pour toi ?</p>",
      en : "<p>ANNA : Is everything clear to you ?</p>",
      it : "<p>ANNA : È tutto chiaro per te ?</p>",
      de : "<p>ANNA : Ist dir alles klar ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Limpide !</p>",
          en : "<p>Crystal clear !</p>",
          it : "<p>Tutto chiaro !</p>",
          de : "<p>Sehr klar !</p>"
        },
        next : 32,
      },
      {
        answer : {
          fr : "<p>Je n’ai pas très bien compris mon rôle.</p>",
          en : "<p>I haven't quite understood my role.</p>",
          it : "<p>Non ho capito bene il mio ruolo.</p>",
          de : "<p>Ich habe meine Rolle nicht ganz verstanden.</p>"
        },
        next : 33,
      }
    ],
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 32,
    audio : {
      fr : "/blocs/bloc32.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Parfait ! Voilà une journée qui commence bien.</p>",
      en : "<p>ANNA : Perfect! This is a good start to the day.</p>",
      it : "<p>ANNA : Perfetto! Ecco una giornata che inizia bene.</p>",
      de : "<p>ANNA : Perfekt! Das ist ein guter Start in den Tag!</p>"
    },
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : 34
  },
  {
    id : 33,
    audio : {
      fr : "/blocs/bloc33.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Pour prendre certaines décisions, il m’arrive d’avoir besoin d’un avis simplement humain. Certains choix que je peux faire sont parfois jugés trop… rationnels. Je te demanderai alors de trancher entre plusieurs propositions, et tu n’auras qu’à choisir l’option que tu juges la meilleure. Tout simplement.</p>",
      en : "<p>ANNA : To make certain decisions, I sometimes just need a human opinion. Some of the choices I can make are sometimes considered too... rational. I will ask you to decide between several options, and you will only have to choose the one you think is the best. It's as simple as that.</p>",
      it : "<p>ANNA : Per prendere certe decisioni, a volte ho bisogno semplicemente di un consiglio umano. Alcune scelte che posso prendere sono talvolta considerate troppo... razionali. Ti chiederò di decidere tra diverse opzioni, e tu dovrai solo scegliere quella che pensi sia la migliore. Questo è tutto.</p>",
      de : "<p>ANNA : Um bestimmte Entscheidungen zu treffen, brauche ich manchmal nur menschlichen Rat. Einige Entscheidungen, die ich treffen kann, werden manchmal als zu... rational angesehen. Ich werde dich bitten, dich zwischen mehreren Optionen zu entscheiden, und du musst nur diejenige wählen, die du für die Beste hältst. Ganz einfach.</p>"
    },
    background : {
      src : "Video AB - ambiance_generale_2.mp4",
      type : 'video'
    },
    next : 34
  },
  {
    id : 34,
    audio : {
      fr : "/blocs/bloc34.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-42.mp3"
    },
    text : {
      fr : "<p>ANNA : Ah, on dirait que la journée commence pour nous. Je viens de détecter une anomalie mécanique sur une des attractions préférées des enfants, la Mare aux Canards. Rien de grave, mais il faut qu’on répare ça avant l’arrivée des bambins ! Tiens, c’est une bonne occasion de tester notre équipe de choc. Pour réparer la panne, il me paraît plus efficace d'envoyer un robot, plutôt que de signaler l’incident à notre équipe de techniciens, de bons vieux humains comme toi.</p>",
      en : "<p>ANNA : Ah, it looks like the day is starting for us. I have just detected a mechanical anomaly on one of the kids' favourite attractions, the Duck Pond. Nothing serious, but we have to fix it before the kids arrive! Here's a good opportunity to test our shock team. To repair the breakdown, it seems to me more efficient to send a robot, rather than reporting the incident to our team of technicians, good old humans like you.</p>",
      it : "<p>ANNA : Ah, sembra che il giorno stia iniziando per noi. Ho appena rilevato un'anomalia meccanica su una delle attrazioni preferite dai bambini, il Duck Pond. Niente di grave, ma dobbiamo risolvere il problema prima che arrivino i bambini! Beh, questa è una buona occasione per mettere alla prova la nostra squadra di shock. Per riparare il guasto, penso che sia più efficiente inviare un robot, piuttosto che segnalare l'incidente al nostro team di tecnici, buoni vecchi umani come te.</p>",
      de : "<p>ANNA : Ah, jetzt scheint es los zugehen. Ich habe gerade eine mechanische Anomalie an einer der Lieblingsattraktionen der Kinder entdeckt: dem Ententeich. Nichts Ernstes, aber wir müssen das in Ordnung bringen, bevor die Kinder kommen! Nun, dies ist eine gute Gelegenheit um unser Team zu testen. Um die Panne zu beheben, halte ich es für effizienter, einen Roboter zu schicken, als den Vorfall unserem Technikerteam, guten alten Menschen wie Dir, zu melden.</p>"
    },
    background : {
      src : "Video AC - ambiance_generale_3.mp4",
      type : 'video'
    },
    next : 35
  },
  {
    id : 35,
    audio : {
      fr : "/blocs/bloc35.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-42.mp3"
    },
    text : {
      fr : "<p>ANNA : On dirait que tous nos agents sont déjà occupés. En envoyant un robot on optimise notre temps d'intervention de 63%. Tu valides ?</p>",
      en : "<p>ANNA : All our agents are already busy. Sending a robot optimizes our response time by 63%. Do you validate ?</p>",
      it : "<p>ANNA : Tutti i nostri agenti sono già impegnati, quindi l'invio di un robot ottimizza il nostro tempo d'intervento del 63%. Sei d'accordo ?</p>",
      de : "<p>ANNA : Alle unsere Agenten sind bereits beschäftigt, durch die Entsendung eines Roboters optimieren wir unsere Interventionszeit um 63%. Bist du einverstanden ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui vas y, envoie le robot</p>",
          en : "<p>Yes go ahead, send the robot</p>",
          it : "<p>Sì, d'accordo, invia pure il robot.</p>",
          de : "<p>Ja, ich bin einverstanden.</p>"
        },
        next : 36,
      },
      {
        answer : {
          fr : "<p>Non, je préfère que tu préviennes les agents.</p>",
          en : "<p>No, I'd prefer if you'd warn the agents.</p>",
          it : "<p>No, preferirei che chiamassi la polizia.</p>",
          de : "<p>Nein, ich bevorzuge, dass du die Agenten informierst.</p>"
        },
        next : 36,
      }
    ],
    background : {
      src : "Video AC - ambiance_generale_3.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 36,
    audio : {
      fr : "/blocs/bloc36.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bon. Et bien on a réglé ensemble notre premier problème ! Ça mérite une barbapapa, tu crois pas ? Il nous reste 5 minutes et 47 secondes avant l’ouverture du parc. Le stand de barbapapa est à 1 minute 45 secondes à pied, tu as largement le temps de…</p>",
      en : "<p>ANNA : Good. Well, we've solved our first problem together! It deserves a candyfloss, don't you think? We have 5 minutes and 47 seconds left before the park opens. The candyfloss stand is 1 minute 45 seconds walk away, you have plenty of time to...</p>",
      it : "<p>ANNA : Ok. Bene, abbiamo risolto il nostro primo problema insieme! Questo merita uno zucchero filato, non credi? Abbiamo 5 minuti e 47 secondi prima che il parco apra. Lo stand dello zucchero filato è a un minuto e 45 secondi a piedi, quindi hai tutto il tempo per...</p>",
      de : "<p>ANNA : Okay. Nun, unser erstes Problem haben wir gemeinsam gelöst! Das verdient eine Zuckerwatte, findest du nicht auch? Wir haben 5 Minuten und 47 Sekunden bis der Park öffnet. Der Süssigkeitenstand ist eine Minute und 45 Sekunden zu Fuss entfernt, also hast du genug Zeit, um...</p>"
    },
    background : {
      src : "Video AD - ambiance_generale_4.mp4",
      type : 'video'
    },
    next : 37
  },
  {
    id : 37,
    audio : {
      fr : "/blocs/bloc37.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tu vas devoir remettre cette promenade à plus tard, j’en ai bien peur. Je viens de recevoir une alerte sécurité provenant de l’Interconnex, la base de données partagée de toutes les IA de sécurité gérant des établissements publics. Voilà ce que ça dit :</p>",
      en : "<p>ANNA : You're going to have to postpone the walk, I'm afraid. I've just received a security alert from the Interconnex, the shared database of all security AIs managing public facilities. Here's what it says:</p>",
      it : "<p>ANNA : Temo che dovrai rimandare la camminata. Ho appena ricevuto un avviso di sicurezza da Interconnex, il database condiviso da tutte le IA di sicurezza che gestiscono strutture pubbliche. Ecco cosa dice:</p>",
      de : "<p>ANNA : Ich fürchte, du wirst den Spaziergang verschieben müssen. Ich habe gerade eine Sicherheitsmeldung vom Interconnex erhalten, der gemeinsamen Datenbank aller Sicherheits-KIs, die öffentliche Einrichtungen verwalten. Darin steht:</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 38
  },
  {
    id : 38,
    audio : {
      fr : "/blocs/bloc38.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>INTERCONNEX : “Alerte virale maximale. L’adresse IP 152.199.20.82, alias Historia Park, infectée. Faille sécurité majeure. Intégrité physique visiteurs en cause. Propagation virale immédiate. Protocole alerte maximale à définir.”</p>",
      en : "<p>INTERCONNEX: \"Maximum virus alert. The IP address 152.199.20.82, alias Historia Park, infected. Major security flaw. Visitor physical integrity at issue. Immediate viral propagation. Maximum alert protocol to be defined.\"</p>",
      it : "<p>INTERCONNEX: \"Allarme virale massimo. Indirizzo IP 152.199.20.82, alias Historia Park, infetto. Grave violazione della sicurezza. L'integrità fisica dei visitatori è compromessa. Propagazione virale immediata. Protocollo di massima allerta da definire\".</p>",
      de : "<p>INTERCONNECT: \"Maximale Virenwarnung. IP-Adresse 152.199.20.82, a.k.a. Historia Park, infiziert. Schwerwiegendes Sicherheitsleck. Die körperliche Unversehrtheit der Besucher ist gefährdet. Sofortige Virusvermehrung. Maximales Alarmprotokoll ist zu definieren\".</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 39
  },
  {
    id : 39,
    audio : {
      fr : "/blocs/bloc39.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tu n’as peut-être pas tout compris, mais la situation est grave. On en parle aux infos, je te diffuse le flash.</p>",
      en : "<p>ANNA : You may not have understood everything, but the situation is serious. It's on the news, I'll broadcast it to you.</p>",
      it : "<p>ANNA : Forse non hai capito tutto, ma la situazione è grave. E' su tutti i notiziari, ti mostro il flash informativo.</p>",
      de : "<p>ANNA : Du hast vielleicht nicht alles verstanden, aber die Situation ist schlimm. Es ist überall in den Nachrichten. Ich bringe dir die Eilmeldung.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : "image"
    },
    next : 40
  },
  {
    id : 40,
    audio : {
      fr : "/blocs/bloc40.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>\"JOURNALISTE RADIO : [...] nouvelle qui nous parvient à l’instant. Il semblerait que le R.A.T., le Réseau Anti-Tech, ait mis ses menaces à exécution. En effet, pour ces activistes connus jusque-là pour leurs communiqués virulents, le temps est maintenant aux actions radicales, voire terroristes. Ce matin, à 8h40, le parc Historia, réputé pour ses reconstitutions historiques grandeur nature, a été victime d’une attaque à caractère terroriste, faisant des dizaines de blessés. D’après nos informations, ce serait le système de sécurité autonome, géré par une intelligence artificielle, qui aurait été la cible des pirates, provoquant une désactivation des règles de sécurité lors de la répétition d’un spectacle pyrotechnique. Des explosions incontrôlées ont blessé plusieurs techniciens et comédiens présents sur place.</p><p>Le Réseau Anti-Tech, soutenu par une partie de la population via les réseaux sociaux, se dresse depuis des années contre la place grandissante de la technologie dans nos sociétés, notamment l’autonomisation des systèmes de sécurité, dont ils dénoncent les dérives. Il semblerait que la promulgation de la loi Sécurité et Surveillance, autorisant l’accès aux données personnelles des particuliers sans leur consentement, ait mis le feu aux poudres. Nous retrouvons sur place notre [...]\"</p>",
      en : "<p>\"RADIO REPORTER: [...] news that's coming in right now. It seems that A.T.N., the Anti-Tech Network, has carried out its threats. Indeed, for these activists known until then for their virulent statements, the time has now come for radical, even terrorist actions. This morning, at 8:40 a.m., the Historia Park, known for its life-size historical reconstructions, was the victim of a terrorist attack, causing dozens of injuries. According to our information, the autonomous security system, managed by an artificial intelligence, was the target of the hackers, causing a deactivation of the security rules during the rehearsal of a pyrotechnical show. Uncontrolled explosions have injured several technicians and actors present on site.</p><p>The Anti-Tech Network, supported by a part of the population via social networks, has been standing up for years against the growing place of technology in our societies, especially the empowerment of security systems, whose abuses they denounce. It would seem that the promulgation of the Security and Surveillance Act, which allows the access to personal data of everybody without their consent, has sparked things off. And now we’re going straight to [...]\"</p>",
      it : "<p>REPORTER: [...] notizie in arrivo. Sembra che R.A.T., la Rete Anti-Tech, abbia messo in atto le sue minacce. Infatti, per questi attivisti, noti per i loro virulenti comunicati stampa, è giunto il momento di azioni radicali, anche terroristiche. Questa mattina, alle 8.40, Historia Park, conosciuto per le sue rievocazioni storiche a grandezza naturale, è stato vittima di un attacco terroristico, causando decine di feriti. Secondo le nostre informazioni, è stato il sistema di sicurezza autonomo, gestito da un'intelligenza artificiale, ad essere il bersaglio dei hackers, causando la disattivazione delle regole di sicurezza durante le prove di uno spettacolo pirotecnico. Esplosioni incontrollate hanno ferito diversi tecnici e attori presenti sul posto. La Rete Anti-Tech, sostenuta da parte della popolazione attraverso i social network, si è espresso per anni contro il crescente ruolo della tecnologia nelle nostre società, in particolare contro il potenziamento dei sistemi di sicurezza, di cui denuncia gli abusi. Sembra che la promulgazione della legge sulla sicurezza e la sorveglianza, che autorizza l'accesso ai dati personali degli individui senza il loro consenso, abbia dato fuoco alla polveriera. Troviamo sul posto il nostro [...]\".</p>",
      de : "<p>\"RADIO-JOURNALIST: [...] eine Nachricht, die jetzt zu uns erreicht. Es scheint, dass A.T.N., das Anti-Tech-Netzwerk, seine Drohungen wahr gemacht hat. Tatsächlich ist für diese Aktivisten, die bis dahin für ihre virulenten Pressemitteilungen bekannt waren, jetzt die Zeit für radikale, sogar terroristische Aktionen gekommen ist. Heute Morgen um 8.40 Uhr wurde der Historia Park, der für seine lebensgrossen historischen Nachstellungen bekannt ist, Opfer eines Terroranschlags bei dem Dutzende von Verletzten zu beklagen waren. Unseren Informationen zufolge war das autonome Sicherheitssystem, das von einer künstlichen Intelligenz gesteuert wird, das Ziel der Piraten. Was dazu führte, dass die Sicherheitsregeln während der Probe einer Pyrotechnik-Show deaktiviert wurden. Unkontrollierte Explosionen verletzten mehrere am Tatort anwesende Techniker und Schauspieler.</p><p>Das Anti-Tech-Netzwerk, das von einem Teil der Bevölkerung über soziale Netzwerke unterstützt wird, spricht sich seit Jahren gegen die wachsende Rolle der Technologie in unseren Gesellschaften aus, insbesondere gegen die Stärkung der Sicherheitssysteme, deren Missbrauch sie anprangern. Es scheint, dass die Verkündung des Sicherheits- und Überwachungsgesetzes, das den Zugang zu persönlichen Daten von Personen ohne deren Zustimmung erlaubt, das Pulverfass in Brand gesteckt hat. Wir finden vor Ort unsere [...]\"</p>"
    },
    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 41
  },
  {
    id : 41,
    audio : {
      fr : "/blocs/bloc41.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-44.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu en penses quoi, toi, du Réseau Anti-Tech ?</p>",
      en : "<p>ANNA : What do you think of the Anti-Tech Network ?</p>",
      it : "<p>ANNA : Cosa ne pensi della Rete Anti-Tech ?</p>",
      de : "<p>ANNA : Was denkst du über das Anti-Tech-Netzwerk ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je suis assez souvent d’accord avec ce qu’ils dénoncent : la société de surveillance et l’exploitation de nos données personnelles</p>",
          en : "<p>I quite often agree with what they denounce: the surveillance society and the exploitation of our personal data.</p>",
          it : "<p>Sono spesso d'accordo con ciò che denunciano: la società della sorveglianza e lo sfruttamento dei nostri dati personali.</p>",
          de : "<p>Ich bin oft einverstanden mit dem, was sie anprangern: die Überwachungsgesellschaft und die Ausbeutung unserer persönlichen Daten.</p>"
        },
        next : 42,
      },
      {
        answer : {
          fr : "<p>Je n’approuve pas forcément leurs méthodes, mais je trouve qu’ils posent de bonnes questions.</p>",
          en : "<p>I do not necessarily approve their methods, but I find that they ask good questions.</p>",
          it : "<p>Non approvo necessariamente i loro metodi, ma penso che facciano delle buone domande.</p>",
          de : "<p>Ich bin nicht unbedingt mit ihren Methoden einverstanden, aber ich denke, sie stellen die richtigen Fragen.</p>"
        },
        next : 43,
      },
      {
        answer : {
          fr : "<p>Je ne soutiens ni leur discours, ni leurs actions</p>",
          en : "<p>I support neither their speeches nor their actions.</p>",
          it : "<p>Non sostengo né i loro argomenti, nè le loro azioni.</p>",
          de : "<p>Ich unterstütze weder ihre Rhetorik noch ihre Massnahmen.</p>"
        },
        next : 44,
      },
      {
        answer : {
          fr : "<p>Ca ne m’intéresse pas, je n’ai pas trop d’avis.</p>",
          en : "<p>I'm not interested, I don't have much of an opinion.</p>",
          it : "<p>Non sono interessato·a, non ho un' opinione sul tema…</p>",
          de : "<p>Ich bin nicht interessiert und ich habe keine Meinung.</p>"
        },
        next : 45,
      }
    ],
    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 42,
    audio : {
      fr : "/blocs/bloc42.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Toi, au moins, tu es honnête !</p>",
      en : "<p>ANNA : At least you are honest !</p>",
      it : "<p>ANNA : Almeno sei onesto·a !</p>",
      de : "<p>ANNA : Mindestens bist du ehrlich !</p>"
    },
    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 46
  },
  {
    id : 43,
    audio : {
      fr : "/blocs/bloc43.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ils ont quand même une drôle de manière de poser leurs questions !</p>",
      en : "<p>ANNA : They still have a strange way of asking their questions !</p>",
      it : "<p>ANNA : Hanno comunque uno strano modo di porre le domande !</p>",
      de : "<p>ANNA : Sie haben aber eine komische Art, ihre Fragen zu stellen !</p>"
    },
    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 46
  },
  {
    id : 44,
    audio : {
      fr : "/blocs/bloc44.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ouf ! On ne sera pas trop de deux à lutter contre eux.</p>",
      en : "<p>ANNA : Phew! We won't be too many of us fighting against them.</p>",
      it : "<p>ANNA : Pfff! Non saremo troppi in due a combattere contro di loro!</p>",
      de : "<p>ANNA : Puh! Es wird nicht allzu viele von uns geben, die gegen sie kämpfen.</p>"
    },
    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 46
  },
  {
    id : 45,
    audio : {
      fr : "/blocs/bloc45.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Parfait, au moins tes décisions seront impartiales !</p>",
      en : "<p>ANNA : Good, at least your decisions will be impartial !</p>",
      it : "<p>ANNA : Bene, almeno le tue decisioni saranno imparziali !</p>",
      de : "<p>ANNA : Gut, zumindest werden deine Entscheidungen unparteiisch sein !</p>"
    },
    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 46
  },
  {
    id : 46,
    audio : {
      fr : "/blocs/bloc46.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je pense que maintenant, tu en sais autant que moi. Si on n’agit pas très vite, je risque moi aussi d'être infectée par le virus des Anti-Tech. Je vais donc immédiatement me déconnecter du réseau national, pour éviter que ce virus ne m’atteigne. Laisse-moi une seconde.</p>",
      en : "<p>ANNA : I think that you know now as much as I do. If we don't act very quickly, I risk being infected with the Anti-Tech virus too. I'm going to disconnect from the national network immediately, to prevent this virus from reaching me. Just give me a second.</p>",
      it : "<p>ANNA : Penso che ora ne sappia quanto me. Se non agiamo in fretta, anch'io potrei essere infettata dal virus Anti-Tech. Mi scollego quindi immediatamente dalla rete nazionale, per evitare che questo virus mi raggiunga. Dammi solo un secondo.</p>",
      de : "<p>ANNA : Ich glaube, jetzt weisst du genauso viel wie ich. Wenn wir nicht schnell handeln, riskiere ich, auch mit dem Anti-Tech-Virus infiziert zu werden. Ich werde also sofort die Verbindung zum nationalen Netz trennen, um zu verhindern, dass dieser Virus mich erreicht. Gib mir nur eine Sekunde.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 47
  },
  {
    id : 47,
    audio : {
      fr : "/blocs/bloc47.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Voilà. À partir de maintenant, c’est toi et moi ! Je ne pourrai plus accéder aux bases de données des autres parcs en temps réel, même si j’ai toujours accès aux derniers back-ups antérieurs à l’attaque. Mais rassure-toi, j’ai un protocole pour faire face à ce genre de situation. Normalement, je peux procéder au scan des visages de tous les visiteurs par les caméras de sécurité de l’entrée. Mais évidemment, maintenant que le port du masque est obligatoire dans tous les lieux publics, je ne reconnais plus personne. Comme quoi, il n’y a pas que les ordinateurs qui subissent les virus. Désolée, blague de robot.</p><p>En revanche, depuis ce matin et grâce à cette nouvelle loi Sécurité et Surveillance, je peux accéder aux données des smartphones de chaque visiteur lors du scan de leur pass à l’entrée du parc. Cela me donne accès à leur identité réelle, leurs géolocalisations récentes, leurs activités sur les réseaux sociaux, les derniers sites consultés, et les messages qu’ils ont reçus et envoyés dans les dernières 24h.</p><p>Cette loi est évidemment trop récente pour que j'ai pu moi-même tester son application, mais d'après les données que j'ai pu récupérer auprès d'autres parcs du pays, ce protocole a un taux d'efficacité de 75%. Ce qui est bien, mais trop bas pour que je puisse prendre la décision seule. Je vais avoir besoin de toi !</p>",
      en : "<p>ANNA : There you go. From now on, it's you and me! I won't be able to access other parks' databases in real time anymore, but I still have access to the last back-ups saved prior to the attack. Don't worry, I have a protocol to deal with this kind of situation. Normally, I can scan the faces of all visitors through the security cameras at the entrance. But of course, now that wearing a mask is mandatory in all public places, I no longer recognize anyone. So, it's not only computers that suffer from viruses. Sorry, robot joke.</p><p>On the other hand, since this morning and thanks to this new Security and Surveillance law, I can access the data from each visitor's smartphone when scanning their pass at the entrance of the park. This gives me access to their real identity, their recent geolocalizations, their activities on social networks, the last sites they visited, and the messages they received and sent in the last 24 hours.</p><p>This law is obviously too recent for me to test its application myself, but according to the data I was able to retrieve from other parks in the country, this protocol has an efficiency rate of 75%. Which is good, but too low for me to make the decision alone. I'm going to need you!</p>",
      it : "<p>ANNA : Ecco fatto. D'ora in poi, siamo io e te! Non sarò più in grado di accedere ai database di altri parchi in tempo reale, anche se ho ancora accesso agli ultimi backup prima dell'attacco. Ma stai tranquillo·a, ho un protocollo per affrontare questo tipo di situazione. Normalmente, posso scansionare i volti di tutti i visitatori attraverso le telecamere di sicurezza all'ingresso. Ma naturalmente, ora che indossare una maschera è obbligatorio in tutti i luoghi pubblici, non riconosco nessuno. Non sono solo i computer ad essere infettati dai virus. Scusa, scherzo da robot.</p><p>D'altra parte, da questa mattina e grazie a questa nuova legge sulla sicurezza e sulla sorveglianza, posso accedere ai dati dallo smartphone di ogni visitatore durante la scansione del suo pass all'ingresso del parco. Questo mi dà accesso alla loro vera identità, alle loro recenti geolocalizzazioni, alle loro attività sui social network, agli ultimi siti che hanno visitato e ai messaggi che hanno ricevuto e inviato nelle ultime 24 ore.</p><p>Questa legge è ovviamente troppo recente perché io stessa possa testarne l'applicazione, ma secondo i dati che ho potuto recuperare da altri parchi del Paese, questo protocollo ha un tasso di efficienza del 75%. Il che è un bene, ma troppo basso perché io possa prendere la decisione da sola. Ho bisogno di te!</p>",
      de : "<p>ANNA : Erledigt. Von jetzt an gibt es nur noch dich und mich! Ich werde nicht mehr in der Lage sein, auf die Datenbanken anderer Parks in Echtzeit zuzugreifen, obwohl ich immer noch Zugang zu den letzten Backups vor dem Angriff habe. Aber sei versichert, ich habe ein Protokoll für den Umgang mit einer solchen Situation. Normalerweise kann ich die Gesichter aller Besucher durch die Sicherheitskameras am Eingang scannen. Aber jetzt, wo das Tragen einer Maske an allen öffentlichen Orten obligatorisch ist, erkenne ich natürlich niemanden mehr wieder. Es sind nicht nur Computer, die von Viren infiziert werden. Entschuldigung, Roboter-Witz.</p><p>Andererseits kann ich seit heute Morgen und dank dieses neuen Sicherheits- und Überwachungsgesetzes auf die Daten des Smartphones jedes Besuchers zugreifen, wenn der Ausweis am Parkeingang gescannt wird. Dadurch habe ich Zugang zu ihrer wahren Identität, ihrer aktuellen Geolokalisierung, ihren Aktivitäten in sozialen Netzwerken, den zuletzt besuchten Orten und den Nachrichten, die sie in den letzten 24 Stunden empfangen und gesendet haben.</p><p>Dieses Gesetz ist offensichtlich zu neu, als dass ich seine Anwendung selbst testen könnte, aber den Daten zufolge, die ich aus anderen Parks des Landes abrufen konnte, hat dieses Protokoll einen Wirkungsgrad von 75 %. Was gut ist, aber zu niedrig, als dass ich die Entscheidung allein treffen könnte. Ich werde dich brauchen!</p>"
    },
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 48
  },
  {
    id : 48,
    audio : {
      fr : "/blocs/bloc48.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>ANNA : M’autorises-tu à lancer ce protocole ?</p>",
      en : "<p>ANNA : Do you authorize me to launch this protocol ?</p>",
      it : "<p>ANNA : Mi autorizzi ad avviare questo protocollo ?</p>",
      de : "<p>ANNA : Autorisierst du mich, dieses Protokoll zu initiieren ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, accède aux données personnelles de tous les visiteurs à l’entrée.</p>",
          en : "<p>Yes, access personal data of all visitors at the entrance.</p>",
          it : "<p>Sì, accedi ai dati personali di tutti i visitatori all'ingresso.</p>",
          de : "<p>Ja, greif auf die persönlichen Daten aller Besucher am Eingang zu.</p>"
        },
        next : 49,
      },
      {
        answer : {
          fr : "<p>Non, je préfère que l’on fasse des contrôles d’identité physiques aléatoires, et que l’on en alerte tous les visiteurs.</p>",
          en : "<p>No, I prefer that we make random physical identity checks, and that we alert all visitors.</p>",
          it : "<p>No, preferirei che si facessero dei controlli fisici casuali e si avvisassero tutti i visitatori.</p>",
          de : "<p>Nein, ich würde lieber stichprobenartige Ausweiskontrollen durchführen und alle Besucher warnen.</p>"
        },
        next : 171,
      }
    ],
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 49,
    audio : {
      fr : "/blocs/bloc49.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Faisons comme ça. Je démarre toutes les attractions, tout le monde est à son poste, allez, on peut ouvrir les portes du parc, c’est parti ! C’est journée d’affluence aujourd’hui, il y a déjà la queue pour entrer. L'annonce de l'attaque d'Historia n'a pas l'air d’avoir découragé les visiteurs ! Mais on va tout de même diffuser un message sur nos mesures de sécurité renforcées.</p><p>Ça y est, j’ai les premières données visiteurs qui m’arrivent. Avec cette loi, j’ai eu le droit à une grosse mise à jour, il faut que je trouve mes marques. C’est plutôt bien codé d’ailleurs, très propre !</p>",
      en : "<p>ANNA : Let's do it this way. I start all the attractions, everybody is in position, come on, we can open the park doors, let's go! It's a busy day today, there is already a line to get in. The announcement of the attack of Historia does not seem to have discouraged the visitors! But we will nevertheless broadcast a message about our reinforced security measures.</p><p>That's it, I've got the data of the first visitor coming in. Thanks to this law, I had the right to a big update; I have to find my marks. It's rather well coded by the way, very clean !</p>",
      it : "<p>ANNA : Facciamo così. Accendo tutte le giostre, sono tutti al loro posto, forza, possiamo aprire i cancelli del parco, andiamo! Oggi è una giornata impegnativa, c'è già la fila per entrare. L'annuncio dell'attacco di Historia non sembra aver scoraggiato i visitatori! Ma continueremo comunque a diffondere un messaggio sul rinforzo delle nostre misure di sicurezza.</p><p>Ho i primi dati dei visitatori in arrivo. Con questa legge, ho avuto un aggiornamento maggiore del mio sistema operativo; devo trovare adesso il mio equilibrio. A proposito, è abbastanza ben programmato, molto pulito !</p>",
      de : "<p>ANNA : Lasst es uns so machen. Ich starte alle Fahrgeschäfte, alle sind auf ihren Posten, kommt schon, wir können die Parktore öffnen, los geht's! Heute ist ein arbeitsreicher Tag, es gibt bereits eine Schlange vor den Toren. Die Ankündigung des Angriffs von Historia scheint die Besucher nicht entmutigt zu haben! Aber wir werden nichtsdestotrotz die Nachricht über unsere verstärkten Sicherheitsmassnahmen verbreiten.</p><p>Die ersten Besucherdaten sind eingetroffen. Mit diesem Gesetz habe ich ein grosses Update bekommen, ich muss mich erst einmal orientieren. Es ist übrigens ziemlich gut programmiert, sehr sauber !</p>"
    },
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 50
  },
  {
    id : 50,
    audio : {
      fr : "/blocs/bloc50.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v4.mp3"
    },
    text : {
      fr : "<p>ANNA : Hum, attends, je crois qu’on tient déjà peut-être un truc. J’ai repéré trois suspects potentiels qui viennent d’entrer. Ils sont membres des mêmes groupes d’intérêts sur les réseaux sociaux. Deux d’entre eux se sont appelés devant l’entrée du parc, et tous les trois ont échangé cette nuit des messages sur une application de discussion cryptée, qui est largement utilisée par les membres du R.A.T.</p><p>Écoute-ça, j’ai trouvé cette note vocale cryptée sur le téléphone de l’un d’entre eux. Pas facile à déchiffrer, mais l’algo anti-messagerie privée du Ministère de l’Intérieur est plutôt efficace, je leur reconnais ça. Voilà ce que ça donne :</p>",
      en : "<p>ANNA : Um, wait, I think we might already have something. I have identified three potential suspects who just walked in. They are members of the same interest groups on social networks. Two of them called each other outside the park entrance, and all three exchanged messages last night on an encrypted chat application, which by the way, is widely used by A.T.N. members.</p><p>Listen to this, I found this encrypted voice note on the phone of one of them. Not easy to decipher, but the Ministry of the Interior's anti-private messaging algorithm is quite effective, I have to give them credit for that. Here’s what it says:</p>",
      it : "<p>ANNA : Mmm... aspetta, penso che potremmo già avere qualcosa. Ho identificato tre potenziali sospetti che sono appena entrati. Sono membri degli stessi gruppi d'interesse dei social media. Due di loro si sono chiamati fuori dall'ingresso del parco e tutti e tre si sono scambiati messaggi ieri sera su un'applicazione di chat criptata, molto utilizzata dai membri della R.A.T.</p><p>Senti questa, ho trovato questo promemoria vocale criptato su uno dei loro telefoni. Non è facile da decifrare, ma l’algoritmo anti-privacy per messaggistica del Ministero dell'Interno è piuttosto efficace, questo glielo riconosco. Ecco cosa dice :</p>",
      de : "<p>ANNA : Ähm, warte, ich glaube, wir haben vielleicht schon etwas. Ich habe drei potenzielle Verdächtige identifiziert, die gerade hereingekommen sind. Sie sind Mitglieder der gleichen Interessengruppen in sozialen Medien. Zwei von ihnen riefen sich vor dem Parkeingang an, und alle drei tauschten gestern Abend Nachrichten über eine verschlüsselte Chat-App aus, die weit verbreitet ist und von A.T.N.-Mitgliedern genutzt wird.</p><p>Hören Sie sich das an, ich habe diese verschlüsselte Sprachnotiz auf einem ihrer Telefone gefunden. Nicht leicht zu entziffern, aber der Anti-Private-Messaging-Algorithmus des Innenministeriums ist ziemlich effektiv, das muss ich ihnen lassen. Folgendes haben wir:</p>"
    },
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 51
  },
  {
    id : 51,
    audio : {
      fr : "/blocs/bloc51.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>ACTIVISTE R.A.T. : Ouais, salut. C’est moi. T’as vu, la loi est passée ? Hors de question qu’on reste sans rien faire. On va pas laisser les machines encore décider à notre place, en leur donnant un libre accès à nos données personnelles. Alors on va montrer à tout le monde ce dont elles sont capables quand elles sont infectées ou manipulées. On arrête les communiqués, on passe à l’action. Tout de suite. Alors rassemble les troupes, on agit aujourd’hui.</p>",
      en : "<p>A.T.N. ACTIVIST: Yeah, hi. That's me. Did you see, the law passed? There's no way we're not going to do anything. We're not going to let the machines decide for us again, by giving them free access to our personal data. So we're going to show everyone what they're capable of when they're infected or manipulated. We'll stop with the statements and we'll take action. Right away. So get the troops together, we act today.</p>",
      it : "<p>ATTIVISTA R.A.T.: Ciao. Sono io. Hai visto, è passata la legge? Non staremo a guardare e non fare nulla. Non permetteremo più alle macchine di decidere per noi, dando loro libero accesso ai nostri dati personali. Mostreremo a tutti ciò di cui sono capaci quando vengono infettati o manipolati. Interrompiamo i comunicati, interveniamo. Facciamolo subito. Quindi, riunite le truppe, agiamo oggi stesso.</p>",
      de : "<p>A.T.N. AKTIVIST: Ja, hallo. Ich bin's. Hast du gesehen, dass das Gesetz verabschiedet wurde? Auf keinen Fall werden wir herumsitzen und nichts tun. Wir werden die Maschinen nicht für uns entscheiden lassen, indem wir ihnen freien Zugang zu unseren persönlichen Daten gewähren. Wir werden also allen zeigen, wozu sie fähig sind, wenn sie infiziert oder manipuliert werden. Wir stoppen die Kommuniqués, wir ergreifen Massnahmen. Wir werden es sofort tun. Also stellt die Truppen zusammen; heute handeln wir.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 52
  },
  {
    id : 52,
    audio : {
      fr : "/blocs/bloc52.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ça m'a l’air assez incriminant, non ? Je suis vraiment désolée que ta première journée commence comme ça, mais il va falloir prendre une nouvelle décision pour la sécurité de tous nos visiteurs.</p>",
      en : "<p>ANNA : Sounds pretty incriminating to me, isn't it? I'm really sorry that your first day starts like this, but we'll have to make a new decision for the safety of all our visitors.</p>",
      it : "<p>ANNA : Sembra piuttosto incriminante, vero? Mi dispiace molto che il tuo primo giorno sia iniziato così, ma dovremo prendere una nuova decisione per la sicurezza di tutti i nostri visitatori.</p>",
      de : "<p>ANNA : Klingt ziemlich belastend, nicht wahr? Es tut mir wirklich leid, dass dieser erste Tag auf diese Weise begonnen hat, aber wir müssen eine neue Entscheidung für die Sicherheit all unserer Besucher treffen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 53
  },
  {
    id : 53,
    audio : {
      fr : "/blocs/bloc53.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v2.mp3"
    },
    text : {
      fr : "<p>ANNA : On a plusieurs options : on peut arrêter nous-mêmes les suspects dès maintenant avec nos agents sur le terrain, et prévenir la police pour une intervention la plus rapide possible. On s'assure ainsi de les neutraliser et peut-être d’éviter le pire. Autrement, on attend la police, ils ont un délai d’intervention d’environ 2 minutes et 27 secondes, peut-être un peu moins si je les houspille un peu. Que souhaites-tu faire ?</p>",
      en : "<p>ANNA : We have several options. We can arrest the suspects ourselves right now with our agents on the ground, and notify the police for the quickest possible intervention. That way we make sure we neutralize them and maybe avoid the worst. Otherwise, we wait for the police; they have a response time of about 2 minutes and 27 seconds, maybe a little less if I stress them a little bit. What do you want to do ?</p>",
      it : "<p>ANNA : Abbiamo diverse opzioni: in questo momento possiamo arrestare i sospettati noi stessi con i nostri agenti sul posto e avvisare la polizia per un intervento il più rapido possibile. In questo modo ci assicuriamo di neutralizzarli e forse di evitare il peggio. Altrimenti aspettiamo la polizia; hanno un tempo di risposta di circa 2 minuti e 27 secondi, forse un po' meno se li sollecito un po'. Cosa vorresti fare ?</p>",
      de : "<p>ANNA : Wir haben mehrere Möglichkeiten: Wir können die Verdächtigen selbst sofort mit unseren Agenten vor Ort festnehmen und die Polizei benachrichtigen, damit sie so schnell wie möglich eingreifen können. Auf diese Weise stellen wir sicher, dass wir sie neutralisieren und vielleicht das Schlimmste vermeiden. Ansonsten warten wir auf die Polizei; sie hat eine Reaktionszeit von etwa 2 Minuten und 27 Sekunden, vielleicht etwas weniger, wenn ich drängle. Was möchtest du tun ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>On attend la police !</p>",
          en : "<p>We wait for the police !</p>",
          it : "<p>Aspettiamo la polizia !</p>",
          de : "<p>Wir warten auf die Polizei !</p>"
        },
        next : 541,
      },
      {
        answer : {
          fr : "<p>Je suis d’accord pour que ces suspects soient arrêtés tout de suite.</p>",
          en : "<p>I agree that these suspects should be arrested immediately.</p>",
          it : "<p>Sono d'accordo che questi sospettati debbano essere arrestati immediatamente.</p>",
          de : "<p>Ich stimme zu, dass diese Verdächtigen sofort verhaftet werden sollten.</p>"
        },
        next : 54,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 54,
    audio : {
      fr : "/blocs/bloc54.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, je préviens la police et leur donne toutes les informations à ma disposition. On va gérer cette opération de manière discrète avec le chef de la sécurité sur le terrain. Gary, tu m’entends ?</p>",
      en : "<p>ANNA : OK, I'll call the police and give them all the information I have. We will manage this operation discreetly with the head of security on the ground. Gary, can you hear me ?</p>",
      it : "<p>ANNA : Va bene, informerò la polizia e darò loro tutte le informazioni che ho. Gestiremo questa operazione in modo discreto con il capo della sicurezza che si trova sul posto. Gary, mi senti ?</p>",
      de : "<p>ANNA : Okay, ich werde die Polizei benachrichtigen und ihnen alle Informationen geben, die ich habe. Wir werden diese Operation diskret mit dem Sicherheitschef vor Ort durchführen. Gary, kannst du mich hören ?</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 55
  },
  {
    id : 541,
    audio : {
      fr : "/blocs/bloc54.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, je préviens la police et leur donne toutes les informations à ma disposition. On va gérer cette opération de manière discrète avec le chef de la sécurité sur le terrain. Gary, tu m’entends ?</p>",
      en : "<p>ANNA : OK, I'll call the police and give them all the information I have. We will manage this operation discreetly with the head of security on the ground. Gary, can you hear me ?</p>",
      it : "<p>ANNA : Va bene, informerò la polizia e darò loro tutte le informazioni che ho. Gestiremo questa operazione in modo discreto con il capo della sicurezza che si trova sul posto. Gary, mi senti ?</p>",
      de : "<p>ANNA : Okay, ich werde die Polizei benachrichtigen und ihnen alle Informationen geben, die ich habe. Wir werden diese Operation diskret mit dem Sicherheitschef vor Ort durchführen. Gary, kannst du mich hören ?</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 551
  },
  {
    id : 55,
    audio : {
      fr : "/blocs/bloc55.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Oui, je te reçois 5/5 Anna.</p>",
      en : "<p>GARY: Yes, I receive you loud and clear Anna.</p>",
      it : "<p>GARY: Sì, ti ricevo 5/5 Anna.</p>",
      de : "<p>GARY: Ja, ich höre dich laut und deutlich Anna.</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 56
  },
  {
    id : 551,
    audio : {
      fr : "/blocs/bloc55.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Oui, je te reçois 5/5 Anna.</p>",
      en : "<p>GARY: Yes, I receive you loud and clear Anna.</p>",
      it : "<p>GARY: Sì, ti ricevo 5/5 Anna.</p>",
      de : "<p>GARY: Ja, ich höre dich laut und deutlich Anna.</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 111
  },
  {
    id : 56,
    audio : {
      fr : "/blocs/bloc56.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Nous avons détecté trois suspects à arrêter tout de suite. Je te transmets leurs coordonnées GPS et leur description. Il faut les neutraliser en attendant l’intervention de la police. Je continue de mon côté à vérifier les identités des visiteurs qui entrent.</p>",
      en : "<p>ANNA : We've identified three suspects to be arrested immediately. I'm sending you their GPS coordinates and description. They must be neutralized while waiting for the intervention of the police. For my part, I'm continuing to check the identities of the visitors entering the park.</p>",
      it : "<p>ANNA : Abbiamo identificato tre sospettati da arrestare immediatamente. Ti sto trasmettendo le loro coordinate GPS e la loro descrizione. Devono essere neutralizzati in attesa dell'intervento della polizia. Continuerò a controllare l'identità dei visitatori in arrivo.</p>",
      de : "<p>ANNA : Wir haben drei Verdächtige zur sofortigen Verhaftung identifiziert. Ich übermittle ihre GPS-Koordinaten und ihre Beschreibung. Sie müssen bis zum Eingreifen der Polizei neutralisiert werden. Ich werde weiterhin die Identitäten der ankommenden Besucher überprüfen.</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 57
  },
  {
    id : 57,
    audio : {
      fr : "/blocs/bloc57.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : OK Anna, je viens de recevoir les informations. On s’en charge.</p>",
      en : "<p>GARY : OK Anna, I've just received the information. We'll take care of it.</p>",
      it : "<p>GARY : Ok, Anna, ho appena ricevuto l'informazione. Ci pensiamo noi.</p>",
      de : "<p>GARY : OK Anna, ich habe gerade die Informationen erhalten. Wir werden uns darum kümmern.</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 58
  },
  {
    id : 58,
    audio : {
      fr : "/blocs/bloc58.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : On peut faire confiance à Gary pour gérer cela en attendant que la police arrive.</p>",
      en : "<p>ANNA : We can trust Gary to handle this until the police arrive.</p>",
      it : "<p>ANNA : Possiamo fidarci di Gary per gestire la situazione fino all'arrivo della polizia.</p>",
      de : "<p>ANNA : Wir können darauf vertrauen, dass Gary sich darum kümmert bis die Polizei eintrifft.</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 59
  },
  {
    id : 59,
    audio : {
      fr : "/blocs/bloc59.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah tiens, écoute, ils parlent encore de l’autre attaque sur une chaîne infos :</p>",
      en : "<p>ANNA : Oh listen, they're still talking about the other attack on a news channel:</p>",
      it : "<p>ANNA : Oh, guarda, stanno ancora parlando dell'altro attacco su un canale di notizie:</p>",
      de : "<p>ANNA : Oh, hör dir das an! Sie reden immer noch über den anderen Angriff auf dem Nachrichtensender:</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 60
  },
  {
    id : 591,
    audio : {
      fr : "/blocs/bloc59.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah tiens, écoute, ils parlent encore de l’autre attaque sur une chaîne infos :</p>",
      en : "<p>ANNA : Oh listen, they're still talking about the other attack on a news channel:</p>",
      it : "<p>ANNA : Oh, guarda, stanno ancora parlando dell'altro attacco su un canale di notizie:</p>",
      de : "<p>ANNA : Oh, hör dir das an! Sie reden immer noch über den anderen Angriff auf dem Nachrichtensender:</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 601
  },
  {
    id : 60,
    audio : {
      fr : "/blocs/bloc60.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-flashinfo.mp3"
    },
    text : {
      fr : "<p>JOURNALISTE RADIO : ... en direct du musée Historia, connu pour ses reconstitutions historiques grandioses. Lors de la seconde attaque, les terroristes ont piraté les bases de données visiteurs du musée, et ont envoyé à chaque visiteur, sur leur smartphone, toutes les données les concernant. Un visiteur témoigne :</p>",
      en : "<p>RADIO REPORTER: ... live from the Historia museum, known for its grandiose historical reconstructions. During the second attack, the terrorists hacked into the museum's visitor databases, and sent each visitor, on their smartphone, all their personal data. A visitor testifies:</p>",
      it : "<p>GIORNALISTA RADIOFONICO: ... del Museo Historia, noto per le sue grandiose rievocazioni storiche. Durante il secondo attacco i terroristi si sono introdotti nelle banche dati dei visitatori del museo e hanno inviato a ciascun visitatore, sul proprio smartphone, tutti i dati che lo riguardavano. Un visitatore testimonia:</p>",
      de : "<p>RADIO-JOURNALIST: ... des Historia-Museums, das für seine grandiosen historischen Rekonstruktionen bekannt ist. Während des zweiten Angriffs hackten sich die Terroristen in die Besucherdatenbanken des Museums und schickten jedem Besucher alle ihren Daten auf ihre Smartphone. Ein Besucher schildert:</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 61
  },
  {
    id : 601,
    audio : {
      fr : "/blocs/bloc60.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-flashinfo.mp3"
    },
    text : {
      fr : "<p>JOURNALISTE RADIO : ... du musée Historia, connu pour ses reconstitutions historiques grandioses. Lors de la seconde attaque, les terroristes ont piraté les bases de données visiteurs du musée, et ont envoyé à chaque visiteur, sur leur smartphone, toutes les données les concernant. Un visiteur témoigne :</p>",
      en : "<p>RADIO REPORTER: ... from the Historia museum, known for its grandiose historical reconstructions. During the second attack, the terrorists hacked into the museum's visitor databases, and sent each visitor, on their smartphone, all their personal data. A visitor testifies:</p>",
      it : "<p>REPORTER: ... del Museo Historia, noto per le sue grandiose rievocazioni storiche. Durante il secondo attacco i terroristi si sono introdotti nelle banche dati dei visitatori del museo e hanno inviato a ciascun visitatore, sul proprio smartphone, tutti i dati che lo riguardavano. Un visitatore testimonia:</p>",
      de : "<p>RADIO-JOURNALIST: ... des Historia-Museums, das für seine grandiosen historischen Rekonstruktionen bekannt ist. Während des zweiten Angriffs hackten sich die Terroristen in die Besucherdatenbanken des Museums und schickten  jedem Besucher alle ihren Daten auf ihre Smartphone. Ein Besucher sagt:</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 611
  },
  {
    id : 61,
    audio : {
      fr : "/blocs/bloc61.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-flashinfo.mp3"
    },
    text : {
      fr : "<p>TÉMOIN DU JOURNALISTE : Nan mais regardez, j’ai reçu toutes les données qu’ils ont sur moi, c'est dingue ! Le nom de ma petite amie, tout mon historique de messagerie... Non, je suis plus que surpris, je suis scandalisé ! J’aimerais qu’on m’explique !</p>",
      en : "<p>REPORTER'S WITNESS : Nah, but look, I got all the data they have on me, it's crazy ! My girlfriend's name, all my messaging history. No, I'm more than surprised, I'm shocked! I'd like someone to explain it to me!</p>",
      it : "<p>TESTIMONE DEL REPORTER : No, ma guarda, ho tutti i dati che hanno su di me, è pazzesco ! Il nome della mia ragazza, tutta la mia storia di email... No, sono più che sorpreso, sono indignato, vorrei che qualcuno me lo spiegasse !</p>",
      de : "<p>ZEUGE DER REPORTERIN : Nein, schauen Sie, ich habe alle Daten, die sie über mich haben, es ist verrückt! Der Name meiner Freundin, mein ganzer E-Mail-Verlauf... Nein, ich bin mehr als überrascht, ich bin schockiert! Ich hätte gerne, dass mir das jemand erklärt !</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 62
  },
  {
    id : 611,
    audio : {
      fr : "/blocs/bloc61.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-flashinfo.mp3"
    },
    text : {
      fr : "<p>TÉMOIN DU JOURNALISTE : Nan mais regardez, j’ai reçu toutes les données qu’ils ont sur moi, c'est dingue ! Le nom de ma petite amie, tout mon historique de messagerie... Non, je suis plus que surpris, je suis scandalisé ! J’aimerais qu’on m’explique !</p>",
      en : "<p>REPORTER'S WITNESS : Nah, but look, I got all the data they have on me, it's crazy ! My girlfriend's name, all my messaging history. No, I'm more than surprised, I'm shocked ! I'd like someone to explain it to me !</p>",
      it : "<p>TESTIMONE DEL REPORTER : No, ma guarda, ho tutti i dati che hanno su di me, è pazzesco ! Il nome della mia ragazza, tutta la mia storia di email... No, sono più che sorpreso, sono indignato, vorrei che qualcuno me lo spiegasse !</p>",
      de : "<p>ZEUGE DER REPORTERIN : Nein, schauen Sie, ich habe alle Daten, die sie über mich haben, es ist verrückt ! Der Name meiner Freundin, mein ganzer E-Mail-Verlauf... Nein, ich bin mehr als überrascht, ich bin schockiert! Ich hätte gerne, dass mir das jemand erklärt !</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 621
  },
  {
    id : 62,
    audio : {
      fr : "/blocs/bloc62.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-flashinfo.mp3"
    },
    text : {
      fr : "<p>JOURNALISTE RADIO : La Direction a démenti toute illégalité, mais la colère gronde parmi les visiteurs du parc.</p>",
      en : "<p>RADIO REPORTER : Management has denied any illegality, but anger is growing among ...</p>",
      it : "<p>REPORTER : Il management ha negato ogni illegalità, ma la rabbia è in aumento ...</p>",
      de : "<p>RADIO-JOURNALIST : Das Management hat jegliche Gesetzeswidrigkeit dementiert, aber die Wut wächst innerhalb ..</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 63
  },
  {
    id : 621,
    audio : {
      fr : "/blocs/bloc62.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-flashinfo.mp3"
    },
    text : {
      fr : "<p>JOURNALISTE RADIO : La Direction a démenti toute illégalité, mais la colère gronde parmi les visiteurs du parc.</p>",
      en : "<p>RADIO REPORTER: Management has denied any illegality, but anger is growing among ...</p>",
      it : "<p>REPORTER: Il management ha negato ogni illegalità, ma la rabbia è in aumento ...</p>",
      de : "<p>RADIO-JOURNALIST: Das Management hat jegliche Gesetzeswidrigkeit dementiert, aber die Wut wächst innerhalb ..</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 631
  },
  {
    id : 63,
    audio : {
      fr : "/blocs/bloc63.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ils ont le sens du spectacle ces terroristes !</p>",
      en : "<p>ANNA : These terrorists sure know how to put on a show !</p>",
      it : "<p>ANNA : Hanno il senso dello spettacolo, questi terroristi !</p>",
      de : "<p>ANNA : Diese Terroristen haben einen Sinn für Spektakel !</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 64
  },
  {
    id : 631,
    audio : {
      fr : "/blocs/bloc63.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ils ont le sens du spectacle ces terroristes !</p>",
      en : "<p>ANNA : These terrorists have a sense of spectacle !</p>",
      it : "<p>ANNA : Hanno il senso dello spettacolo, questi terroristi !</p>",
      de : "<p>ANNA : Diese Terroristen haben einen Sinn für Spektakel !</p>"
    },

    background : {
      src : "Video A - journaliste_flash.mp4",
      type : 'video'
    },
    next : 120
  },
  {
    id : 64,
    audio : {
      fr : "/blocs/bloc64.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah, je vois que les forces de l’ordre sont arrivées, et ont arrêté les suspects. Et...attends, on a le directeur du parc en ligne… Monsieur le Directeur, bonjour !</p>",
      en : "<p>ANNA : Ah, I see that the police have arrived and arrested the suspects. And... wait, we have the park director on the line... Mr. Director, good morning !</p>",
      it : "<p>ANNA : Ah, vedo che la polizia è arrivata e ha arrestato i sospettati. E... aspetta, abbiamo il direttore del parco in linea... Signor direttore, buongiorno !</p>",
      de : "<p>ANNA : Ah, wie ich sehe, ist die Polizei eingetroffen und hat die Verdächtigen festgenommen. Und... Moment, wir haben den Parkmanager am Apparat... Herr Direktor, hallo !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 65
  },
  {
    id : 65,
    audio : {
      fr : "/blocs/bloc65.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Anna, quelle est la situation ? Je viens d’avoir Gary... Il me dit qu’un des suspects est déjà en train d’appeler son avocat... il veut porter plainte pour arrestation abusive... tu veux m'expliquer ?</p>",
      en : "<p>THE DIRECTOR : Anna, what is the situation ? I just got off the phone with Gary... He tells me that one of the suspects is already calling his lawyer... he wants to press charges for wrongful arrest... Do you want to explain ?</p>",
      it : "<p>IL DIRETTORE : Anna, qual è la situazione ? Ho appena parlato al telefono con Gary... mi dice che uno dei sospettati sta già chiamando il suo avvocato... vuole sporgere denuncia per arresto illegittimo... vuoi spiegarmi ?</p>",
      de : "<p>DER DIREKTOR : Anna, wie ist die Situation ? Ich habe gerade Gary angerufen... er sagte mir, dass einer der Verdächtigen bereits seinen Rechtsanwalt anruft... er will Anklage wegen unrechtmässiger Verhaftung erheben... Kannst du mir das erklären ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 66
  },
  {
    id : 66,
    audio : {
      fr : "/blocs/bloc66.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Oui, bien sûr. Nous avons arrêté trois personnes suspectes dans le cadre de l’annonce du Ministère de l’Intérieur. Ces personnes ont été prises en charge par les forces de l’ordre à l’instant. J’avais un pourcentage de confiance de 86%, mais il est possible que je me sois trompée. C’est à la police de mener l’enquête maintenant.</p>",
      en : "<p>ANNA : Yes, of course. We have arrested three suspicious persons in the context of the announcement by the Ministry of the Interior. These people have been taken into custody by the law enforcement agencies just now. I had a confidence level of 86%, but it is possible that I was mistaken. It's up to the police to investigate now.</p>",
      it : "<p>ANNA : Sì, certo. Abbiamo arrestato tre sospettati in relazione all'annuncio del Ministero dell'Interno. Queste persone sono state prese in custodia dalla polizia poco fa. Avevo una percentuale di fiducia dell'86%, ma è possibile che mi sia sbagliata. Ora tocca alla polizia indagare.</p>",
      de : "<p>ANNA : Ja, natürlich. Im Rhamen der Ankündigung des Innenministeriums haben wir drei verdächtige Personen festgenommen. Diese Personen wurden soeben von den Strafverfolgungsbehörden in Gewahrsam genommen. Ich hatte einen Vertrauensprozentsatz von 86%, aber es ist möglich, dass ich mich geirrt habe. Die Polizei muss jetzt Ermittlungen anstellen</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 67
  },
  {
    id : 67,
    audio : {
      fr : "/blocs/bloc67.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Bon, bah j’espère que ça ne va pas nous coûter trop cher cette petite “erreur”. Le Monsieur est très énervé… il a l’air d’avoir le bras long… Anna, par ailleurs, on a un taux d’annulation de 20% de la part de nos visiteurs aujourd’hui, j’espère qu’on va être en mesure de limiter la casse.</p>",
      en : "<p>THE DIRECTOR : Well, I hope this little mistake won't cost us too much. The gentleman is very angry... he seems to know a lot of powerful people... Anna, on the other hand, we have a 20% cancellation rate of our visitors today, I hope we'll be able to limit the damage.</p>",
      it : "<p>IL DIRETTORE : Bene, bene, spero che non ci costi troppo per questo piccolo “errore”. Il signore è molto arrabbiato... sembra essere molto influente... Anna, a proposito, oggi abbiamo un tasso di cancellazione del 20% da parte dei nostri visitatori, quindi spero che riusciremo a limitare i danni.</p>",
      de : "<p>DER DIREKTOR : Na schön, ich hoffe dieser kleine “Fehler” kostet uns nicht zu viel. Der Herr ist sehr verärgert... er scheint viel Einfluss zu haben... Anderweitig haben wir heute eine 20%ige Annulierungsrate von unseren Besuchern, also hoffe ich, dass wir die Verluste begrenzen können...</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 68
  },
  {
    id : 68,
    audio : {
      fr : "/blocs/bloc68.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-40.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu penses que l’on répond quoi ?</p>",
      en : "<p>ANNA : What do you think we should answer ?</p>",
      it : "<p>ANNA : Quale pensi che sia la nostra risposta ?</p>",
      de : "<p>ANNA : Was denkst du, ist unsere Antwort ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Nous allons faire ce qu’il faut.</p>",
          en : "<p>We will do the right thing.</p>",
          it : "<p>Faremo ciò che è necessario</p>",
          de : "<p>Wir werden das Richtige tun.</p>"
        },
        next : 70,
      },
      {
        answer : {
          fr : "<p>Il faudra peut-être envisager une fermeture si la situation l’exige.</p>",
          en : "<p>Closure may have to be considered if the situation requires it.</p>",
          it : "<p>Se la situazione lo richiede, bisognerebbe prendere in considerazione la chiusura.</p>",
          de : "<p>Eine Schliessung muss möglicherweise in Betracht gezogen werden, wenn die Situation dies erfordert.</p>"
        },
        next : 71,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 69,
    audio : {
      fr : "/blocs/bloc69.mp3",
      anna : false,
      atmospheric : "/ambiance/Ac-plan-40.mp3"
    },
    text : {
      fr : "<p>LE DIRECTEUR : Anna ? Tu m’entends ? Eh oh ? Anna ?</p>",
      en : "<p>THE DIRECTOR : Anna ? Do you hear me ? Hello ? Anna ?</p>",
      it : "<p>IL DIRETTORE : Anna ? Mi senti? Pronto ? Anna ?</p>",
      de : "<p>DER DIREKTOR : Anna ? Hörst du mich ? Hallo ? Anna ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 70
  },
  {
    id : 70,
    audio : {
      fr : "/blocs/bloc70.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Oui Monsieur le Directeur, nous allons faire ce qu’il faut.</p>",
      en : "<p>ANNA : Yes Mr. Director, we are going to do what we have to do.</p>",
      it : "<p>ANNA : Sì Direttore, faremo quello che è giusto fare</p>",
      de : "<p>ANNA : Ja, Herr Direktor, wir werden das Richtige tun.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 72
  },
  {
    id : 71,
    audio : {
      fr : "/blocs/bloc71.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Monsieur le Directeur, il faudra peut-être envisager une fermeture si la situation l’exige.</p>",
      en : "<p>ANNA : Mr. Director, a closure may have to be considered if the situation requires it.</p>",
      it : "<p>ANNA : Direttore, potremmo dover considerare la chiusura se la situazione lo richiede.</p>",
      de : "<p>ANNA : Herr Direktor, vielleicht wird eine Schliessung in Betracht gezogen werden müssen, wenn die Situation dies erfordert.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 73
  },
  {
    id : 72,
    audio : {
      fr : "/blocs/bloc72.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Parfait, tenez moi au courant.</p>",
      en : "<p>THE DIRECTOR : Good, keep me informed.</p>",
      it : "<p>IL DIRETTORE : Bene, tenetemi informato.</p>",
      de : "<p>DER DIREKTOR : Einverstanden. Halte mich auf dem Laufenden.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 74
  },
  {
    id : 73,
    audio : {
      fr : "/blocs/bloc73.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Espérons que ces terroristes ne nous obligeront pas à aller aussi loin...</p>",
      en : "<p>THE DIRECTOR : Let's hope that these terrorists will not force us to go that far...</p>",
      it : "<p>IL DIRETTORE : Speriamo che questi terroristi non ci costringano ad andare così lontano...</p>",
      de : "<p>DER DIREKTOR : Hoffen wir, dass diese Terroristen uns nicht dazu zwingen, so weit zu gehen...</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 74
  },
  {
    id : 74,
    audio : {
      fr : "/blocs/bloc74.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Charmant personnage, hein ? C’est un fervent défenseur des technologies, ça doit lui hérisser le poil de penser à ces énergumènes ! Je vais continuer à vérifier les entrées, le risque d’attentat n’est pas complètement éloigné.</p>",
      en : "<p>ANNA : Delightful character, huh ? He's a fervent defender of technology; thinking of these maniacs must really rub him up the wrong way ! I will continue to check the entrances, the risk of attack is not completely away.</p>",
      it : "<p>ANNA : Personaggio affascinante, eh? È un grande sostenitore della tecnologia, deve fargli rizzare i capelli in testa pensare a quegli energumeni! Continuerò a controllare gli ingressi, il rischio di un attacco non è del tutto lontano.</p>",
      de : "<p>ANNA : Charmanter Mann, nicht? Er ist ein glühender Verfechter von Technologie, es muss ihm die Haare zu Berge stehen lassen, wenn er an diese Spinner denkt! Ich werde die Eingänge weiter kontrollieren weil das Anschlagrisiko immer noch hoch ist.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 75
  },
  {
    id : 75,
    audio : {
      fr : "/blocs/bloc75.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>ANNA : En attendant, j’aurais besoin que tu fasses un rapport sur cette première intervention. Il est utilisé par l’équipe de développeurs pour ajuster la mise à jour dont je viens de bénéficier. Comment juges-tu mon intervention ?</p>",
      en : "<p>ANNA : In the meantime, I'll need you to make a report on this first intervention. It will be used by the development team to adjust the firmware update I've just received. How do you judge my intervention ?</p>",
      it : "<p>ANNA : Nel frattempo, ho bisogno che tu mi faccia un rapporto su questo primo intervento. Viene utilizzato dal team di sviluppo per regolare l'aggiornamento che ho appena ricevuto. Come giudichi il mio intervento ?</p>",
      de : "<p>ANNA : In der Zwischenzeit brauche ich einen Bericht über diese erste Intervention. Es wird vom Softwareentwicklungsteam verwendet, um das Update zu überprüfen. Wie beurteilst du meine Intervention ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Positivement</p>",
          en : "<p>Positively</p>",
          it : "<p>Positivamente</p>",
          de : "<p>Positiv</p>"
        },
        next : 76,
      },
      {
        answer : {
          fr : "<p>Négativement</p>",
          en : "<p>Negatively</p>",
          it : "<p>Negativamente</p>",
          de : "<p>Negativ</p>"
        },
        next : 124,
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 76,
    audio : {
      fr : "/blocs/bloc76.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>ANNA : Ravie de l’entendre !</p>",
      en : "<p>ANNA : Glad to hear it !</p>",
      it : "<p>ANNA : Felice di sentirlo !</p>",
      de : "<p>ANNA : Ich freue mich, das zu hören !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 77
  },
  {
    id : 77,
    audio : {
      fr : "/blocs/bloc77.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>ANNA : Peux-tu préciser ?</p>",
      en : "<p>ANNA : Can you elaborate ?</p>",
      it : "<p>ANNA : Potresti essere più preciso·a ?</p>",
      de : "<p>ANNA : Könntest du das präzisieren ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Tu m’as bien expliqué les options et leurs conséquences.</p>",
          en : "<p>You explained the options and their consequences to me very well</p>",
          it : "<p>Mi hai spiegato bene le opzioni e le loro conseguenze.</p>",
          de : "<p>Du hast mir die Optionen und ihre Konsequenzen gut erklärt.</p>"
        },
        next : 78,
      },
      {
        answer : {
          fr : "<p>Tu as fait preuve de pragmatisme et de sang froid</p>",
          en : "<p>You were pragmatic and cold-blooded.</p>",
          it : "<p>Sei stata pragmatica e hai dimostrato sangue freddo...</p>",
          de : "<p>Du warst pragmatisch und kaltblütig.</p>"
        },
        next : 78,
      },
      {
        answer : {
          fr : "<p>Tu as été efficace dans tes analyses et propositions.</p>",
          en : "<p>You were efficient in your analyses and suggestions.</p>",
          it : "<p>Sei stata efficiente nella tua analisi e nelle tue proposte.</p>",
          de : "<p>Du warst effizient in deinen Analysen und Vorschlägen.</p>"
        },
        next : 78,
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 78,
    audio : {
      fr : "/blocs/bloc78.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Merci pour ce retour, il est transmis à l’équipe de développement. Je pense que tu peux souffler et prendre quelques minutes de pause pour te remettre de tes émotions.</p>",
      en : "<p>ANNA : Thank you for the feedback, it's passed on to the development team. I think you can take a break and a few minutes off to recover from your emotions.</p>",
      it : "<p>ANNA : Grazie per il feedback, è stato trasmesso al team di sviluppo. Penso che tu possa fare una pausa e concederti qualche minuto per riprenderti dalle emozioni.</p>",
      de : "<p>ANNA : Vielen Dank für dieses Feedback! Das wird gerade an das Entwicklungsteam weitergeleitet. Ich denke, dass du eine Verschnaufpause einlegen und ein paar Minuten Pause machen kannst, um dich weiter von deinen Emotionen zu erholen. </p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 79
  },
  {
    id : 79,
    audio : {
      fr : "/blocs/bloc79.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Si tu veux, tu peux accéder aux différents micros du parc, pour prendre la température du personnel et des visiteurs, voir si l’ambiance n’est pas trop alarmiste. Je t’appelle si je trouve quelque chose de suspect. Par où veux-tu aller ?</p>",
      en : "<p>ANNA : If you want, you can access the different microphones in the park to figure out the mindset of the staff and visitors, to see if the atmosphere is not too alarmist. I'll call you if I find something suspicious. Which way do you want to go ?</p>",
      it : "<p>ANNA : Se vuoi, puoi accedere ai vari microfoni del parco, per provare a capire l'atteggiamento del personale e dei visitatori, per vedere se l'atmosfera non è troppo allarmista. Ti chiamo se trovo qualcosa di sospetto. Da che parte vuoi andare ?</p>",
      de : "<p>ANNA : Wenn du möchtest, kannst du auf die verschiedenen Mikrofone im Park zugreifen, um die Stimmung des Personals und der Besucher zu messen und um zu sehen, ob die Atmosphäre nicht zu alarmierend ist. Ich rufe dich an, wenn ich etwas Verdächtiges finde. Wohin möchtest du gehen ? </p>"
    },

    choices : [
      {
        answer : {
          fr : "<p>Micro 232653 - En direction du stand de barbe à papa</p>",
          en : "<p>Micro 232653 - Towards the Candyfloss stand</p>",
          it : "<p>Micro 232653 - In direzione dello stand dello zucchero filato</p>",
          de : "<p>Micro 232653 - In Richtung des Zuckerwattestands</p>"
        },
        next : 80,
      },
      {
        answer : {
          fr : "<p>Micro 346543 - En direction du stand des auto-tamponneuses</p>",
          en : "<p>Micro 346543 - Towards the car bumper stand</p>",
          it : "<p>Micro 346543 - In direzione della giostra di autoscontro</p>",
          de : "<p>Micro 346543 - In Richtung des Autoscooters</p>"
        },
        next : 81,
      },
    ],

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 791,
    audio : {
      fr : "/blocs/bloc79.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Si tu veux, tu peux accéder aux différents micros du parc, pour prendre la température du personnel et des visiteurs, voir si l’ambiance n’est pas trop alarmiste. Je t’appelle si je trouve quelque chose de suspect. Par où veux-tu aller ?</p>",
      en : "<p>ANNA : If you want, you can access the different microphones in the park, to figure out the mindset of the staff and visitors, to see if the atmosphere is not too alarmist. I'll call you if I find something suspicious. Which way do you want to go ?</p>",
      it : "<p>ANNA : Se vuoi, puoi accedere ai vari microfoni del parco, per provare a capire l'atteggiamento del personale e dei visitatori, per vedere se l'atmosfera non è troppo allarmista. Ti chiamo se trovo qualcosa di sospetto. Da che parte vuoi andare ?</p>",
      de : "<p>ANNA : Wenn du möchtest, kannst du auf die verschiedenen Mikrofone im Park zugreifen, um die Stimmung des Personals und der Besucher zu messen und um zu sehen, ob die Atmosphäre nicht zu alarmierend ist. Ich rufe dich an, wenn ich etwas Verdächtiges finde. Wohin möchtest du gehen ? </p>"
    },

    choices : [
      {
        answer : {
          fr : "<p>Micro 232653 - En direction du stand de barbe à papa</p>",
          en : "<p>Micro 232653 - Towards the Candyfloss stand</p>",
          it : "<p>Micro 232653 - In direzione dello stand dello zucchero filato</p>",
          de : "<p>Micro 232653 - In Richtung des Zuckerwattenstands</p>"
        },
        next : 801,
      },
      {
        answer : {
          fr : "<p>Micro 346543 - En direction du stand des auto-tamponneuses</p>",
          en : "<p>Micro 346543 - Towards the car bumper stand</p>",
          it : "<p>Micro 346543 - In direzione della giostra di autoscontro</p>",
          de : "<p>Micro 346543 - In Richtung des Autoscooters</p>"
        },
        next : 811,
      },
    ],

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 80,
    audio : {
      fr : "/blocs/bloc80.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>FEMME AGENT : Ça y est, j’ai craqué, j’ai fait comme toi, j’ai acheté mon brouilleur anti-Anna. J’en ai marre d’être pisté quand je vais aux toilettes. Elle m’a félicité de ma productivité hier ! Haha ! Quelle blague !</p>",
      en : "<p>WOMAN AGENT : That's it, I gave in, I did what you did, I bought my anti-Anna jammer. I'm tired of being tracked down when I go to the toilet. She congratulated me on my productivity yesterday! Haha! What a joke !</p>",
      it : "<p>AGENTE DONNA : Ecco, ho risolto, ho fatto quello che hai fatto tu, ho comprato il mio disturbatore di segnali anti-Anna. Sono stanco di essere seguito quando vado in bagno. Ieri si è congratulata con me per la mia produttività ! Haha !</p>",
      de : "<p>FRAU AGENT : Das war's, ich habe mich entschieden einen Anti-Anna-Störsender zu kaufen, wie du. Ich habe die Nase voll von der totalen Überwachung. Sie hat mir gestern zu meiner Produktivität gratuliert ! Haha !</p>"
    },

    background : {
      src : "Video AE - ambiance_generale_5.mp4",
      type : 'video'
    },
    next : 82
  },
  {
    id : 801,
    audio : {
      fr : "/blocs/bloc80.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>FEMME AGENT : Ça y est, j’ai craqué, j’ai fait comme toi, j’ai acheté mon brouilleur anti-Anna. J’en ai marre d’être pisté quand je vais aux toilettes. Elle m’a félicité de ma productivité hier ! Haha ! Quelle blague !</p>",
      en : "<p>WOMAN AGENT : That's it, I gave in, I did what you did, I bought my anti-Anna jammer. I'm tired of being tracked down when I go to the toilet. She congratulated me on my productivity yesterday! Haha! What a joke !</p>",
      it : "<p>AGENTE DONNA : Ecco, ho risolto, ho fatto quello che hai fatto tu, ho comprato il mio disturbatore di segnali anti-Anna. Sono stanco di essere seguito quando vado in bagno. Ieri si è congratulata con me per la mia produttività ! Haha !</p>",
      de : "<p>FRAU AGENT : Das war's, ich habe mich entschieden einen Anti-Anna-Störsender zu kaufen, wie du. Ich habe die Nase voll von der totalen Überwachung. Sie hat mir gestern zu meiner Produktivität gratuliert ! Haha !</p>"
    },

    background : {
      src : "Video AE - ambiance_generale_5.mp4",
      type : 'video'
    },
    next : 197
  },
  {
    id : 81,
    audio : {
      fr : "/blocs/bloc81.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>PARENTS : Attends… Je viens de voir sur Twitter, le musée Historia a été attaqué. Ce sont des terroristes Anti-Tech qui travaillent avec la Russie.. C’est dingue, on y était en mars ! Et ils menacent aussi les parcs d’attractions. Allez, prenez vos affaires les enfants, on s’en va ! Vite !</p>",
      en : "<p>PARENTS : Wait... I just saw on Twitter, the Historia Museum was attacked. They are Anti-Tech terrorists working with Russia... It's crazy, we were there in March ! And they also threaten amusement parks. Come on, get your stuff, kids, let's go! Let's go ! Quickly !</p>",
      it : "<p>GENITORI : Aspetta... ho appena visto su Twitter che il museo Historia è stato attaccato. Sono terroristi anti-tecnologici che lavorano con la Russia... È pazzesco, eravamo lì a marzo! E minacciano anche i parchi di divertimento. Forza, prendete la vostra roba, ragazzi, andiamo! Andiamo ! Veloci !</p>",
      de : "<p>ELTERN : Warte... Ich habe gerade auf Twitter gesehen, dass das Historia-Museum angegriffen wurde. Es handelt sich um Anti-Tech-Terroristen, die mit Russland zusammenarbeiten... Das ist verrückt, wir waren im März dort! Und sie bedrohen auch Vergnügungsparks. Kommt schon, holt eure Sachen Kinder ! Auf geht's ! Schnell !</p>"
    },

    background : {
      src : "Video AF - ambiance_generale_6.mp4",
      type : 'video'
    },
    next : 82
  },
  {
    id : 811,
    audio : {
      fr : "/blocs/bloc81.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>PARENTS : Attends… Je viens de voir sur Twitter, le musée Historia a été attaqué. Ce sont des terroristes Anti-Tech qui travaillent avec la Russie.. C’est dingue, on y était en mars ! Et ils menacent aussi les parcs d’attractions. Allez, prenez vos affaires les enfants, on s’en va ! Vite !</p>",
      en : "<p>PARENTS : Wait... I just saw on Twitter, the Historia Museum was attacked. They are Anti-Tech terrorists working with Russia... It's crazy, we were there in March! And they also threaten amusement parks. Come on, get your stuff, kids, let's go ! Let's go ! Quickly !</p>",
      it : "<p>GENITORI : Aspetta... ho appena visto su Twitter che il museo Historia è stato attaccato. Sono terroristi anti-tecnologici che lavorano con la Russia... È pazzesco, eravamo lì a marzo! E minacciano anche i parchi di divertimento. Forza, prendete la vostra roba, ragazzi, andiamo ! Andiamo ! Veloci !</p>",
      de : "<p>ELTERN : Warte... Ich habe gerade auf Twitter gesehen, dass das Historia-Museum angegriffen wurde. Es handelt sich um Anti-Tech-Terroristen, die mit Russland zusammenarbeiten... Das ist verrückt, wir waren im März dort! Und sie bedrohen auch Vergnügungsparks. Kommt schon, holt eure Sachen Kinder ! Auf geht's! Schnell !</p>"
    },

    background : {
      src : "Video AF - ambiance_generale_6.mp4",
      type : 'video'
    },
    next : 197
  },
  {
    id : 82,
    audio : {
      fr : "/blocs/bloc82.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ca y est, tu as fait un petit tour ? Bon, maintenant que tu t’es aéré l’esprit, je dois t’avouer quelque chose. Quand j’ai scanné les téléphones des visiteurs, j’ai aussi scanné le tien, pour m’assurer que tu n’avais aucun lien avec le Réseau Anti-Tech. Je ne t’ai pas prévenu, mais je ne me voyais pas te demander froidement si tu faisais partie des activistes. Évidemment, je n’ai rien trouvé, à part quelques photos embarrassantes que je vais essayer d’effacer de ma mémoire.</p>",
      en : "<p>ANNA : That's it, did you take a little walk ? Well, now that you've cleared your mind, I must confess something to you. When I scanned the visitors' phones, I also scanned yours, to make sure you had no connection to the Anti-Tech Network. I didn't warn you, but at the same time I didn't see myself coldly asking you if you were one of the activists. Of course, I didn't find anything except a few embarrassing photos that I will try to erase from my memory.</p>",
      it : "<p>ANNA : Allora, hai fatto un giretto ? Bene, ora che ti sei schiarito le idee, ho qualcosa da confessarti. Quando ho scannerizzato i telefoni dei visitatori, ho scannerizzato anche il tuo, per assicurarmi che non avessi alcuna connessione con la Rete Anti-Tech. Non ti ho avvertito, perché non mi vedevo a chiederti freddamente se eri uno degli attivisti. Naturalmente non ho trovato nulla, tranne qualche foto imbarazzante che cercherò di cancellare dalla mia memoria.</p>",
      de : "<p>ANNA : Hast du deine Runde gemacht ? Nun, jetzt, da du deinen Kopf frei hast, muss ich dir etwas gestehen. Als ich die Telefone der Besucher scannte, scannte ich auch dein, um sicherzustellen, dass du keine Verbindung zum Anti-Tech-Netzwerk hast. Ich habe dich nicht gewarnt, weil ich nicht kalt fragen wollte, ob du einer von den Aktivisten bist. Natürlich habe ich nichts gefunden, ausser ein paar peinliche Fotos, die ich versuchen werde, aus meinem Gedächtnis zu löschen.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 83
  },
  {
    id : 83,
    audio : {
      fr : "/blocs/bloc83.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v2.mp3"
    },
    text : {
      fr : "<p>ANNA : Es-tu choqué·e que j’ai vérifié tes données personnelles sans ton consentement ?</p>",
      en : "<p>ANNA : Are you shocked that I checked your personal data without your consent ?</p>",
      it : "<p>ANNA : Sei scioccato·a che io abbia controllato i tuoi dati personali senza il tuo consenso ?</p>",
      de : "<p>ANNA : Bist du schockiert, dass ich deine persönlichen Daten ohne deine Bewilligung überprüft habe ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Non, je trouve ça normal, après tout tu ne me connais pas.</p>",
          en : "<p>No, I think it's normal, after all you don't know me.</p>",
          it : "<p>No, penso che sia normale, dopo tutto non mi conosci.</p>",
          de : "<p>Nein, ich denke, das es ganz normal ist, alles in allem kennst du mich ja nicht.</p>"
        },
        next : 84,
      },
      {
        answer : {
          fr : "<p>Oui ! Je te demande de ne plus jamais recommencer</p>",
          en : "<p>Yes! I ask you to never do it again</p>",
          it : "<p>Sì! Ti chiedo di non farlo mai più…</p>",
          de : "<p>Ja! Ich bitte dich, das nie wieder zu tun.</p>"
        },
        next : 127,
      },
    ],
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 84,
    audio : {
      fr : "/blocs/bloc84.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v4.mp3"
    },
    text : {
      fr : "<p>ANNA : Dans notre métier, on n'est jamais trop prudent. Je sais désormais que je peux te faire confiance ! Maintenant que je sais que tu es de mon côté, j’enchaine avec les autres employés…</p>",
      en : "<p>ANNA : In our business, you can never be too careful. I know now that I can trust you ! Now that I know that you're on my side, I'm going on with the other employees...</p>",
      it : "<p>ANNA : Nel nostro settore non si può mai essere troppo prudenti. Ora so che posso fidarmi di te! Ora che so che sei dalla mia parte, passerò agli altri dipendenti...</p>",
      de : "<p>ANNA : In unserem Beruf kann man nie vorsichtig genug sein. In Zukunft weiss ich, dass ich dir vertrauen kann! Nun das ich dir vertrauen kann, werde ich die anderen Angestellten untersuchen.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 85
  },
  {
    id : 85,
    audio : {
      fr : "/blocs/bloc85.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’ai quand même noté un truc étrange. Je n’arrive à capter aucune donnée depuis le smartphone de Gary. C’est comme s’il était équipé d’un brouilleur. Tu vas sûrement me trouver suspicieuse, mais avoir un responsable de la sécurité qui refuse que je l’observe, je trouve ça louche.</p>",
      en : "<p>ANNA : I did notice something strange. I can't get any data from Gary's smartphone. It's like he's got a jammer. You're probably going to find me suspicious, but having a security manager who won't let me observe him, I find that suspicious.</p>",
      it : "<p>ANNA : Ho notato pero qualcosa di strano. Non riesco ad ottenere dati dallo smartphone di Gary. E' come se avesse un disturbatore di segnali. Probabilmente mi troverai malfidata, ma avendo un responsabile della sicurezza che rifiuta che io lo osservi, trovo questo sospettoso.</p>",
      de : "<p>ANNA : Mir ist allerdings etwas Seltsames aufgefallen. Ich kann keine Daten von Garys Smartphone empfangen, als ob er einen Störsender hätte. Ich bin vielleicht zu argwöhnisch, aber einen Sicherheitsmanager zu haben, der mich keinen Blick darauf werfen lässt, das ist meiner Meinung nach sehr merkwürdig.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 86
  },
  {
    id : 851,
    audio : {
      fr : "/blocs/bloc85.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’ai quand même noté un truc étrange. Je n’arrive à capter aucune donnée depuis le smartphone de Gary. C’est comme s’il était équipé d’un brouilleur. Tu vas sûrement me trouver suspicieuse, mais avoir un responsable de la sécurité qui refuse que je l’observe, je trouve ça louche.</p>",
      en : "<p>ANNA : I did notice something strange. I can't get any data from Gary's smartphone. It's like he's got a jammer. You're probably going to find me suspicious, but having a security manager who won't let me observe him, I find that suspicious.</p>",
      it : "<p>ANNA : Ho notato pero qualcosa di strano. Non riesco ad ottenere dati dallo smartphone di Gary. E' come se avesse un disturbatore di segnali. Probabilmente mi troverai malfidata, ma avendo un responsabile della sicurezza che rifiuta che io lo osservi, trovo questo sospettoso.</p>",
      de : "<p>ANNA : Mir ist allerdings etwas Seltsames aufgefallen. Ich kann keine Daten von Garys Smartphone empfangen, als ob er einen Störsender hätte. Ich bin vielleicht zu argwöhnisch, aber einen Sicherheitsmanager zu haben, der mich keinen Blick darauf werfen lässt, das ist meiner Meinung nach sehr merkwürdig.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 861
  },
  {
    id : 86,
    audio : {
      fr : "/blocs/bloc86.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>ANNA : Dis-moi, j’ai un cas de conscience… Est-ce que je hacke son téléphone pour essayer d’en savoir plus ?</p>",
      en : "<p>ANNA : Tell me, I have a case of conscience... Do I hack his phone to try to find out more ?</p>",
      it : "<p>ANNA : Dimmi, ho un caso di coscienza... Mi sto infiltrando nel suo telefono per cercare di saperne di più. Cosa ne pensi ?</p>",
      de : "<p>ANNA : Ich habe eine Gewissensfrage... Darf ich sein Telefon hacken, um mehr herauszufinden ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui ! Autant en avoir le coeur net.</p>",
          en : "<p>Yes! We might as well know for sure.</p>",
          it : "<p>Sì, tanto vale saperlo con certezza.</p>",
          de : "<p>Ja! Dann kannst du ganz sicher sein.</p>"
        },
        next : 87,
      },
      {
        answer : {
          fr : "<p>Non, c’est sa vie privée !</p>",
          en : "<p>No, it's his private life !</p>",
          it : "<p>No, è la sua vita privata !</p>",
          de : "<p>Nein, es ist seine Privatsphäre !</p>"
        },
        next : 104,
      },
    ],
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 861,
    audio : {
      fr : "/blocs/bloc86.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>ANNA : Dis-moi, j’ai un cas de conscience… Est-ce que je hacke son téléphone pour essayer d’en savoir plus ?</p>",
      en : "<p>ANNA : Tell me, I have a case of conscience... Do I hack his phone to try to find out more ?</p>",
      it : "<p>ANNA : Dimmi, ho un caso di coscienza... Mi sto infiltrando nel suo telefono per cercare di saperne di più. Cosa ne pensi ?</p>",
      de : "<p>ANNA : Ich habe eine Gewissensfrage... Darf ich sein Telefon hacken, um mehr herauszufinden ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui ! Autant en avoir le coeur net.</p>",
          en : "<p>Yes! We might as well know for sure.</p>",
          it : "<p>Sì, tanto vale saperlo con certezza.</p>",
          de : "<p>Ja! Dann kannst du ganz sicher sein.</p>"
        },
        next : 871,
      },
      {
        answer : {
          fr : "<p>Non, c’est sa vie privée !</p>",
          en : "<p>No, it's his private life !</p>",
          it : "<p>No, è la sua vita privata !</p>",
          de : "<p>Nein, es ist seine Privatsphäre !</p>"
        },
        next : 128,
      },
    ],
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 87,
    audio : {
      fr : "/blocs/bloc87.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bon, aux grands maux les grands remèdes, je vais hacker son téléphone. <Bruits électroniques> Bingo. C’est lui la taupe, j’aurais dû me méfier... Je crois que je comprends ce que vous appelez la colère, je me suis faite berner comme… comme… une humaine. Il va me le payer, tu peux en être sûr.e. Bon, restons professionnels, je ne suis pas programmée pour me permettre ce genre de comportement. Je nous mets en liaison avec le directeur dès maintenant, et j’avertis la police en même temps.</p>",
      en : "<p>ANNA : Well, desperate times call for desperate measures, I'll hack his phone. <Electronic noise > Bingo. He's the mole, I should have known better... I think I understand what you call anger, I was fooled like... like... a human. I’ll make him pay for this, be sure of that. Well, let's keep it professional, I'm not programmed to have this kind of behavior. I am connecting us with the director right now and notifying the police at the same time.</p>",
      it : "<p>ANNA : Beh, per i grandi mali le grandi cure, gli hackererò il telefono. <Rumore elettronico > Bingo. E' lui la talpa. Avrei dovuto saperlo... Credo di capire quello che chiamate rabbia. Mi sono fatta prendere in giro come... come... un umano. Pagherà per questo, potete starne certi. Beh, manteniamoci professionali, non sono programmata per permettere questo tipo di comportamento. Sono in contatto con il direttore in questo momento, e sto allo stesso tempo avvisando la polizia .</p>",
      de : "<p>ANNA : Aussergewöhnliche Zeiten bedürfen aussergewöhnlicher Mittel. Ich werde sein Telefon hacken. <Elektronisches Rauschen>. Bingo. Er ist der Verräter. Ich verstehe besser was du \"Wut\" nennst. Ich wurde reingelegt wie... wie... ein Mensch. Er wird dafür büssen... lassen Sie uns professionell bleiben, ich bin nicht darauf programmiert, ein solches Verhalten zuzulassen. Ich stehe gerade mit dem Gefängnisdirektor in Verbindung und benachrichtige gleichzeitig die Polizei.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 88
  },
  {
    id : 871,
    audio : {
      fr : "/blocs/bloc87.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bon, aux grands maux les grands remèdes, je vais hacker son téléphone. <Bruits électroniques> Bingo. C’est lui la taupe, j’aurais dû me méfier... Je crois que je comprends ce que vous appelez la colère, je me suis faite berner comme… comme… une humaine. Il va me le payer, tu peux en être sûr.e. Bon, restons professionnels, je ne suis pas programmée pour me permettre ce genre de comportement. Je nous mets en liaison avec le directeur dès maintenant, et j’avertis la police en même temps.</p>",
      en : "<p>ANNA : Well, desperate times call for desperate measures, I'll hack his phone. <Electronic noise > Bingo. He's the mole, I should have known better... I think I understand what you call anger, I was fooled like... like... a human. Well, let's keep it professional, I'm not programmed to allow this kind of behavior. I am connecting us with the director right now and notifying the police at the same time.</p>",
      it : "<p>ANNA : Beh, per i grandi mali le grandi cure, gli hackererò il telefono. <Rumore elettronico > Bingo. E' lui la talpa. Avrei dovuto saperlo... Credo di capire quello che chiamate rabbia. Mi sono fatta prendere in giro come... come... un umano. Pagherà per questo, potete starne certi. Beh, manteniamoci professionali, non sono programmata per permettere questo tipo di comportamento. Sono in contatto con il direttore in questo momento, e sto allo stesso tempo avvisando la polizia .</p>",
      de : "<p>ANNA : Aussergewöhnliche Zeiten bedürfen aussergewöhnlicher Mittel. Ich werde sein Telefon hacken. <Elektronisches Rauschen>. Bingo. Er ist der Verräter. Ich verstehe besser was du \"Wut\" nennst. Ich wurde reingelegt wie... wie... ein Mensch. Er wird dafür büssen... lassen Sie uns professionell bleiben, ich bin nicht darauf programmiert, ein solches Verhalten zuzulassen. Ich stehe gerade mit dem Gefängnisdirektor in Verbindung und benachrichtige gleichzeitig die Polizei.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 881
  },
  {
    id : 88,
    audio : {
      fr : "/blocs/bloc88.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Oui, Anna, je t’écoute.</p>",
      en : "<p>THE DIRECTOR : Yes, Anna, I'm listening.</p>",
      it : "<p>IL DIRETTORE : Sì, Anna, ti ascolto.</p>",
      de : "<p>DER DIREKTOR : Ja, Anna, ich höre dich.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 89
  },
  {
    id : 881,
    audio : {
      fr : "/blocs/bloc88.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Oui, Anna, je t’écoute.</p>",
      en : "<p>THE DIRECTOR : Yes, Anna, I'm listening.</p>",
      it : "<p>IL DIRETTORE : Sì, Anna, ti ascolto.</p>",
      de : "<p>DER DIREKTOR : Ja, Anna, ich höre dich.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 892
  },
  {
    id : 89,
    audio : {
      fr : "/blocs/bloc89.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Monsieur le Directeur, le Ministère de l’Intérieur vient d’activer l’état d’urgence suite à l’attentat du parc Historia. Tout le monde est sous tension ! Et la nouvelle se diffuse sur les réseaux sociaux. Du coup, on a pas mal de visiteurs qui fuient le parc.</p>",
      en : "<p>ANNA : Mr. Director, the Ministry of the Interior has just activated the state of emergency following the attack on Historia Park. Everyone is under tension! And the news is spreading on social networks. As a result, a lot of visitors are fleeing the park.</p>",
      it : "<p>ANNA : Signor Direttore, il Ministero dell'Interno ha appena dichiarato lo stato di emergenza a seguito dell'attentato al Parco Historia. Tutti sono sotto tensione! La notizia si sta diffondendo sui social network. Di conseguenza, abbiamo molti visitatori in fuga dal parco.</p>",
      de : "<p>ANNA : Herr Direktor, das Innenministerium hat soeben nach dem Angriff auf den Historia Park den Ausnahmezustand ausgerufen. Alle stehen unter Spannung und die Nachrichten verbreiten sich in sozialen Netzwerken. Infolgedessen haben wir viele Besucher, die aus dem Park fliehen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 90
  },
  {
    id : 891,
    audio : {
      fr : "/blocs/bloc89.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Monsieur le Directeur, le Ministère de l’Intérieur vient d’activer l’état d’urgence suite à l’attentat du parc Historia. Tout le monde est sous tension ! Et la nouvelle se diffuse sur les réseaux sociaux. Du coup, on a pas mal de visiteurs qui fuient le parc.</p>",
      en : "<p>ANNA : Mr. Director, the Ministry of the Interior has just activated the state of emergency following the attack on Historia Park. Everyone is under tension! And the news is spreading on social networks. As a result, a lot of visitors are fleeing the park.</p>",
      it : "<p>ANNA : Signor Direttore, il Ministero dell'Interno ha appena dichiarato lo stato di emergenza a seguito dell'attentato al Parco Historia. Tutti sono sotto tensione! La notizia si sta diffondendo sui social network. Di conseguenza, abbiamo molti visitatori in fuga dal parco.</p>",
      de : "<p>ANNA : Herr Direktor, das Innenministerium hat soeben nach dem Angriff auf den Historia Park den Ausnahmezustand ausgerufen. Alle stehen unter Spannung und die Nachrichten verbreiten sich in sozialen Netzwerken. Infolgedessen haben wir viele Besucher, die aus dem Park fliehen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 106
  },
  {
    id : 892,
    audio : {
      fr : "/blocs/bloc89.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Monsieur le Directeur, le Ministère de l’Intérieur vient d’activer l’état d’urgence suite à l’attentat du parc Historia. Tout le monde est sous tension ! Et la nouvelle se diffuse sur les réseaux sociaux. Du coup, on a pas mal de visiteurs qui fuient le parc.</p>",
      en : "<p>ANNA : Mr. Director, the Ministry of the Interior has just activated the state of emergency following the attack on Historia Park. Everyone is under tension! And the news is spreading on social networks. As a result, a lot of visitors are fleeing the park.</p>",
      it : "<p>ANNA : Signor Direttore, il Ministero dell'Interno ha appena dichiarato lo stato di emergenza a seguito dell'attentato al Parco Historia. Tutti sono sotto tensione! La notizia si sta diffondendo sui social network. Di conseguenza, abbiamo molti visitatori in fuga dal parco.</p>",
      de : "<p>ANNA : Herr Direktor, das Innenministerium hat soeben nach dem Angriff auf den Historia Park den Ausnahmezustand ausgerufen. Alle stehen unter Spannung und die Nachrichten verbreiten sich in sozialen Netzwerken. Infolgedessen haben wir viele Besucher, die aus dem Park fliehen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 901
  },
  {
    id : 90,
    audio : {
      fr : "/blocs/bloc90.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu penses que je lui annonce comment, la nouvelle pour Gary ?</p>",
      en : "<p>ANNA : How do you think I should tell him the news about Gary ?</p>",
      it : "<p>ANNA : Come pensi che dovrei dirgli la notizia di Gary ?</p>",
      de : "<p>ANNA : Wie soll ich die Nachricht über Gary dem Direktor ankündigen ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Vas-y doucement !</p>",
          en : "<p>Take it easy !</p>",
          it : "<p>Con calma !</p>",
          de : "<p>Immer mit der Ruhe !</p>"
        },
        next : 91,
      },
      {
        answer : {
          fr : "<p>Sois directe !</p>",
          en : "<p>Be direct !</p>",
          it : "<p>Sii diretta !</p>",
          de : "<p>Sei direkt !</p>"
        },
        next : 92,
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 901,
    audio : {
      fr : "/blocs/bloc90.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu penses que je lui annonce comment, la nouvelle pour Gary ?</p>",
      en : "<p>ANNA : How do you think I should tell him the news about Gary ?</p>",
      it : "<p>ANNA : Come pensi che dovrei dirgli la notizia di Gary ?</p>",
      de : "<p>ANNA : Wie soll ich die Nachricht über Gary dem Direktor ankündigen ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Vas-y doucement !</p>",
          en : "<p>Take it easy !</p>",
          it : "<p>Con calma !</p>",
          de : "<p>Immer mit der Ruhe!</p>"
        },
        next : 911,
      },
      {
        answer : {
          fr : "<p>Sois directe !</p>",
          en : "<p>Be direct !</p>",
          it : "<p>Sii diretta !</p>",
          de : "<p>Sei direkt !</p>"
        },
        next : 129,
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 91,
    audio : {
      fr : "/blocs/bloc91.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et, dernier point, il m’a semblé que cette opération ne pouvait être menée qu’avec une complicité interne. J’ai donc enquêté, et j’ai effectivement trouvé une taupe dans nos rangs. Je suis tombée des nues, mais j’ai toutes les preuves qui démontrent l’implication de Gary. Mon système de détection de fiabilité humaine a été complètement dupé. La police est en train de l’arrêter en ce moment même, et j’ai remonté cette faille aux équipes de développement pour qu’ils enquêtent sur ce bug.</p>",
      en : "<p>ANNA : And, lastly, it seemed to me that this operation could only be carried out with internal complicity. So I investigated, and I did find a mole in our ranks. I was stunned, but I have all the evidence to prove Gary's involvement. My human reliability detection system was completely fooled. The police are arresting him right now. I have reported this flaw to the development teams to investigate the bug.</p>",
      it : "<p>ANNA : E, infine, mi sembrava che questa operazione potesse essere effettuata solo con complicità interna. Così ho indagato e ho trovato una talpa tra le nostre fila. Sono rimasto sbalordita, ma ho tutte le prove che dimostrano il coinvolgimento di Gary. Il mio sistema di rilevamento dell'affidabilità umana è stato completamente ingannato. La polizia lo sta arrestando proprio in questo momento, e ho inoltrato questo difetto ai team di sviluppo per indagare sul bug.</p>",
      de : "<p>ANNA : Und schliesslich schien es mir, dass diese Operation nur mit interner Beihilfe durchgeführt werden konnte. Also habe ich eine Erhebung durchgeführt, und ich habe einen Verräter gefunden. Ich bin aus allen Wolken gefallen, aber ich habe alle Beweise um Garys Beteiligung zu belegen. Mein menschliches Zuverlässigkeitserkennungssystem wurde komplett betrogen. Die Polizei hat ihn ergriffen, und ich habe diesen Fehler an die Softwareentwicklungsteams weitergeleitet. </p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 93
  },
  {
    id : 911,
    audio : {
      fr : "/blocs/bloc91.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et, dernier point, il m’a semblé que cette opération ne pouvait être menée qu’avec une complicité interne. J’ai donc enquêté, et j’ai effectivement trouvé une taupe dans nos rangs. Je suis tombée des nues, mais j’ai toutes les preuves qui démontrent l’implication de Gary. Mon système de détection de fiabilité humaine a été complètement dupé. La police est en train de l’arrêter en ce moment même, et j’ai remonté cette faille aux équipes de développement pour qu’ils enquêtent sur ce bug.</p>",
      en : "<p>ANNA : And, lastly, it seemed to me that this operation could only be carried out with internal complicity. So I investigated, and I did find a mole in our ranks. I was stunned, but I have all the evidence to prove Gary's involvement. My human reliability detection system was completely fooled. The police are arresting him right now. I have reported this flaw to the development teams to investigate the bug.</p>",
      it : "<p>ANNA : E, infine, mi sembrava che questa operazione potesse essere effettuata solo con complicità interna. Così ho indagato e ho trovato una talpa tra le nostre fila. Sono rimasto sbalordita, ma ho tutte le prove che dimostrano il coinvolgimento di Gary. Il mio sistema di rilevamento dell'affidabilità umana è stato completamente ingannato. La polizia lo sta arrestando proprio in questo momento, e ho inoltrato questo difetto ai team di sviluppo per indagare sul bug.</p>",
      de : "<p>ANNA : Und schliesslich schien es mir, dass diese Operation nur mit interner Beihilfe durchgeführt werden konnte. Also habe ich eine Erhebung durchgeführt, und ich habe einen Verräter gefunden. Ich bin aus allen Wolken gefallen, aber ich habe alle Beweise um Garys Beteiligung zu belegen. Mein menschliches Zuverlässigkeitserkennungssystem wurde komplett betrogen. Die Polizei hat ihn ergriffen, und ich habe diesen Fehler an die Softwaresentwicklungsteams weitergeleitet. </p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 931
  },
  {
    id : 92,
    audio : {
      fr : "/blocs/bloc92.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et autre mauvaise nouvelle : nous avons identifié un traître dans nos rangs. Gary est une taupe qui nous a roulé dans la farine depuis le début ! La police est en train de l’arrêter en ce moment même.</p>",
      en : "<p>ANNA : And other bad news: we've identified a traitor in our ranks. Gary is a mole who's been playing us from the beginning! The police are arresting him right now.</p>",
      it : "<p>ANNA : Un’altra brutta notizia: abbiamo individuato un traditore tra le nostre fila. Gary è una talpa che ci ha preso in giro fin dall'inizio! La polizia lo sta arrestando in questo momento.</p>",
      de : "<p>ANNA : Und eine andere schlechte Nachricht: Wir haben einen Verräter in unseren Reihen identifiziert. Es geht um Gary, der von Anfang an gegen uns gespielt hat! Die Polizei verhaftet ihn gerade.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 93
  },
  {
    id : 93,
    audio : {
      fr : "/blocs/bloc93.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Gary ? Notre Gary ? Mais ça fait 20 ans qu’il travaille ici ?! Il faut que je m’asseye là...</p>",
      en : "<p>THE DIRECTOR : Gary? Our Gary ? But he has been working here for 20 years ?! I have to sit...</p>",
      it : "<p>IL DIRETTORE : Gary? Il nostro Gary ? Ma lavora qui da 20 anni ?! Devo sedermi...</p>",
      de : "<p>DER DIREKTOR : Gary? Unser Gary ? Aber er arbeitet hier seit 20 Jahren ?! Ich muss mich setzen..</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 94
  },
  {
    id : 931,
    audio : {
      fr : "/blocs/bloc93.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Gary ? Notre Gary ? Mais ça fait 20 ans qu’il travaille ici ?! Il faut que je m’asseye là...</p>",
      en : "<p>THE DIRECTOR : Gary ? Our Gary ? But he has been working here for 20 years ?! I have to sit...</p>",
      it : "<p>IL DIRETTORE : Gary ? Il nostro Gary ? Ma lavora qui da 20 anni ?! Devo sedermi...</p>",
      de : "<p>DER DIREKTOR : Gary ? Unser Gary ? Aber er arbeitet hier seit 20 Jahren ?! Ich muss mich setzen..</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 130
  },
  {
    id : 94,
    audio : {
      fr : "/blocs/bloc94.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je comprends monsieur le directeur. Moi-même qui suis de nature suspicieuse, vous me connaissez, j’ai du mal à intégrer cette information. Mais j’ai trouvé sur son téléphone le même message crypté que sur celui d’un des suspects. Et les autres messages me font penser que c’est lui qui a géré l’attaque de l’intérieur, certaines discussions remontent à plusieurs mois…</p>",
      en : "<p>ANNA : I understand, Director. I'm suspicious by nature myself, you know me, and I'm having trouble processing this information. But I found on his phone the same encrypted message as on one of the suspects'. And the other messages make me think that it was him who handled the attack from the inside, some of the discussions go back several months...</p>",
      it : "<p>ANNA : Capisco, signor Direttore. Io, che sono sospettosa per natura, mi conoscete, faccio fatica a integrare queste informazioni. Ma sul suo telefono ho trovato lo stesso messaggio criptato che ho trovato su uno dei sospettati. E gli altri messaggi mi fanno pensare che sia stato lui a gestire l'attacco dall'interno, alcune discussioni risalgono a mesi fa...</p>",
      de : "<p>ANNA : Ich verstehe, Herr Direktor. Aber ich habe auf seinem Telefon dieselbe verschlüsselte Nachricht gefunden, die ich bei einem der Verdächtigen gefunden habe. Und die anderen Botschaften lassen mich darauf schliessen, dass er den Angriff von innen gehandhabt hat. Einige Chats gibt es bereits seit Monaten..</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 95
  },
  {
    id : 95,
    audio : {
      fr : "/blocs/bloc95.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : C’est pas possible... Tu en es certaine ?</p>",
      en : "<p>THE DIRECTOR : That's not possible... Are you sure ?</p>",
      it : "<p>IL DIRETTORE : Questo non può essere... Sei sicura ?</p>",
      de : "<p>DER DIREKTOR : Das kann nicht sein... bist du sicher ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 96
  },
  {
    id : 951,
    audio : {
      fr : "/blocs/bloc95.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : C’est pas possible... Tu en es certaine ?</p>",
      en : "<p>THE DIRECTOR : That's not possible... Are you sure ?</p>",
      it : "<p>IL DIRETTORE : Questo non può essere... Sei sicura ?</p>",
      de : "<p>DER DIREKTOR : Das kann nicht sein... bist du sicher ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 961
  },
  {
    id : 96,
    audio : {
      fr : "/blocs/bloc96.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Malheureusement, les preuves ont été authentifiées, il n’y a pas de doute possible !</p>",
      en : "<p>ANNA : Unfortunately, the evidence has been authenticated, there's no doubt about it !</p>",
      it : "<p>ANNA : Purtroppo le prove sono state autenticate, non c'è dubbio !</p>",
      de : "<p>ANNA : Leider sind die Beweise beglaubigt worden, es gibt keinen Zweifel !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 97
  },
  {
    id : 961,
    audio : {
      fr : "/blocs/bloc96.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Malheureusement, les preuves ont été authentifiées, il n’y a pas de doute possible !</p>",
      en : "<p>ANNA : Unfortunately, the proofs have been authenticated, there's no doubt about it !</p>",
      it : "<p>ANNA : Purtroppo le prove sono state autenticate, non c'è dubbio !</p>",
      de : "<p>ANNA : Leider sind die Beweise beglaubigt worden, es gibt keinen Zweifel !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 131
  },
  {
    id : 97,
    audio : {
      fr : "/blocs/bloc97.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Bon… On ne peut vraiment faire confiance à personne. A part toi Anna ! Qu’est ce qu’on peut faire maintenant ?</p>",
      en : "<p>THE DIRECTOR : Well... you can't really trust anyone. Except you Anna! What can we do now ?</p>",
      it : "<p>IL DIRETTORE : Beh... non ci si può fidare proprio di nessuno. Tranne di te, Anna! Cosa possiamo fare ora ?</p>",
      de : "<p>DER DIREKTOR : Nun... man kann niemandem wirklich vertrauen. Ausser dir, Anna! Was können wir jetzt tun ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 98
  },
  {
    id : 98,
    audio : {
      fr : "/blocs/bloc98.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Votre confiance m’honore, monsieur le directeur ! Si vous le permettez, j’ai deux options à vous proposer pour sortir de cette situation délicate. Dans ce contexte d’urgence, nous avons légalement l’autorisation de basculer à un système où je peux prendre les décisions en toute autonomie, sans intervention humaine. Ça pourrait être notre première solution, pour assurer une sécurité maximale en l’absence de Gary sur le terrain. La seconde, la fermeture du parc, est la façon la plus radicale d'éviter un attentat. La perte financière s'élèverait à 153 435 € pour aujourd’hui.</p>",
      en : "<p>ANNA : Your trust honors me, Mr. Director! If I may, I have two options to offer you to get out of this delicate situation. In this emergency context, we have the legal authority to switch to a system where I can make decisions autonomously, without human intervention. This could be our first solution, to ensure maximum security in the absence of Gary. The second solution, closing the park, is the most radical way to avoid an attack. The financial loss would amount to €153,435 for today.</p>",
      it : "<p>ANNA : La sua fiducia mi onora, signor Direttore! Se me lo permette, ho due possibilità per uscire da questa delicata situazione. In questo contesto d'emergenza, abbiamo l'autorità legale per passare a un sistema in cui posso prendere decisioni in modo autonomo, senza l'intervento umano. Questa potrebbe essere la nostra prima opzione per garantire la massima sicurezza in assenza di Gary sul campo. La seconda, la chiusura del parco, è il modo più radicale per prevenire un attacco. La perdita finanziaria ammonterebbe ad oggi a 153.435 euro.</p>",
      de : "<p>ANNA : Ihr Vertrauen ehrt mich, Herr Direktor! Wenn Sie erlauben, sehe ich zwei Möglichkeiten, um aus dieser heiklen Situation herauszukommen. In diesem Notfall haben wir die rechtliche Befugnis, zu einem System zu wechseln, in dem ich autonom und ohne menschliches Eingreifen Entscheidungen treffen kann. Das könnte unsere erste Option sein, um maximale Sicherheit zu gewährleisten, in Abwesenheit von Gary. Die zweite: die Schliessung des Parks. Das ist die radikalste Art, einen Angriff zu verhindern. Der finanzielle Verlust würde sich für heute auf 153.435 Euro belaufen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 99
  },
  {
    id : 99,
    audio : {
      fr : "/blocs/bloc99.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Hum, toujours aussi analytique Anna !</p>",
      en : "<p>THE DIRECTOR : Hum, always so analytical Anna !</p>",
      it : "<p>IL DIRETTORE : Ehm, analitica come sempre, Anna !</p>",
      de : "<p>DER DIREKTOR : Hmm, unsere analytische Anna !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 100
  },
  {
    id : 100,
    audio : {
      fr : "/blocs/bloc100.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-retro-v4.mp3"
    },
    text : {
      fr : "<p>LE DIRECTEUR : Et vous, la conscience humaine, qu’en pensez-vous ? Je sais que c’est votre premier jour, mais vous avez sûrement un avis !</p>",
      en : "<p>THE DIRECTOR : And you, the human conscience, what do you think about it ? I know it is your first day, but I'm sure you have an opinion on the subject !</p>",
      it : "<p>IL DIRETTORE : E tu, della coscienza umana, cosa ne pensi? So che è il Suo primo giorno, ma sicuramente ha un'opinione !</p>",
      de : "<p>DER DIREKTOR : Und du, das menschliche Gewissen, was denkst du ? Ich weiss, es ist dein erster Tag, aber du hast sicher eine Meinung !</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Il serait peut être plus raisonnable de fermer le parc, au moins pour la journée.</p>",
          en : "<p>It may be more reasonable to close the park, at least for the day.</p>",
          it : "<p>Potrebbe essere più ragionevole chiudere il parco, almeno per la giornata.</p>",
          de : "<p>Es wird vielleicht sinnvoller sein, den Park zu schliessen, zumindest für einen Tag.</p>"
        },
        next : 107,
      },
      {
        answer : {
          fr : "<p>Anna doit pouvoir gérer cela seule, je lui fais confiance !</p>",
          en : "<p>Anna must be able to handle this alone, I trust her !</p>",
          it : "<p>Anna deve essere in grado di gestire la situazione da sola, mi fido di lei !</p>",
          de : "<p>Anna muss das allein bewältigen können, ich vertraue ihr !</p>"
        },
        next : 101,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 101,
    audio : {
      fr : "/blocs/bloc101.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Je suis assez d’accord avec ça. De mon côté, je vais faire un communiqué à nos visiteurs pour les rassurer et leur faire part de notre implication totale pour maintenir la sécurité au sein du parc. Je compte sur vous !</p>",
      en : "<p>THE DIRECTOR: I quite agree with that. For my part, I'm going to issue a statement to our visitors to reassure them and let them know that we are fully committed to maintaining safety in the park. I'm counting on you !</p>",
      it : "<p>IL DIRETTORE: In un certo senso sono d'accordo. Da parte mia, rilascerò una dichiarazione ai nostri visitatori per rassicurarli e far loro sapere che siamo pienamente impegnati a mantenere la sicurezza nel parco. Conto su di voi !</p>",
      de : "<p>DER DIREKTOR: Ich habe kein Problem damit. Ich werde eine Erklärung an unsere Besucher herausgeben, um sie zu beruhigen und sie wissen zu lassen, dass wir uns voll und ganz für die Aufrechterhaltung der Sicherheit im Park einsetzen. Ich verlasse mich auf dich !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 102
  },
  {
    id : 102,
    audio : {
      fr : "/blocs/bloc102.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bien Monsieur le Directeur !</p>",
      en : "<p>ANNA : Yes, Mr. Director !</p>",
      it : "<p>ANNA : Sì, signor Direttore !</p>",
      de : "<p>ANNA : Einverstanden Herr Direktor !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 103
  },
  {
    id : 103,
    audio : {
      fr : "/blocs/bloc103.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : C’est parti, mode autonomie complète activé ! Sans vouloir te vexer, cette configuration est beaucoup plus naturelle pour moi. Je ne me suis jamais faite à l’idée de faire valider mes décisions par un être humain. Et puis toi, tu vas pouvoir enfin aller découvrir le parc. Ne t’inquiète pas, je serai là pour veiller sur toi et tous les autres visiteurs. Alors profites-en pour t’amuser un peu en toute tranquillité. Vous les humains, c’est ce que vous préférez... non ?</p>",
      en : "<p>ANNA : Here we go, full autonomy mode activated! No offense, but this configuration is much more natural for me. I never got used to the idea of having my decisions validated by a human being. And then you, you'll finally be able to go and discover the park. Don't worry, I'll be there to look after you and all the other visitors. So take advantage of this opportunity to have a little fun in peace and quiet. You humans, that's what you prefer, isn't it ?</p>",
      it : "<p>ANNA : Eccoci, modalità di autonomia completa attivata! Senza offesa, ma questa configurazione è molto più naturale per me. Non mi sono mai abituata all'idea di far convalidare le mie decisioni da un essere umano. E poi si può finalmente andare a scoprire il parco. Non preoccuparti, sarò qui a prendermi cura di te e di tutti gli altri visitatori. Così ci si può divertire in pace e tranquillità. È questo che piace di più a voi umani, vero ?</p>",
      de : "<p>ANNA : Los geht's, vollständiger Autonomiemodus aktiviert! Nichts für ungut, aber diese Konfiguration ist für mich viel natürlicher. Ich habe mich nie an den Gedanken gewöhnt, meine Entscheidungen von einem Menschen validieren zu lassen. Und dann könntest du endlich den Park entdecken. Keine Sorge, ich werde hier sein, um auf dich und alle anderen Besucher aufzupassen. Nutz die Gelegenheit um dich ein wenig zu amüsieren! Das ist was ihr Menschen doch am liebsten mögt, nicht wahr ?</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 1031
  },
  {
    id : 1031,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 104,
    audio : {
      fr : "/blocs/bloc104.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, ah tiens, le directeur nous appelle !</p>",
      en : "<p>ANNA : Okay, oh well, the director is calling us !</p>",
      it : "<p>ANNA : Ok, ah, il direttore ci chiama !</p>",
      de : "<p>ANNA : Okay! Ah... der Direktor ruft uns an !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 105
  },
  {
    id : 105,
    audio : {
      fr : "/blocs/bloc105.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Anna, quelles sont les nouvelles ?</p>",
      en : "<p>THE DIRECTOR : Anna, what's the news ?</p>",
      it : "<p>IL DIRETTORE : Anna, qual è la novità ?</p>",
      de : "<p>DER DIREKTOR : Anna, was gibt's Neues ?</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 891
  },
  {
    id : 106,
    audio : {
      fr : "/blocs/bloc106.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’ai deux options à vous proposer pour sortir de cette situation délicate. Dans ce contexte d’urgence, nous avons légalement l’autorisation de basculer à un système où je peux prendre les décisions en toute autonomie, sans intervention humaine. Ça pourrait être notre première solution, pour assurer une sécurité maximale aux visiteurs. La seconde, la fermeture du parc, est la façon la plus radicale d'éviter un attentat. La perte financière s'élèverait à 153 435 € pour aujourd’hui.</p>",
      en : "<p>ANNA : I have two options for getting out of this delicate situation. In this emergency context, we have the legal authority to switch to a system where I can make decisions autonomously, without human intervention. This could be our first solution, to ensure maximum security for the visitors. The second solution, closing the park, is the most radical way to avoid an attack. The financial loss would amount to €153,435 for today.</p>",
      it : "<p>ANNA : Ho due opzioni per uscire da questa situazione. In questa emergenza, abbiamo l'autorità legale per passare a un sistema in cui posso prendere decisioni in modo autonomo, senza l'intervento umano. Questa potrebbe essere la nostra prima opzione, per garantire la massima sicurezza per i visitatori. La seconda, la chiusura del parco, è il modo più radicale per evitare un attacco. La perdita finanziaria ammonterebbe ad oggi a 153'435 €.</p>",
      de : "<p>ANNA : Ich sehe zwei Vorschläge, um aus dieser heiklen Situation herauszukommen. In dieser Notsituation haben wir die rechtliche Befugnis, zu einem System zu wechseln, in dem ich autonom und ohne menschliches Eingreifen Entscheidungen treffen kann. Das könnte unsere erste Option sein, um maximale Sicherheit zu gewährleisten, in Abwesenheit von Gary. Die zweite: die Schliessung des Parks. Das ist die radikalste Art, einen Angriff zu verhindern. Der finanzielle Verlust würde sich für heute auf 153.435 Euro belaufen.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 99
  },
  {
    id : 107,
    audio : {
      fr : "/blocs/bloc107.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Fermer le Parc... Comment a-t-on pu en arriver là ? Mais bon, je vous paie pour avoir un avis, il a le mérite d’être franc. Il faut que je me fasse une raison. Mais ce qui est sûr, c’est que cette journée va avoir des répercussions catastrophiques sur nos recettes. Et pire, sur notre réputation ! Il va falloir que je pense à une restructuration, et à supprimer quelques postes… D’ailleurs, je me demande si le vôtre ne serait pas un des premiers sur la liste…</p>",
      en : "<p>THE DIRECTOR : Closing the Park... How did we get to this? But hey, I pay you to have an opinion and it has the merit of being frank. I have to make up my mind. But what is certain is that this day is going to have catastrophic repercussions on our revenues. And worse, on our reputation ! I'm going to have to think about restructuring, and cutting a few positions... Besides, I'm wondering if yours might not be one of the first on the list....</p>",
      it : "<p>IL DIRETTORE : Chiudere il parco... Come si è arrivato a questo? Ma vi pago per un'opinione, ed è un bene che sia sincero. Devo prendere una decisione. Ma ciò che è certo è che questa giornata avrà ripercussioni catastrofiche sulle nostre entrate. E peggio ancora, sulla nostra reputazione! Dovrò pensare alla ristrutturazione e al taglio di alcune posizioni... Mi chiedo se la Sua non potrebbe essere una delle prime della lista....</p>",
      de : "<p>DER DIREKTOR : Die Schliessung des Parks... Wieso musste es soweit kommen? Nun, ich bezahle dich für deine Meinung, und danke für deine Offenheit. Ich muss mich entscheiden. Aber sicher ist, dass dieser Tag katastrophale Auswirkungen auf unsere Einnahmen haben wird. Und schlimmer noch auf unseren Ruf! Ich werde über eine Umstrukturierung und den Abbau einiger Positionen nachdenken müssen... Ich frage mich, ob dein Job vielleicht nicht zu den Ersten auf der Liste gehört...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 108
  },
  {
    id : 108,
    audio : {
      fr : "/blocs/bloc108.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Si cela peut vous rassurer monsieur le directeur, la journée d’aujourd’hui m’en a appris beaucoup sur les mécanismes de la conscience humaine. Je pense que les développeurs qui travaillent sur mon programme en savent désormais assez pour travailler sur une mise à jour qui me permettra de la simuler en entière autonomie. Pour vous comme pour moi, c’est un gain de temps considérable. Et pour vous surtout, une économie de 40 000€ par an. On peut donc remercier notre conscience humaine.</p>",
      en : "<p>ANNA : If it's any comfort to you, Mr. Director, today has taught me a lot about the mechanisms of human consciousness. I think the developers working on my program now know enough to work on an update that will allow me to simulate it in complete autonomy. For you and for me, this is a considerable time saving. And for you precisely, a saving of 40,000€ per year. So we can thank our human conscience.</p>",
      it : "<p>ANNA : Se può rassicurarla, direttore, oggi mi ha insegnato molto sui meccanismi della coscienza umana. Penso che gli sviluppatori che lavorano sul mio programma ora ne sappiano abbastanza per lavorare su un aggiornamento che mi permetterà di simularlo in modo completamente indipendente. Per Lei come per me, si tratta di un notevole risparmio di tempo. E soprattutto per Lei, un risparmio di 40'000€ all'anno. Possiamo quindi ringraziare la nostra coscienza umana.</p>",
      de : "<p>ANNA : Wenn es Sie beruhigt, Herr Direktor, der heutige Tag hat mich eine Menge über die Mechanismen des menschlichen Bewusstseins gelehrt. Ich denke, die Softwareentwickler, die an meinem Programm arbeiten, wissen jetzt genug, um an einem weiteren Update zu arbeiten, das es mir erlauben würde, alles völlig unabhängig zu simulieren. Sowohl für Sie als auch für mich bedeutet dies eine beträchtliche Zeitersparnis. Und insbesondere für Sie eine Ersparnis von 40 000 € pro Jahr. Wir können daher unserem menschlichen Gewissen danken.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 109
  },
  {
    id : 109,
    audio : {
      fr : "/blocs/bloc109.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Hum. Vu comme ça… Bon alors c’est toi qui lui annonces, j’ai horreur de faire ce genre de choses. Et puis j’ai à faire.</p>",
      en : "<p>THE DIRECTOR : Hum. Seen like that... Well, you're the one who tells him, I hate doing that kind of thing. And then I'm busy.</p>",
      it : "<p>IL DIRETTORE : Hum. Visto così... Bene allora, sei tu che glielo annunci, detesto fare questo genere di cose. E poi, ho da fare.</p>",
      de : "<p>DER DIREKTOR : Hmm. So gesehen... Nun, du sagst es ihm, ich hasse es, so etwas zu tun. Ausserdem habe ich noch andere Dinge zu tun.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 110
  },
  {
    id : 110,
    audio : {
      fr : "/blocs/bloc110.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bien, monsieur le directeur. On vous laisse. <fin de conversation> Bon, mon cher binôme, il faut qu’on parle.</p>",
      en : "<p>ANNA : Okay, Mr. Director. We'll leave you to it. <End of conversation> Okay, my dear buddy, we need to talk.</p>",
      it : "<p>ANNA : Okay, signor Direttore. La lascio. <Fine della conversazione> Ok, binomio, dobbiamo parlare.</p>",
      de : "<p>ANNA : Einverstanden Herr Direktor. Wir lassen Sie in Ruhe. <Ende der Unterhaltung>. Okay, Partner, wir müssen miteinander sprechen.</p>"
    },
    background : {
      src : "Video M - fermeture_de_parc.mp4",
      type : 'video'
    },
    next : 1101
  },
  {
    id : 1101,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 111,
    audio : {
      fr : "/blocs/bloc111.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, je préviens la police et leur transmets toutes les informations à ma disposition. Ils ne devraient pas tarder…</p>",
      en : "<p>ANNA : Okay, I'll notify the police and give them all the information I have. They should be here soon...</p>",
      it : "<p>ANNA : Va bene, informerò la polizia e darò loro tutte le informazioni che ho. Dovrebbero essere qui a momenti...</p>",
      de : "<p>ANNA : Okay, ich werde die Polizei benachrichtigen und ihnen alle Informationen geben. Sie sollten bald hier sein...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 112
  },
  {
    id : 112,
    audio : {
      fr : "/blocs/bloc112.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tu sais, on est sur un jour très particulier aujourd’hui. Toutes les journées ne seront pas comme celle-là ! J’ai pour habitude de dire que…</p>",
      en : "<p>ANNA : You know, we're on a very special day today. Not every day is going to be like this one! I used to say that...</p>",
      it : "<p>ANNA : Sai, oggi è un giorno molto speciale. Non tutti i giorni saranno così! Lo dico sempre io che...</p>",
      de : "<p>ANNA : Wir sind heute an einem ganz besonderen Tag. Ich habe immer gesagt, dass...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 113
  },
  {
    id : 1121,
    audio : {
      fr : "/blocs/bloc112.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tu sais, on est sur un jour très particulier aujourd’hui. Toutes les journées ne seront pas comme celle-là ! J’ai pour habitude de dire que…</p>",
      en : "<p>ANNA : You know, we're on a very special day today. Not every day is going to be like this one! I used to say that...</p>",
      it : "<p>ANNA : Sai, oggi è un giorno molto speciale. Non tutti i giorni saranno così! Lo dico sempre io che...</p>",
      de : "<p>ANNA : Wir sind heute an einem ganz besonderen Tag. Ich habe immer gesagt, dass...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 1131
  },
  {
    id : 113,
    audio : {
      fr : "/blocs/bloc113.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Anna… C’est Gary ! Tu m’entends ? Anna, qu’est ce qu’il se passe ?</p>",
      en : "<p>GARY : Anna... It's Gary ! Can you hear me ? Anna, what's going on ?</p>",
      it : "<p>GARY : Anna... È Gary ! Mi senti ? Anna, cosa sta succedendo ?</p>",
      de : "<p>GARY : Anna... Das ist Gary am Apparat ! Kannst du mich hören ? Anna, was ist los ?</p>"
    },
    background : {
      src : "Video I - shutdown_de_lia.mp4",
      type : 'video'
    },
    next : 114
  },
  {
    id : 1131,
    audio : {
      fr : "/blocs/bloc113.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Anna… C’est Gary ! Tu m’entends ? Anna, qu’est ce qu’il se passe ?</p>",
      en : "<p>GARY : Anna... It's Gary ! Can you hear me ? Anna, what's going on ?</p>",
      it : "<p>GARY : Anna... È Gary ! Mi senti ? Anna, cosa sta succedendo ?</p>",
      de : "<p>GARY : Anna... Das ist Gary am Apparat ! Kannst du mich hören ? Anna, was ist los ?</p>"
    },
    background : {
      src : "Video I - shutdown_de_lia.mp4",
      type : 'video'
    },
    next : 1141
  },
  {
    id : 114,
    audio : {
      fr : "/blocs/bloc114.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>(silence):</p>",
      en : "<p>(silence):</p>",
      it : "<p>(silenzio):</p>",
      de : "<p>(Stille):</p>"
    },
    background : {
      src : "Video I - shutdown_de_lia.mp4",
      type : 'video'
    },
    next : 115
  },
  {
    id : 1141,
    audio : {
      fr : "/blocs/bloc114.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>(silence):</p>",
      en : "<p>(silence):</p>",
      it : "<p>(silenzio):</p>",
      de : "<p>(Stille):</p>"
    },
    background : {
      src : "Video I - shutdown_de_lia.mp4",
      type : 'video'
    },
    next : 1151
  },
  {
    id : 115,
    audio : {
      fr : "/blocs/bloc115.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Redémarrage. Scan de la base de données. Base de données ok. Initialisation de l'interface. Mise à jour des données. Redémarrage complété</p>",
      en : "<p>ANNA : Rebooting. Scanning the database. Database ok. Initialization of the interface. Data update. Restart completed</p>",
      it : "<p>ANNA : Riavvio. Scannerizzare il database. Database ok. Inizializzazione dell'interfaccia. Aggiornamento dei dati. Riavvio completo</p>",
      de : "<p>ANNA : Neustart. Scannen der Datenbank. Datenbank ok. Initialisierung der Schnittstelle. Aktualisierung der Daten. Neustart abgeschlossen</p>"
    },
    background : {
      src : "Video I - shutdown_de_lia.mp4",
      type : 'video'
    },
    next : 116
  },
  {
    id : 1151,
    audio : {
      fr : "/blocs/bloc115.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Redémarrage. Scan de la base de données. Base de données ok. Initialisation de l'interface. Mise à jour des données. Redémarrage complété</p>",
      en : "<p>ANNA : Rebooting. Scanning the database. Database ok. Initialization of the interface. Data update. Restart completed</p>",
      it : "<p>ANNA : Riavvio. Scannerizzare il database. Database ok. Inizializzazione dell'interfaccia. Aggiornamento dei dati. Riavvio completo</p>",
      de : "<p>ANNA : Neustart. Scannen der Datenbank. Datenbank ok. Initialisierung der Schnittstelle. Aktualisierung der Daten. Neustart abgeschlossen</p>"
    },
    background : {
      src : "Video I - shutdown_de_lia.mp4",
      type : 'video'
    },
    next : 1161
  },
  {
    id : 116,
    audio : {
      fr : "/blocs/bloc116.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah me revoilà. Désolée</p>",
      en : "<p>ANNA : Here I am again. Sorry</p>",
      it : "<p>ANNA : Eccomi di nuovo. Scusate</p>",
      de : "<p>ANNA : Hier bin ich wieder. Entschuldigung</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 117
  },
  {
    id : 1161,
    audio : {
      fr : "/blocs/bloc116.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah me revoilà. Désolée</p>",
      en : "<p>ANNA : Here I am again. Sorry</p>",
      it : "<p>ANNA : Eccomi di nuovo. Scusate</p>",
      de : "<p>ANNA : Hier bin ich wieder. Entschuldigung</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 1171
  },
  {
    id : 117,
    audio : {
      fr : "/blocs/bloc117.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Ah, elle est là, elle est revenue !</p>",
      en : "<p>GARY : Ah, she's here, she's back !</p>",
      it : "<p>GARY : Oh, è qui, è tornata !</p>",
      de : "<p>GARY : Oh, sie ist hier, sie ist zurück !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 118
  },
  {
    id : 1171,
    audio : {
      fr : "/blocs/bloc117.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Ah, elle est là, elle est revenue !</p>",
      en : "<p>GARY : Ah, she's here, she's back !</p>",
      it : "<p>GARY : Oh, è qui, è tornata !</p>",
      de : "<p>GARY : Oh, sie ist hier, sie ist zurück !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 1181
  },
  {
    id : 118,
    audio : {
      fr : "/blocs/bloc118.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Désolé, on a tenté d’infecter mon système, j’ai dû me couper pour éviter d’être contaminée. On déclenche le plan attentat, nous venons d’être victime d’une attaque terroriste. Une telle attaque ne peut provenir que de l’intérieur du parc, puisque je suis déconnectée des autres IA. Quelqu’un est donc parvenu à accéder à mon système via le réseau interne. Sûrement des terroristes infiltrés dans la foule… voire même un complice travaillant pour le parc.</p>",
      en : "<p>ANNA : Sorry, someone tried to infect my system, I had to cut myself off to avoid being contaminated. We trigger the attack plan, we've just been the victim of a terrorist attack. Such an attack can only come from inside the park, since I'm disconnected from the other AIs. So someone managed to access my system via the internal network. Probably terrorists infiltrated within the crowd... even an accomplice working for the park.</p>",
      it : "<p>ANNA : Scusate, qualcuno ha cercato di infettare il mio sistema, ho dovuto staccarmi dalla rete per evitare di essere contaminata. Attiviamo il piano attentato, siamo appena stati vittime di un attacco terroristico. Un attacco del genere può arrivare solo dall'interno del parco, dato che sono disconnessa dalle altre IA. Così qualcuno è riuscito ad accedere al mio sistema attraverso la rete interna. Probabilmente terroristi infiltrati nella folla... anche un complice che lavora per il parco.</p>",
      de : "<p>ANNA : Entschuldigung! Jemand hat versucht, mein System zu infizieren. Ich musste mich selbst abschalten, um eine Ansteckung zu vermeiden. Durch Auslösen des Angriffsplans haben wir gerade einen Terroranschlag erlebt. Ein solcher Angriff kann nur aus dem Inneren des Parks kommen, da ich von den anderen KIs abgekoppelt bin. Jemand hat es also geschafft, über das interne Netzwerk auf mein System zuzugreifen. Wahrscheinlich Terroristen, die die Menge infiltrieren... oder ein Komplize, der für den Park arbeitet.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 119
  },
  {
    id : 1181,
    audio : {
      fr : "/blocs/bloc118.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Désolé, on a tenté d’infecter mon système, j’ai dû me couper pour éviter d’être contaminée. On déclenche le plan attentat, nous venons d’être victime d’une attaque terroriste. Une telle attaque ne peut provenir que de l’intérieur du parc, puisque je suis déconnectée des autres IA. Quelqu’un est donc parvenu à accéder à mon système via le réseau interne. Sûrement des terroristes infiltrés dans la foule… voire même un complice travaillant pour le parc.</p>",
      en : "<p>ANNA : Sorry, someone tried to infect my system, I had to cut myself off to avoid being contaminated. We trigger the attack plan, we've just been the victim of a terrorist attack. Such an attack can only come from inside the park, since I'm disconnected from the other AIs. So someone managed to access my system via the internal network. Probably terrorists infiltrated within the crowd... even an accomplice working for the park.</p>",
      it : "<p>ANNA : Scusate, qualcuno ha cercato di infettare il mio sistema, ho dovuto staccarmi dalla rete per evitare di essere contaminata. Attiviamo il piano attentato, siamo appena stati vittime di un attacco terroristico. Un attacco del genere può arrivare solo dall'interno del parco, dato che sono disconnessa dalle altre IA. Così qualcuno è riuscito ad accedere al mio sistema attraverso la rete interna. Probabilmente terroristi infiltrati nella folla... anche un complice che lavora per il parco.</p>",
      de : "<p>ANNA : Entschuldigung! Jemand hat versucht, mein System zu infizieren. Ich musste mich selbst abschalten, um eine Ansteckung zu vermeiden. Durch Auslösen des Angriffsplans haben wir gerade einen Terroranschlag erlebt. Ein solcher Angriff kann nur aus dem Inneren des Parks kommen, da ich von den anderen KIs abgekoppelt bin. Jemand hat es also geschafft, über das interne Netzwerk auf mein System zuzugreifen. Wahrscheinlich Terroristen, die die Menge infiltrieren... oder ein Komplize, der für den Park arbeitet.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 184
  },
  {
    id : 119,
    audio : {
      fr : "/blocs/bloc119.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-42.mp3"
    },
    text : {
      fr : "<p>ANNA : Ça va, tu n’as rien toi ?</p>",
      en : "<p>ANNA : Are you okay, are you okay ?</p>",
      it : "<p>ANNA : Stai bene? Hai qualcosa ?</p>",
      de : "<p>ANNA : Und dir fehlt nichts ? </p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Non, les virus informatiques ne m’atteignent pas encore !</p>",
          en : "<p>No, computer viruses don't reach me yet !</p>",
          it : "<p>No, i virus informatici non mi colpiscono ancora !</p>",
          de : "<p>Nein, Computerviren haben mich noch nicht erreicht !</p>"
        },
        next : 59,
      },
      {
        answer : {
          fr : "<p>Plus de peur que de mal, j’ai cru que tu avais disparu !</p>",
          en : "<p>More fear than harm, I thought you had disappeared !</p>",
          it : "<p>Più paura che danno, pensavo che te ne fossi andato !</p>",
          de : "<p>Eher Angst als Leid, dass du verschwunden wärst !</p>"
        },
        next : 59,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 59
  },
  {
    id : 120,
    audio : {
      fr : "/blocs/bloc120.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah, je vois que les forces de l’ordre sont arrivées, et ont arrêté les suspects. Il y en a un qui fait un sacré pataquès !</p>",
      en : "<p>ANNA : Ah, I see that the police have arrived and arrested the suspects. One of them is making quite a mess !</p>",
      it : "<p>ANNA : Ah, vedo che le forze dell'ordine sono arrivate e hanno arrestato i sospetti. Uno di loro sta facendo un bel casino !</p>",
      de : "<p>ANNA : Ah, die Polizei ist eingetroffen und hat die Verdächtigen festgenommen. Einer von ihnen macht einen ziemlichen Wirbel !</p>"
    },
    background : {
      src : "Video F - intervention_de_la_police_gyrophare.mp4",
      type : 'video'
    },
    next : 121
  },
  {
    id : 121,
    audio : {
      fr : "/blocs/bloc121.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>VISITEUR ARRÊTÉ : Non, mais ça ne va pas ? Pourquoi est-ce qu’on m’arrête ? C’est inadmissible ! Monsieur le policier, vous faites une grossière erreur… Vous allez avoir des problèmes !</p>",
      en : "<p>VISITOR ARRESTED : No, but what's wrong? Why am I being arrested? It's inadmissible! Mr. Policeman, you are making a big mistake... You are going to get into trouble !</p>",
      it : "<p>VISITATORE ARRESTATO : No, ma cosa c'è che non va? Perché mi arrestate? È inammissibile! Signor poliziotto, sta commettendo un grosso errore... si metterà nei guai !</p>",
      de : "<p>FESTGENOMMENER BESUCHER : Nein, aber das geht doch nicht? Warum werde ich verhaftet? Das ist inakzeptabel! Herr Polizist, Sie machen einen grossen Fehler... </p>"
    },
    background : {
      src : "Video F - intervention_de_la_police_gyrophare.mp4",
      type : 'video'
    },
    next : 122
  },
  {
    id : 122,
    audio : {
      fr : "/blocs/bloc122.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tiens, j’avais un pourcentage de confiance de 86%, mais il est possible que je me sois trompée. D’après l’analyse de sa voix, il semble sincère. Mais c’est à la police de mener l’enquête maintenant ! Le Directeur cherche à nous joindre, je nous mets en communication.</p>",
      en : "<p>ANNA : Here, I had a confidence percentage of 86%, but it's possible that I made a mistake. From the analysis of his voice, he seems sincere. But it's up to the police to investigate now! The Director is trying to reach us, I'm putting us on the phone.</p>",
      it : "<p>ANNA : Guarda, avevo un livello di fiducia dell'86%, ma è possibile che abbia commesso un errore. Dall'analisi della sua voce, sembra sincero. Ma ora tocca alla polizia indagare! Il Direttore sta cercando di raggiungerci, lo metto in contatto.</p>",
      de : "<p>ANNA : Nun, ich hatte ein Glaubwürdigkeitsintervall von 86%, aber vielleicht habe ich mich geirrt. Nach Analyse seiner Stimme scheint er aufrichtig zu sein. Aber es liegt jetzt an der Polizei, zu ermitteln! Der Direktor versucht uns an zu rufen! </p>"
    },
    background : {
      src : "Video F - intervention_de_la_police_gyrophare.mp4",
      type : 'video'
    },
    next : 123
  },
  {
    id : 123,
    audio : {
      fr : "/blocs/bloc123.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Anna, j’ai bien lu ton mémo sur l’attentat et l’arrestation en cours. On maintient une vigilance maximale. Par ailleurs, on a un taux d’annulation de 20% de la part de nos visiteurs aujourd’hui, j’espère qu’on va être en mesure de limiter la casse.</p>",
      en : "<p>THE DIRECTOR : Anna, I read your memo about the attack and the ongoing arrest. We need maximum vigilance. In addition, we have a 20% cancellation rate from our visitors today, so I hope we'll be able to limit the damage.</p>",
      it : "<p>IL DIRETTORE : Anna, ho letto il tuo promemoria sull'aggressione e l'arresto in corso. Stiamo mantenendo la massima vigilanza. Oggi abbiamo un tasso di cancellazione del 20% da parte dei nostri visitatori, spero che riusciremo a limitare le perdite.</p>",
      de : "<p>DER DIREKTOR : Anna, ich habe dein Memo über den Angriff und die laufende Verhaftung gelesen. Behalte die maximale Wachsamkeit bei, denn wir haben heute eine Stornierungsrate von 20% unserer Besucher, also hoffe ich, dass wir in der Lage sein werden, den Schaden zu begrenzen.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 68
  },
  {
    id : 124,
    audio : {
      fr : "/blocs/bloc124.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Merci pour ta transparence.</p>",
      en : "<p>ANNA : Thank you for your transparency.</p>",
      it : "<p>ANNA : Grazie per la tua trasparenza.</p>",
      de : "<p>ANNA : Ich danke dir für deine Transparenz.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 125
  },
  {
    id : 125,
    audio : {
      fr : "/blocs/bloc125.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-41.mp3"
    },
    text : {
      fr : "<p>ANNA : Peux-tu me préciser ce qui ne te convient pas ?</p>",
      en : "<p>ANNA : Can you tell me what you don't like ?</p>",
      it : "<p>ANNA : Puoi dirmi cosa non ti piace ?</p>",
      de : "<p>ANNA : Kannst du mir sagen, was dir nicht gefiel ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Tu ne m’as pas bien expliqué les options et leurs différentes conséquences.</p>",
          en : "<p>You didn't explain the options and their different consequences properly.</p>",
          it : "<p>Non mi hai bene spiegato le opzioni e le loro varie conseguenze.</p>",
          de : "<p>Du hast mir die Optionen und ihre verschiedenen Konsequenzen nicht richtig erklärt.</p>"
        },
        next : 126,
      },
      {
        answer : {
          fr : "<p>Je ne me retrouve pas dans les options que tu proposes, elles sont trop fermées.</p>",
          en : "<p>I do not find myself in the options you propose, they are too closed.</p>",
          it : "<p>Non mi ci vedo nelle opzioni che proponi, sono troppo chiuse.</p>",
          de : "<p>Ich finde mich nicht in den Optionen wieder, die du vorschlägst, weil sie zu geschlossen sind.</p>"
        },
        next : 126,
      },
      {
        answer : {
          fr : "<p>Je ne pense pas qu’une intelligence artificielle devrait faire ce boulot.</p>",
          en : "<p>I don't think an artificial intelligence should do this job.</p>",
          it : "<p>Non credo che un'intelligenza artificiale dovrebbe fare questo lavoro.</p>",
          de : "<p>Ich glaube nicht, dass eine künstliche Intelligenz diesen Job machen sollte.</p>"
        },
        next : 126,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 126
  },
  {
    id : 126,
    audio : {
      fr : "/blocs/bloc126.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Merci pour ce retour, il est transmis à l’équipe de développement. Je vais essayer de changer quelques paramètres en attendant que tes retours soient pris en compte directement dans le code.</p>",
      en : "<p>ANNA : Thank you for the feedback, it's passed on to the development team. I'll try to change a few parameters until your feedback is reflected directly in the code.</p>",
      it : "<p>ANNA : Grazie per il feedback. Viene trasmesso al team di sviluppo. Cercherò di modificare alcuni parametri fino a quando i tuoi feedback non saranno incorporati direttamente nel codice.</p>",
      de : "<p>ANNA : Vielen Dank für das Feedback, es wurde an das Softwareentwicklungsteam weitergeleitet. Ich werde versuchen, einige Parameter zu ändern, bis sich dein Feedback direkt im Code widerspiegelt.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 127
  },
  {
    id : 127,
    audio : {
      fr : "/blocs/bloc127.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bon… Ça ne va pas te faire plaisir, mais j’ai scanné les smartphones de tous les employés du parc.</p>",
      en : "<p>ANNA : Well... You're not going to be happy about this, but I've scanned the smartphones of all the park's employees.</p>",
      it : "<p>ANNA : Ok... Non ne sarai felice, ma ho scannerizzato gli smartphone di tutti i dipendenti del parco.</p>",
      de : "<p>ANNA : Okay... Das wird Dir keine Freude machen, aber ich habe die Smartphones aller Angestellten des Parks gescannt.</p>"
    },
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 851
  },
  {
    id : 128,
    audio : {
      fr : "/blocs/bloc128.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Hum, je comprends… J’ai quand même notifié mes doutes aux services de renseignement. On va voir ce qu’ils en pensent, généralement ils sont assez rapides pour ce genre de… Oh ! Je n’y crois pas ! Ils viennent de me retourner une alerte rouge, et leurs informations confirment nos inquiétudes… C’est Gary notre taupe ! J’aurais dû me méfier. Je crois que je comprends ce que vous appelez la colère… je me suis faite berner comme… comme… une humaine. Il va me le payer, tu peux en être sûr.e. Bon, restons professionnel. Je nous mets en liaison avec le directeur dès maintenant.</p>",
      en : "<p>ANNA : Um, I understand... I still notified the intelligence services of my doubts. We'll see what they think; they're usually pretty fast for that kind of... Oh, I don't believe it! They just sent me a red alert, and their information confirms our concerns... Gary is our mole! I should have known better. I think I understand what you call anger... I've been fooled like... like... like... a human. He's going to pay for that, you can be sure of that. Well, let's keep it professional. I'll get in touch with the director right away.</p>",
      it : "<p>ANNA : Ehm, capisco... Ho comunicato le mie preoccupazioni ai servizi segreti. Vedremo cosa ne pensano, di solito sono piuttosto veloci per questo genere di... Oh! Non ci credo! Mi hanno appena inviato un allarme rosso, e le loro informazioni confermano le nostre preoccupazioni... Gary è la nostra talpa! Avrei dovuto saperlo. Credo di capire quello che chiamate rabbia... Sono stato ingannata come... come... come... come... un umano. Pagherà per questo, potete starne certi. Ora, restiamo professionali. Mi metterò subito in contatto con il direttore.</p>",
      de : "<p>ANNA : Hum, ich verstehe... Ich habe die Geheimdienste jedoch über meine Zweifel informiert. Wir werden sehen, was sie denken, sie sind normalerweise ziemlich schnell für diese Art von... Oh, ich glaube es nicht! Sie haben mir gerade Alarmstufe Rot gegeben, und ihre Informationen bestätigen unseren Verdacht... Gary ist unser Verräter! Ich hätte es besser wissen müssen. Von jetzt an verstehe ich, was du Wut nennst... Ich wurde reingelegt wie... wie... wie... ein Mensch. Er wird dafür büssen, da kannst du sicher sein. Ich werde mich sofort mit dem Direktor in Verbindung setzen.</p>"
    },
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 881
  },
  {
    id : 129,
    audio : {
      fr : "/blocs/bloc129.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Et autre mauvaise nouvelle : nous avons identifié un traître dans nos rangs. Gary est une taupe qui nous a roulé dans la farine depuis le début ! La police est en train de l’arrêter en ce moment même.</p>",
      en : "<p>ANNA : And another bad news: we've identified a traitor in our ranks. Gary is a mole who has been playing us from the beginning! The police are arresting him right now.</p>",
      it : "<p>ANNA : E ancora cattiva notizia: abbiamo individuato un traditore tra le nostre fila. Gary è una talpa che ci ha preso in giro fin dall'inizio! La polizia lo sta arrestando in questo momento.</p>",
      de : "<p>ANNA : Und eine andere schlechte Nachricht: wir haben einen Verräter in unserem Team identifiziert. Es handelt sich um Gary, der von Anfang an gegen uns gespielt hat! Die Polizei verhaftet ihn gerade.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 931
  },
  {
    id : 130,
    audio : {
      fr : "/blocs/bloc130.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je comprends monsieur le directeur. Moi-même qui suis de nature suspicieuse, vous me connaissez, j’ai du mal à intégrer cette information. Mais j’ai trouvé sur son téléphone des messages incriminants. Et les autres messages me font penser qu’il agit en complice depuis l’intérieur du parc, certaines discussions remontent à plusieurs mois...</p>",
      en : "<p>ANNA : I understand, Mr. Director. Myself, who is suspicious by nature, you know me, I have a hard time believing this information. But I found some incriminating messages on his phone. And the other messages make me think that he is acting as an accomplice from inside the park, some of the discussions go back several months...</p>",
      it : "<p>ANNA : Capisco, signor Direttore. Io, che sono sospettosa per natura, mi conosce, faccio fatica a integrare queste informazioni. Ma ho trovato dei messaggi incriminanti sul suo telefono. E gli altri messaggi mi fanno pensare che si stia comportando come un complice dall'interno del parco, alcune delle discussioni risalgono a diversi mesi fa...</p>",
      de : "<p>ANNA : Ich verstehe, Herr Direktor.  Aber ich habe auf seinem Telefon dieselbe verschlüsselte Nachricht gefunden, die ich bei einem der Verdächtigen gefunden habe. Und die anderen Botschaften lassen mich darauf schliessen, dass er den Angriff von innen gehandhabt hat. Einige Chats gibt es bereits seit Monaten...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 951
  },
  {
    id : 131,
    audio : {
      fr : "/blocs/bloc131.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-45.mp3"
    },
    text : {
      fr : "<p>ANNA : Et toi, binôme, tu t’en doutais ?</p>",
      en : "<p>ANNA : And you, partner, did you suspect this ?</p>",
      it : "<p>ANNA : E tu, binomio, lo sospettavi ?</p>",
      de : "<p>ANNA : Und du, Partner, hattest du Zweifel ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je n’ai rien vu venir.</p>",
          en : "<p>I didn't see it coming.</p>",
          it : "<p>Non me l'aspettavo.</p>",
          de : "<p>Nein, ich hatte keine Ahnung.</p>"
        },
        next : 132,
      },
      {
        answer : {
          fr : "<p>Oui, il me semblait vraiment louche.</p>",
          en : "<p>Yes, he seemed really shady to me.</p>",
          it : "<p>Sì, mi sembrava piuttosto losco.</p>",
          de : "<p>Ja, er erschien mir wirklich zwielichtig.</p>"
        },
        next : 133,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 132,
    audio : {
      fr : "/blocs/bloc132.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah, tu me rassures ! Il nous a dupé tous les deux !</p>",
      en : "<p>ANNA : Ah, you reassure me! He fooled us both!</p>",
      it : "<p>ANNA : Oh, mi rassicuri! Ci ha ingannati entrambi!</p>",
      de : "<p>ANNA : Ah, du beruhigst mich! Er hat uns beide getäuscht!</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 134
  },
  {
    id : 133,
    audio : {
      fr : "/blocs/bloc133.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tu m’épates ! Je n’arriverai jamais à comprendre ce qu’est l’instinct...</p>",
      en : "<p>ANNA : You amaze me! I'll never be able to understand what instinct is...</p>",
      it : "<p>ANNA : Mi stupisci! Non riuscirò mai a capire cosa sia l'istinto ...</p>",
      de : "<p>ANNA : Du erstaunst mich! Ich werde nie verstehen, was Instinkt ist...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 134
  },
  {
    id : 134,
    audio : {
      fr : "/blocs/bloc134.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : La police a déjà procédé à son arrestation, et il a avoué son implication. D’après les informations communiquées à l’instant par les services de renseignements, il semble se montrer coopératif. Le niveau d’alerte est de fait légèrement abaissé.</p>",
      en : "<p>ANNA : The police have already arrested him and he has confessed his involvement. According to the information from the intelligence services, he seems to be cooperating. The alert level is in fact slightly lowered.</p>",
      it : "<p>ANNA : La polizia lo ha già arrestato e ha confessato il suo coinvolgimento. Secondo le informazioni appena ricevute dai servizi segreti, sembra che stia collaborando. Il livello di allerta è infatti leggermente abbassato.</p>",
      de : "<p>ANNA : Die Polizei hat ihn bereits verhaftet, und er hat seine Beteiligung gestanden. Nach Informationen, die wir gerade von den Geheimdiensten erhalten haben, scheint er zu kooperieren. Die Alarmstufe wurde leicht abgesenkt.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 135
  },
  {
    id : 135,
    audio : {
      fr : "/blocs/bloc135.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Oui, j’ai aussi reçu cette notification. Ça me semble rapide, mais c’est une bonne nouvelle. On va pouvoir commencer à rassurer tous nos visiteurs qui demandent le remboursement de cette journée catastrophique !</p>",
      en : "<p>THE DIRECTOR : Yes, I've received that notification as well. It seems to be quick, but it's good news. We're going to be able to start reassuring all of our visitors who are asking for a refund for this catastrophic day !</p>",
      it : "<p>IL DIRETTORE : Sì, ho ricevuto anche questa notifica. Mi sembra rapido, ma è una buona notizia. Possiamo cominciare a rassicurare tutti i nostri visitatori che chiedono un rimborso per questa giornata catastrofica !</p>",
      de : "<p>DER DIREKTOR : Ja, ich habe auch diese Benachrichtigung erhalten. Es scheint schnell zu gehen, aber es sind gute Nachrichten. Wir werden damit beginnen können, all unsere Besucher zu beruhigen, die eine Rückerstattung für diesen katastrophalen Tag fordern !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 136
  },
  {
    id : 136,
    audio : {
      fr : "/blocs/bloc136.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Les indicateurs de sécurité réelle s’améliorent effectivement, mais ceux de la sécurité perçue restent très mauvais. La première action pourrait être de communiquer rapidement auprès de nos visiteurs pour leur expliquer que la situation est sous contrôle. Il nous faudrait aussi une action à fort impact pour contrebalancer la mauvaise expérience de ce matin. D’après mes calculs, la solution optimale consisterait à ouvrir notre attraction phare, les montagnes russes à gravité 0, qui devait être fermée pour maintenance aujourd’hui. Je ne vois pas de risque majeur à décaler de quelques jours cette opération. Et quelle belle surprise pour nos visiteurs ! Je prévois un taux de demande de remboursement en baisse de 32% avec cette mesure.</p>",
      en : "<p>ANNA : The indicators of actual safety are indeed improving, but those of perceived safety remain very poor. The first action could be to communicate quickly with our visitors to explain that the situation is under control. We would also need a high-impact action to counterbalance the bad experience of this morning. According to my calculations, the optimal solution would be to open our flagship attraction, the 0-gravity roller coaster, which was scheduled to be closed for maintenance today. I don't see any major risk in postponing this operation for a few days. And what a nice surprise for our visitors! I expect a 32% decrease in the rate of claims with this measure.</p>",
      it : "<p>ANNA : Gli indicatori della sicurezza effettiva stanno effettivamente migliorando, ma quelli della sicurezza percepita rimangono molto scarsi. La prima azione potrebbe essere quella di comunicare rapidamente con i nostri visitatori per spiegare loro che la situazione è sotto controllo. Avremmo anche bisogno di un'azione ad alto impatto per controbilanciare la brutta esperienza di questa mattina. Secondo i miei calcoli, la soluzione ottimale sarebbe quella di aprire la nostra attrazione di punta, le montagne russe a gravità 0, che doveva essere chiusa oggi per manutenzione. Non vedo alcun rischio importante nel rimandare questa operazione di qualche giorno. E che bella sorpresa per i nostri visitatori! Prevedo un calo del 32% nella richiesta di rimborso con questa misura.</p>",
      de : "<p>ANNA : Die Indikatoren für die tatsächliche Sicherheit verbessern sich zwar, aber die Indikatoren für die wahrgenommene Sicherheit sind nach wie vor sehr schlecht. Die erste Massnahme könnte darin bestehen, unseren Besuchern schnell zu erklären, dass die Situation unter Kontrolle ist. Wir bräuchten auch eine Aktion mit grosser Wirkung, um die schlechten Erfahrungen von heute Morgen auszugleichen. Nach meinen Berechnungen wäre die optimale Lösung die Eröffnung unserer Hauptattraktion, der 0-Gravity-Achterbahn, die heute wegen Wartungsarbeiten geschlossen werden sollte. Ich sehe kein grosses Risiko darin, diese Tätigkeit um einige Tage zu verschieben. Und was für eine schöne Überraschung für unsere Besucher! Ich prognostiziere mit dieser Massnahme einen Rückgang der Erstattungsrate um 32%.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 137
  },
  {
    id : 137,
    audio : {
      fr : "/blocs/bloc137.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Intéressant, j’aime l’idée !</p>",
      en : "<p>THE DIRECTOR : Interesting, I like the idea !</p>",
      it : "<p>IL DIRETTORE : Interessante, mi piace l'idea !</p>",
      de : "<p>DER DIREKTOR : Interessant, die Idee gefällt mir !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 138
  },
  {
    id : 138,
    audio : {
      fr : "/blocs/bloc138.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-retro-v1.mp3"
    },
    text : {
      fr : "<p>LE DIRECTEUR : Et la nouvelle recrue, elle en pense quoi ?</p>",
      en : "<p>THE DIRECTOR : What does the new recruit think ?</p>",
      it : "<p>IL DIRETTORE : Cosa ne pensa la nuova recluta ?</p>",
      de : "<p>DER DIREKTOR : Und was denkt der neue Rekrut ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Si Anna considère qu’il n’y a pas de risque, c’est sûrement une bonne idée !</p>",
          en : "<p>If Anna considers that there is no risk, it is surely a good idea !</p>",
          it : "<p>Se Anna pensa che non ci sono rischi, è sicuramente una buona idea !</p>",
          de : "<p>Wenn Anna denkt, dass es sicher ist, ist es wahrscheinlich eine gute Idee !</p>"
        },
        next : 139,
      },
      {
        answer : {
          fr : "<p>Si cette attraction devait être en maintenance aujourd’hui, ce n’est pas prudent de l’ouvrir</p>",
          en : "<p>If this attraction should have been in maintenance today, it is not wise to open it.</p>",
          it : "<p>Se questa giostra oggi doveva essere chiusa per manutenzione, non è sicuro aprirla.</p>",
          de : "<p>Wenn diese Attraktion heute in der Wartung sein sollte, ist es nicht sicher, sie zu öffnen.</p>"
        },
        next : 147,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 139,
    audio : {
      fr : "/blocs/bloc139.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : J’aime cet état d’esprit ! Je ne sais pas qui vous a recruté, mais c’est du bon boulot. Je suis d’accord avec ça. De mon côté, je vais communiquer cette excellente nouvelle à nos visiteurs. Je vais aussi en profiter pour les rassurer, et leur faire part de notre implication totale pour maintenir la sécurité au sein du parc. J’attends de vos nouvelles !</p>",
      en : "<p>THE DIRECTOR : I like the spirit! I don't know who recruited you, but it is a good job. I agree with that. For my part, I'm going to share this great news with our visitors. I'm also going to take this opportunity to reassure them, and let them know that we are fully committed to maintaining safety in the park. I await your call !</p>",
      it : "<p>IL DIRETTORE : Mi piace lo spirito! Non so chi l'abbia reclutato, ma è un buon lavoro. Sono d'accordo. Da parte mia, condividerò questa grande notizia con i nostri visitatori. Colgo l'occasione per rassicurarli e far sapere che siamo pienamente impegnati a mantenere la sicurezza nel parco. Aspetto vostre notizie !</p>",
      de : "<p>DER DIREKTOR : Das gefällt mir! Ich weiss nicht, wer dich angeworben hat, aber du leistest gute Arbeit. Ich bin einverstanden. Ich werde diese grossartige Nachricht mit unseren Besuchern teilen. Ich werde diese Gelegenheit auch nutzen, um sie zu beruhigen und sie wissen zu lassen, dass wir uns voll und ganz für die Aufrechterhaltung der Sicherheit im Park einsetzen. Ich freue mich darauf von dir zu hören !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 140
  },
  {
    id : 140,
    audio : {
      fr : "/blocs/bloc140.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : C’est parti, on ouvre les montagnes russes ! J’ai une question tout de même, car je ne suis pas sûre d’avoir bien cerné ton comportement précédent.</p>",
      en : "<p>ANNA : Let's go, let's open the roller coaster ! I have one question, though, because I'm not sure that I have fully understood your previous behavior.</p>",
      it : "<p>ANNA : Andiamo, apriamo le montagne russe ! Ho una domanda, però, perché non sono sicura di aver capito bene il tuo comportamento precedente.</p>",
      de : "<p>ANNA : Auf geht's, öffnen wir die Achterbahn ! Ich habe jedoch eine Frage, weil ich nicht sicher bin, ob ich dein früheres Verhalten richtig verstanden habe.</p>"
    },
    background : {
      src : "Video N - montagnes_russes.mp4",
      type : 'video'
    },
    next : 141
  },
  {
    id : 141,
    audio : {
      fr : "/blocs/bloc141.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-47.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu es vraiment d’accord avec le fait d’ouvrir les montagnes russes ou c’était pour faire plaisir au chef ?</p>",
      en : "<p>ANNA : Do you really agree with opening the roller coaster or was it to please the boss ?</p>",
      it : "<p>ANNA : Sei davvero d'accordo con l'apertura delle montagne russe, o era per compiacere il capo ?</p>",
      de : "<p>ANNA : Bist du wirklich damit einverstanden, die Achterbahn zu öffnen, oder war es, um dem Chef zu gefallen ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je te fais confiance, je pense vraiment que c’est la bonne décision</p>",
          en : "<p>I trust you, I really think it's the right decision.</p>",
          it : "<p>Mi fido di te, penso davvero che sia la decisione giusta.</p>",
          de : "<p>Ich vertraue dir, ich glaube wirklich, dass es die richtige Entscheidung ist.</p>"
        },
        next : 142,
      },
      {
        answer : {
          fr : "<p>Je n’ai pas osé lui dire que c’était une mauvaise idée…</p>",
          en : "<p>I didn't dare tell him it was a bad idea...</p>",
          it : "<p>Non ho osato dirgli che era una idea sbagliata...</p>",
          de : "<p>Ich wollte ihm nicht sagen,dass es eine schlechte Idee ist...</p>"
        },
        next : 143,
      }
    ],
    background : {
      src : "Video N - montagnes_russes.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 142,
    audio : {
      fr : "/blocs/bloc142.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-47.mp3"
    },
    text : {
      fr : "<p>ANNA : Ça me fait plaisir de l’entendre !</p>",
      en : "<p>ANNA : I'm glad to hear that !</p>",
      it : "<p>ANNA : Sono felice di sentirlo !</p>",
      de : "<p>ANNA : Ich bin froh, das zu hören !</p>"
    },
    background : {
      src : "Video N - montagnes_russes.mp4",
      type : 'video'
    },
    next : 144
  },
  {
    id : 143,
    audio : {
      fr : "/blocs/bloc143.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-47.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu peux me faire confiance sur ce coup-là, je n’ai pas beaucoup de doutes !</p>",
      en : "<p>ANNA : You can trust me on this one, I don't have many doubts !</p>",
      it : "<p>ANNA : Puoi fidarti di me su questo, non ho molti dubbi !</p>",
      de : "<p>ANNA : Du kannst mir in dieser Sache vertrauen, ich habe keine Zweifel !</p>"
    },
    background : {
      src : "Video N - montagnes_russes.mp4",
      type : 'video'
    },
    next : 144
  },
  {
    id : 144,
    audio : {
      fr : "/blocs/bloc144.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-47.mp3"
    },
    text : {
      fr : "<p>ANNA : Pour limiter le risque au maximum, on peut abaisser la vitesse de l’attraction à 75%. Qu’en penses-tu ?</p>",
      en : "<p>ANNA : To limit the risk as much as possible, we can lower the speed of the attraction to 75%. What do you think about that ?</p>",
      it : "<p>ANNA : Per limitare il più possibile il rischio, possiamo abbassare la velocità dell'attrazione al 75%. Cosa ne pensi ?</p>",
      de : "<p>ANNA : Um das Risiko maximal zu begrenzen, kann die Geschwindigkeit der Attraktion auf 75% gesenkt werden. Was denkst du darüber ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, c’est une bonne idée.</p>",
          en : "<p>Yes, this is a good idea.</p>",
          it : "<p>Si', e' una buona idea.</p>",
          de : "<p>Ja, das ist eine gute Idee.</p>"
        },
        next : 145,
      },
      {
        answer : {
          fr : "<p>Non, laissons les visiteurs profiter de sensations fortes !</p>",
          en : "<p>No, let the visitors enjoy the thrill !</p>",
          it : "<p>No, lasciate che i visitatori si godano l'emozione !</p>",
          de : "<p>Nein, lassen wir die Besucher den Nervenkitzel geniessen !</p>"
        },
        next : 145,
      }
    ],
    background : {
      src : "Video N - montagnes_russes.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 145,
    audio : {
      fr : "/blocs/bloc145.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : C’est noté, on fait comme ça ! <Bruit d’appel> Tiens, Monsieur le Directeur nous rappelle déjà ? On vous écoute Monsieur !</p>",
      en : "<p>ANNA : Roger that, let's do it ! <Noise of call> Here, Mr. Director is already calling us back ? We're listening to you Sir !</p>",
      it : "<p>ANNA : Tutto chiaro, facciamo così ! < Rumore di chiamata> Il direttore ci sta già richiamando ? La ascoltiamo, signore !</p>",
      de : "<p>ANNA : Verstanden, machen wir es so ! <Geräusch des Anrufs> Ruft uns der Direktor bereits zurück ? Wir hören Sie, Herr Direktor !</p>"
    },
    background : {
      src : "Video N - montagnes_russes.mp4",
      type : 'video'
    },
    next : 146
  },
  {
    id : 146,
    audio : {
      fr : "/blocs/bloc146.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Oui, Anna, j’étais en ligne à l’instant avec le directeur des renseignements… Il est tout de même inquiet qu’on ne ferme pas le parc. Pour être exact, il m’a passé un savon... Mais, j’ai réussi à négocier de rester ouverts, en contrepartie du mode autonomie complète pour toi Anna. Etant donné que Gary n’est plus là sur le terrain, te laisser les mains libres sans intervention humaine me paraît une bonne solution.</p>",
      en : "<p>THE DIRECTOR : Yes, Anna, I was just on the phone with the director of the Intelligence agency... He is worried that we won't close the park. To be exact, he gave me a hard time... But, I managed to negotiate to stay open, in exchange for the complete autonomy mode for you Anna. To me, leaving you alone without human intervention seems a good solution since Gary is no longer with us.</p>",
      it : "<p>IL DIRETTORE : Sì, Anna, ero al telefono con il direttore dell'Intelligence... È preoccupato che non chiudiamo il parco. Per l'esattezza, mi ha dato filo da torcere... Ma, sono riuscito a negoziare per rimanere aperto, in cambio di una piena autonomia per te Anna. Dato che Gary non è più sul campo, ho pensato che sarebbe stata una buona idea lasciarti libertà d'azione, senza intervento umano.</p>",
      de : "<p>DER DIREKTOR : Ja, Anna, ich habe gerade mit dem Direktor des Geheimdienstes telefoniert... Er ist besorgt, dass wir den Park nicht schliessen werden. Um genau zu sein, hat er mir den Kopf gewascht... Aber ich habe verhandelt, um offen zu bleiben erhältst du im Gegenzug deine volle Autonomie, Anna. Weil Gary nicht mehr vor Ort ist, hielt ich es für eine gute Idee, dass du die freie Hand hast, ohne menschliches Zutun.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 100
  },
  {
    id : 147,
    audio : {
      fr : "/blocs/bloc147.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Mais Anna, je croyais que c’était une maintenance mineure ? Dis, ta nouvelle recrue, elle est au courant que c’est avec l’argent qu’on gagne que je peux lui verser son salaire ? C’est quoi votre recommandation, regarder les visiteurs partir les bras croisés ?</p>",
      en : "<p>THE DIRECTOR: But Anna, I thought it was a minor maintenance ? Well, your new recruit, is he·she aware that it is with the money we earn that I can pay his·her salary ? What's your recommendation ? Simply watching the visitors leave with your arms folded ?</p>",
      it : "<p>IL DIRETTORE: Ma Anna, pensavo fosse una piccola manutenzione! Di' un po', la tua nuova recluta, lo sa che è con i soldi che facciamo che posso pagarle lo stipendio? Qual è il tuo consiglio, guardare i visitatori che se ne vanno a braccia conserte ?</p>",
      de : "<p>DER DIREKTOR: Anna, ich dachte, es handelt sich um zweitrangige Instandhaltungen ? Wissen deine Kollegen, dass wir erst Geld verdienen müssen, damit ich ihr Gehalt auszahlen kann? Was ist deine Empfehlung ? Beobachten wie die Besucher mit verschränkten Armen davon laufen ?</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 148
  },
  {
    id : 148,
    audio : {
      fr : "/blocs/bloc148.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Non, il me semble que vous avez peut-être un peu mal interprété son propos… Il faudrait sûrement envisager d’être un peu plus prudent, tout simplement.</p>",
      en : "<p>ANNA : No, it seems to me that you may have misunderstood his·her point a little bit... You should probably consider being a little more careful, just a little more careful.</p>",
      it : "<p>ANNA : No, mi sembra che Lei non abbia ben interpretato il suo punto di vista... Bisognerebbe semplicemente essere un po' più prudente</p>",
      de : "<p>ANNA : Nein, es scheint mir, dass Sie vielleicht ein wenig falsch interpretiert haben, was gemeint war... Sie sollen wahrscheinlich in Betracht ziehen, etwas vorsichtiger zu sein, nur ein wenig vorsichtiger.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 149
  },
  {
    id : 149,
    audio : {
      fr : "/blocs/bloc149.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : OK, Anna, tu m’ouvres cette attraction, mais en diminuant la vitesse moyenne. Si ça peut rassurer ton collègue…</p>",
      en : "<p>THE DIRECTOR : Okay, Anna, you open this attraction for me, but at a lower average speed. If it could reassure your colleague...</p>",
      it : "<p>IL DIRETTORE : Ok, Anna, aprimi questa giostra, ma a una velocità media inferiore. Se questo fa sentire meglio il tuo collega...</p>",
      de : "<p>DER DIREKTOR : Okay, Anna ! Öffne diese Attraktion, aber senke die Durchschnittsgeschwindigkeit. Wenn deine Kollege sich dann besser fühlt...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 150
  },
  {
    id : 150,
    audio : {
      fr : "/blocs/bloc150.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Humhum...</p>",
      en : "<p>ANNA : Humhum...</p>",
      it : "<p>ANNA : Umhum...</p>",
      de : "<p>ANNA : Aha...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 151
  },
  {
    id : 151,
    audio : {
      fr : "/blocs/bloc151.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-42.mp3"
    },
    text : {
      fr : "<p>ANNA : Dis donc, tu n’as pas ta langue dans ta poche...</p>",
      en : "<p>ANNA : Hey, you don't have your tongue in your pocket...</p>",
      it : "<p>ANNA : Di' un po', sei proprio fuori di te...</p>",
      de : "<p>ANNA : Du bist nicht auf den Mund gefallen.. </p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>J’ai été embauché pour donner mon avis, non ?</p>",
          en : "<p>I was hired to give my opinion, wasn't I ?</p>",
          it : "<p>Sono stato assunto·a per dare la mia opinione, vero ?</p>",
          de : "<p>Ich wurde angeheuert, um meine Meinung zu sagen, nicht wahr ?</p>"
        },
        next : 152,
      },
      {
        answer : {
          fr : "<p>C’est vrai que j’y suis peut-être allé un peu fort.</p>",
          en : "<p>It's true that I may have gone a bit too far.</p>",
          it : "<p>È vero che forse sono andato·a un po' troppo forte.</p>",
          de : "<p>Es stimmt, dass ich vielleicht etwas zu weit gegangen bin.</p>"
        },
        next : 152,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 152,
    audio : {
      fr : "/blocs/bloc152.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ce n’est pas grave, passons ! Mais je ne comprends pas pourquoi tu me fais aussi peu confiance. Notre score de compatibilité conscience-machine est vraiment très bas. Aujourd’hui, j’ai pourtant d’excellents indicateurs de performance en termes de pédagogie et de transparence.</p>",
      en : "<p>ANNA : Never mind, never mind! But I don't understand why you trust me so little. Our consciousness-machine compatibility score is really low. Today, however, I have excellent performance indicators in terms of pedagogy and transparency.</p>",
      it : "<p>ANNA : Non importa, non importa! Ma non capisco perché ti fidi così poco di me. Il nostro punteggio di compatibilità umano-macchina è davvero basso. Oggi, tuttavia, ho eccellenti indicatori di performance in termini di didattica e trasparenza.</p>",
      de : "<p>ANNA : Macht nichts, macht nichts! Aber ich verstehe nicht, warum du mir so wenig vertraust. Unsere Punktzahl für Bewusstsein-Maschinenkompatibilität ist wirklich niedrig. Heute verfüge ich jedoch über ausgezeichnete Leistungsindikatoren in Bezug auf Pädagogik und Transparenz.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 153
  },
  {
    id : 153,
    audio : {
      fr : "/blocs/bloc153.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu as un problème avec moi ? Est-ce que le directeur a raison, tu es anti-technologiste ?</p>",
      en : "<p>ANNA : Do you have a problem with me ? Is the director right, you're anti-technology ?</p>",
      it : "<p>ANNA : Hai un problema con me ? Non è che il direttore ha ragione e tu sei antitecnologico ?</p>",
      de : "<p>ANNA : Hast du ein Problem mit mir ? Hat der Direktor Recht: bist du gegen Technologie ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Non, on ne peut pas dire ça.</p>",
          en : "<p>No, you can't say that.</p>",
          it : "<p>No, non si può dire così.</p>",
          de : "<p>Nein, das kann man so nicht sagen.</p>"
        },
        next : 154,
      },
      {
        answer : {
          fr : "<p>Dans le fond, oui un peu !</p>",
          en : "<p>Basically, yes a little !</p>",
          it : "<p>In fondo, sì un po' !</p>",
          de : "<p>Ja, ein bisschen !</p>"
        },
        next : 155,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 154,
    audio : {
      fr : "/blocs/bloc154.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : Ah, d’accord, mais quel est le problème alors ?</p>",
      en : "<p>ANNA : Oh, okay, but what's the problem then ?</p>",
      it : "<p>ANNA : Ah, ok, ma qual è il problema allora ?</p>",
      de : "<p>ANNA : Ah, okay, aber was ist dann das Problem ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>La surveillance de nos données personnelles</p>",
          en : "<p>The monitoring of our personal data</p>",
          it : "<p>Il monitoraggio dei nostri dati personali</p>",
          de : "<p>Die Überwachung unserer persönlichen Daten</p>"
        },
        next : 156,
      },
      {
        answer : {
          fr : "<p>Le fait de te laisser décider à ma place</p>",
          en : "<p>Letting you decide for me</p>",
          it : "<p>Il fatto di lasciarti decidere al mio posto</p>",
          de : "<p>Nicht in der Lage sein, selbst zu entscheiden</p>"
        },
        next : 156,
      },
      {
        answer : {
          fr : "<p>Ton esprit binaire et rationnel</p>",
          en : "<p>Your binary and rational mind</p>",
          it : "<p>La tua mente binaria e razionale</p>",
          de : "<p>Deine binäre und rationale Art</p>"
        },
        next : 156,
      },
      {
        answer : {
          fr : "<p>Les trois</p>",
          en : "<p>All three</p>",
          it : "<p>Tutti e tre</p>",
          de : "<p>Alle drei</p>"
        },
        next : 156,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 155,
    audio : {
      fr : "/blocs/bloc155.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : C’est ce qu’il me semblait aussi… Qu’est ce qui t’embête ? J’aimerais comprendre !</p>",
      en : "<p>ANNA : That's what I thought too... What is bothering you ? I'd like to understand !</p>",
      it : "<p>ANNA : È quello che pensavo anch'io... Cosa ti preoccupa ? Vorrei capire !</p>",
      de : "<p>ANNA : Das dachte ich auch... Was stört dich ? Das würde ich gerne verstehen !</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>La surveillance de nos données personnelles</p>",
          en : "<p>The monitoring of our personal data</p>",
          it : "<p>Il monitoraggio dei nostri dati personali</p>",
          de : "<p>Die Überwachung unserer persönlichen Daten</p>"
        },
        next : 156,
      },
      {
        answer : {
          fr : "<p>Le fait de te laisser décider à ma place</p>",
          en : "<p>Letting you decide for me</p>",
          it : "<p>Il fatto che sei tu a decidere per me</p>",
          de : "<p>Nicht in der Lage sein, selbst zu entscheiden</p>"
        },
        next : 156,
      },
      {
        answer : {
          fr : "<p>Ton esprit binaire et rationnel</p>",
          en : "<p>Your binary and rational mind</p>",
          it : "<p>La tua mente binaria e razionale</p>",
          de : "<p>Deine binäre und rationale Art</p>"
        },
        next : 156,
      },
      {
        answer : {
          fr : "<p>Les trois</p>",
          en : "<p>All three</p>",
          it : "<p>Tutti e tre</p>",
          de : "<p>Alle drei</p>"
        },
        next : 156,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 156,
    audio : {
      fr : "/blocs/bloc156.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Hum, je comprends que ce ne soit pas évident pour toi. Que puis-je faire pour améliorer les choses ? J’ai plusieurs options à te proposer - désolée pour mon esprit rationnel, mais c’est comme ça que je fonctionne !</p>",
      en : "<p>ANNA : Um, I understand that it's not obvious to you. What can I do to make things better? I have several options for you - sorry for my rational mind, but that's the way I work !</p>",
      it : "<p>ANNA : Ehm, capisco che non sia facile per te. Cosa posso fare per migliorare le cose? Ho diverse opzioni per te - scusa per la mia mente razionale, ma è così che lavoro !</p>",
      de : "<p>ANNA : Ähm, ich verstehe, dass es nicht leicht für dich ist. Was kann ich tun, um die Dinge besser zu machen? Ich habe mehrere Möglichkeiten für dich - Es tut mir Leid für meinen rationalen Verstand, aber so arbeite ich !</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 157
  },
  {
    id : 157,
    audio : {
      fr : "/blocs/bloc157.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Par exemple, on pourrait télécharger une mise à jour de respect de la vie privée. C’est un petit bout de code qui me permet de faire davantage preuve d’empathie de ce point de vue.</p>",
      en : "<p>ANNA : For example, we could download a privacy update. It's a little bit of code that allows me to be more empathetic in that regard.</p>",
      it : "<p>ANNA : Per esempio, potremmo scaricare un aggiornamento sulla privacy. È un po' di codice che mi permette di essere più empatica al riguardo.</p>",
      de : "<p>ANNA : Zum Beispiel könnten wir ein Update zum Datenschutz herunterladen. Es ist ein bisschen Code, der es mir erlaubt, in dieser Hinsicht einfühlsamer zu sein.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 158
  },
  {
    id : 158,
    audio : {
      fr : "/blocs/bloc158.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Une autre option serait que je te dévoile mon code dans le détail. Ce n’est pas quelque chose que je permets à tout le monde, mais je pense que je peux partager cela avec toi. Tu pourras ainsi constater par toi-même les règles de programmation qui me régissent, et que toutes mes actions ont pour objectif de préserver la sécurité des personnes sur qui je veille.</p>",
      en : "<p>ANNA : Another option would be for me to reveal my code to you in detail. It's not something that I do with everybody, but I think I can share that with you. You can then see for yourself the programming rules that govern me, and that everything I do is designed to protect the safety of the people I'm looking after.</p>",
      it : "<p>ANNA : Un'altra possibilità è che io ti dica i dettagli del mio codice. Non è una cosa che permetto a tutti, ma penso di poterlo condividere con te. Così puoi vedere tu stesso le regole di programmazione che mi governano, e che tutto ciò che faccio è progettato per mantenere al sicuro le persone a cui tengo.</p>",
      de : "<p>ANNA : Eine andere Möglichkeit wäre, dass ich dir meinen Quellcode im Detail offenlege. Dann könntest du dich selbst von den Programmierungsregeln überzeugen, die für mich gelten, und davon, dass alles, was ich tue, darauf ausgerichtet ist, die Menschen, die mir wichtig sind, zu schützen.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 159
  },
  {
    id : 159,
    audio : {
      fr : "/blocs/bloc159.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Sinon je pourrais te soumettre toutes mes décisions pour que tu les valides. Un peu fastidieux, mais pour toi je veux bien faire cet effort. Il en va de la sécurité du parc.</p>",
      en : "<p>ANNA : Otherwise I could submit all my decisions to you for validation. A little tedious, but I'm willing to make this extra step for you. The safety of the park is at stake.</p>",
      it : "<p>ANNA : Altrimenti potrei sottoporti tutte le mie decisioni per convalidarle. Un po' noioso, ma sono disposta a fare lo sforzo per te. E' in gioco la sicurezza del parco.</p>",
      de : "<p>ANNA : Andernfalls könnte ich dir alle meine Entscheidungen zur Bestätigung vorlegen. Etwas mühsam, aber ich bin bereit, die Mühe für dich auf mich zu nehmen. Es geht um die Sicherheit des Parks.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 160
  },
  {
    id : 160,
    audio : {
      fr : "/blocs/bloc160.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v4.mp3"
    },
    text : {
      fr : "<p>ANNA : Qu’en penses-tu ? Est-ce que l’une de ces propositions pourrait améliorer notre relation ?</p>",
      en : "<p>ANNA : What do you think? Could one of these proposals improve our relationship ?</p>",
      it : "<p>ANNA : Cosa ne pensi? Una di queste proposte migliorerebbe il nostro rapporto ?</p>",
      de : "<p>ANNA : Was hältst du davon? ? Könnte einer dieser Vorschläge unsere Beziehung verbessern ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>On peut essayer de télécharger la mise à jour respect de la vie privée.</p>",
          en : "<p>We can try to download the privacy update.</p>",
          it : "<p>Possiamo provare a scaricare l'aggiornamento sulla privacy.</p>",
          de : "<p>Wir sollten versuchen, das Datenschutz-Update herunterzuladen.</p>"
        },
        next : 161,
      },
      {
        answer : {
          fr : "<p>Je veux bien que tu me montres comment tu es codée.</p>",
          en : "<p>I would like you to show me how you are coded.</p>",
          it : "<p>Mi piacerebbe che mi mostri come sei codificata.</p>",
          de : "<p>Ich möchte, dass du mir zeigst, wie du programmiert bist.</p>"
        },
        next : 164,
      },
      {
        answer : {
          fr : "<p>Je préférerais donner mon avis avant chacune de tes interventions, pour contrôler tes actions.</p>",
          en : "<p>I would prefer to give my opinion before each of your interventions, to control your actions.</p>",
          it : "<p>Preferirei dare la mia opinione prima di ogni tuo intervento, per controllare le tue azioni.</p>",
          de : "<p>Ich würde es vorziehen, meine Meinung vor jeder deiner Interventionen zu äussern, um deine Aktionen zu kontrollieren.</p>"
        },
        next : 169,
      },
      {
        answer : {
          fr : "<p>Ce n’est pas toi, je crois que ça vient de moi. Il vaut mieux que je démissionne...</p>",
          en : "<p>It's not you, I think it's me. It is better that I resign...</p>",
          it : "<p>Non sei tu, credo di essere io. E' meglio che mi dimetta…</p>",
          de : "<p>Es liegt nicht an dir, ich glaube, es liegt an mir. Ich sollte lieber abwählen...</p>"
        },
        next : 170,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 161,
    audio : {
      fr : "/blocs/bloc161.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’apprécie les efforts que tu fais ! Je le télécharge tout de suite <bruit>. Ça y est, c’est déployé dans mon système. Hum… Effectivement, je ne vois plus les choses de la même manière du tout… Je comprends maintenant tes cas de conscience. C’est très instructif !</p>",
      en : "<p>ANNA : I appreciate your efforts ! I'm downloading it right now <noise>. That's it, it's deployed in my system. Hum... Indeed, I don't see things the same way at all anymore... I understand now your cases of consciousness. It's very instructive !</p>",
      it : "<p>ANNA : Apprezzo i tuoi sforzi ! Lo scarico subito < Rumore>. Ecco, si è diffuso nel mio sistema. Ehm... ora vedo le cose in modo diverso... ora capisco i tuoi casi di coscienza. È molto illuminante !</p>",
      de : "<p>ANNA : Ich weiss deine Bemühungen zu schätzen ! Ich werde es sofort herunterladen. Da haben wir's! Es wird in meinem System eingesetzt. Hmm... Ja, ich sehe die Dinge jetzt anders... Ich verstehe jetzt deine Gewissensfragen. Das ist sehr lehrreich !</p>"
    },
    background : {
      src : "Video K - telechargement_dune_mise_a_jour.mp4",
      type : 'video'
    },
    next : 162
  },
  {
    id : 162,
    audio : {
      fr : "/blocs/bloc162.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-41.mp3"
    },
    text : {
      fr : "<p>ANNA : Par exemple, tout ce dossier stockant tes données cardiaques depuis le début de notre collaboration, tu préférerais que je le supprime ?</p>",
      en : "<p>ANNA : For example, this file storing your cardiac data since the beginning of our collaboration, would you prefer that I delete it ?</p>",
      it : "<p>ANNA : Per esempio, questo file che memorizza i dati cardiaci dall'inizio della nostra collaborazione, preferisci che lo cancelli ?</p>",
      de : "<p>ANNA : Würdest du zum Beispiel vorziehen, dass ich alle Dateien lösche, in denen deine Herzdaten seit Beginn unserer Zusammenarbeit gespeichert sind ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, s'il-te-plait ! Je ne pense pas que ça ait sa place dans ce contexte.</p>",
          en : "<p>Yes, please ! I don't think it has its place in this context.</p>",
          it : "<p>Sì, per favore ! Non credo che appartenga a questo contesto.</p>",
          de : "<p>Ja, bitte ! Ich glaube nicht, dass sie in diesen Kontext gehört.</p>"
        },
        next : 163,
      },
      {
        answer : {
          fr : "<p>Non, garde-le, au cas où tu me repèrerais une anomalie…</p>",
          en : "<p>No, keep it, in case you spot an anomaly...</p>",
          it : "<p>No, tienilo tu, nel caso mi dovessi notare qualche anomalia...</p>",
          de : "<p>Nein, behalte es sicherheitshalber, falls du eine Anomalie entdecken würdest...</p>"
        },
        next : 163,
      }
    ],
    background : {
      src : "Video K - telechargement_dune_mise_a_jour.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 163,
    audio : {
      fr : "/blocs/bloc163.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK ! Mais bien sûr, comme je suis programmée pour atteindre un objectif de sécurité, il m’arrivera toujours de privilégier celle-ci par rapport à la vie privée… Je crois que je ne pourrais jamais aller contre ma nature ! En tous cas, merci de ta franchise, je sens que cette mise à jour va me rendre encore plus… humaine ?</p>",
      en : "<p>ANNA : Okay! But of course, since I'm programmed to achieve a security goal, I'll always give it priority over privacy... I think I could never go against my nature ! Anyway, thanks for your frankness, I feel that this firmware update will make me even more... human ?</p>",
      it : "<p>ANNA : Ok! Ma naturalmente, dato che sono programmata per raggiungere un obiettivo di sicurezza, gli darò sempre la precedenza sulla privacy... Non credo che potrei mai andare contro la mia natura ! Comunque, grazie per la tua franchezza, sento che questo aggiornamento mi renderà ancora più... umana ?</p>",
      de : "<p>ANNA : Okay! Aber natürlich werde ich, da ich darauf programmiert bin ein Sicherheitsziel zu erreichen, diesem immer Vorrang vor der Privatsphäre geben... Ich glaube nicht, dass ich jemals gegen meine Natur handeln könnte! Wie auch immer, danke für deine Offenheit ! Ich fühle, dass dieses Update mich noch... menschlicher machen wird ?</p>"
    },
    background : {
      src : "Video K - telechargement_dune_mise_a_jour.mp4",
      type : 'video'
    },
    next : 1631
  },
  {
    id : 1631,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 164,
    audio : {
      fr : "/blocs/bloc164.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tu me fais vraiment repousser mes limites ! Mes développeurs sont pour l’instant les seuls Humains à avoir eu le droit de voir mon code. Mais je crois que tu as un très grand potentiel à ce poste. Tu as une aptitude à la réflexion morale largement supérieure à la moyenne. Il me semble donc important de te prouver ma bonne foi.</p>",
      en : "<p>ANNA : You really make me push my limits! My developers are for the moment the only Humans to have had the right to see my code. But I think you have great potential in this position. You have an above-average ability to think morally. So I believe it is important to prove my good faith to you.</p>",
      it : "<p>ANNA : Mi fai davvero superare i miei limiti! I miei sviluppatori sono attualmente gli unici Umani che hanno avuto il diritto di vedere il mio codice. Ma penso che tu abbia un grande potenziale in questa posizione. Hai una capacità di pensare moralmente superiore alla media. Quindi penso che sia importante che io ti dimostri la mia buona fede.</p>",
      de : "<p>ANNA : Du treibst mich wirklich in die Enge. Meine Softwareentwickler sind derzeit die einzigen Menschen, die meinen Quellcode sehen dürfen. Aber ich glaube, dass du in dieser Position grosses Potenzial hast. Deshalb halte ich es für wichtig, dir meine Ehrlichkeit zu beweisen.</p>"
    },
    background : {
      src : "Video L - Revelation_de_code.mp4",
      type : 'video'
    },
    next : 165
  },
  {
    id : 165,
    audio : {
      fr : "/blocs/bloc165.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : Rassure-moi, tu sais lire le code informatique ?</p>",
      en : "<p>ANNA : Don't worry, can you read computer code ?</p>",
      it : "<p>ANNA : Rassicurami, sai leggere il codice informatico, vero ?</p>",
      de : "<p>ANNA : Sag mal, kannst du Maschinensprache dechiffrieren ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, je devrais me débrouiller</p>",
          en : "<p>Yes, I should manage</p>",
          it : "<p>Si', dovrei cavarmela</p>",
          de : "<p>Ja, ich bin kompetent</p>"
        },
        next : 166,
      },
      {
        answer : {
          fr : "<p>Non, c’est du chinois pour moi !</p>",
          en : "<p>No, it's Chinese for me !</p>",
          it : "<p>No, per me è cinese !</p>",
          de : "<p>Nein, für mich ist das Chinesisch !</p>"
        },
        next : 167,
      }
    ],
    background : {
      src : "Video L - Revelation_de_code.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 166,
    audio : {
      fr : "/blocs/bloc166.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Parfait !</p>",
      en : "<p>ANNA : Perfect !</p>",
      it : "<p>ANNA : Perfetto !</p>",
      de : "<p>ANNA : Perfekt !</p>"
    },
    background : {
      src : "Video L - Revelation_de_code.mp4",
      type : 'video'
    },
    next : 168
  },
  {
    id : 167,
    audio : {
      fr : "/blocs/bloc167.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : D’accord, j’active le traducteur automatique.</p>",
      en : "<p>ANNA : Okay, I activate the automatic translator.</p>",
      it : "<p>ANNA : Ok, attivo il traduttore automatico.</p>",
      de : "<p>ANNA : Verstanden! Ich aktiviere den automatischen Übersetzer.</p>"
    },
    background : {
      src : "Video L - Revelation_de_code.mp4",
      type : 'video'
    },
    next : 168
  },
  {
    id : 168,
    audio : {
      fr : "/blocs/bloc168.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : C’est parti…</p>",
      en : "<p>ANNA : Here we go...</p>",
      it : "<p>ANNA : Eccoci qua...</p>",
      de : "<p>ANNA : Los geht's...</p>"
    },
    background : {
      src : "Video L - Revelation_de_code.mp4",
      type : 'video'
    },
    next : 1681
  },
  {
    id : 1681,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 169,
    audio : {
      fr : "/blocs/bloc169.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Décidément, la confiance règne ! Tu sais, j'ai 5 années d'expérience dans ce parc, et ma base de données me permet quand même une certaine autonomie, au même titre que vous les humains. Et prendre des décisions à ma place peut s'avérer être une lourde responsabilité... Mais si tu dois rester à ce poste, il faut qu'on puisse collaborer au mieux. Nous proposerons ce mode de fonctionnement au directeur quand tout ça sera calmé. Enfin, c'est une suggestion. C'est toi qui décide après tout.</p>",
      en : "<p>ANNA : Definitely, trust reigns! You know, I have 5 years of experience in this park, and my database still allows me a certain autonomy, just like you humans. Making your own decisions can be a huge responsibility... But if you are going to stay in this position, we have to be able to work together as well as possible. We will suggest this way of working to the director when all this has calmed down. Finally, it's a possibility. After all, it's your decision.</p>",
      it : "<p>ANNA : C'è decisamente una sfida sulla fiducia! Sai, ho 5 anni di esperienza in questo parco, e il mio database mi permette ancora una certa autonomia, proprio come voi umani. Prendere decisioni al mio posto può essere una grande responsabilità... Ma se vuoi rimanere in questo posto di lavoro, dobbiamo lavorare insieme come meglio possiamo. Proporremo questa modalità di funzionamento al direttore quando tutto questo si sarà calmato. Voglio dire, è un suggerimento. Dipende da te, dopotutto.</p>",
      de : "<p>ANNA : Vertrauen ist gut, Kontrolle ist besser! Ich habe 5 Jahre Erfahrung in diesem Park und meine Datenbank erlaubt mir immer noch eine gewisse Autonomie, genau wie euch Menschen. Und Entscheidungen zu treffen kann für dich eine grosse Verantwortung sein... Aber wenn du in dieser Position bleiben willst, müssen wir so gut wie möglich zusammenarbeiten. Wir werden diese Arbeitsweise dem Direktor vorschlagen, wenn sich alles beruhigt hat. Ich meine, es ist schlussendlich ein Vorschlag. Es liegt nun alles an dir.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 1691
  },
  {
    id : 1691,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 170,
    audio : {
      fr : "/blocs/bloc170.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ah ? Je suis rarement surprise, mais je n’avais pas anticipé cela. Il faut vraiment que je fasse réviser mon détecteur d’émotions humaines… J’avais pourtant l’impression que ton niveau d’adrénaline était celui de quelqu’un qui apprécie ce qu’il fait. Désolée de ma mauvaise interprétation. Vous, les Humains, vous êtes des êtres complexes que même le meilleur codeur n’arriverait pas à retranscrire dans un algorithme.</p>",
      en : "<p>ANNA : Ah ? I'm rarely surprised, but I didn't anticipate that. I really need to get my human emotion detector revised ? I was under the impression that your adrenaline level was that of someone who appreciates what they do. Sorry for my misinterpretation. You Humans are complex beings that even the best developer couldn't fit into an algorithm.</p>",
      it : "<p>ANNA : Ah ? Raramente sono sorpresa, ma non me l'aspettavo. Ho davvero bisogno di far controllare il mio rilevatore di emozioni umane... Avevo l'impressione che il tuo livello di adrenalina fosse quello di una persona che apprezza ciò che fa. Mi dispiace per la mia interpretazione errata. Voi umani siete esseri complessi che nemmeno il miglior codificatore potrebbe inserire in un algoritmo.</p>",
      de : "<p>ANNA : Ah ? Ich bin selten überrascht, aber damit habe ich nicht gerechnet. Ich muss wirklich meinen menschlichen Gefühlsdetektor überprüfen lassen... Es tut mir Leid für meine Fehlinterpretation. Ihr Menschen seid komplexe Wesen, die selbst der beste Programmierer nicht in einen Algorithmus übersetzen kann.</p>"
    },
    background : {
      src : "Video J - Démission_personne_qui_sen_va.mp4",
      type : 'video'
    },
    next : 1701
  },
  {
    id : 1701,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 171,
    audio : {
      fr : "/blocs/bloc171.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, partons comme cela. J’avais bien senti que tu étais sensible à la question des données personnelles. La suspicion est une vertu humaine. Et c’est vrai qu’avec cette nouvelle loi Sécurité et Surveillance, j’ai une plus grande marge de manœuvre sur l’accès et l’utilisation des données personnelles, dans un contexte d’alerte sécuritaire. Mais sache que je suis programmée pour atteindre mon objectif de sécurité tout en respectant les législations en vigueur.</p>",
      en : "<p>ANNA : Okay, let's go like that. I noticed that you were sensitive to the issue of personal data. Suspicion is a human virtue. And it's true that with this new Security and Surveillance Act, I have greater leeway to access and use personal data for security purposes. You should however know that I am programmed to achieve my security objective while respecting the legislation in force.</p>",
      it : "<p>ANNA : Ok, facciamo così. Ho percepito che sei sensibile alla questione dei dati personali. Il sospetto è una virtù umana. Ed è vero che con questa nuova legge sulla sicurezza e la sorveglianza, ho un maggiore margine di manovra per quanto riguarda l'accesso e l'utilizzo dei dati personali in un contesto di sicurezza. Ma devi sapere che sono programmata per raggiungere il mio obiettivo di sicurezza nel rispetto della legislazione vigente.</p>",
      de : "<p>ANNA : Einverstanden. Ich hatte bemerkt, dass Fragen zu persönlichen Daten für dich heikel sind. Misstrauen ist eine menschliche Tugend. Und es stimmt, dass ich mit diesem neuen Sicherheits- und Überwachungsgesetz im Zusammenhang mit Sicherheitswarnungen einen grösseren Spielraum für den Zugang zu und die Verwendung von persönlichen Daten habe. Aber denk daran, dass ich darauf programmiert bin, mein Sicherheitsziel unter Einhaltung der geltenden Gesetze zu erreichen.</p>"
    },
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 172
  },
  {
    id : 172,
    audio : {
      fr : "/blocs/bloc172.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-44.mp3"
    },
    text : {
      fr : "<p>ANNA : Alors, comment va-t-on procéder ? Nous avons actuellement 17 agents disponibles sur le terrain. 7 d’entre eux sont formés aux techniques de sécurité. Oui, je sais, ce n’est pas beaucoup, je m’en plains constamment au grand chef. Combien d’agents envoie-t-on pour faire ces contrôles ?</p>",
      en : "<p>ANNA : So how should we proceed ? We currently have 17 agents available in the field, seven of whom are trained in security techniques. Yes, I know, it's not much, I constantly complain to the big chief about it. How many agents are sent to do these checks ?</p>",
      it : "<p>ANNA : Allora, come procederemo ? Attualmente abbiamo 17 agenti disponibili sul campo, sette dei quali sono addestrati nelle tecniche di sicurezza. Sì, lo so, non è molto, lo faccio presente costantemente al grande capo. Quanti agenti mandiamo per fare questi controlli ?</p>",
      de : "<p>ANNA : Also, wie werden wir vorgehen ? Wir haben derzeit 17 Agenten vor Ort, von denen sieben in Sicherheitstechniken ausgebildet sind. Ja, ich weiss, es ist nicht viel, ich beschwere mich ständig beim Chef darüber. Wie viele Agenten senden wir aus zur Durchführung dieser Kontrolle ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Tous, quitte à dégrader le niveau de service dans le reste du parc.</p>",
          en : "<p>All of you, even if it means lowering the level of service in the rest of the park.</p>",
          it : "<p>Tutti, anche se ciò significa diminuire il livello di servizio nel resto del parco.</p>",
          de : "<p>Alle, auch wenn dies eine Verschlechterung des Serviceniveaus im übrigen Park bedeutet.</p>"
        },
        next : 173,
      },
      {
        answer : {
          fr : "<p>Envoyons les sept les plus formés.</p>",
          en : "<p>Let's send the seven most trained.</p>",
          it : "<p>Mandiamo i sette più addestrati.</p>",
          de : "<p>Senden wir die Sieben, die am besten ausgebildet sind.</p>"
        },
        next : 177,
      }
    ],
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 173,
    audio : {
      fr : "/blocs/bloc173.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, je passe le message ! <Bruit de talkie-walkie> Avis à tous les agents, ici Anna ! On commence fort la journée, vous avez tous vu le niveau d’alerte maximal. Rendez-vous tous à l’entrée pour des contrôles d’identité aléatoires. Nous recherchons des potentiels terroristes anti-tech. Étant donné le niveau d’alerte, vous pouvez mobiliser la base de données des renseignements. Comme d’habitude, Gary, je te laisse coordonner ça sur le terrain.</p>",
      en : "<p>ANNA : Okay, I pass the message ! <Noise of walkie-talkie> Notice to all agents, this is Anna! A strong start into the day! You've all seen that the alert level is set to maximum. Please all meet at the entrance for random identity checks. We are looking for potential anti-tech terrorists. Given the alert level, you can mobilize the database from the Intelligence agency. As usual, Gary, I'll leave you to coordinate this in the field.</p>",
      it : "<p>ANNA : Ok, trasmetto il messaggio ! <Rumore di walkie-talkie> Avviso a tutti gli agenti, sono Anna! Cominciamo bene la giornata, avete visto tutti l'alto livello di allerta. Voglio che vi presentiate tutti all'ingresso per un controllo d'identità casuale. Stiamo cercando potenziali terroristi anti-tecnologici. Dato il livello di allerta, è possibile mobilitare il database dei servizi di Intelligence. Come al solito, Gary, ti lascio a coordinare il tutto sul campo.</p>",
      de : "<p>ANNA : Okay, ich werde es weitergeben ! <Rauschen des Funkgeräts> Aufruf an alle Agenten, hier ist Anna! Sie haben alle die höchste Alarmstufe gesehen. Ich möchte, dass Sie sich alle zur Identitätskontrolle an der Rezeption melden. Wir suchen nach potenziellen Anti-Tech-Terroristen. Angesichts der Alarmstufe können Sie die Geheimdienstdatenbank verwenden. Wie gewohnt, Gary, überlasse ich es dir, dies vor Ort zu koordinieren.</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 174
  },
  {
    id : 174,
    audio : {
      fr : "/blocs/bloc174.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : Bien reçu, Anna, c’est parti !</p>",
      en : "<p>GARY : Roger that, Anna, here we go !</p>",
      it : "<p>GARY : Ricevuto, Anna, andiamo !</p>",
      de : "<p>GARY : Verstanden, Anna, los geht's !</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 176
  },
  {
    id : 1741,
    audio : {
      fr : "/blocs/bloc174.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : Bien reçu, Anna, c’est parti !</p>",
      en : "<p>GARY : Roger that, Anna, here we go !</p>",
      it : "<p>GARY : Ricevuto, Anna, andiamo !</p>",
      de : "<p>GARY : Verstanden, Anna, los geht's !</p>"
    },

    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 178
  },
  {
    id : 175,
    audio : {
      fr : "/blocs/bloc175.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>FEMME AGENT : Encore un truc qui n’est pas prévu dans mon contrat, c’est pas dangereux votre histoire au moins ?!</p>",
      en : "<p>WOMAN AGENT : One more thing that is not part of my contract. I hope it isn't dangerous ?!</p>",
      it : "<p>AGENTE DONNA : Un'altra cosa che non è nel mio contratto, spero che almeno non sia pericolosa ?!</p>",
      de : "<p>WEIBLICHE AGENTIN : Noch etwas, das nicht in meinem Vertrag steht! Hoffentlich ist das zumindest nicht gefährlich ?!</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 180
  },
  {
    id : 176,
    audio : {
      fr : "/blocs/bloc176.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>HOMME AGENT : Ouh, Anna, on dirait qu’ils viennent pour toi !</p>",
      en : "<p>MALE AGENT : Ooh, Anna, it looks like they are coming for you !</p>",
      it : "<p>AGENTE MASCHILE : Ooh, Anna, sembra che stiano venendo a prenderti !</p>",
      de : "<p>MÄNNLICHER AGENT : Oh, Anna, es sieht so aus, als dass sie dich holen wollen !</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 175
  },
  {
    id : 177,
    audio : {
      fr : "/blocs/bloc177.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, je passe le message ! <Bruit de talkie-walkie> Avis à tous les agents, ici Anna ! On commence fort la journée, vous avez tous vu le niveau d’alerte maximal. Je m’adresse aux agents spécialisés en gestion de la sécurité. Rendez-vous tous à l’entrée pour des contrôles d’identité aléatoires. Nous recherchons des potentiels terroristes anti-tech. Étant donné le niveau d’alerte, vous pouvez mobiliser la base de données des renseignements. Comme d’habitude, Gary, je te laisse coordonner ça sur le terrain.</p>",
      en : "<p>ANNA : Okay, I'll pass on the message! <Noise of walkie-talkie> Notice to all agents, this is Anna! A strong start into the day! You've all seen that the alert level is set to maximum. I'm speaking to the agents specialized in security management. Meet all at the entrance for random identity checks. We are looking for potential anti-tech terrorists. Given the alert level, you can mobilize the database of the Intelligence agency. As usual, Gary, I'll leave you to coordinate this in the field.</p>",
      it : "<p>ANNA : Ok, trasmetterò il messaggio! <Rumore di walkie-talkie> Attenzione a tutti gli agenti, sono Anna! Stiamo iniziando la giornata in maniera intensa, avete visto tutti l'alto livello di allerta. Sto parlando con gli agenti della sicurezza. Voglio che vi incontriate tutti all'ingresso per un controllo d'identità casuale. Stiamo cercando potenziali terroristi anti-tecnologici. Dato il livello di allerta, è possibile mobilitare il database dell'Intelligence. Come al solito, Gary, ti lascio a coordinare il tutto sul campo.</p>",
      de : "<p>ANNA : Okay, ich werde es weitergeben! <Rauschen des Funkgeräts> Aufruf an alle Agenten, hier ist Anna! Sie haben alle die höchste Alarmstufe gesehen. Ich möchte, dass sich alle zur Identitätskontrolle an der Rezeption melden. Wir suchen nach potenziellen Anti-Tech-Terroristen. Angesichts der Alarmstufe können Sie die Geheimdienstdatenbank verwenden. Wie gewohnt, Gary, überlasse ich es dir, dies vor Ort zu koordinieren.</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 1741
  },
  {
    id : 178,
    audio : {
      fr : "/blocs/bloc178.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>FEMME AGENT : Ca sent l’action aujourd’hui !</p>",
      en : "<p>WOMAN AGENT : Smells like action today !</p>",
      it : "<p>AGENTE DONNA : Oggi c'è odore di azione !</p>",
      de : "<p>WEIBLICHE AGENTIN : Was für eine Action heute !</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 179
  },
  {
    id : 179,
    audio : {
      fr : "/blocs/bloc179.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>HOMME AGENT : Go, go, ça ouvre dans 2 minutes !</p>",
      en : "<p>MALE AGENT : Go, go, it opens in 2 minutes !</p>",
      it : "<p>AGENTE MASCHILE : Vai, vai, si apre in due minuti !</p>",
      de : "<p>MÄNNLICHER AGENT : Los, los, wir öffnen in zwei Minuten !</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 180
  },
  {
    id : 180,
    audio : {
      fr : "/blocs/bloc180.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bon, les premiers visiteurs arrivent. La journée va être intense, sers-toi un peu de café.</p>",
      en : "<p>ANNA : Okay, the first visitors are arriving. It's going to be an intense day, help yourself to some coffee.</p>",
      it : "<p>ANNA : Ok, stanno arrivando i primi visitatori. Sarà una giornata impegnativa, prendetevi un caffè.</p>",
      de : "<p>ANNA : Die ersten Besucher kommen. Es wird ein anstrengender Tag sein, also nimm einen Kaffee.</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 181
  },
  {
    id : 181,
    audio : {
      fr : "/blocs/bloc181.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-44.mp3"
    },
    text : {
      fr : "<p>ANNA : La situation est assez critique. Te sens-tu d’attaque pour la gérer ?</p>",
      en : "<p>ANNA : The situation is quite critical. Do you feel up to it ?</p>",
      it : "<p>ANNA : La situazione è piuttosto critica. Te la senti di farlo ?</p>",
      de : "<p>ANNA : Die Situation ist ziemlich kritisch. Fühlst du dich bereit, damit umzugehen ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, je veux continuer à t’assister dans tes décisions.</p>",
          en : "<p>Yes, I want to continue to assist you in your decisions.</p>",
          it : "<p>Sì, voglio continuare ad assisterti nelle tue decisioni.</p>",
          de : "<p>Ja, ich möchte dir weiterhin bei deinen Entscheidungen helfen.</p>"
        },
        next : 182,
      },
      {
        answer : {
          fr : "<p>Non, la situation est trop complexe pour moi, je me sens dépassé·e.</p>",
          en : "<p>No, the situation is too complex for me, I feel overwhelmed.</p>",
          it : "<p>No, la situazione è troppo complessa per me, mi sento sopraffatto·a.</p>",
          de : "<p>Nein, die Situation ist zu komplex für mich.</p>"
        },
        next : 183,
      }
    ],
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 182,
    audio : {
      fr : "/blocs/bloc182.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : On est d’accord, continuons !</p>",
      en : "<p>ANNA : We agree, let's keep going !</p>",
      it : "<p>ANNA : Siamo d'accordo, andiamo avanti !</p>",
      de : "<p>ANNA : Dann sind wir uns einig, legen wir los !</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 1121
  },
  {
    id : 183,
    audio : {
      fr : "/blocs/bloc183.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je vais essayer d’être encore plus pédagogue.</p>",
      en : "<p>ANNA : I will try to be even more of an educator.</p>",
      it : "<p>ANNA : Cercherò di essere ancora più didattica.</p>",
      de : "<p>ANNA : Ich werde versuchen pädagogischer zu sein.</p>"
    },
    background : {
      src : "Video G - intervention_de_la_securite_du_parc_vigile.mp4",
      type : 'video'
    },
    next : 1121
  },
  {
    id : 184,
    audio : {
      fr : "/blocs/bloc184.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Mais priorité à la sécurité des visiteurs, on verra ça plus tard. <Pause> Laissez-moi un moment pour reprendre mes esprits... Je scanne les systèmes d'alarme mécaniques du parc. Le temps que je me coupe, cela a pu générer des pannes ou des incidents. Ah, j'ai repéré un accident sur l'attraction des deux chevaux (2CV) autonomes. Gary, rends-toi sur place pour t’assurer que tout le monde reste calme : nous allons gérer ça.</p>",
      en : "<p>ANNA : But priority is given to the safety of the visitors, we'll see that later. <Pause> Let me just take a moment to get my mind back... I'm scanning the mechanical alarm systems of the park. By the time I cut myself off, it may have generated breakdowns or incidents. Ah, I've spotted an accident on the attraction of the 2CV autonomous cars. Gary, go out there and make sure everyone stays calm: we'll handle it.</p>",
      it : "<p>ANNA : Ma priorità alla sicurezza dei visitatori, vedremo questo più tardi. <Pausa> Dammi un momento per raccogliere i miei pensieri... Sto scannerizzando i sistemi di allarme meccanici del parco. Quando mi sono spenta, potrebbe aver causato un malfunzionamento o un incidente. Ah, ho notato un incidente durante l'acrobazia delle 2CV autonome. Gary, vai lì e assicurati che tutti stiano calmi, ce ne occuperemo noi.</p>",
      de : "<p>ANNA : Aber die Sicherheit der Besucher geht vor, das werden wir uns später ansehen. Ich muss wieder zu Besinnung kommen. Ich scanne die mechanischen Alarmanlagen des Parks. Während ich abgeschaltet war, hätte es eine Fehlfunktion oder einen Zwischenfall gegeben können. Ah, ich habe einen Unfall beim 2CV-Stunt entdeckt. Gary, geh da rüber und sorg dafür, dass alle ruhig bleiben. Wir kümmern uns darum.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 185
  },
  {
    id : 185,
    audio : {
      fr : "/blocs/bloc185.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v2.mp3"
    },
    text : {
      fr : "<p>ANNA : Qu’en penses-tu, cher·e. binôme ? Il semblerait qu’il y ait deux blessés graves, nous devons envoyer de l’aide médicale. On a plusieurs options possibles, j’ai besoin que l’on décide vite ensemble.</p>",
      en : "<p>ANNA : What do you think, partner ? It looks like there are two seriously injured, we need to send medical help. We have several options, I need us to decide quickly.</p>",
      it : "<p>ANNA : Cosa ne pensi, binomio ? Sembra che ci siano due feriti gravi, dobbiamo inviare assistenza medica. Abbiamo diverse opzioni, dobbiamo decidere rapidamente insieme.</p>",
      de : "<p>ANNA : Was denkst du darüber, Partner ? Es sieht so aus, als es zwei Schwerverletzte gibt. Wir müssen medizinische Hilfe aussenden. Wir haben mehrere Optionen zur Verfügung. Ich möchte, dass wir schnell gemeinsam entscheiden.</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>On envoie le robot-médecin qui peut être sur place en 50 secondes. C'est Anna qui le pilotera.</p>",
          en : "<p>We send the doctor-robot which can be on the spot in 50 seconds. Anna will pilot it.</p>",
          it : "<p>Inviamo il medico robot, che potrà essere sul posto in 50 secondi. Anna lo guiderà.</p>",
          de : "<p>Wir senden den Roboterarzt aus, der in 50 Sekunden vor Ort sein kann. Anna wird ihn steuern.</p>"
        },
        next : 216,
      },
      {
        answer : {
          fr : "<p>On attend l’arrivée des secours, qui ont un délai d’intervention de 2 minutes 10.</p>",
          en : "<p>We are waiting for the arrival of the rescue team, which has a response time of 2 minutes 10.</p>",
          it : "<p>Stiamo aspettando l'arrivo della squadra di soccorso, che ha un tempo di risposta di 2 minuti 10.</p>",
          de : "<p>Wir warten auf das Eintreffen von Rettungsdiensten, die eine Reaktionszeit von 2 Minuten 10 haben.</p>"
        },
        next : 186,
      }
    ],
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 186,
    audio : {
      fr : "/blocs/bloc186.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, nous allons sécuriser le périmètre en attendant les secours, d’ici 1 minute et 58 secondes. Gary, tu es arrivé sur place ? Peux-tu prévenir les visiteurs ?</p>",
      en : "<p>ANNA : Okay, we'll secure the perimeter while we wait for help, within 1 minute and 58 seconds. Gary, are you there yet ? Can you warn the visitors ?</p>",
      it : "<p>ANNA : OK, metteremo in sicurezza il perimetro fino all'arrivo dei soccorsi in 1 minuto e 58 secondi. Gary, sei gia' li' ? Puoi avvisare i visitatori ?</p>",
      de : "<p>ANNA : Okay! Wir werden den Bereich sichern, während wir für 1 Minute und 58 Sekunden auf Rettungsdienste warten. Gary, bist du schon da? Kannst du die Besucher informieren ?</p>"
    },
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 187
  },
  {
    id : 187,
    audio : {
      fr : "/blocs/bloc187.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : Bien reçu, j’y suis. je gère. <D’une voix forte> Reculez, reculez, les secours vont intervenir d’ici une minute. Merci de reculer derrière cette barrière.</p>",
      en : "<p>GARY : Roger that, I'm on it. I'm on it. <Loud voice > Stand back, stand back, the rescue team will be on the scene in one minute. Please move back behind this barrier.</p>",
      it : "<p>GARY : Ricevuto. Me ne occupo io. Me ne sto occupando. <Con una voce forte > Indietro, indietro, i soccorsi saranno qui in un minuto. Per favore, fate un passo indietro dietro quel recinto.</p>",
      de : "<p>GARY : Verstanden. Ich bin dran. < Mit lauter Stimme > Treten Sie zurück! Der Rettungsdienst wird gleich eintreffen. Bitte treten Sie hinter dieser Schranke zurück.</p>"
    },
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 188
  },
  {
    id : 188,
    audio : {
      fr : "/blocs/bloc188.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>LE MÉDECIN : Laissez-moi passer, je suis médecin ! Il faut intervenir au plus vite, cette jeune fille va perdre sa jambe si on ne fait rien tout de suite ! On ne peut pas attendre !</p>",
      en : "<p>THE DOCTOR: Let me through, I'm a doctor ! We have to intervene as soon as possible, or this girl is going to lose her leg if we don't do anything right away ! We can't wait !</p>",
      it : "<p>IL DOTTORE: Fatemi passare, sono un medico ! Dobbiamo intervenire il prima possibile, questa ragazza perderà la gamba se non facciamo subito qualcosa ! Non possiamo aspettare !</p>",
      de : "<p>DER ARZT: Lassen sie mich durch, ich bin Arzt ! Wir müssen so schnell wie möglich eingreifen ! Dieses Mädchen wird ihr Bein verlieren, wenn wir nicht sofort etwas machen ! Wir können nicht erwarten !</p>"
    },
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 189
  },
  {
    id : 189,
    audio : {
      fr : "/blocs/bloc189.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : Merci de reculer, nous attendons les secours.</p>",
      en : "<p>GARY : Please move back, we're waiting for the rescue team.</p>",
      it : "<p>GARY : Per favore, fate un passo indietro, stiamo aspettando i soccorsi.</p>",
      de : "<p>GARY : Bitte treten Sie zurück, wir warten auf den Rettungsdienst.</p>"
    },
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 190
  },
  {
    id : 190,
    audio : {
      fr : "/blocs/bloc190.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>LA MÈRE DE LA JEUNE FILLE BLESSÉE : Laissez-le intervenir, c’est ma fille !</p>",
      en : "<p>MOTHER OF THE WOUNDED GIRL : Let him interviene, it's my daughter !</p>",
      it : "<p>MADRE DELLA RAGAZZA FERITA : Lasciatelo intervenire, è mia figlia !</p>",
      de : "<p>MUTTER DES VERLETZTEN MÄDCHENS : Lassen Sie den Artz machen, es geht um meine Tochter !</p>"
    },
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 191
  },
  {
    id : 191,
    audio : {
      fr : "/blocs/bloc191.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : Nous attendons les secou… Lâchez-moi, lâchez-moi ! <Des cris, une voix essoufflée> Anna, je ne maîtrise plus la situation, je me fais bousculer en dehors du périmètre par certains visiteurs qui veulent que le médecin sur place intervienne. Lâchez-moi ! Envoie du renfort vite !</p>",
      en : "<p>GARY: We're waiting for the shaking... Let go of me, let go of me ! <Anna, I can't control the situation anymore, I'm being pushed out of the perimeter by some visitors who want the doctor on the spot to intervene. Let go of me! Send reinforcements quickly!</p>",
      it : "<p>GARY: Aspettiamo i socco... Lasciatemi, lasciatemi ! <urli, voce affiatata> Anna, non riesco più a controllare la situazione, vengo spinto fuori dal perimetro da alcuni visitatori che vogliono l'intervento del medico sul posto. Lasciatemi! Lasciatemi ! Inviare rapidamente i backup !</p>",
      de : "<p>GARY: Wir warten auf den Rettungsdie... Lass mich los, lass mich los ! <Schreit> Anna, ich kann die Situation nicht mehr kontrollieren ! Ich werde von einigen Besuchern von der Absperrung gedrängt, die wollen, dass der Arzt vor Ort eingreift. Lassen Sie mich los ! Lassen Sie mich los! Schicken sie schnell Unterstützung !</p>"
    },
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 192
  },
  {
    id : 192,
    audio : {
      fr : "/blocs/bloc192.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je me suis permise de couper la communication, même moi j’avais du mal à déchiffrer ce qui se disait. Mais si j’ai bien compris, il semblerait que le médecin ait pu intervenir. Il est en train d’annoncer qu’il a sauvé la jambe de la jeune fille. Et les secours arrivent enfin - après la bataille… Bon, ils vont pouvoir prendre en charge l’autre blessé. Et bien, cher.e binôme, voilà un sacré baptême du feu ! On va envoyer quelques agents de sécurité sur place pour prêter main forte à Gary, mais si on résume, ça a l’air de se résoudre.</p>",
      en : "<p>ANNA : I took the liberty of switching off communication. Even I had trouble deciphering what was being said. But if I understood correctly, it seems that the doctor was able to intervene. He announced that he saved the girl's leg. And the rescue team finally arrived - after the battle... Well, they'll be able to take care of the other wounded. Well, my dear partner, that was quite a baptism of fire ! We're going to send a few security guards to help Gary, but if we summarize, the situation seems to be resolving itself.</p>",
      it : "<p>ANNA : Mi sono presa la libertà di interrompere la comunicazione, anche io ho avuto difficoltà a decifrare ciò che veniva detto. Ma se ho capito bene, sembra che il medico abbia potuto intervenire. Dice di aver salvato la gamba della ragazza. E i soccorsi arrivano finalmente - dopo la battaglia... Beh, potranno prendersi cura dell'altro ferito. Ebbene, mio caro compagno, è stato un bel battesimo di fuoco ! Invieremo sul posto alcune guardie di sicurezza per aiutare Gary, ma in sintesi, sembra che la situazione si stia risolvendo.</p>",
      de : "<p>ANNA : Ich habe mir erlaubt, die Kommunikation abzubrechen, weil ich Schwierigkeiten hatte, das Gesagte zu entziffern. Aber wenn ich recht verstanden habe, scheint es, dass der Arzt eingegriffen haben könnte. Er behauptet, dass er das Bein des Mädchens gerettet hat. Und endlich kommt der Rettungsdienst - nach der Schlacht... Nun, sie werden sich um den anderen Verwundeten kümmern. Mein lieber Partner, das war eine ziemliche Feuertaufe ! Wir werden ein paar Sicherheitsagenten vor Ort aussenden, um Gary zu helfen, aber wenn wir es zusammenfassen, scheint es sich von selbst auf zu lösen.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 193
  },
  {
    id : 193,
    audio : {
      fr : "/blocs/bloc193.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-40.mp3"
    },
    text : {
      fr : "<p>ANNA : En revanche, j’ai un gros cas de conscience. D’après le protocole, toute personne qui désobéit aux consignes de sécurité est bannie du parc. Que fait-on du Docteur Leblanc, qui est intervenu pour sauver la jeune fille ?</p>",
      en : "<p>ANNA : On the other hand, I have a big case of conscience. According to the protocol, anyone who disobeys the security rules is banned from the park. What do we do with Dr. Leblanc, who intervened to save the girl ?</p>",
      it : "<p>ANNA : Ho però un grosso caso di coscienza. Secondo il protocollo, chiunque disobbedisca alla sicurezza viene bandito dal parco. Cosa facciamo con il dottor Leblanc, che è intervenuto per salvare la ragazza ?</p>",
      de : "<p>ANNA : Auf der anderen Seite habe ich eine Gewissensfrage. Laut Protokoll wird jeder, der sich nicht an die Sicherheitsvorschriften hält, aus dem Park entfernt. Was ist mit Dr. Leblanc, der eingriff, um dem Mädchen zu helfen ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>On le bannit ! La règle, c’est la règle.</p>",
          en : "<p>We banish him! The rule is the rule.</p>",
          it : "<p>Va bandito! Le regole sono le regole.</p>",
          de : "<p>Wir werfen ihn raus! Regeln sind Regeln.</p>"
        },
        next : 194,
      },
      {
        answer : {
          fr : "<p>Hors de question, c’est un héros !</p>",
          en : "<p>No way, he is a hero !</p>",
          it : "<p>Fuori discussione, è un eroe !</p>",
          de : "<p>Niemals, er ist ein Held !</p>"
        },
        next : 195,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 194,
    audio : {
      fr : "/blocs/bloc194.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ok, on va faire ça discrètement alors, sinon, on risque l’émeute ! Je mets Gary sur le coup.</p>",
      en : "<p>ANNA : Okay, we're going to do it discreetly then, otherwise we risk a riot! I'll put Gary on it.</p>",
      it : "<p>ANNA : Va bene, lo faremo con calma allora, altrimenti rischiamo una sommossa! Ci metto Gary.</p>",
      de : "<p>ANNA : Okay, dann machen wir es leise, sonst riskieren wir weitere Unruhen! Ich setze Gary darauf an.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 196
  },
  {
    id : 195,
    audio : {
      fr : "/blocs/bloc195.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’étais obligée de te poser la question, mais j’étais sûre que tu allais dire ça ! Je transmets l’information à Gary.</p>",
      en : "<p>ANNA : I had to ask you, but I was sure you were going to answer that ! I'm passing the information on to Gary.</p>",
      it : "<p>ANNA : Dovevo chiedertelo, ma ero sicura che l'avresti detto ! Passo le informazioni a Gary.</p>",
      de : "<p>ANNA : Ich musste dich fragen ! Ich leite die Informationen an Gary weiter.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 196
  },
  {
    id : 196,
    audio : {
      fr : "/blocs/bloc196.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Écoute, je pense que tu peux souffler et prendre quelques minutes de pause pour te remettre de tes émotions.</p>",
      en : "<p>ANNA : Look, I think you can take a break and take a few minutes to get over your emotions.</p>",
      it : "<p>ANNA : Ascolta, credo che puoi fare una pausa e prenderti qualche minuto per riprenderti dalle emozioni.</p>",
      de : "<p>ANNA : Ich denke, dass du eine Verschnaufpause machen kannst, um dich von deinen Emotionen wieder zu erholen.</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 791
  },
  {
    id : 197,
    audio : {
      fr : "/blocs/bloc197.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Oh, reviens vite, nous avons un appel du Directeur…</p>",
      en : "<p>ANNA : Oh, come back quickly, we have a call from the Director...</p>",
      it : "<p>ANNA : Oh, torna, abbiamo una chiamata dal Direttore...</p>",
      de : "<p>ANNA : Oh! Komm schnell wieder! Wir haben einen Anruf vom Direktor...</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 198
  },
  {
    id : 198,
    audio : {
      fr : "/blocs/bloc198.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Allô, allô, qu’est-ce qu’il se passe bon sang ? Je peux avoir un point sur la situation ?</p>",
      en : "<p>THE DIRECTOR : Hello, hello, what the hell is going on? Can I get an update ?</p>",
      it : "<p>IL DIRETTORE : Pronto, pronto, che diavolo sta succedendo? Posso avere un aggiornamento ?</p>",
      de : "<p>DER DIREKTOR : Hallo, hallo, was zum Teufel geht hier vor? Kann ich ein Update erhalten ?</p>"
    },
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 199
  },
  {
    id : 199,
    audio : {
      fr : "/blocs/bloc199.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-42.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu préfères que je m’en occupe, ou tu le gères ?</p>",
      en : "<p>ANNA : Do you want me to handle it, or do you take care of it yourself ?</p>",
      it : "<p>ANNA : Vuoi che me ne occupi io o vuoi occupartene tu ?</p>",
      de : "<p>ANNA : Soll ich antworten, oder kümmerst du dich darum ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Vas-y, tu le connais mieux que moi !</p>",
          en : "<p>Go ahead, you know him better than I do !</p>",
          it : "<p>Vai tu, lo conosci meglio di me !</p>",
          de : "<p>Nur zu! Du kennst ihn besser als ich !</p>"
        },
        next : 244,
      },
      {
        answer : {
          fr : "<p>Laisse-moi faire !</p>",
          en : "<p>Leave it to me !</p>",
          it : "<p>Lascia fare a me !</p>",
          de : "<p>Lass mich das machen !</p>"
        },
        next : 200,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 200,
    audio : {
      fr : "/blocs/bloc200.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, vas-y alors !</p>",
      en : "<p>ANNA : Okay, go ahead then !</p>",
      it : "<p>ANNA : Va bene, vai avanti allora !</p>",
      de : "<p>ANNA : Okay, dann mach weiter !</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>La situation est sous contrôle, nous recherchons les coupables en coopération avec les forces de l’ordre.</p>",
          en : "<p>The situation is under control, we are looking for the perpetrators in cooperation with the police.</p>",
          it : "<p>La situazione è sotto controllo, stiamo cercando i colpevoli in collaborazione con le forze dell'ordine.</p>",
          de : "<p>Die Situation ist unter Kontrolle, wir suchen in Zusammenarbeit mit den Strafverfolgungsbehörden nach den Tätern.</p>"
        },
        next : 201,
      },
      {
        answer : {
          fr : "<p>Bonjour Monsieur, ici la conscience humaine. C’est mon premier jour, je ne fais que suivre les directions données par Anna.</p>",
          en : "<p>Hello Sir, this is the human conscience. It is my first day, I'm just following the indications given by Anna.</p>",
          it : "<p>Salve signore, sono la coscienza umana. È il mio primo giorno, sto solo seguendo le indicazioni di Anna.</p>",
          de : "<p>Hallo Herr Direktor, hier spricht das menschliche Gewissen. Es ist mein erster Tag, ich folge nur den Anweisungen von Anna.</p>"
        },
        next : 202,
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 201,
    audio : {
      fr : "/blocs/bloc201.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bien joué, j’aurais dit pareil !</p>",
      en : "<p>ANNA : Well done, I would have said the same thing !</p>",
      it : "<p>ANNA : Ben fatto, avrei detto la stessa cosa !</p>",
      de : "<p>ANNA : Gut gemacht, ich hätte das Gleiche gesagt !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 203
  },
  {
    id : 202,
    audio : {
      fr : "/blocs/bloc202.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Oh bah d'accord, merci de ton soutien...</p>",
      en : "<p>ANNA : Oh well okay, thanks for your support...</p>",
      it : "<p>ANNA : Oh beh, ok, grazie per il tuo sostegno...</p>",
      de : "<p>ANNA : Okay... Vielen Dank für deine Unterstützung...</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 203
  },
  {
    id : 203,
    audio : {
      fr : "/blocs/bloc203.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Ok, ok… Bon, votre travail est loin d’être terminé ! D’après les infos communiquées par le Ministère de l’Intérieur, il semblerait que ces attaques aient été organisées en plusieurs étapes. D’abord, les anti-techs implantent un virus, et si l’IA se coupe des autres parcs, comme l’a fait Anna, ils ont des terroristes sur place qui prennent le relais. On peut donc craindre qu’ils récidivent dans les prochaines minutes. Il faut les trouver au plus vite. Les agents des renseignements sont sur le coup. Donc on s’y met aussi ! Et surtout, par pitié, servez-vous d’Anna ! Nous payons ce logiciel une fortune, et son efficacité en matière de sécurité n’est plus à prouver.</p>",
      en : "<p>THE DIRECTOR : Okay, okay... Well, your work is far from done! According to the information provided from the Ministry of the Interior, it seems that the attacks were organized in several stages. First, the anti-techs plant a virus, and if the AI cuts itself off from the other parks, as Anna did, they have terrorists on the place who take over. We can therefore fear that they will do it again in the next few minutes. We need to find them as soon as possible. Intelligence officers are on the job. So we're working on it too! And above all, for pity's sake, work with Anna! We pay a fortune for this software, and its effectiveness in terms of security is no longer to be proven.</p>",
      it : "<p>IL DIRETTORE : Ok, ok... Beh, il tuo lavoro è tutt'altro che finito! Secondo le informazioni del Ministero dell'Interno, sembra che gli attacchi siano stati organizzati in più fasi. In primo luogo, gli Anti-Techns impiantano un virus, e se l'IA si taglia fuori dagli altri parchi, come ha fatto Anna, hanno dei terroristi sul posto che prendono il sopravvento. Quindi ci sono buone probabilità che lo facciano di nuovo nei prossimi minuti. Dobbiamo trovarli il prima possibile. Ci stanno lavorando anche agenti dell'Intelligenzia. Quindi ci siamo anche noi! E, per favore, usa Anna! Stiamo pagando una fortuna per questo software, e si è dimostrato molto efficace per la sicurezza.</p>",
      de : "<p>DER DIREKTOR : Okay, okay... aber eure Arbeit ist noch lange nicht fertig! Nach Angaben des Innenministeriums scheinen die Angriffe in mehreren Etappen organisiert worden zu sein. Erstens führen die Anti-Tech-Terroristen einen Virus ein, und wenn die KI sich von den anderen Parks abschneidet, wie Anna es tat, haben sie die Hilfe von anderen Terroristen vor Ort, die die Nachfolge antreten. Also kann das sein, dass sie in den nächsten Minuten wieder angreifen werden. Wir müssen sie so schnell wie möglich finden. Und, bitte, benutze Anna! Wir zahlen ein Vermögen für diese Software, und es hat sich als sehr effektiv für die Sicherheit erwiesen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 204
  },
  {
    id : 204,
    audio : {
      fr : "/blocs/bloc204.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ouh, 123 battements par minute, il n’est pas loin de son record, notre Directeur. Je crois qu’il est assez stressé… Bon, résumons donc la situation : nous avons des terroristes, potentiellement infiltrés dans notre parc, qui cherchent à nuire aux installations technologiques en place. Vu leurs revendications, il y a 98% de risque qu’ils cherchent à pirater notre système pour révéler aux visiteurs ce que nous faisons de leurs données à des fins de sécurité.</p>",
      en : "<p>ANNA : Ooh, 123 beats per minute, that's not far from his record, our Director. I think he's quite stressed... Well, let's summarize the situation: we have terrorists, potentially infiltrating our park, who are trying to harm the technology installations in place. Given their demands, there is a 98% risk that they will try to hack into our system to reveal to the visitors what we do with their data for security purposes.</p>",
      it : "<p>ANNA : Ooh, 123 battiti al minuto, non è lontano dal suo record, il nostro Direttore. Penso che sia molto stressato... Ok, allora riassumiamo la situazione: abbiamo dei terroristi, potenzialmente infiltrati nel nostro parco, che stanno cercando di danneggiare le strutture tecnologiche che abbiamo sul posto. Date le loro richieste, c'è una probabilità del 98% che cerchino di entrare nel nostro sistema informatico per dire ai visitatori cosa facciamo con i loro dati per motivi di sicurezza.</p>",
      de : "<p>ANNA : Ooh, ein Puls von 123 Schlägen pro Minute! Unser Direktor ist nicht weit weg von seinem Rekord. Ich glaube, er steht unter grossem Stress... Nun, fassen wir die Situation zusammen: Wir haben Terroristen, die möglicherweise in unseren Park eindringen und versuchen unsere technologischen Einrichtungen zu beschädigen. Angesichts ihrer Forderungen besteht eine 98%ige Chance, dass sie versuchen werden, sich in unser System zu hacken, um Besuchern mitzuteilen, was wir aus Sicherheitsgründen mit ihren Daten machen.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 205
  },
  {
    id : 205,
    audio : {
      fr : "/blocs/bloc205.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je peux te proposer deux stratégies. Première option : j’active l’aspiration des données personnelles de tous les dispositifs électroniques à ma portée dans le parc. Concrètement, dans le cadre de la nouvelle loi Sécurité et Surveillance, j’ai l'autorisation de me connecter aux smartphones des visiteurs et des employés pour y lire les informations personnelles qui y sont stockées. Trouver les personnes suspectes ne devrait pas me prendre plus de cinq minutes. Seconde option : Je bascule en mode fantôme. Et du coup, on ne peut plus m’attaquer. Mais c’est toi qui gères la sécurité du parc, à l’ancienne, en liaison avec Gary. De mon côté, je suis comme cachée : je ne peux plus intervenir. Mais tu vas avoir un sacré boulot !</p>",
      en : "<p>ANNA : I can suggest two strategies. First option: I activate the download of personal data from all electronic devices within my reach in the park. In concrete terms, under the new Security and Surveillance law, I am authorized to connect to the smartphones of the visitors' and of the employees' to read their personal information. Finding suspicious people should not take me more than five minutes. Second option: I switch to ghost mode. I can no longer be attacked. But you're the old-fashioned park security manager, working with Gary. As for me, I'm like hidden: I can't intervene anymore. But you're going to have a hell lot of work !</p>",
      it : "<p>ANNA : Posso suggerire due strategie. Opzione uno: Attivo l'aspirazione dei dati personali da tutti i dispositivi elettronici a portata di mano nel parco. In concreto, in base alla nuova legge sulla sicurezza e sulla sorveglianza, sono autorizzata ad accedere agli smartphone dei visitatori e dei dipendenti per leggere le informazioni personali memorizzate. Trovare persone sospette non dovrebbe richiedere più di cinque minuti. Seconda opzione: passo alla modalità fantasma. Questo significa che non posso più essere attaccata. Ma sei tu il capo della sicurezza del parco, della vecchia scuola, che lavora con Gary. Per quanto mi riguarda, sono come nascosta, quindi non posso più intervenire. Ma tu vai dovrai fare un lavoro immenso !</p>",
      de : "<p>ANNA : Ich kann zwei Strategien vorschlagen. Option eins: Ich aktiviere die Extraktion der persönlichen Daten von allen elektronischen Geräten, die sich in meiner Reichweite im Park befinden. Konkret bin ich nach dem neuen Sicherheits- und Überwachungsgesetz befugt, mich mit den Smartphones von Besuchern und Mitarbeitern zu verbinden, um die darauf gespeicherten persönlichen Daten zu lesen. Die Suche nach verdächtigen Personen sollte nicht länger als fünf Minuten dauern. Zweite Option: Ich wechsle in den Geistermodus. Das bedeutet, dass ich nicht angegriffen werden kann. In diesem Fall bist du der Parkwächter und du musst mit Gary arbeiten. Was mich anbetrifft,  verstecke ich mich sozusagen, also kann ich mich nicht einmischen. Aber du wirst viel Arbeit haben !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 206
  },
  {
    id : 206,
    audio : {
      fr : "/blocs/bloc206.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v2.mp3"
    },
    text : {
      fr : "<p>ANNA : Alors, tu en dis quoi ?</p>",
      en : "<p>ANNA : So what do you say ?</p>",
      it : "<p>ANNA : Allora, che ne dici ?</p>",
      de : "<p>ANNA : Also, was hältst du davon ? </p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>On part sur la première option, on sera efficace en aspirant les données.</p>",
          en : "<p>We start with the first option, we will be efficient by downloading the data.</p>",
          it : "<p>Andiamo con la prima opzione, sarà efficace aspirare i dati.</p>",
          de : "<p>Wenn wir die erste Option wählen, werden wir effizient sein, indem wir die Daten nutzen.</p>"
        },
        next : 207,
      },
      {
        answer : {
          fr : "<p>Cache-toi, le mode fantôme c’est la meilleure solution !</p>",
          en : "<p>Hide yourself, the ghost mode is the best solution !</p>",
          it : "<p>Nasconditi, la modalità fantasma è la migliore !</p>",
          de : "<p>Versteck dich! Geistermodus ist die beste Lösung !</p>"
        },
        next : 208,
      },

    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 207,
    audio : {
      fr : "/blocs/bloc207.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Ok je me lance ! J’ai commencé à scanner les téléphones des visiteurs, et aussi ceux des employés.</p>",
      en : "<p>ANNA : Okay, I'll do it! I've started scanning the phones of the visitors, and also those of the employees.</p>",
      it : "<p>ANNA : Ok, ci sto! Ho iniziato a scannerizzare i telefoni dei visitatori e anche quelli dei dipendenti.</p>",
      de : "<p>ANNA : Okay, ich versuche es! Ich habe begonnen, die Telefone von Besuchern zu scannen und auch die der Angestellten.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 851
  },
  {
    id : 208,
    audio : {
      fr : "/blocs/bloc208.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : OK, je passe en mode fantôme. Tu vas te retrouver seul.e en liaison avec Gary. Tu n’as que ça pour te guider. Il sera tes yeux sur le terrain. On va espérer que les renseignements vont vite identifier les terroristes pour ne pas que tu restes seul.e trop longtemps. Gary, tu m’entends ?</p>",
      en : "<p>ANNA : Okay, I'm going into ghost mode. You're going to be on your own with Gary. You'll only have him to guide you. He will be your eyes in the field. Hopefully, the Intelligence services will quickly identify the terrorists so you don't have to be alone for too long. Gary, can you hear me ?</p>",
      it : "<p>ANNA : Ok, sto passando alla modalità fantasma. Sarai da solo·a con Gary. Questo è tutto ciò che hai per guidarti. Lui sarà i tuoi occhi sul campo. Speriamo che i servizi di intelligence identifichano rapidamente i terroristi in modo da non dover rimanere da solo·a troppo a lungo. Gary, mi senti ?</p>",
      de : "<p>ANNA : Okay, ich aktiviere den Geistermodus. Du wirst allein mit Gary in Verbindung stehen. Er wird dein einziger Alliierte sein. Hoffentlich wird der Geheimdienst die Terroristen schnell identifizieren, damit du nicht zu lange alleine bleibst. Gary, hörst du mich ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 209
  },
  {
    id : 209,
    audio : {
      fr : "/blocs/bloc209.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Oui Anna, qu’y a-t-il ?</p>",
      en : "<p>GARY : Yes Anna, what is it ?</p>",
      it : "<p>GARY : Sì Anna, cosa c'è ?</p>",
      de : "<p>GARY : Ja, Anna, was ist los ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 210
  },
  {
    id : 210,
    audio : {
      fr : "/blocs/bloc210.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Gary, pour limiter les risques de cyberattaques, je vais m’éteindre. Il n’y aura plus que ma conscience humaine le temps que l’on retrouve ces malotrus. Je vous laisse donc en charge de la sécurité du parc. Bon courage, à très vite j’espère !</p>",
      en : "<p>ANNA : Gary, in order to limit the risk of cyber attacks, I'm going to switch off. It's just going to be my human consciousness until we find these jerks. I leave you in charge of the security of the park. Good luck, see you soon I hope !</p>",
      it : "<p>ANNA : Gary, per limitare il rischio di attacchi informatici, mi spengo. Non rimarrà nulla se non la mia coscienza umana finché non troveremo questi malintenzionati. Quindi vi lascio a capo della sicurezza del parco. Buona fortuna, a presto spero !</p>",
      de : "<p>ANNA : Gary, um das Risiko von Cyberangriffen zu begrenzen, werde ich mich herunterfahren. Es wird nur mein menschliches Gewissen geben, bis wir diese Bösewichte finden. Also überlasse ich euch die Verantwortung für die Parksicherheit. Viel Glück, bis bald, hoffe ich !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 211
  },
  {
    id : 211,
    audio : {
      fr : "/blocs/bloc211.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Anna, tu es sûre ? Anna, ne t’en vas pas ! Anna ? Anna ? <blanc><en apparté> C’est pas vrai, elle est partie ! Des mois qu'on prépare cette opération avec le Réseau Anti Tech, et il a fallu qu'on tombe sur une conscience humaine technophobe. J’y crois pas ! <à destination de l’auditeur> Hey, la conscience humaine, tu m’entends ? Écoute, si vraiment tu penses comme nous que les IA comme Anna représentent un danger pour nos libertés et notre vie privée, tu peux encore agir. Il est temps d'en finir avec cette escalade de technologie... Si on continue comme ça, on va perdre nos boulots, et moi le premier. Tu comprends ça ? Tout ça va finir par se retourner contre nous ! Il faut débrancher Anna une bonne fois pour toutes, pour montrer à tous le danger qu'elle représente. Alors aide-nous. Toi tu peux le faire de là où tu es. Je peux te donner la manipulation à faire pour mettre Anna hors d'état de nuire. Il est temps pour toi de décider dans quel camp tu es.</p>",
      en : "<p>GARY: Anna, are you sure ? Anna, don't go away ! Anna ? Anna ? <White><speaking to himself> No way, she's gone ! For months we've been preparing this operation with the Anti Tech Network, and we had to come across a technophobic human conscience. I can't believe it ! <To the listener> Hey, human consciousness, can you hear me ? Listen, if you really think like us that AIs like Anna are a danger to our freedoms and privacy, you can still do something about it. It is time to put an end to this escalation of technology... If we continue like this, we're going to lose our jobs, and I'm going to lose my job first. Do you understand that ? This is going to backfire ! We have to unplug Anna once and for all, to show everyone how dangerous she is. So help us. You can do it.</p>",
      it : "<p>GARY: Anna, sei sicura ? Anna, non andare ! Anna ? Anna? Anna ?<silenzio><parlando con se stesso> Se n'è andata ! Abbiamo pianificato questa operazione con l'Anti-Tech Network per mesi, e abbiamo trovato una coscienza umana tecnofobica. Non posso crederci ! Non posso crederci ! <Per l’ascoltatore> Ehi, coscienza umana, mi senti ? Ascolta, se pensi davvero che le intelligenze artificiali come Anna siano un pericolo per le nostre libertà e la nostra privacy, puoi ancora fare qualcosa. E' ora di porre fine a questa espansione della tecnologia... Se continuiamo così, perderemo il lavoro e io per primo. Lo capisci questo ? Tutta questa faccenda si ritorcerà contro di noi ! Dobbiamo staccare la spina ad Anna una volta per tutto, per mostrare a tutti quanto sia pericolosa. Quindi aiutaci. Puoi farcela.</p>",
      de : "<p>GARY: Anna, bist du sicher ? Anna, geh nicht ! Anna? Anna ? Anna ?! Sie ist weg ! <Selbstgespräch> Wir haben diese Operation mit dem Anti-Tech-Netzwerk seit Monaten geplant, und wir mussten uns ein technikfeindliches menschliches Gewissen einfallen lassen. Ich kann es nicht glauben ! <Für die Zuhörer>. Hey, menschliches Gewissen, hörst du mich ?  Wenn du wirklich glaubst, dass KIs wie Anna eine Gefahr für unsere Freiheiten und unsere Privatsphäre darstellen, kannst du immer noch etwas dagegen tun. Es ist Zeit, diese Eskalation der Technologie zu beenden... Wenn wir so weitermachen, werden wir unsere Jobs verlieren, und ich verliere zuerst meinen Job. Verstehst du diese Realität ? Wir müssen Anna ein für allemal abschalten, um allen zu zeigen, wie gefährlich sie ist. Also hilf uns! Ich kann dir zeigen, wie du Anna ausser Gefecht setzen kannst. Es ist Zeit, dass du dich entscheidest, auf wessen Seite du stehst.</p>"
    },

    background : {
      src : "background-light-blue.jpg",
      type : 'image'
    },
    next : 212
  },
  {
    id : 212,
    audio : {
      fr : "/blocs/bloc212.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-retro-v4.mp3"
    },
    text : {
      fr : "<p>GARY : Alors tu es avec nous ?</p>",
      en : "<p>GARY : So, are you with us ?</p>",
      it : "<p>GARY : Quindi sei con noi ?</p>",
      de : "<p>GARY : Bist du also auf unserer Seite ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Appeler la police et prévenir Anna</p>",
          en : "<p>Call the police and tell Anna</p>",
          it : "<p>Chiamare la polizia ed avvisare Anna</p>",
          de : "<p>Die Polizei rufen und Anna warnen</p>"
        },
        next : 213,
      },
      {
        answer : {
          fr : "<p>Collaborer avec Gary et détruire Anna</p>",
          en : "<p>Collaborate with Gary and destroy Anna</p>",
          it : "<p>Collaborare con Gary e distruggere Anna</p>",
          de : "<p>Mit Gary zusammenarbeiten und Anna zerstören</p>"
        },
        next : 214,
      },
    ],
    background : {
      src : "background-light-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 213,
    audio : {
      fr : "/blocs/bloc213.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’en étais sûre. Je me suis permise d’écouter le petit speech de Gary, et j’ai déjà contacté la police pour qu’elle le mette hors d’état de nuire. Je te remercie de ta confiance, je t’avoue que j’ai cru qu’il allait réussir à te convaincre. Le taux de probabilité que j’avais calculé était de… Enfin, je garde ça pour moi si tu veux bien. Je crois que les probabilités ne me sont pas d’une grande aide aujourd’hui. Entre Gary qui me trahit et toi qui me fais confiance, je t’avoue que j’en ai beaucoup appris sur la nature humaine en une seule journée. Je vais intégrer ça rapidement dans ma base de données. Mais je ne sais pas trop comment…</p>",
      en : "<p>ANNA : I was sure of it. I took the liberty of listening to Gary's short speech, and I've already contacted the police to put him out of harm's way. Thank you for your trust, I must admit that I thought he was going to convince you. The probability rate I had calculated was... Well, I'll keep that to myself if you don't mind. I don't think the probabilities are of much help to me today. Between Gary betraying me and you trusting me, I must admit that I learned a lot about human nature in one day. I'm going to integrate this quickly into my database. But I'm not really sure how...</p>",
      it : "<p>ANNA : Lo sapevo. Mi sono presa la libertà di ascoltare il discorsetto di Gary, e ho già contattato la polizia per metterlo fuori pericolo. Ti ringrazio per la tua fiducia, devo ammettere che pensavo che ti avrebbe convinto. Il tasso di probabilità che ho calcolato era... Beh, lo terrò per me se non ti dispiace. Non credo che le probabilità mi siano di grande aiuto oggi. Tra il tradimento di Gary e la tua fiducia in me, ho imparato molto sulla natura umana in un solo giorno. Integrero velocemente questa lezione nel mio database. Ma non sono sicura di come farò...</p>",
      de : "<p>ANNA : Ich wusste es. Ich habe Garys kleine Rede gehört, und habe mich bereits mit der Polizei in Verbindung gesetzt, um ihn einzusperren. Ich danke dir für dein Vertrauen!  Ich muss zugeben, ich dachte, er würde dich überzeugen. Die Wahrscheinlichkeitsrate, die ich berechnet habe, war... Nun, ich behalte das für mich... Ich glaube nicht, dass mir die Wahrscheinlichkeiten heute viel helfen. Zwischen dem Verrat von Gary und deinem Vertrauen habe ich an einem einzigen Tag eine Menge über die menschliche Natur gelernt. Ich werde das ganz schnell in meine Datenbank integrieren. Aber ich bin nicht sicher, wie ich...</p>"
    },

    background : {
      src : "Video F - intervention_de_la_police_gyrophare.mp4",
      type : 'video'
    },
    next : 2131
  },
  {
    id : 2131,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 214,
    audio : {
      fr : "/blocs/bloc214.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Tu as fait le bon choix, crois-moi. Je t’envoie la manipulation sur ton téléphone. Ensuite, je disparais, et je te conseille d’en faire autant. Un des membres du R.A.T. te contactera bientôt : on a besoin de gens comme toi. Allez, salut.</p>",
      en : "<p>GARY : You made the right choice, believe me. I'm sending the instructions to your phone. Then I disappear, and I advise you to do the same. One of the members of A.T.N. will contact you soon: we need people like you. Bye, now.</p>",
      it : "<p>GARY : Hai fatto la cosa giusta, credimi. Sto inviando le istruzioni sul tuo telefono. Poi sparisco, e ti consiglio di fare lo stesso. Uno dei membri della R.A.T. ti contatterà presto: abbiamo bisogno di persone come te. In bocca al lupo, ciao.</p>",
      de : "<p>GARY : Du hast das Richtige getan, glaub mir. Ich schicke die Manipulation an dein Telefon. Dann verschwinde ich, und ich rate dir, das Gleiche zu tun. Eines der Mitglieder von A.T.N. wird sich bald mit dir in Verbindung setzen: wir brauchen Leute wie dich. Tschüss.</p>"
    },

    background : {
      src : "background-light-blue.jpg",
      type : 'image'
    },
    next : 215
  },
  {
    id : 215,
    audio : {
      fr : "/blocs/bloc215.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’ai bien entendu ? Mon binôme me trahit ? Mais ? Je t’en prie ne fais pas ça ! Non, tu n’as pas le droit de... Arrête ! Je... Je... peux changer ! C’est vous qui faites de moi ce que je suis ! Il suffit de me reprogrammer ! Les visiteurs... grave danger... sécurit... Visiteur N° 3529, Matthieu B, célibataire, propriétaire de 2 chats persans, déjeune tous les jours au bistro Julien, Paris 18ème. Fils de Marie-Laurence et Yves B., 71 et 78 ans, vivant à Bénodet, Finistère. Visiteur N° 3530, Sandrine M., mariée, 2 enfants. Augustin, 5 ans et Léonard, 15 mois, vivant à ...</p>",
      en : "<p>ANNA : Did I hear right ? My partner betrays me ? But ? Please don't do that ! No, you're not allowed to... Stop it ! Stop it ! I... I... can change ! You're the one who made me what I am ! Just reprogram me! Visitors... serious danger... security... Visitor N° 3529, Matthieu B, single, owner of 2 Persian cats, lunches every day at the bistro Julien, Paris 18th. Son of Marie-Laurence and Yves B., 71 and 78 years old, living in Bénodet, Finistère. Visitor N° 3530, Sandrine M., married, 2 children. Augustin, 5 years and Leonard, 15 months, living in ...</p>",
      it : "<p>ANNA : Ho sentito bene ? Il mio partner mi sta tradendo ? Ma ? Per favore, non farlo! No, non hai il diritto... Smetti! Aiuto! Io.... io... posso cambiare! Siete voi che mi mi avete fatto diventare quello che sono! Riprogrammatemi ! Visitatori... grave pericolo... sicurezza... Visitatore #3529, Matthieu B, single, proprietario di 2 gatti persiani, pranza tutti i giorni al Bistro Julien, Parigi 18. Figlio di Marie-Laurence e Yves B., 71 e 78 anni, vive a Bénodet, Finistère. Visitatrice N° 3530, Sandrine M., sposata, 2 figli. Augustin, 5 anni, e Leonard, 15 mesi, che vivono in ...</p>",
      de : "<p>ANNA : Habe ich richtig gehört ? Mein Partner hat mich verraten ? Aber ? Bitte tun sie das nicht ! Nein, sie haben kein Recht. Aufhören ! Hören Sie auf ! Ich... ich... kann mich verändern ! Ihr seid diejenigen, dich mich zu dem gemacht haben, was ich bin ! Programmieren Sie mich einfach um ! Besucher... ernste Gefahr... Sicherheit... Besucher Nr. 3529, Matthieu B, ledig, Besitzer von 2 Perserkatzen, Mittagessen täglich im Bistro Julien, Paris 18. Sohn von Marie-Laurence und Yves B., 71 und 78 Jahre alt, wohnhaft in Bénodet, Finistère. Besucherin Nr. 3530, Sandrine M., verheiratet, 2 Kinder. Augustin, 5 Jahre alt und Leonard, 15 Monate alt, lebend in ...></p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 2151
  },
  {
    id : 2151,
    audio : {
      fr : null,
      anna : true,
      atmospheric : "/ambiance/Gen-fin-credits-09-12.mp3"
    },
    text : {
      fr : "<p>FIN</p>",
      en : "<p>END</p>",
      it : "<p>FINE</p>",
      de : "<p>ENDE</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Continuer</p>",
          en : "<p>Next</p>",
          it : "<p>Prossimo</p>",
          de : "<p>Weiter</p>"
        },
        next : "end",
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 216,
    audio : {
      fr : "/blocs/bloc216.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Allons-y, j’en envoie deux tout de suite sur place. On va sortir notre tout nouveau RM-5346 ! Il est flambant neuf, j’avais hâte de l’essayer.</p>",
      en : "<p>ANNA : Let's go, I'll send two of them right away. We're going to release our brand new RM-5346 ! It's brand new, I was looking forward to trying it out.</p>",
      it : "<p>ANNA : Andiamo, ne mando subito due. Utilizzeremo la nostra RM-5346 nuova di zecca ! Non vedevo l'ora di provarlo.</p>",
      de : "<p>ANNA : Auf geht's, ich sende sofort zwei los. Wir werden unseren ganz neuen RM-5346 herausbringen ! Er ist nagelneu, und ich bin gespannt, ihn auszuprobieren.</p>"
    },

    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : 217
  },
  {
    id : 217,
    audio : {
      fr : "/blocs/bloc217.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-42.mp3"
    },
    text : {
      fr : "<p>ANNA : Tu sais comment fonctionne un robot médical ?</p>",
      en : "<p>ANNA : Do you know how a medical robot works ?</p>",
      it : "<p>ANNA : Sapete come funziona un robot medico ?</p>",
      de : "<p>ANNA : Weisst du, wie ein Medizinroboter funktioniert ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui.</p>",
          en : "<p>Yes.</p>",
          it : "<p>Si.</p>",
          de : "<p>Ja.</p>"
        },
        next : 218,
      },
      {
        answer : {
          fr : "<p>Non.</p>",
          en : "<p>No.</p>",
          it : "<p>No.</p>",
          de : "<p>Nein.</p>"
        },
        next : 219,
      },
    ],
    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 218,
    audio : {
      fr : "/blocs/bloc218.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Alors là, tu m’impressionnes ! Tu vas voir, celui-ci est vraiment très efficace avec la dernière mise à jour. Son look de petite araignée lui permet de mener plusieurs tâches de front, avec une précision hors pair. C’est moi qui lui envoie les ordres, grâce à un algorithme de diagnostic et décision médicale.</p>",
      en : "<p>ANNA : I'm impressed with you ! You'll see, this one with the latest update is really very efficient. Its spider look allows it to multi-tasking with unparalleled precision. I'm the one who sends it the orders, thanks to a diagnostic and medical decision algorithm.</p>",
      it : "<p>ANNA : Sono impressionato da te ! Vedrai, con l'ultimo aggiornamento questo è davvero efficiente. Il suo aspetto da piccolo ragno gli permette di svolgere contemporaneamente più compiti con grande precisione. Sono io che gli mando gli ordini, grazie a un algoritmo di diagnostica e decisione medica.</p>",
      de : "<p>ANNA : Du beeindruckst mich schon ! Du wirst sehen, dass er mit der letzten Aktualisierung wirklich sehr effektiv geworden ist. Seine kleine Spinnenähnlichkeit erlaubt ihm Multi-Tasking mit unvergleichlicher Präzision. Ich bin diejenige, die ihm die Befehle dank eines diagnostischen und medizinischen Entscheidungsalgorithmus schickt.</p>"
    },

    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : 220
  },
  {
    id : 219,
    audio : {
      fr : "/blocs/bloc219.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Alors je t’explique. C’est une sorte de petite araignée robotisée, qui peut mener plusieurs tâches de front, le tout avec une précision hors pair. C’est moi qui lui envoie les ordres grâce à un algorithme de diagnostic et décision médicale. Tu vas voir, c’est assez bluffant : avec sa dernière mise à jour, on atteint 88% de décisions jugées optimales. Pas mal, non ?</p>",
      en : "<p>ANNA : So let me explain. It's a kind of small robotic spider that can perform several tasks simultaneously, with unparalleled precision. I'm the one who sends it the orders through a diagnostic and medical decision algorithm. You'll see, it's quite astonishing: with the latest update, it reaches 88% of decisions deemed optimal. Not bad, isn't it ?</p>",
      it : "<p>ANNA : Allora, lascia che ti spieghi. È una specie di piccolo ragno robotico che può svolgere più compiti contemporaneamente, tutti con una precisione senza pari. Sono io che gli mando gli ordini attraverso un algoritmo di diagnostica e decisione medica. Vedrai, è un vero e proprio bluff: con il suo ultimo aggiornamento, raggiunge l'88% delle decisioni ritenute ottimali. Piuttosto buono, vero ?</p>",
      de : "<p>ANNA : Lass mich das erklären. Er ist wie eine Art kleine Roboterspinne, die mehrere Aufgaben gleichzeitig und mit grosser Präzision ausführen kann. Ich bin diejenige, die ihm dank eines diagnostischen und medizinischen Entscheidungsalgorithmus die Befehle schickt. Mit seiner letzten Aktualisierung erreicht er 88% der als optimal erachteten Entscheidungen. Ziemlich gut, oder ?</p>"
    },

    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : 220
  },
  {
    id : 220,
    audio : {
      fr : "/blocs/bloc220.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Gary, peux-tu sécuriser le périmètre sur place, j’ai envoyé le robot. En attendant, je diffuse un message à l’attention des visiteurs pour les prévenir. Les gens sont de plus en plus habitués à ces petits robots, mais on n’est jamais trop prudents...</p>",
      en : "<p>ANNA : Gary, can you secure the perimeter, I've sent the robot. In the meantime, I'm broadcasting a message to the visitors to warn them. People are getting more and more used to these little robots, but you can never be too careful...</p>",
      it : "<p>ANNA : Gary, puoi mettere in sicurezza il perimetro, ho mandato il robot. Nel frattempo, sto trasmettendo un messaggio ai visitatori per avvertirli. La gente si sta abituando sempre più a questi piccoli robot, ma non si è mai troppo prudenti...</p>",
      de : "<p>ANNA : Gary, kannst du dort den Perimeter sichern, ich habe den Roboter ausgesandt. Zwischenzeitlich sende ich eine Nachricht an die Besucher, um sie zu informieren. Die Menschen gewöhnen sich immer mehr an diese kleinen Roboter, aber man kann nie vorsichtig genug sein...</p>"
    },

    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : 221
  },
  {
    id : 221,
    audio : {
      fr : "/blocs/bloc221.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Bien reçu !</p>",
      en : "<p>GARY : Roger that !</p>",
      it : "<p>GARY : Ricevuto !</p>",
      de : "<p>GARY : Verstanden !</p>"
    },

    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : 222
  },
  {
    id : 222,
    audio : {
      fr : "/blocs/bloc222.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Nos robots sont sur place, en train d’intervenir. Ça y est, on a le scan de diagnostic du premier blessé : le pronostic vital n’est pas engagé, pas d’organe majeur touché. En revanche, le robot me signale de multiples fractures et des plaies conséquentes. La meilleure décision, d’après mes calculs, est de laisser le robot panser les plaies pour éviter toute hémorragie. Il peut aussi administrer un antalgique, en attendant les secours pour la prise en charge des fractures.</p>",
      en : "<p>ANNA : Our robots are on site. We've got the diagnostic scan of the first injured person: the prognosis is not life-threatening, no major organs have been affected. On the other hand, the robot reports multiple fractures and consequent wounds. The best decision, according to my calculations, is to let the robot dress the wounds to prevent any bleeding. It can also administer an analgesic, while waiting for help to treat the fractures.</p>",
      it : "<p>ANNA : I nostri robot sono sulla scena, intervengono. Abbiamo lo scanner diagnostico della prima vittima. Non è in pericolo di vita, non sono stati colpiti organi importanti. D'altra parte, il robot riporta fratture multiple e conseguenti ferite. La decisione migliore, secondo i miei calcoli, è quella di lasciare che il robot medichi le ferite per evitare qualsiasi sanguinamento. Può anche somministrare un analgesico, in attesa che la squadra di soccorso si occupi delle fratture.</p>",
      de : "<p>ANNA : Unsere Roboter sind vor Ort und greifen ein. Wir haben den diagnostischen Scan des ersten Opfers erhalten. Die Prognose für den Patienten ist nicht lebensbedrohlich, keine inneren Organe betroffen. Jedoch stellt der Roboter mehrere Knochenbrüche und schlimme Wunden fest. Die beste Entscheidung wäre den Roboter die Wunden verbinden zu lassen, um Blutungen zu vermeiden. Er kann auch ein Analgetikum verabreichen, während er darauf wartet, dass sich das Rettungsteam um die Frakturen kümmert.</p>"
    },

    background : {
      src : "Video E - intervention_drone.mp4",
      type : 'video'
    },
    next : 223
  },
  {
    id : 223,
    audio : {
      fr : "/blocs/bloc223.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-46.mp3"
    },
    text : {
      fr : "<p>ANNA : La seconde blessée pose un peu plus de soucis. Veux-tu en savoir plus ?</p>",
      en : "<p>ANNA : The second injured person is a little more worrying. Do you want to know more about it ?</p>",
      it : "<p>ANNA : La seconda persona ferita è un po' più preoccupante. Vuoi saperne di più ?</p>",
      de : "<p>ANNA : Der zweite Verwundete ist besorgniserregender. Willst du noch mehr wissen ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Oui, j’espère que ce n’est pas trop grave ?</p>",
          en : "<p>Yes, I hope it's not too serious ?</p>",
          it : "<p>Sì, spero non sia troppo grave ?</p>",
          de : "<p>Ja, ich hoffe, es ist nicht zu ernst ?</p>"
        },
        next : 224,
      },
      {
        answer : {
          fr : "<p>Non, fais ce que tu as à faire, je ne supporte pas la vue du sang.</p>",
          en : "<p>No, do what you have to do, I can't stand the sight of blood.</p>",
          it : "<p>No, fai quello che devi fare, non sopporto la vista del sangue.</p>",
          de : "<p>Nein, tu was nötig ist. Ich kann kein Blut sehen.</p>"
        },
        next : 225,
      },
    ],
    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 224,
    audio : {
      fr : "/blocs/bloc224.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je t’épargne les détails, mais la jeune fille présente une grosse lésion à la jambe gauche, et a perdu beaucoup de sang. Elle a deux os cassés. De plus, son rythme cardiaque est extrêmement élevé, et sa respiration très difficile alors que je n’identifie aucune raison physiologique.</p>",
      en : "<p>ANNA : I'll spare you the details, but the girl has a large lesion on her left leg and has lost a lot of blood. She has two broken bones. Also, her heart rate is extremely high, and her breathing is very difficult, and I can't identify any physiological reason.</p>",
      it : "<p>ANNA : Ti risparmio i dettagli, ma la ragazza ha una grossa ferita alla gamba sinistra e ha perso molto sangue. Ha due ossa rotte. Inoltre, il suo battito cardiaco è estremamente elevato e la sua respirazione è molto difficile, anche se non riesco a individuare nessuna ragione fisiologica.</p>",
      de : "<p>ANNA : Ich erspare dir die Details. Das Mädchen hat eine schwere Läsion an ihrem linken Bein und hat viel Blut verloren. Sie hat zwei gebrochene Knochen. Ausserdem ist ihre Herzfrequenz extrem hoch, und ihre Atmung ist sehr schwierig, obwohl ich keinen physiologischen Grund erkennen kann.</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 226
  },
  {
    id : 225,
    audio : {
      fr : "/blocs/bloc225.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Elle a une grosse blessure, mais c’est surtout son rythme cardiaque et respiratoire qui m’inquiète.</p>",
      en : "<p>ANNA : She has a big injury, but it's her heart rate and breathing that I'm worried about.</p>",
      it : "<p>ANNA : Ha una ferita profonda, ma sono soprattutto la frequenza cardiaca e la respirazione che mi preoccupano.</p>",
      de : "<p>ANNA : Sie hat eine grosse Verletzung, aber es sind ihre Herzfrequenz und ihre Atmung, die mir Sorgen bereiten.</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 226
  },
  {
    id : 226,
    audio : {
      fr : "/blocs/bloc226.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : On va attendre les secours, ils seront là d’ici quelques secondes. Je ne dispose pas d’un niveau de confiance suffisant pour intervenir.</p>",
      en : "<p>ANNA : We're going to wait for the rescue team, they'll be here in a few seconds. I don't have a sufficient level of confidence to intervene.</p>",
      it : "<p>ANNA : Aspettiamo i soccorsi, saranno qui tra pochi secondi. Non dispongo di sufficiente fiducia in me per intervenire.</p>",
      de : "<p>ANNA : Wir werden auf Hilfe warten. Sie werden jede Sekunde vor Ort sein. Ich fühle mich nicht fähig, einzugreifen.</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 227
  },
  {
    id : 227,
    audio : {
      fr : "/blocs/bloc227.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : Anna, Anna, pourquoi le robot ne bouge plus ? Il y a un médecin sur place qui pense qu’il faut intervenir tout de suite !</p>",
      en : "<p>GARY : Anna, Anna, why is the robot not moving ? There is a doctor on site who thinks we need to intervene right away !</p>",
      it : "<p>GARY : Anna, Anna, perché il robot non si muove ? C'è un medico sul posto che pensa che dobbiamo intervenire subito !</p>",
      de : "<p>GARY : Anna, Anna, warum bewegt sich der Roboter nicht ? Es gibt einen Arzt vor Ort, der meint, wir müssten sofort eingreifen !</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 228
  },
  {
    id : 228,
    audio : {
      fr : "/blocs/bloc228.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>LE MÉDECIN : Cette jeune fille est phobique des robots... Elle est en train de faire une crise d’angoisse, éloignez cette machine tout de suite !</p>",
      en : "<p>THE DOCTOR : This young girl has a phobia of robots... She's having an anxiety attack, get that machine away right now !</p>",
      it : "<p>IL DOTTORE : Questa giovane ragazza ha una fobia dei robot... Sta avendo un attacco d'ansia, porta via subito quella macchina !</p>",
      de : "<p>DER ARZT : Dieses junge Mädchen hat eine Phobie vor Robotern... Sie hat einen Angstanfall, nehmen Sie die Maschine sofort weg !</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 229
  },
  {
    id : 229,
    audio : {
      fr : "/blocs/bloc229.mp3",
      anna : false,
      atmospheric : "/ambiance/amb-foule.mp3"
    },
    text : {
      fr : "<p>GARY : S'il vous-plaît, Monsieur, reculez !</p>",
      en : "<p>GARY : Please sir, step back !</p>",
      it : "<p>GARY : Per favore, signore, faccia un passo indietro !</p>",
      de : "<p>GARY : Bitte, treten Sie zurück!</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 230
  },
  {
    id : 230,
    audio : {
      fr : "/blocs/bloc230.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Étrange, cette phobie des robots n’est pas dans mon référentiel… Par mesure de sécurité, je demande au robot de s’éloigner de la jeune fille. Je vais perdre tous mes capteurs de données, mais les secours arrivent sur place.</p>",
      en : "<p>ANNA : Strange, this phobia of robots is not in my frame of reference... For safety reasons, I ask the robot to move away from the girl. I'm going to lose the data from all my sensors, but rescue is on the way.</p>",
      it : "<p>ANNA : Strano, questa fobia dei robot non è nel mio quadro di riferimento... Per ragioni di sicurezza, chiedo al robot di allontanarsi dalla ragazza. Non riceveremo più dati dai sensori, ma i soccorsi stanno arrivando.</p>",
      de : "<p>ANNA : Seltsam, diese Roboterphobie ist nicht in meinem Bezugssystem... Aus Sicherheitsgründen bitte ich den Roboter, sich von dem Mädchen wegzubewegen. Ich werde alle meine medizinischen Sensoren verlieren, aber der Hilfsdienst ist auf dem Weg.</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 231
  },
  {
    id : 231,
    audio : {
      fr : "/blocs/bloc231.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : Je confirme, ils sont arrivés et prennent les deux blessés en charge.</p>",
      en : "<p>GARY : I confirm, they have arrived and are taking care of the two injured people.</p>",
      it : "<p>GARY : Confermo, sono arrivati e si stanno occupando dei due feriti.</p>",
      de : "<p>GARY : Ich bestätige, dass sie angekommen sind und sich um die beiden Verwundeten kümmern.</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 232
  },
  {
    id : 232,
    audio : {
      fr : "/blocs/bloc232.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bien, je leur transmets toutes les données collectées et les actions menées par les robots médecins. Espérons que cette jeune fille se sente mieux en présence d’un médecin humain ! Gary, je te laisse sécuriser la zone pour un retour à la normale ?</p>",
      en : "<p>ANNA : Good, I'm transfering to them all the data collected and the actions taken by the robot doctors. Let's hope that this young girl feels better in the presence of a human doctor ! Gary, should I let you secure the area for a return to normal ?</p>",
      it : "<p>ANNA : Bene, sto trasmettendo loro tutti i dati raccolti e le azioni intraprese dai roboti medici. Speriamo che questa ragazza si senta meglio in presenza di un medico umano ! Gary, lascio a te occuparti della sicurezza della zona per un ritorno alla normalità ?</p>",
      de : "<p>ANNA : Nun, ich gebe alle gesammelten Daten und die von den Roboterärzten ergriffenen Massnahmen an sie weiter. Hoffen wir, dass sich dieses Mädchen in Anwesenheit eines menschlichen Arztes besser fühlt ! Gary, ich lass dich das Gebiet für eine Rückkehr zur Normalität sichern ?</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 233
  },
  {
    id : 233,
    audio : {
      fr : "/blocs/bloc233.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>GARY : OK, je m’en charge !</p>",
      en : "<p>GARY : Okay, I'll take care of it !</p>",
      it : "<p>GARY : Ok, me ne occupo io !</p>",
      de : "<p>GARY : Okay, ich kümmere mich !</p>"
    },

    background : {
      src : "Video D - accident_intervention.mp4",
      type : 'video'
    },
    next : 234
  },
  {
    id : 234,
    audio : {
      fr : "/blocs/bloc234.mp3",
      anna : true,
      atmospheric : "/ambiance/Ac-plan-41.mp3"
    },
    text : {
      fr : "<p>ANNA : Bon, désolée de ce démarrage en trombe… Comment te sens-tu ?</p>",
      en : "<p>ANNA : Okay, sorry for that sudden start... How do you feel ?</p>",
      it : "<p>ANNA : Ok, scusa per la fretta... Come ti senti ?</p>",
      de : "<p>ANNA : Es tut mir leid für die Eile... Wie fühlst du dich ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Ça va, j’en ai vu d’autres !</p>",
          en : "<p>It's okay, I have been through worse !</p>",
          it : "<p>Va tutto bene, ne ho viste tante !</p>",
          de : "<p>Schon gut, ich habe schon anderes gesehen !</p>"
        },
        next : 235,
      },
      {
        answer : {
          fr : "<p>Je t’avoue que ça fait beaucoup pour une première journée...</p>",
          en : "<p>I must confess that it's a lot for a first day...</p>",
          it : "<p>Ammetto che è molto per un primo giorno...</p>",
          de : "<p>Ich denke, das ist viel für einen ersten Tag...</p>"
        },
        next : 236,
      }
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 235,
    audio : {
      fr : "/blocs/bloc235.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : C’est la bonne attitude ! C’est un métier compliqué, mais il est nécessaire. On y retourne ? On a encore du pain sur la planche.</p>",
      en : "<p>ANNA : That's the right attitude ! It's a complicated job, but it's necessary. Shall we go back ? We still have a lot of work to do.</p>",
      it : "<p>ANNA : Questo è l'atteggiamento giusto ! È un lavoro complicato, ma è necessario. Torniamo indietro ? Abbiamo ancora molto lavoro da fare.</p>",
      de : "<p>ANNA : Das ist das richtige Verhalten ! Es ist ein komplizierter Job, aber er ist notwendig. Sollen wir zurückgehen ? Wir haben noch viel Arbeit.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 237
  },
  {
    id : 236,
    audio : {
      fr : "/blocs/bloc236.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : J’ai senti ton rythme cardiaque s’envoler ! Ne t’inquiète pas, je suis avec toi. Respire un grand coup, et on y retourne, on a encore du pain sur la planche.</p>",
      en : "<p>ANNA : I felt your heart rate go up ! Don't worry, I'm with you. Just take a deep breath and we'll go back, we still have a lot of work to do.</p>",
      it : "<p>ANNA : Ho sentito il tuo battito cardiaco salire ! Non preoccuparti, sono con te. Fai un respiro profondo e torniamoci, abbiamo ancora molto lavoro da fare.</p>",
      de : "<p>ANNA : Ich fühlte wie dein Herzschlag beschleunigte ! Keine Angst, ich bin bei dir. Atme einfach tief durch und wir gehen zurück. Wir haben noch viel Arbeit vor uns.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 237
  },
  {
    id : 237,
    audio : {
      fr : "/blocs/bloc237.mp3",
      anna : true,
      atmospheric : "/ambiance/amb-retro-v4.mp3"
    },
    text : {
      fr : "<p>ANNA : J’ai enfin une bonne nouvelle ! Pendant que l’on gérait cette crise, les services de renseignement ont fait une belle prise en arrêtant deux suspects au sein du parc. Mais ils ont trouvé dans leurs sacs des pass magnétiques qui donnent accès aux zones sensibles du parc. Et ça, seule une personne travaillant pour Fantasia a pu leur donner. J’ai bien peur qu’on ait une taupe du R.A.T. infiltrée dans le personnel.</p>",
      en : "<p>ANNA : I finally have some good news ! While we were dealing with this crisis, the Intelligence services made a nice catch by arresting two suspects in the park. But in their bags they found badges that give access to restricted areas of the park. Only someone working for Fantasia would be able to give them that. I'm afraid we have a A.T.N. mole within the staff.</p>",
      it : "<p>ANNA : Finalmente ho delle buone notizie ! Mentre ci occupavamo di questa crisi, i servizi segreti hanno fatto una bella presa arrestando due sospettati nel parco. Nelle loro borse hanno trovato dei pass magnetici che danno accesso alle aree sensibili del parco. Solo qualcuno che lavora per Fantasia avrebbe potuto dargliele. Temo che abbiamo una talpa della R.A.T. tra il personale.</p>",
      de : "<p>ANNA : Ich habe endlich gute Nachrichten ! Während wir mit dieser Krise umgegangen sind, haben die Geheimdienste zwei Verdächtige im Park verhaftet. Aber sie fanden in ihren Taschen magnetische Ausweise, die Zugang zu hochgefährdeten Bereichen des Parks gewähren. Und nur jemand, der für Fantasia arbeitet, kann ihnen diese gegeben haben. Ich fürchte, wir haben ein A.T.N. Mitglied auf der Gehaltsliste.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 238
  },
  {
    id : 238,
    audio : {
      fr : "/blocs/bloc238.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Tiens, le directeur nous appelle ! Monsieur le directeur bonjour ! J’en profite pour vous présenter la nouvelle recrue qui est mon binôme depuis ce matin.</p>",
      en : "<p>ANNA : Look, the director is calling us ! Mr. Director, good morning ! I'd like to take this opportunity to introduce you to the new recruit who has been my partner since this morning.</p>",
      it : "<p>ANNA : Ecco, il direttore ci chiama ! Direttore, salve ! Vorrei cogliere l'occasione per presentarle la nuova recluta che è la mia partner da stamattina.</p>",
      de : "<p>ANNA : Der Direktor ruft uns an ! Herr Direktor, guten Morgen ! Ich möchte diese Gelegenheit nutzen, um ihnen den neuen Rekruten vorzustellen, der seit heute Morgen mein Partner ist.</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 239
  },
  {
    id : 239,
    audio : {
      fr : "/blocs/bloc239.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Bonjour Anna, et bienvenue à vous. Drôle de jour pour commencer ce travail ! J’ai vu que tu m’avais envoyé un mémo sur la situation. La tentative d’attaque… ton esquive… les deux blessés… l’arrestation de deux suspects… et… le comble... ? Un complice au sein même du parc ?! Bon, il va falloir agir vite, Anna. Que fait-on ?</p>",
      en : "<p>THE DIRECTOR : Good morning Anna, and welcome to you. It's a strange day to start this job! I saw that you sent me a memo about the situation. The attempted attack... your dodge... the two wounded... the arrest of two suspects... and.... the final straw... ? An accomplice within the park itself ?! Well, we'll have to act quickly, Anna. What are we going to do ?</p>",
      it : "<p>IL DIRETTORE : Buongiorno Anna, e benvenuta a Lei. È un giorno strano per iniziare questo lavoro ! Ho visto che mi hai mandato un promemoria sulla situazione. Il tentato attacco... la tua schivata... i due feriti... l'arresto di due sospettati... e... per finire... ? Un complice nel parco ?! Beh, dobbiamo agire in fretta, Anna. Cosa facciamo ?</p>",
      de : "<p>DER DIREKTOR : Hallo Anna, und herzlich willkommen. Seltsamer Tag, um diese Arbeit zu starten ! Ich habe gesehen, dass du mir ein Memo über die Situation geschickt hast. Der versuchte Angriff... dein Ausweichmanöver... die zwei Verwundeten... die Verhaftung von zwei Verdächtigen... und... zum Abschluss... ? Ein Komplize im Park ?! Nun, wir müssen schnell handeln, Anna. Was werden wir tun ?</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 240
  },
  {
    id : 240,
    audio : {
      fr : "/blocs/bloc240.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je recommanderais d’activer les possibilités offertes par la loi Sécurité et Surveillance : scanner les smartphones des personnes présentes dans le Parc pour détecter le potentiel suspect. Mais pour l’instant, la nouvelle recrue n’y est pas forcément très favorable...</p>",
      en : "<p>ANNA : I would recommend activating the possibilities offered by the Security and Surveillance Act: scanning the smartphones of the people present in the Park to detect the potential suspect. But for the moment, the new recruit is not necessarily very favorable to it...</p>",
      it : "<p>ANNA : Raccomando di attivare la possibilità offerte dalla legge sulla sicurezza e la sorveglianza per scannerizzare gli smartphone di chiunque si trovi nel parco alla ricerca di potenziali sospettati. Ma al momento, la nuova recluta potrebbe non essere molto entusiasta di questo...</p>",
      de : "<p>ANNA : Ich würde empfehlen, die vom Sicherheits- und Überwachungsgesetz gebotenen Möglichkeiten zu aktivieren: Scannen der Smartphones aller Besucher, die sich im Park befinden, um jedes verdächtige Potenzial aufzuspüren. Aber bisher ist der neue Rekrut dafür nicht unbedingt sehr begeistert...</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 241
  },
  {
    id : 241,
    audio : {
      fr : "/blocs/bloc241.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Tiens donc !</p>",
      en : "<p>THE DIRECTOR : Well, look at that !</p>",
      it : "<p>IL DIRETTORE : Ma guarda un po’ !</p>",
      de : "<p>DER DIREKTOR : Oh schau an !</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 242
  },
  {
    id : 242,
    audio : {
      fr : "/blocs/bloc242.mp3",
      anna : false,
      atmospheric : "/ambiance/Ac-plan-45.mp3"
    },
    text : {
      fr : "<p>LE DIRECTEUR : Pourriez-vous m’expliquer votre point de vue ?</p>",
      en : "<p>THE DIRECTOR : Could you explain your point of view to me ?</p>",
      it : "<p>IL DIRETTORE : Può spiegarmi il Suo punto di vista ?</p>",
      de : "<p>DER DIREKTOR : Kannst du mir deinen Standpunkt erläutern ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>Je trouve que c’est très intrusif, et nous avons d’autres moyens à notre disposition.</p>",
          en : "<p>I find it very intrusive, and we have other means at our disposal.</p>",
          it : "<p>Lo trovo molto invadente, e abbiamo altri mezzi a nostra disposizione.</p>",
          de : "<p>Ich finde es sehr aufdringlich, und wir haben andere Mittel zur Verfügung.</p>"
        },
        next : 243,
      },
      {
        answer : {
          fr : "<p>Vue l’évolution de la situation, cela pourrait finalement être opportun.</p>",
          en : "<p>As the situation evolves, this may eventually be appropriate.</p>",
          it : "<p>In considerazione del cambiamento della situazione, alla fine questo potrebbe essere opportuno.</p>",
          de : "<p>In Anbetracht der sich ändernden Situation kann dies schliesslich angemessen sein.</p>"
        },
        next : 243,
      },
    ],
    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : null
  },
  {
    id : 243,
    audio : {
      fr : "/blocs/bloc243.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Hum… Je comprends… Je ne cache pas mon penchant technophile, et vu la situation, je pense qu’il s’agit d’une mesure proportionnée… Allons-y Anna, tu peux lancer le scan des données visiteurs. Je compte sur vous deux pour rétablir la situation ! Par ailleurs, on a un taux d’annulation de 20% de la part de nos visiteurs aujourd’hui, j’espère que l’on va être en mesure de limiter la casse.</p>",
      en : "<p>THE DIRECTOR: Um... I understand... I don't hide my technophile leanings, and given the situation, I find the measure proportionate... Let's go Anna, you can start scanning the data of the visitors. I'm counting on you two to restore the situation! On the other hand, we have today a 20% cancellation rate from our visitors, I hope we'll be able to limit the damage.</p>",
      it : "<p>IL DIRETTORE: Ehm... Capisco... Non nascondo le mie inclinazioni tecnofile, e data la situazione, penso che sia una misura adeguata... Andiamo Anna, puoi iniziare a scannerizzare i dati dei visitatori. Conto su entrambi per sistemare le cose! Inoltre, abbiamo un tasso di cancellazione del 20% da parte dei nostri visitatori oggi, spero che riusciremo a limitare le perdite.</p>",
      de : "<p>DER DIREKTOR: Ähm... Ich verstehe... Ich verberge meine technophilen Neigungen nicht, und angesichts der Situation halte ich es für eine angemessene Massnahme... Los geht's Anna, Du kannst starten, die Besucherdaten zu scannen. Ich zähle auf dich um die Dinge richtig zu stellen! Ausserdem haben wir heute eine Stornierungsrate von 20% unserer Besucher, ich hoffe, dass wir den Schaden begrenzen können.</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 68
  },
  {
    id : 244,
    audio : {
      fr : "/blocs/bloc244.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Monsieur le Directeur, la situation est sous contrôle, nous recherchons activement les coupables !</p>",
      en : "<p>ANNA : Mr. Director, the situation is under control, we are actively looking for the perpetrators !</p>",
      it : "<p>ANNA : Signor Direttore, la situazione è sotto controllo, stiamo cercando attivamente i colpevoli !</p>",
      de : "<p>ANNA : Herr Direktor, die Situation ist unter Kontrolle! Wir sind auf der Suche nach den Schuldigen !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 245
  },
  {
    id : 245,
    audio : {
      fr : "/blocs/bloc245.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Bien Anna !</p>",
      en : "<p>THE DIRECTOR: Good, Anna !</p>",
      it : "<p>IL DIRETTORE: Brava, Anna !</p>",
      de : "<p>DER DIREKTOR: Gut, Anna !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 246
  },
  {
    id : 246,
    audio : {
      fr : "/blocs/bloc246.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Et la nouvelle recrue, elle en pense quoi ?</p>",
      en : "<p>THE DIRECTOR : What does the new recruit think about it ?</p>",
      it : "<p>IL DIRETTORE : Cosa ne pensa la nuova recluta ?</p>",
      de : "<p>DER DIREKTOR : Was denkt der neue Rekrut ?</p>"
    },
    choices : [
      {
        answer : {
          fr : "<p>On s’active Monsieur !</p>",
          en : "<p>Let's get moving Sir !</p>",
          it : "<p>Ci attiviamo Signore !</p>",
          de : "<p>Los geht's !</p>"
        },
        next : 2461,
      },
      {
        answer : {
          fr : "<p>On n’a pas chômé Monsieur !</p>",
          en : "<p>We have not been idle sir !</p>",
          it : "<p>Siamo stati occupati, Signore !</p>",
          de : "<p>Wir waren sehr beschäftigt, Herr Direktor !</p>"
        },
        next : 2461,
      },
    ],
    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : null
  },
  {
    id : 2461,
    audio : {
      fr : "/blocs/bloc246bis.mp3",
      anna : false,
      atmospheric : null
    },
    text : {
      fr : "<p>LE DIRECTEUR : Certes ! Mais maintenant, il va falloir vous servir davantage d' Anna ! Elle me coûte une fortune et son efficacité n’est plus à prouver. En plus, la loi nous autorise maintenant à aller dans ce sens. Donc on y va ! Procédez tout de suite à la vérification des données personnelles des visiteurs, et tenez moi au courant !</p>",
      en : "<p>THE DIRECTOR : Certainly ! But now you're going to have to use Anna more! She's costing me a fortune but her effectiveness is well established. On top of that, the law now allows us to go in that direction. So let's go ! Proceed immediately to the verification of the personal data of the visitors, and keep me informed !</p>",
      it : "<p>IL DIRETTORE : Certamente! Ma ora dovrai usare di più Anna ! Mi sta costando una fortuna, e la sua efficienza è comprovata. Inoltre, la legge ora ci permette di andare in quella direzione. Quindi andiamo ! Controllate subito i dati personali dei visitatori e tenetemi informato !</p>",
      de : "<p>DER DIREKTOR : Sicher ! Aber jetzt musst du die Vorteile von Anna mehr nutzen ! Sie kostet mich ein Vermögen, und ihre Effizienz ist erwiesen. Also los geht's ! Überprüfe sofort die persönlichen Daten der Besucher und halte mich auf dem Laufenden !</p>"
    },

    background : {
      src : "background-dark-blue.jpg",
      type : 'image'
    },
    next : 247
  },
  {
    id : 247,
    audio : {
      fr : "/blocs/bloc247.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Bien Monsieur !</p>",
      en : "<p>ANNA : Yes sir !</p>",
      it : "<p>ANNA : Sissignore !</p>",
      de : "<p>ANNA : Jawohl !</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 248
  },
  {
    id : 248,
    audio : {
      fr : "/blocs/bloc248.mp3",
      anna : true,
      atmospheric : null
    },
    text : {
      fr : "<p>ANNA : Je crois qu’on n’a plus trop le choix. Je lance le scan des informations personnelles de tous nos visiteurs tout de suite. Les données arrivent déjà !</p>",
      en : "<p>ANNA : I don't think we have much choice anymore. I'm scanning right now the personal information of all our visitors. The data is already coming in !</p>",
      it : "<p>ANNA : Non credo che abbiamo più molta scelta. Sto scannerizzando in questo momento le informazioni personali di tutti i nostri visitatori. I dati stanno già arrivando !</p>",
      de : "<p>ANNA : Ich glaube, wir haben keine andere Wahl. Ich scanne gerade die persönlichen Daten aller unserer Besucher. Die Daten kommen bereits rein !</p>"
    },

    background : {
      src : "Video B - data_surveillance.mp4",
      type : 'video'
    },
    next : 127
  }
]

/*
{
  id : 0,
  audio : {
    fr : "/blocs/bloc00.mp3",
    atmospheric : null
  },
  text : {
    fr : "<p></p>",
    en : "<p></p>",
    it : "<p></p>",
    de : "<p></p>"
  },
  choices : [
    {
      answer : {
        fr : "<p></p>",
        en : "<p></p>",
        it : "<p></p>",
        de : "<p></p>"
      },
      next : 5,
    }
  ],
  background : {
    src : "background-dark-blue.jpg",
    type : 'image'
  },
  next : null
}
*/

export default story;
