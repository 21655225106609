import { useState, useEffect, useRef } from 'react'
import { withWindowSize } from 'react-fns';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import styles from './end.module.scss'
import { MdVolumeOff, MdVolumeUp } from "react-icons/md"
import logo from '../../../assets/images/logo.png'
import { traductionsEnd } from '../../../config/traductions-end'
import { traductions } from '../../../config/traductions'

import Button from '../../../components/Button'
import InputText from '../../../components/InputText'
import InputNumber from '../../../components/InputNumber'
import StyledSelect from '../../../components/StyledSelect'
import InputRate from '../../../components/InputRate'
import Textarea from '../../../components/Textarea'
import Checkboxes from '../../../components/Checkboxes'

const marksRate = [
{
  value: 0,
  label: '0',
},
{
  value: 1,
  label: '1',
},
{
  value: 2,
  label: '2',
},
{
  value: 3,
  label: '3',
},
{
  value: 4,
  label: '4',
},
{
  value: 5,
  label: '5',
},
{
  value: 6,
  label: '6',
},
{
  value: 7,
  label: '7',
},
{
  value: 8,
  label: '8',
},
{
  value: 9,
  label: '9',
},
{
  value: 10,
  label: '10',
},
];

const End = ({isMuted, playSound, pauseSound, width}) => {
  const podcastReducer = useSelector(state => state.podcastReducer)
  const history = useHistory();
  const [ isLoading, setIsLoading ] = useState(false)
  const mailRef = useRef()
  const ageRef = useRef()
  const nationRef = useRef()
  const levelRef = useRef()
  const scoreRef = useRef()
  const textareaRef = useRef()
  const technoRef = useRef()
  const majorityRef = useRef()

  const marksAcceptance = [
  {
    value: 0,
    label: traductionsEnd[podcastReducer.lang]["closed"],
  },
  {
    value: 100,
    label: traductionsEnd[podcastReducer.lang]["open"],
  },
  ];

  const optionsLevel = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8+', value: '8+' }
  ]

  function submit(){
    const formData = {
      ...mailRef.current.getObject(),
      ...ageRef.current.getObject(),
      ...nationRef.current.getObject(),
      ...levelRef.current.getObject(),
      ...scoreRef.current.getObject(),
      ...textareaRef.current.getObject(),
      ...technoRef.current.getObject(),
      ...majorityRef.current.getObject(),
    }



    let obj = {
      startDate: localStorage.getItem("start_time"),
      endDate: localStorage.getItem('end_time'),
      responses: podcastReducer.answers,
      form: {...formData},
      lang: podcastReducer.lang
    }

    setIsLoading(true)
    axios.post('https://api.technocast.org/api/response', obj)
    .then(response => {
      setIsLoading(false)
      width < 767 ? history.push("/fantas-ia/pages/navigation-annexes") : history.push("/fantas-ia/pages/resultat")

    }).catch(error => {
      setIsLoading(false)
    })


  }

  return (
    <div className={styles["end"]}>
      <div className={isLoading ? `${styles["container"]} ${styles["loading"]} container` : `${styles["container"]} container`}>
        <h2>{traductionsEnd[podcastReducer.lang]["title"]}</h2>
        <span dangerouslySetInnerHTML={{ __html : traductionsEnd[podcastReducer.lang]["text"] }}/>
        <div className={styles["form"]}>
          <div className={styles["inline-fields"]}>
            <div>
              <label>{traductionsEnd[podcastReducer.lang]["age"]}</label>
              <InputNumber name="ageinyears" format={"###"} ref={ageRef}/>
            </div>
            <div>
              <label>{traductionsEnd[podcastReducer.lang]["where"]}</label>
              <InputText name="nation" ref={nationRef}/>
            </div>
            <div>
              <label>{traductionsEnd[podcastReducer.lang]["studiesLevel"]}</label>
              <div className={styles["container-select"]}>
                <StyledSelect name="level" ref={levelRef} options={optionsLevel}/>
              </div>
            </div>
          </div>
          <div>
            <label>{traductionsEnd[podcastReducer.lang]["score"]}</label>
            <div className={styles["container-input-rate"]}>
              <InputRate name="score"
                ref={scoreRef}
                defaultValue={5}
                step={1}
                min={0}
                max={10}
                marks={marksRate}
              />
            </div>
          </div>
          <div>
            <label>{traductionsEnd[podcastReducer.lang]["free-text"]}</label>
            <div className={styles["container-textarea"]}>
              <Textarea name="free-text" ref={textareaRef} />
            </div>
          </div>
          <div>
            <label>{traductionsEnd[podcastReducer.lang]["techno-acceptance"]}</label>
            <div className={styles["container-input-rate"]}>
              <InputRate name="acceptance"
                ref={technoRef}
                defaultValue={50}
                unit="%"
                step={10}
                min={0}
                max={100}
                marks={marksAcceptance}
              />
            </div>
          </div>
          <div style={{marginBottom:30}}>
            <label>{traductionsEnd[podcastReducer.lang]["majority"]}</label>
            <Checkboxes
              ref={majorityRef}
              name="majority"
              options={[{value : true, label : traductionsEnd[podcastReducer.lang]["yes"]},{ value : false, label : traductionsEnd[podcastReducer.lang]["no"]}]}
            />
          </div>
          <div>
            <span dangerouslySetInnerHTML={{ __html : traductionsEnd[podcastReducer.lang]["emailIntro"] }}/>
            <label style={{display:'block', marginTop:20}}>{traductionsEnd[podcastReducer.lang]["email"]}</label>
            <InputText name="mail" ref={mailRef} style={{marginTop:0}}/>
          </div>
          {/*<span  style={{display:'block', marginBottom:50}} dangerouslySetInnerHTML={{ __html : traductionsEnd[podcastReducer.lang]["emailConsent"] }}/>*/}
          <span dangerouslySetInnerHTML={{ __html : traductionsEnd[podcastReducer.lang]["thanks"] }}/>
          <div className={styles["container-submit"]}><Button onClick={submit} text={traductions[podcastReducer.lang]["submit"]} /></div>
        </div>
      </div>
      <div className={styles["end-controls"]}>
        { isMuted ?
          <MdVolumeUp onClick={()=> playSound()} /> :
          <MdVolumeOff onClick={()=> pauseSound()} />
        }
      </div>
    </div>
  )
}

export default withWindowSize(End)
