export const traductionsNavigationAnnexes = {
  en : {
    "text" : "Thank you for participating in this experience.",
    "see-result" : "The result of your journey",
    "see-sources" : "To go futher - Suggested Readings",
    "see-credits" : "Credits"
  },
  fr : {
    "text" : "Merci d’avoir participé à cette expérience.",
    "see-result" : "Le résultat de votre expérience",
    "see-sources" : "Pour aller plus loin - Sources",
    "see-credits" : "Crédits"
  },
  it : {
    "text" : "Grazie per aver partecipato a questa esperienza.",
    "see-result" : "Il risultato della tua esperienza",
    "see-sources" : "Ulteriori informazioni - Fonti consigliate",
    "see-credits" : "Crediti"
  },
  de : {
    "text" : "Danke, dass Sie an dieser Erfahrung teilhaben.",
    "see-result" : "Das Ergebnis Ihrer Erfahrung",
    "see-sources" : "Weitere Informationen - Empfohlene Quellen",
    "see-credits" : "Credits"
  }
}
