export const traductions = {
  en : {
    "next" : "Next",
    "submit" : "Submit",
    "learn-more" : "Learn more",
    "general-conditions" : "General conditions"
  },
  fr : {
    "next" : "Suivant",
    "submit" : "Valider",
    "learn-more" : "En savoir plus",
    "general-conditions" : "Mentions légales"
  },
  it : {
    "next" : "Prossimo",
    "submit" : "Convalida",
    "learn-more" : "Per saperne di più",
    "general-conditions" : "Avviso legale"
  },
  de : {
    "next" : "Weiter",
    "submit" : "Validieren",
    "learn-more" : "Mehr darüber wissen",
    "general-conditions" : "Rechtlicher Hinweis"
  }
}
