import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import {
  scaleTime
} from 'd3-scale';

import { useDispatch, useSelector } from 'react-redux'

import podcastActions from '../../actions/podcastActions'


const Audio = ({
  source,
  loop = false,
  autoPlay = false,
  controls = false,
  volume = 1,
  id = ""
},ref) => {
  const audioRef = useRef()
  const podcastReducer = useSelector(state => state.podcastReducer)
  const dispatch = useDispatch()

  useImperativeHandle(ref,()=>({
    getDuration : (bool) => {
      return new Promise((resolve, reject)=>{
        if(audioRef.current.readyState === 0){
          audioRef.current.onloadeddata = () => {
            resolve(audioRef.current.duration)
          }
        }else{
          resolve(audioRef.current.duration)
        }
      })
    },
    getCurrentTime : (bool) => {
      return new Promise((resolve, reject)=>{
        if(audioRef.current.readyState === 0){
          audioRef.current.onloadeddata = () => {
            resolve(audioRef.current.currentTime)
          }
        }else{
          resolve(audioRef.current.currentTime)
        }
      })
    },
    muted : (bool) => {
      audioRef.current.muted = bool
    },
    pause: () => {
      audioRef.current.pause()
    },
    play: () => {
      playAndHandleInteractFirst()
    },
    fadeOut : () => {
      let scale = scaleTime()
        .domain([0, 50])
        .range([1, 0]);

      let i = 0;
      let timer = setInterval(function () {
        i++;
        if(audioRef && audioRef.current) audioRef.current.volume = scale(i)
        if(i === 50) clearInterval(timer)
      }, 30);

      setTimeout(() => {
        if(audioRef && audioRef.current) audioRef.current.pause();
      }, 1000);

    },
    fadeIn : (fromStart = false, long = false) => {
      let max = long ? 50 : 200
      let scale = scaleTime()
        .domain([0, max])
        .range([0, 1]);

      if(fromStart) audioRef.current.currentTime = 0;
      let i = 0;
      let timer = setInterval(function () {
        i++;
        audioRef.current.volume = scale(i)
        if(i === max) clearInterval(timer)
      }, 30);

    }
  }))

  useEffect(()=>{
    audioRef.current.volume = volume
    if(autoPlay){
      playAndHandleInteractFirst()
    }
  },[])

  function playWithPromise(){
    var promise = audioRef.current.play();
    if (promise !== undefined) {
      promise.then(_ => {
        audioRef.current.play()

      }).catch(error => {
        console.log(error);
        if(!podcastReducer.modalSound){
          dispatch(podcastActions.toggleModalSound(true))
        }
      });
    }
  }

  function playAndHandleInteractFirst(){
    if(!audioRef || !audioRef.current){
      return
    }

    if(audioRef.current.readyState === 4 ||
      audioRef.current.readyState === 1){
      playWithPromise()
    }else{
      audioRef.current.addEventListener("loadedmetadata",()=>{
        playWithPromise()
        audioRef.current.removeEventListener("loadedmetadata",()=>{});
      })
    }
  }

  /*if(audioRef && audioRef.current && !audioRef.current.id){
    audioRef.current.src = source
    playAndHandleInteractFirst()
  }*/

  return (
    <>
      <audio
        id={id}
        loop={loop}
        ref={audioRef}
        controls={controls}
        src={source}>
            Your browser does not support the
            <code>audio</code> element.
      </audio>
    </>
  )

}

export default forwardRef(Audio)
