import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux'
import styles from './step.module.scss'
import Aura from '../Aura'
import Audio from '../Audio'
import Question from '../Question'
import Subtitles from '../Subtitles'

import { traductions } from '../../config/traductions'

import ButtonSecondary from '../../components/ButtonSecondary'

import podcastActions from '../../actions/podcastActions'

// https://ttsmp3.com/

const Step = ({content, setStep, isAnnaMuted, showSubtitles, lang},ref) => {
  const [ timeStart, setTimeStart ] = useState(0)

  const dispatch = useDispatch();
  const mainAudioRef = useRef();
  const atmosphericAudioRef = useRef();
  const auraRef = useRef()

  const timerAura = useRef()
  const timerNext = useRef()

  const isVoicePaused = useRef(false)

  useImperativeHandle(ref,()=>({
    play : () => {
      if(mainAudioRef.current) {
        mainAudioRef.current.play()
        isVoicePaused.current = false
      }
      handleAuraShowTime()
      if(auraRef.current) auraRef.current.play()
      if(atmosphericAudioRef.current) atmosphericAudioRef.current.play()
    },
    pause : () => {
      if(mainAudioRef.current) {
        mainAudioRef.current.pause()
        isVoicePaused.current = true
      }

      // timerAura.current && clearTimeout(timerAura.current);
      // timerNext.current && clearTimeout(timerNext.current);

      if(auraRef.current) auraRef.current.pause()
      if(atmosphericAudioRef.current) atmosphericAudioRef.current.pause()
    },
    muted : (bool) => {
      if(atmosphericAudioRef.current) atmosphericAudioRef.current.muted(bool)
    },
    annaMuted : (bool) => {
      if(mainAudioRef.current) mainAudioRef.current.muted(bool)
    }
  }))

  function handleAnswer(next,answer){
    dispatch(podcastActions.setAnswers({id : content.id, answer, time : Date.now()-timeStart}))
    setStep(next)
    setTimeStart(0)
  }

  useEffect(()=>{
    if(!auraRef.current) return;
    mainAudioRef.current && handleAuraShowTime()

    return () => {
      timerAura.current && clearTimeout(timerAura.current);
      timerNext.current && clearTimeout(timerNext.current);
    }

  },[content.id])

  // HANDLE TIME LEFT
  useEffect(()=>{
    if(content.choices){
      setTimeStart(Date.now())
    }
  },[content.choices])

  async function handleAuraShowTime(){
    let duration = await mainAudioRef.current.getDuration()
    let currentTime = await mainAudioRef.current.getCurrentTime()

    timerAura.current && clearTimeout(timerAura.current);
    timerNext.current && clearTimeout(timerNext.current);

    let timeLeft = ( duration - currentTime ) * 1000;
    let timing = 500

    content?.audio.anna && auraRef.current.toggleAura(true)

    timerAura.current = setTimeout(()=>{
      if(auraRef.current && !isVoicePaused.current && content?.audio.anna) auraRef.current.toggleAura(false)
      clearTimeout(timerAura.current);

      if(!content.choices){
        timerNext.current = setTimeout(()=>{
          (!isVoicePaused.current && !isAnnaMuted) && setStep(content.next)
          clearTimeout(timerNext.current);
        },timing)
      }

    },timeLeft)
  }

  return (
    <div className={styles["step"]}>
      <div className={styles["container"]}>

        {/* (!content.choices && content.next) &&
          <div style={{position : "fixed", zIndex : 1000, right : "80px", bottom : "80px"}}>
            <label style={{marginRight : "10px"}}>Bloc : {content.id}</label>
            <button onClick={()=> setStep(content.next)}>
              Next
            </button>
          </div>
        */}

        { (!content.choices /*&& isAnnaMuted*/) &&
          <div style={{position : "fixed", zIndex : 1000, right : "10px", top : "26px"}}>
            <ButtonSecondary
              onClick={()=> setStep(content.next)}
              text={traductions[lang]["next"]}
            />
          </div>
        }

        { content.choices &&
          <div className={styles["choices"]}>
            {content.text && <Question text={content.text[lang]}/>}
            <div className={styles["answers"]}>
              {content.choices.map((d,i)=>(
                <ButtonSecondary
                  key={`choices-${d.next}-${content.id}-${i}`}
                  onClick={()=> handleAnswer(d.next,i)}
                  text={d.answer[lang]}
                />
              ))}
            </div>

          </div>
        }

      </div>

      { (!content.choices && content.text[lang]) &&
        <Subtitles text={content.text[lang]} hide={showSubtitles} centered={true/*!!(!content.choices && content.audio[lang])*/}/>
      }

      {
        content.audio['fr'] && <Audio ref={mainAudioRef} volume={.35} source={`${process.env.PUBLIC_URL}/audios${content.audio['fr']}`} autoPlay/>
      }

      {
        content.audio.atmospheric && <Audio ref={atmosphericAudioRef} source={`${process.env.PUBLIC_URL}/audios/${content.audio.atmospheric}`} autoPlay loop/>
      }

      {<Aura ref={auraRef}/>}
    </div>
  )
}

export default forwardRef(Step)
