import styles from './intro.module.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { traductionsIntro } from '../../../config/traductions-intro'
import logo from '../../../assets/images/logo.png'

import { MdVolumeOff, MdVolumeUp } from "react-icons/md"

import Button from '../../../components/Button'

export default function Intro({isMuted, playSound, pauseSound}){
  const podcastReducer = useSelector(state => state.podcastReducer)

  return (
    <div className={styles["intro"]}>
      <div className={styles["background"]}></div>
      <div className={`${styles["container"]} container`}>
        <div className={styles["logo"]}>
          <img src={logo} alt="logo fantas-i.a" />
        </div>
        <label>{traductionsIntro[podcastReducer.lang]["label"]}</label>
        <span dangerouslySetInnerHTML={{ __html : traductionsIntro[podcastReducer.lang]["text"]}} />
        <label>{traductionsIntro[podcastReducer.lang]["label-2"]}</label>
        <Button to={"/fantas-ia/story"} text={traductionsIntro[podcastReducer.lang]["start"]} />
      </div>
      <div className={styles["intro-controls"]}>
        { isMuted ?
          <MdVolumeUp onClick={()=> playSound()} /> :
          <MdVolumeOff onClick={()=> pauseSound()} />
        }
      </div>
    </div>
  )
}
