import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import NumberFormat from 'react-number-format';
import styles from "./input-number.module.scss";

const InputNumber = ({name, handleFormValidation, format, placeholder = "" }, ref) => {
  const [ value, setValue ] = useState("")

  useImperativeHandle(ref,()=>({
    getValue : () => value,
    getObject : () => ({ [name] : value})
  }))

  function handleChange(value){
    setValue(value)
  }

  function getClassName(){
    return `${styles["input-number"]}`
  }

  return (
    <NumberFormat
      format={format}
      value={value}
      className={getClassName()}
      onChange={e => handleChange(e.target.value)}
      placeholder={placeholder}
    />
  )
}

export default forwardRef(InputNumber)
