import styles from "./button.module.scss"
import { Link } from "react-router-dom";

export default function Button ({to, onClick, text}){
  return (
    <>
      { to &&
        <div className={styles["container-btn"]}>
          <Link className={styles["a-n1"]} to={to}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {text}
          </Link>
          <span className={styles["shadow"]}></span>
        </div>

      }
      { onClick &&
        <div className={styles["container-btn"]}>
          <a className={styles["a-n1"]} onClick={onClick}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <p dangerouslySetInnerHTML={{ __html : text}}/>
          </a>
          <span className={styles["shadow"]}></span>
        </div>

      }
    </>

  )
}
