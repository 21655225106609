import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import usePrevious from '../../hooks/usePrevious'

import styles from "./textarea.module.scss";

const Textarea = ({ name, placeholder = "" }, ref) => {
  const [ value, setValue ] = useState("")
  const textareaRef = useRef()
  const [ style, setStyle ] = useState({})

  useEffect(()=>{
    autosize()
  },[])

  function autosize(){
    let elt = textareaRef.current
    elt.style.cssText = 'height:auto; padding:0';
    elt.style.cssText = 'height:' + elt.scrollHeight + 'px';
    setStyle({
      padding : 0,
      height : `${elt.scrollHeight}px`
    })
  }


  useImperativeHandle(ref,()=>({
    getValue : () => value,
    getObject : () => ({ [name] : value}),
    getElement : () => ref.current
  }))

  function handleChange(e,value){
    setValue(value)
    autosize()
  }

  return (
    <textarea
      ref={textareaRef}
      rows='1'
      style={style}
      value={value}
      className={styles["textarea"]}
      onChange={e => handleChange(e,e.target.value)}
      placeholder={placeholder}
    >
    </textarea>
  )
}

export default forwardRef(Textarea)
