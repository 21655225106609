import {
  Switch,
  NavLink,
  Route
} from "react-router-dom";
import { useSelector } from 'react-redux'
import { traductionsNavigationAnnexes } from '../../../config/traductions-navigation-annexes.js'
import { MdVolumeOff, MdVolumeUp } from "react-icons/md"

import styles from './page-annexes.module.scss';


import Resultat from './Resultat'
import Credits from './Credits'
import Sources from './Sources'

export default function PageAnnexes({isMuted, playSound, pauseSound}){

  const podcastReducer = useSelector(state => state.podcastReducer)

  return (
    <>
    <ul className={styles["navigation"]}>
      <li><NavLink activeClassName={styles["active"]} to="/fantas-ia/pages/resultat">{traductionsNavigationAnnexes[podcastReducer.lang]["see-result"]}</NavLink></li>
      <li><NavLink activeClassName={styles["active"]} to="/fantas-ia/pages/sources">{traductionsNavigationAnnexes[podcastReducer.lang]["see-sources"]}</NavLink></li>
      <li><NavLink activeClassName={styles["active"]} to="/fantas-ia/pages/credits">{traductionsNavigationAnnexes[podcastReducer.lang]["see-credits"]}</NavLink></li>
    </ul>
    <Switch>
      <Route exact path="/fantas-ia/pages/resultat"><Resultat/></Route>
      <Route exact path="/fantas-ia/pages/credits"><Credits/></Route>
      <Route exact path="/fantas-ia/pages/sources"><Sources/></Route>
    </Switch>
    <div className={styles["annexes-controls"]}>
      { isMuted ?
        <MdVolumeUp onClick={()=> playSound()} /> :
        <MdVolumeOff onClick={()=> pauseSound()} />
      }
    </div>
    </>
  )
}
