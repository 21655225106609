import {
  TOGGLE_MODAL_SOUND,
  SELECT_LANG,
  INIT_ANSWERS,
  SET_START_TIME,
  SET_END_TIME,
  RESET_ANSWERS,
  SET_ANSWERS
} from '../actions/types.js'

let lang = "fr"

if(localStorage.getItem("lang")){
  lang = localStorage.getItem("lang")
}

const initialState = {
  lang : lang,
  answers : [],
  startTime : null,
  endTime : null,
  modalSound : null
}

const counter = (state = initialState, action) => {
    switch(action.type){
      case TOGGLE_MODAL_SOUND :
        return { ...state, modalSound : action.payload}
      case SELECT_LANG :
        localStorage.setItem('lang',action.payload)
        return { ...state, lang : action.payload}
      case INIT_ANSWERS :
        return { ...state, answers : action.payload.answers, startTime : action.payload.startTime , endTime :action.payload.endTime }
      case RESET_ANSWERS :
        localStorage.removeItem('answers')
        localStorage.removeItem('start_time')
        localStorage.removeItem('end_time')
        return { ...state, answers : [], startTime : null, endTime : null}
      case SET_START_TIME :
        localStorage.setItem('start_time',action.payload)
        return { ...state, startTime : action.payload}
      case SET_END_TIME :
        localStorage.setItem('end_time',action.payload)
        return { ...state, endTime : action.payload}
      case SET_ANSWERS :
        localStorage.setItem('answers',JSON.stringify([ ...state.answers, action.payload ]))
        return { ...state, answers : [ ...state.answers, action.payload ]}
      default:
          return state
    }
}

export default counter
