export const traductionsSources = {
  en : {
    "title": "To go further - Recommended sources",
    "text": "We advise you to consult the following sources:",
    "title-col-1": "For digging, with rather favorable opinions",
    "title-col-2": "To go further, with rather critical points of view",
    "title-col-1-1": "To open the debate",
    "etude-1" : {
      title : '<p><a href="https://aiartists.org/" target="_blank">Website - Artists creating with artificial intelligence</a></p>'
    },
    "etude-2" : {
      title : '<p><a href="https://www.youtube.com/watch?v=w2fuw8NIKZc" target="_blank">Music - Grimes x Endel, a lullaby created by an artificial intelligence</a></p>'
    },
    "etude-3" : {
      title : '<p><a href="https://www.youtube.com/watch?v=q4y8YAMPFhk" target="_blank">Film - Robot and Frank - The friendship between a robot and an old man</a></p>'
    },
    "etude-4" : {
      title : '<p><a href="https://en.wikipedia.org/wiki/Be_Right_Back" target="_blank">Serie - Be right back (Black Mirror, S2 E1), grieving in a tech-oriented world</a></p>'
    },
    "etude-5" : {
      title : '<p><a href="https://www.wsj.com/articles/why-kids-should-call-the-robot-it-11566811801" target="_blank">Read - Why children should learn to call the robot "it"</a></p>'
    },
    "etude-6" : {
      title : '<p><a href="https://medium.com/@the_economist/why-the-worlds-first-robot-hotel-was-a-disaster-b9c2ed66d35b" target="_blank">Read - Why the world\'s first robot hotel was a disaster</a></p>'
    },
    "etude-7" : {
      title : '<p><a href="https://www.moralmachine.net/" target="_blank">Experience - Morale Machine: and you, what will you choose?</a></p>'
    },
    "etude-8" : {
      title : '<p><a href="https://open.spotify.com/show/3SltI1zTwbUea66SMtrafu" target="_blank">Podcast - Sandra, when artificial intelligence is not that artificial...</a></p>'
    }
  },
  fr : {
    "title" : "Pour aller plus loin - Sources conseillées",
    "text" : "Nous vous conseillons de consulter les sources suivantes :",
    "title-col-1" : "Pour creuser, avec des avis plutôt favorables",
    "title-col-2" : "Pour aller plus loin, avec des points de vues plutôt critiques",
    "title-col-1-1" : "Pour ouvrir le débat",
    "etude-1" : {
      title : '<p><a href="https://theconversation.com/et-si-les-machines-pouvaient-nous-rendre-plus-humains-150001" target="_blank">Article à lire - Et si les machines pouvaient nous rendre plus humains ?</a></p>'
    },
    "etude-2" : {
      title : '<p><a href="https://www.youtube.com/watch?v=w2fuw8NIKZc" target="_blank">Musique à écouter - Grimes x Endel, une berceuse créée par une intelligence artificielle</a></p>'
    },
    "etude-3" : {
      title : '<p><a href="https://www.youtube.com/watch?v=Qk2XzeQg5jU" target="_blank">Film à voir - Robot and Frank, l\'histoire attachante d\'un robot et d\'un vieil homme</a></p>'
    },
    "etude-4" : {
      title : '<p><a href="https://fr.wikipedia.org/wiki/Bientôt_de_retour" target="_blank">Série à voir - Black Mirror (S2E1) : Bientôt de retour. Le deuil dans un monde technologisé</a></p>'
    },
    "etude-5" : {
      title : '<p><a href="https://siecledigital.fr/2019/04/11/anthropomorphisation-des-robots-du-service-au-lien-affectif/" target="_blank">Article à lire - Le risque de l\'anthropomorphisation des robots</a></p>'
    },
    "etude-6" : {
      title : '<p><a href="https://www.lesechos.fr/idees-debats/editos-analyses/tu-seras-poli-avec-ta-google-home-238275" target="_blank">Article à lire - Faut-il être poli.e avec les robots ?</a></p>'
    },
    "etude-7" : {
      title : '<p><a href="http://www.assemblee-nationale.fr/dyn/15/rapports/cion_def/l15b3248_rapport-information.pdf" target="_blank">Rapport à lire - Rapport d\'information sur les systèmes d’armes létaux autonomes (Assemblée Nationale)</a></p>'
    },
    "etude-8" : {
      title : '<p><a href="https://open.spotify.com/show/0QBWvZ8D4RG2ipzGx0SSRo" target="_blank">Podcast à écouter - Sara, quand l\'intelligence artificielle n\'est pas si artificielle que ça...</a></p>'
    }
  },
  it : {
    "title": "Andare oltre - Fonti raccomandate",
    "text": "Vi consigliamo di consultare le seguenti fonti:",
    "title-col-1": "Per approfondire, con opinioni piuttosto favorevoli",
    "title-col-2": "Andare oltre, con punti di vista piuttosto critici",
    "title-col-1-1": "Per aprire il dibattito",
    "etude-1" : {
      title : '<p><a href="https://aiartists.org/">Sito web - Artisti che creano con intelligenza artificiale</a></p>'
    },
    "etude-2" : {
      title : '<p><a href="https://www.youtube.com/watch?v=w2fuw8NIKZc" target="_blank">Musica - Grimes x Endel, una ninnananna creata da un\'intelligenza artificiale</a></p>'
    },
    "etude-3" : {
      title : '<p><a href="https://www.youtube.com/watch?v=q4y8YAMPFhk" target="_blank">Film - Robot e Frank - L\'amicizia tra un robot e un vecchio</a></p>'
    },
    "etude-4" : {
      title : '<p><a href="https://en.wikipedia.org/wiki/Be_Right_Back" target="_blank">Serie - Torno subito (Black Mirror, S2 E1), in lutto in un mondo orientato alla tecnologia</a></p>'
    },
    "etude-5" : {
      title : '<p><a href="https://www.wsj.com/articles/why-kids-should-call-the-robot-it-11566811801" target="_blank">Leggi - Perché i bambini dovrebbero imparare a chiamare il robot "it"</a></p>'
    },
    "etude-6" : {
      title : '<p><a href="https://medium.com/@the_economist/why-the-worlds-first-robot-hotel-was-a-disaster-b9c2ed66d35b" target="_blank">Leggere - Perché il primo robot hotel al mondo è stato un disastro</a></p>'
    },
    "etude-7" : {
      title : '<p><a href="https://www.moralmachine.net/" target="_blank">Esperienza - Macchina del morale: e tu, cosa sceglierai?</a></p>'
    },
    "etude-8" : {
      title : '<p><a href="https://open.spotify.com/show/3SltI1zTwbUea66SMtrafu" target="_blank">Podcast - Sandra, quando l\'intelligenza artificiale non è così artificiale...</a></p>'
    }
  },
  de : {
    "title": "Weitergehen - Empfohlene Quellen",
    "text": "Wir empfehlen Ihnen, die folgenden Quellen zu konsultieren:",
    "title-col-1": "Zum Graben, mit eher günstigen Meinungen",
    "title-col-2": "Weiter gehen, mit eher kritischen Gesichtspunkten",
    "title-col-1-1": "Zur Eröffnung der Debatte",
    "etude-1" : {
      title : '<p><a href="https://aiartists.org/" target="_blank">Website - Künstler schaffen mit künstlicher Intelligenz</a></p>'
    },
    "etude-2" : {
      title : '<p><a href="https://www.youtube.com/watch?v=w2fuw8NIKZc" target="_blank">Musik - Grimes x Endel, ein von einer künstlichen Intelligenz geschaffenes Wiegenlied</a></p>'
    },
    "etude-3" : {
      title : '<p><a href="https://www.youtube.com/watch?v=q4y8YAMPFhk" target="_blank">Film - Roboter und Frank - Die Freundschaft zwischen einem Roboter und einem alten Mann</a></p>'
    },
    "etude-4" : {
      title : '<p><a href="https://en.wikipedia.org/wiki/Be_Right_Back" target="_blank">Serie - Be right back (Black Mirror, S2 E1), Trauer in einer technikorientierten Welt</a></p>'
    },
    "etude-5" : {
      title : '<p><a href="https://www.wsj.com/articles/why-kids-should-call-the-robot-it-11566811801" target="_blank">Lesen - Warum Kinder lernen sollten, den Roboter "es" zu nennen</a></p>'
    },
    "etude-6" : {
      title : '<p><a href="https://medium.com/@the_economist/why-the-worlds-first-robot-hotel-was-a-disaster-b9c2ed66d35b" target="_blank">Lesen - Warum das erste Roboterhotel der Welt ein Desaster war</a></p>'
    },
    "etude-7" : {
      title : '<p><a href="https://www.moralmachine.net/" target="_blank">Erleben - Moralmaschine: und Sie, was werden Sie wählen?</a></p>'
    },
    "etude-8" : {
      title : '<p><a href="https://open.spotify.com/show/3SltI1zTwbUea66SMtrafu" target="_blank">Podcast - Sandra, wenn künstliche Intelligenz gar nicht so künstlich ist...</a></p>'
    }
  }
}
