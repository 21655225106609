import styles from './intro.module.scss'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import podcastActions from '../../../actions/podcastActions'
import { traductionsPausePodcast } from '../../../config/traductions-pause-podcast'
import logo from '../../../assets/images/logo.png'

import { MdVolumeOff, MdVolumeUp } from "react-icons/md"

import Button from '../../../components/Button'

export default function PausePodcast({setPausePodcast}){
  const history = useHistory();
  const dispatch = useDispatch()
  const podcastReducer = useSelector(state => state.podcastReducer)

  function restart(){
    localStorage.removeItem('step')
    dispatch(podcastActions.resetAnswers())
    setPausePodcast(false)
  }

  function result(){
    history.push("/fantas-ia/end")
    setPausePodcast(false)
  }

  function continueStory(){
    setPausePodcast(false)
  }

  return (
    <div className={styles["intro"]}>
      <div className={`${styles["container"]} container`}>
        <div className={styles["logo"]}>
          <img src={logo} alt="logo fantas-i.a" />
        </div>
        <p>
          {traductionsPausePodcast[podcastReducer.lang]["text"]}
        </p>
        <Button text={traductionsPausePodcast[podcastReducer.lang]["continue"]} onClick={continueStory}/>
        <Button text={traductionsPausePodcast[podcastReducer.lang]["restart"]} onClick={restart}/>
      </div>
    </div>
  )
}
