import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import Select , { components } from 'react-select'
import propTypes from 'prop-types'
import styles from './select.module.scss';
import customStyle from './style'

const StyledSelect = ({options, name, onChange},ref) => {
  const [ selectedOption, setSelectedOption ] = useState();

  useImperativeHandle(ref,()=>({
    getValue : () => selectedOption,
    getObject : () => ({ [name] : selectedOption.value})
  }))

  useEffect(()=>{
    setSelectedOption(options[0])
  },[])

  function handleChange(selectedOption){
    onChange && onChange(selectedOption)
    setSelectedOption(selectedOption)
  }

  return (
    <Select
      styles={customStyle}
      options={options}
      onChange={handleChange}
      value={selectedOption}
      isClearable={false}
      components={{
        DropdownIndicator : CustomDropdownIndicator
      }}
    />
  )
}

const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={styles["dropdown-indicator"]}>
      </div>
    </components.DropdownIndicator>
  );
};

export default forwardRef(StyledSelect)

StyledSelect.propTypes = {
  options: propTypes.oneOfType([propTypes.func, propTypes.array]),
  value: propTypes.oneOfType([propTypes.object, propTypes.array]),
  placeholder: propTypes.string,
  id: propTypes.string,
  onChange : propTypes.func,
  isMulti : propTypes.bool
};
