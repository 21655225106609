import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react'
import { useTransition, animated, config } from 'react-spring'

import styles from './animated-background.module.scss'

const AnimatedBackground = ({backgroundFade, slides,index = 0}, ref) => {
  const slidesRefs = useRef(generateRefsMap())
  const [ firstMount, setFirstMount ] = useState(true)

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: firstMount ? 1 : 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses
  })

  function generateRefsMap(){
    let map = new Map()
    slides.forEach(d => map.set(d.id,React.createRef()))
    return map
  }

  useEffect(()=>{
    if(slides[index] && slides[index].type === "video"){
      const { current: videoElement } = slidesRefs.current.get(slides[index].id)
      if(!videoElement){return}
      videoElement.setAttribute('muted', '')
    }
  },[index])

  useImperativeHandle(ref,()=>({
    pause : () => {
      if(slidesRefs.current){
        let media = slidesRefs.current.get(index)
        if(media.current.nodeName === "VIDEO"){
          media.current.pause()
        }
      }
    },
    play : () => {
      if(slidesRefs.current){
        let media = slidesRefs.current.get(index)
        if(media.current.nodeName === "VIDEO"){
          media.current.play()
        }
      }
    }
  }))

  useEffect(()=>{
    setFirstMount(false)
  },[])

  return (
    <div className={backgroundFade ? `${styles["background"]} ${styles["fade"]}` : `${styles["background"]}`}>
      {
        transitions.map(({ item, props, key }) => {
          let source;
          if(item.type === "image"){
            source = require(`../../assets/images/story/${item.src}`).default
          }else if(item.type === "video"){
            source = `${process.env.PUBLIC_URL}/background/${item.src}`
          }
          return (
            <React.Fragment key={key}>
            { item.type === "image" &&
              <animated.div
                id={"background-media"}
                ref={slidesRefs.current.get(item.id)}
                className={styles["media"]}
                style={{ ...props, backgroundImage: `url(${source})` }}
              />
            }

            { item.type === "video" &&
              <animated.div
                id={"background-media"}
                className={styles["media"]}
                style={{ ...props, backgroundImage: `url(${source})` }}
              >
                <video ref={slidesRefs.current.get(item.id)} controls={false} autoPlay muted loop playsInline >
                  <source src={source} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </animated.div>
            }

            </React.Fragment>

          )
        })
      }
    </div>
  )
}

export default forwardRef(AnimatedBackground)
