export const traductionsCredits = {
  en : {
    "title" : "Credits",
    "text": `
      <p>This experiment was produced as part of the research program in technology foresight. It is the property of armasuisse and the technology foresight program, headed by Dr. Quentin Ladetto.</p>
      <p>
        With the participation, on the study and statistical aspects of</br>
        Aya Kachi - University of Basel (<a href="https://wwz.unibas.ch/de/kachi/" target="_blank">https://wwz.unibas.ch/de/kachi/</a>)</br>
        Umberto Michelucci - Toelt (<a href="http://toelt.ai/about-us/" target="_blank">http://toelt.ai/about-us/</a>)</br>
      </p>
      <label>Design and Realisation</label>
      <p>
        <a href="http://www.lecoupdapres.fr/" target="_blank">Le Coup d’Après</a> - Noémie Aubron, Pamela Bellier, Romain Fenouil and Matthieu Gioani.
      </p>
      <p>
        The Coup d'Après team warmly thanks all the contributors of the project:
      </p>

      <label>Scenario</label>
      <p>
        Le Coup d’Après</br>
        Benjamin Hoguet</br>
        Nicolas Martinez</br>
      </p>

      <label>Technical and artistic direction</label>
      <p>
        Bakasable : Heidi Ghernati, Hugo Rulier, Marie-Isabelle Goyet, Marjorie Gaboriau</br>
        Fenikso : Alexandre Jullian-Fradin</br>
        With the support of Brice Coustillet</br>
      </p>

      <label>Visual identity</label>
      <p>
        Stéphanie Loyer
      </p>

      <label>Composition, editing, mixing</label>
      <p>
        Studio Laboreal - Maxime Robin
      </p>

      <label>Sound recording</label>
      <p>
        Studio Le Phonarium - Gérald Dorai
      </p>

      <label> Voices</label>
      <p>
        Ingrid Parois, in the role of Anna</br>
        Sébastien Landry, in the role of Gary, the security manager</br>
        Rodolphe Collé, in the role of director</br>
        With also the participation of Nicolas Martinez, Gérald Dorai, Cécilia Dorai, Quentin Ladetto, Pamela Bellier, Romain Fenouil, Matthieu Gioani, Anne-Laure Guermont</br>
        Voice-over (trailer and end credits): Mélanie Périrhin
      </p>

      <label>Translation</label>
      <p>
        English version: Noémie Joubert, Marie Brummer and Kristin Knickelbein</br>.
        German version: Jeanne Simonnet</br>
        Italian version: Cristina Lazzari, Quentin Ladetto
      </p>

      <label>Site hosting</label>
      <p>
        Infomaniak, Switzerland
      </p>
    `
  },
  fr : {
    "title" : "Crédits",
    "text": `
      <p>Cette expérience a été produite dans le cadre du programme de recherche en prospective technologique. Elle est la propriété d’armasuisse et du programme de prospective technologique, dirigé par Dr. Quentin Ladetto.</p>
      <p>
        Avec la participation, sur l’étude et les aspects statistiques de</br>
        Aya Kachi - Université de Bâle (<a href="https://wwz.unibas.ch/de/kachi/" target="_blank">https://wwz.unibas.ch/de/kachi/</a>)</br>
        Umberto Michelucci - Toelt (<a href="http://toelt.ai/about-us/" target="_blank">http://toelt.ai/about-us/</a>)</br>
      </p>
      <label>Conception et Réalisation</label>
      <p>
        <a href="http://www.lecoupdapres.fr/" target="_blank">Le Coup d’Après</a> - Noémie Aubron, Pamela Bellier, Romain Fenouil et Matthieu Gioani.
      </p>
      <p>
        L’équipe du Coup d’Après remercie chaleureusement tous les contributeurs au projet :
      </p>

      <label>Scénario</label>
      <p>
        Le Coup d’Après</br>
        Benjamin Hoguet</br>
        Nicolas Martinez</br>
      </p>

      <label>Direction technique et artistique</label>
      <p>
        Bakasable : Heidi Ghernati, Hugo Rulier, Marie-Isabelle Goyet, Marjorie Gaboriau</br>
        Fenikso : Alexandre Jullian-Fradin</br>
        Avec l’appui de Brice Coustillet</br>
      </p>

      <label>Identité visuelle</label>
      <p>
        Stéphanie Loyer
      </p>

      <label>Composition, montage, mixage</label>
      <p>
        Studio Laboreal - Maxime Robin
      </p>

      <label>Prise de son</label>
      <p>
        Studio Le Phonarium - Gérald Dorai
      </p>

      <label>Voix</label>
      <p>
        Ingrid Parois, dans le rôle d’Anna</br>
        Sébastien Landry, dans le rôle de Gary, le responsable de la sécurité</br>
        Rodolphe Collé, dans le rôle du directeur</br>
        Avec également la participation de Nicolas Martinez, Gérald Dorai, Cécilia Dorai, Quentin Ladetto, Pamela Bellier, Romain Fenouil, Matthieu Gioani, Anne-Laure Guermont</br>
        Voix off (trailer et générique de fin) : Mélanie Périrhin
      </p>

      <label>Traduction</label>
      <p>
        Version anglaise : Noémie Joubert, Marie Brummer et Kristin Knickelbein</br>
        Version allemande : Jeanne Simonnet</br>
        Version italienne : Cristina Lazzari, Quentin Ladetto
      </p>

      <label>Hébergement du site</label>
      <p>
        Infomaniak, Suisse
      </p>
    `
  },
  it : {
    "title" : "Crediti",
    "text": `
      <p>Questo progetto è stato prodotto come parte del programma di ricerca di foresight tecnologico di armasuisse scienza e tecnologia, diretto dal Dr. Quentin Ladetto.</p>
      <p>
        Con la partecipazione per la parte analitica di :</br>
        Prof Aya Kachi, (<a href="https://www.ayakachi.org" target="_blank">https://www.ayakachi.org</a>)</br>
        Umberto Michelucci - Toelt (<a href="http://toelt.ai/about-us/" target="_blank">http://toelt.ai/about-us/</a>)</br>
      </p>
      <label>Progettazione e realizzazione</label>
      <p>
        <a href="http://www.lecoupdapres.fr/" target="_blank">Le Coup d’Après</a> - Noémie Aubron, Pamela Bellier, Romain Fenouil e Matthieu Gioani.
      </p>
      <p>
        Il team di Coup d'Après ringrazia calorosamente tutti i collaboratori del progetto:
      </p>

      <label>Scenario</label>
      <p>
        Le Coup d'Après</br>
        Benjamin Hoguet</br>
        Nicolas Martinez</br>
      </p>

      <label>Direzione tecnica e artistica</label>
      <p>
        Bakasable: Heidi Ghernati, Hugo Rulier, Marie-Isabelle Goyet, Marjorie Gaboriau</br>
        Fenikso: Alexandre Jullian-Fradin</br>
        Con il sostegno di Brice Coustillet</br>
      </p>

      <label>ID visuale</label>
      <p>
        Stéphanie Loyer
      </p>

      <label>Comporre, modificare, miscelare</label>
      <p>
        Studio Laboreal - Maxime Robin
      </p>

      <label>Registrazione del suono</label>
      <p>
        Phonarium Studio - Gerald Dorai
      </p>

      <label>Voce</label>
      <p>
        Ingrid Parois, come Anna</br>
        Sebastian Landry, come Gary, il capo della sicurezza</br>
        Rodolphe Collé, nel ruolo del regista</br>
        Con la partecipazione di Nicolas Martinez, Gérald Dorai, Cécilia Dorai, Quentin Ladetto, Pamela Bellier, Romain Fenouil, Matthieu Gioani, Anne-Laure Guermont</br>
        Voce fuori campo (trailer e crediti finali): Mélanie Périrhin
      </p>

       <label>Traduzione</label>
      <p>
        Versione inglese: Noémie Joubert, Marie Brummer e Kristin Knickelbein</br>
        Versione tedesca: Jeanne Simonnet</br>
        Versione italiana: Cristina Lazzari, Quentin Ladetto
      </p>

       <label>Sito di hosting </label>
      <p>
        Infomaniak, Svizzera
      </p>
    `
  },
  de : {
    "title" : "Credits",
    "text": `
      <p>Dieses Experiment wurde im Rahmen des Forschungsprogramms zur Technologievorausschau erstellt. Sie ist im Besitz von armasuisse und dem Technology Foresight Programm, geleitet von Dr. Quentin Ladetto.</p>
      <p>
        Mit der Teilnahme, über die Studie und statistische Aspekte der</br>
        Aya Kachi - Universität Basel (<a href="https://wwz.unibas.ch/de/kachi/"  target="_blank">https://wwz.unibas.ch/de/kachi/</a>)</br>
        Umberto Michelucci - Toelt (<a href="http://toelt.ai/about-us/" target="_blank">http://toelt.ai/about-us/</a>)</br>
      </p>
      <label>Konzept und Realisierung</label>
      <p>
        <a href="http://www.lecoupdapres.fr/" target="_blank">Le Coup d’Après</a> - Noémie Aubron, Pamela Bellier, Romain Fenouil und Matthieu Gioani.
      </p>
      <p>
        Das Coup d'Après-Team bedankt sich herzlich bei allen, die zu diesem Projekt beigetragen haben:
      </p>

      <label>Szenario</label>
      <p>
        Le Coup d'Après</br>
        Benjamin Hoguet</br>
        Nicolas Martinez</br>
      </p>

      <label>Technische und künstlerische Leitung</label>
      <p>
        Bakasable: Heidi Ghernati, Hugo Rulier, Marie-Isabelle Goyet, Marjorie Gaboriau</br>
        Fenikso: Alexandre Jullian-Fradin</br>
        Mit der Unterstützung von Brice Coustillet</br>
      </p>

      <label>Visuelle ID</label>
      <p>
        Stéphanie Loyer
      </p>

      <label>Komponieren, Bearbeiten, Mischen</label>
      <p>
        Studio Laboreal - Maxime Robin
      </p>

      <label>Tonaufnahme</label>
      <p>
        Phonarium Studio - Gerald Dorai
      </p>

      <label>Stimme</label>
      <p>
        Ingrid Parois, als Anna</br>
        Sebastian Landry, als Gary, der Sicherheitschef</br>
        Rodolphe Collé, in der Rolle des Regisseurs</br>
        Unter anderem mit der Beteiligung von Nicolas Martinez, Gérald Dorai, Cécilia Dorai, Quentin Ladetto, Pamela Bellier, Romain Fenouil, Matthieu Gioani, Anne-Laure Guermont</br>
        Synchronsprecher (Trailer und Abspann): Mélanie Périrhin
      </p>

      <label>Übersetzung</label>
      <p>
        Englische Version: Noémie Joubert, Marie Brummer und Kristin Knickelbein</br>
        Deutsche Version: Jeanne Simonnet</br>
        Italienische Version: Cristina Lazzari, Quentin Ladetto
      </p>

      <label>Site-Hosting</label>
      <p>
        Infomaniak, Schweiz
      </p>
    `
  }
}
