import styles from './general-conditions.module.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logoWhite from '../../assets/images/logo-white.png'
import { traductionsGeneralConditions } from '../../config/traductions-general-conditions'


export default function GeneralConditions(){
  const podcastReducer = useSelector(state => state.podcastReducer)

  return (
    <div className={styles["general-conditions"]}>
      <Link className={styles["logo-header"]} to="/"><img  src={logoWhite} alt="fantas-ia" /></Link>
      <div className={`${styles["container"]} container`}>
        <h2>{traductionsGeneralConditions[podcastReducer.lang]["title"]}</h2>
        <span dangerouslySetInnerHTML={{ __html : traductionsGeneralConditions[podcastReducer.lang]["text"]}} />
      </div>
    </div>
  )

}
