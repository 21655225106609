import styles from './credits.module.scss'
import { useSelector } from 'react-redux'
import { traductionsCredits } from '../../../../config/traductions-credits'
import { traductions } from '../../../../config/traductions'
import ArrowBack from '../../../../assets/images/arrow-back.svg'
import { useHistory } from 'react-router-dom'

export default function Credits(){
  const podcastReducer = useSelector(state => state.podcastReducer)
  const history = useHistory();

  return (
    <div className={styles["credits"]}>
      <div className={styles["container"]}>
        <div className={styles["back-navigation"]} onClick={() => { history.push("/fantas-ia/pages/navigation-annexes") }}>
          <img src={ArrowBack} alt="retour" />
          <label>{traductions[podcastReducer.lang]["backward"]}</label>
        </div>
        <h1>{traductionsCredits[podcastReducer.lang]["title"]}</h1>
        <span dangerouslySetInnerHTML={{ __html : traductionsCredits[podcastReducer.lang]["text"]} } />
      </div>
    </div>
  )
}

// NOM SITE LOGO
