export const traductionsHome = {
  en : {
    "discover" : "Discover the FantasIA experience",
    "button-date" : "17.12.2020 - Discover the FantasIA experience",
    "text" : "<p>Proposed by Deftech and the collective Le Coup d'Après, Technocast aims to experiment with new ways of conducting prospective studies through fiction, emphasizing immersion.</p><p>The objective of this approach is to better understand our uses and acceptance of new technologies in both the civil and military fields.</p><p>FantasIA is an interactive and prospective audio fiction, which is the first project of the Technocast approach.</p>",
    "learn-more" : "Learn more about the Technocast approach"
  },
  fr : {
    "discover" : "Découvrir l'expérience FantasIA",
    "button-date" : "Le 17 décembre, découvrez l’expérience FantasIA",
    "text" : "<p>Proposé par Deftech et le collectif Le Coup d’Après, Technocast vise à expérimenter de nouvelles manières de mener des études prospectives au travers de la fiction, privilégiant l’immersion.</p><p>L’objectif de cette démarche est de mieux comprendre nos usages et notre acceptation des nouvelles technologies dans le domaine civil comme militaire.</p><p>FantasIA est une fiction audio interactive et prospective, qui constitue le 1er projet de la démarche Technocast.</p>",
    "learn-more" : "En savoir plus sur la démarche Technocast"
  },
  it : {
    "discover" : "Scopri l'esperienza FantasIA",
    "button-date" : "17.12.2020 - Scopri l'esperienza FantasIA",
    "text" : "<p>Da un progetto di Deftech e di Le Coup d'Après, Technocast si propone di sperimentare nuovi modi di condurre studi di foresight attraverso la finzione, privilegiando il metodo dell’immersione.</p><p>L'obiettivo di questo approccio è quello di comprendere meglio i nostri usi e l'accettazione delle nuove tecnologie sia in campo civile che militare.</p><p>FantasIA è una fiction audio interattiva di foresight e costituisce il primo capitolo del progetto Technocast.</p>",
    "learn-more" : "Per saperne di più sull'approccio Technocast"
  },
  de : {
    "discover" : "Entdecken Sie das FantasIA-Erlebnis",
    "button-date" : "17.12.2020 - Entdecken Sie das FantasIA-Erlebnis",
    "text" : "<p>Vorgeschlagen von Deftech und dem Kollektiv Le Coup d'Après, zielt Technocast darauf ab, mit neuen Wegen der Durchführung von prospektiven Studien durch Fiktion zu experimentieren, wobei die Immersion bevorzugt wird.</p><p>Ziel dieses Ansatzes ist es, die Nutzung und Akzeptanz neuer Technologien sowohl im zivilen als auch im militärischen Bereich besser zu verstehen.</p><p>FantasIA ist eine interaktive und prospektive Audio-Fiction, die das erste Projekt des Technocast-Ansatzes ist.</p>",
    "learn-more" : "Erfahren Sie mehr über den Technocast-Ansatz"
  }
}
