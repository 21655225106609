import styles from './technocast.module.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logoWhite from '../../assets/images/logo-white.png'
import { traductionsTechnocast } from '../../config/traductions-technocast'


export default function Technocast(){
  const podcastReducer = useSelector(state => state.podcastReducer)

  return (
    <div className={styles["technocast"]}>
      <Link className={styles["logo-header"]} to="/"><img  src={logoWhite} alt="fantas-ia" /></Link>
      <div className={`${styles["container"]} container`}>
        <h2>{traductionsTechnocast[podcastReducer.lang]["title"]}</h2>
        <label>{traductionsTechnocast[podcastReducer.lang]["label-1"]}</label>
        <span dangerouslySetInnerHTML={{ __html : traductionsTechnocast[podcastReducer.lang]["text-1"]}} />
        <label>{traductionsTechnocast[podcastReducer.lang]["label-2"]}</label>
        <span dangerouslySetInnerHTML={{ __html : traductionsTechnocast[podcastReducer.lang]["text-2"]}} />
        <label>{traductionsTechnocast[podcastReducer.lang]["contact"]}</label>
        <span dangerouslySetInnerHTML={{ __html : traductionsTechnocast[podcastReducer.lang]["text-contact"]}} />
      </div>
    </div>
  )

}
