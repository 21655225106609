import { useRef, useState, useEffect, useMemo } from 'react'
import {useTransition, animated} from 'react-spring'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdSubtitles, MdVolumeOff, MdVolumeUp, MdPause, MdPlayArrow, MdMic, MdMicOff } from "react-icons/md";
import { uniqBy } from 'lodash'
import useKonamiCode from '../../../hooks/useKonamiCode'
import usePrevious from '../../../hooks/usePrevious'
import styles from './story.module.scss'
import story from '../../../config/story.js';

// import Audio from '../../../components/Audio'
import Step from '../../../components/Step'
import AnimatedBackground from '../../../components/AnimatedBackground'

import podcastActions from '../../../actions/podcastActions.js'

export default function Story({playSound, pauseSound}){
  const stepRef = useRef()
  const animatedBackgroundRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch();
  const podcastReducer = useSelector(state => state.podcastReducer)
  const code = useKonamiCode("step", (code) => {
    console.log('step',code);
    setStepIndex(code)
  } )
  const [ isPlaying, setIsPlaying ] = useState(true)
  const [ isMuted, setIsMuted ] = useState(false)
  const [ isAnnaMuted, setIsAnnaMuted ] = useState(false)

  const [ backgroundFade, setBackgroundFade ] = useState(false)
  const [ stepIndex, setStepIndex ] = useState(1)
  const prevIndex = usePrevious(stepIndex)
  const [ backgroundIndex, setBackgroundIndex ] = useState(null)
  const [ storyMap, setStoryMap ] = useState()
  const [ backgroundSlides, setBackgroundSlides ] = useState();
  const [ showSubtitles, setShowSubtitles ] = useState(false)

  // INIT STEP
  useEffect(()=>{
    let step = localStorage.getItem('step')
    !isNaN(parseInt(step)) && setStepIndex(parseInt(step))

    if(step === 1 || step === null){
      dispatch(podcastActions.setDateStart(new Date()))
    }

  },[])

  useEffect(()=>{
    let map = new Map();

    let arrBackground = []

    story.forEach((d)=>{
      if(d.background) arrBackground.push(d.background)
      map.set(d.id,d)
    })

    arrBackground =  uniqBy(arrBackground,"src")
    arrBackground.forEach((d,i)=>{
      d.id = i
    })

    setBackgroundSlides(arrBackground)
    setStoryMap(map)

  },[])

  useEffect(()=>{
    if(!stepIndex || !storyMap || !backgroundSlides) return;

    if(storyMap.get(stepIndex) && storyMap.get(stepIndex).background){
      let findIndex = backgroundSlides.findIndex((d,i)=>{
        return d.src === storyMap.get(stepIndex).background.src
      })

      setBackgroundIndex(findIndex)
    }

  },[stepIndex,storyMap,backgroundSlides])

  useEffect(()=>{
    if(!storyMap) return;
    let step = storyMap.get(stepIndex)
    let lang = podcastReducer.lang

    if(step.choices || (step.text[lang] && !showSubtitles)){
      // opacity .3
      setBackgroundFade(true)
    }else{
      setBackgroundFade(false)
      // opacity 1
    }
  },[storyMap,showSubtitles,stepIndex])

  function pause(){
    // PAUSE ALL AUDIO IN STEP
    stepRef.current.pause()
    // PAUSE SOUND FROM PARENT CONTAINER
    pauseSound()
    //PAUSE ANIMATED BACKGROUND
    animatedBackgroundRef.current.pause()
    setIsPlaying(false)
  }

  function play(){
    // PLAY ALL AUDIO IN STEP
    stepRef.current.play()
    // PLAY SOUND FROM PARENT CONTAINER
    playSound()
    //PLAY ANIMATED BACKGROUND IF VIDEO
    animatedBackgroundRef.current.play()
    setIsPlaying(true)
  }

  function muted(bool){
    stepRef.current.muted(bool)
    setIsMuted(bool)
  }

  function annaMuted(bool){
    stepRef.current.annaMuted(bool)
    setIsAnnaMuted(bool)
  }

  function setStep(id){
    if(id === "end"){
      dispatch(podcastActions.setDateEnd(new Date()))
      history.push('/fantas-ia/end')

    }else{
      localStorage.setItem('step',id)
      setStepIndex(id);
    }
  }

  return (
    <div className={styles["story"]}>

      { (stepIndex && storyMap) &&
        <>
          <Step
            ref={stepRef}
            content={storyMap.get(stepIndex)}
            setStep={setStep}
            isAnnaMuted={isAnnaMuted}
            showSubtitles={showSubtitles}
            lang={podcastReducer.lang}
          />
        </>

      }

      {/*
        //WIP
        storyMap && <AudioAtmospheric storyMap={storyMap} stepIndex={stepIndex}/>
      */}


      {((backgroundSlides && backgroundSlides.length>0) && backgroundIndex !== null) &&
        <AnimatedBackground
          ref={animatedBackgroundRef}
          backgroundFade={backgroundFade}
          slides={backgroundSlides}
          index={backgroundIndex}
        />
      }

      <div className={`${styles["story-controls"]} ${styles["hide-on-mobile"]} hide-on-mobile`}>
        <span>
          <MdSubtitles onClick={() => setShowSubtitles(!showSubtitles)}/>
          { isPlaying ?
            <MdPause onClick={pause}/> :
            <MdPlayArrow onClick={play}/>
          }
        </span>

        { isMuted ?
          <MdVolumeOff onClick={()=>muted(false)}/> :
          <MdVolumeUp onClick={()=>muted(true)}/>
        }

        { isAnnaMuted ?
          <MdMicOff onClick={()=>annaMuted(false)}/> :
          <MdMic onClick={()=>annaMuted(true)}/>
        }

      </div>

      <div className={`${styles["story-controls"]} ${styles["only-on-mobile"]} only-on-mobile`}>
        <MdSubtitles onClick={() => setShowSubtitles(!showSubtitles)}/>
        { isPlaying ?
          <MdPause onClick={pause}/> :
          <MdPlayArrow onClick={play}/>
        }

        { isMuted ?
          <MdVolumeOff onClick={()=>muted(false)}/> :
          <MdVolumeUp onClick={()=>muted(true)}/>
        }

        { isAnnaMuted ?
          <MdMicOff onClick={()=>annaMuted(false)}/> :
          <MdMic onClick={()=>annaMuted(true)}/>
        }

      </div>

    </div>
  )
}

// const AudioAtmospheric = ({storyMap,stepIndex}) => {
//   const [index, set] = useState()
//   const prevIndex = usePrevious(index)
//
//   const colors = [ "tomato", "blue", "yellow"]
//
//   useEffect(()=>{
//     set(state => storyMap.get(stepIndex).audio?.atmospheric)
//   },[stepIndex])
//
//   const transitions = useTransition(index, p => p, {
//     from: { opacity: 0, volume : 0, transform: 'translate3d(100%,0,0)' },
//     enter: { opacity: 1, volume : 1, transform: 'translate3d(0%,0,0)' },
//     leave: { opacity: 0, volume : 0, transform: 'translate3d(-50%,0,0)' },
//     config : { duration : 1000 }
//   })
//
//   return useMemo(()=>{
//     return (
//       <div className="simple-trans-main">
//         {transitions.map(({ item, props, key }) => {
//           return (
//             <animated.div key={key} style={{...props, position:"fixed", zIndex:1000, padding : "120px", background : "tomato", top: 0, right : 0}}>
//               {item && <Audio id={key} volume={props.volume} source={`${process.env.PUBLIC_URL}/audios${item}`} autoPlay/>}
//             </animated.div>
//           )
//         })}
//       </div>
//     )
//   },[index])
//
// }
