import styles from './sources.module.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ArrowBack from '../../../../assets/images/arrow-back.svg'
import { traductionsSources } from '../../../../config/traductions-sources.js'
import { traductions } from '../../../../config/traductions'

export default function Sources(){
  const podcastReducer = useSelector(state => state.podcastReducer)
  const history = useHistory();

  return (
    <div className={styles["sources"]}>
      <div className={styles["container"]}>
        <div className={styles["back-navigation"]} onClick={() => { history.push("/fantas-ia/pages/navigation-annexes") }}>
          <img src={ArrowBack} alt="retour" />
          <label>{traductions[podcastReducer.lang]["backward"]}</label>        </div>
        <h1>{traductionsSources[podcastReducer.lang]["title"]}</h1>
        <p>{traductionsSources[podcastReducer.lang]["text"]}</p>
        <div className={styles["container-col"]}>
          <div>
            <h3>{traductionsSources[podcastReducer.lang]["title-col-1"]}</h3>
            <ul className={styles["list-studies"]}>
              <li>
                <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-1"]["title"]}}></span>
                <p>{traductionsSources[podcastReducer.lang]["etude-1"]["text"]}</p>
              </li>
              <li>
                <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-2"]["title"]}}></span>
                <p>{traductionsSources[podcastReducer.lang]["etude-2"]["text"]}</p>
              </li>
              <li>
                <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-3"]["title"]}}></span>
                <p>{traductionsSources[podcastReducer.lang]["etude-3"]["text"]}</p>
              </li>
            </ul>
          </div>
          <div>
            <h3>{traductionsSources[podcastReducer.lang]["title-col-2"]}</h3>
            <ul className={styles["list-studies"]}>
            <li>
              <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-5"]["title"]}}></span>
              <p>{traductionsSources[podcastReducer.lang]["etude-4"]["text"]}</p>
            </li>
            <li>
              <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-6"]["title"]}}></span>
              <p>{traductionsSources[podcastReducer.lang]["etude-5"]["text"]}</p>
            </li>
            <li>
              <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-7"]["title"]}}></span>
              <p>{traductionsSources[podcastReducer.lang]["etude-6"]["text"]}</p>
            </li>
            </ul>
          </div>
          <div>
            <h3>{traductionsSources[podcastReducer.lang]["title-col-1-1"]}</h3>
            <ul className={styles["list-studies"]}>
            <li>
              <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-7"]["title"]}}></span>
              <p>{traductionsSources[podcastReducer.lang]["etude-7"]["text"]}</p>
            </li>
            <li>
              <span dangerouslySetInnerHTML={{ __html : traductionsSources[podcastReducer.lang]["etude-8"]["title"]}}></span>
              <p>{traductionsSources[podcastReducer.lang]["etude-8"]["text"]}</p>
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
