import styles from "./button.module.scss"
import { Link } from "react-router-dom";

export default function Button ({to, onClick, text}){
  return (
    <>
      { to &&
        <Link className={styles["button"]} to={to}>
            {text}
        </Link>
      }
      { onClick &&
        <a className={styles["button"]} onClick={onClick}
          dangerouslySetInnerHTML={{ __html : text}}
        />
      }
    </>

  )
}
