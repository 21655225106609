const darkGrey = '#1F1938'
const tonic = "#00FFE0"
const nuanceTonic = "#00FFE0"
const white = "#FFFFFF"
const fontSize = '16px'
const font = 'Gudea, sans-serif'

const border = `solid 2px ${tonic}`

const style = {
  container: (provided, state) => {
    return ({
      ...provided,
      margin: '10px 0',
      minWidth : '200px'
    })
  },
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : fontSize,
    color :  white,
    fontFamily : font,
    fontWeight : 700,
    textTransform : 'uppercase'
  }),
  control: (provided, state) => {
    return ({
      ...provided,
      backgroundColor : "transparent",
      borderSizing : "border-box",
      borderRadius : 0,
      border: "none",
      borderBottom: border,
      outline: "none",
      boxShadow: 0,
      cursor: 'pointer',
      '&:hover': {
        borderColor : tonic
      }
    })
  },
  singleValue : (provided, state) => ({
    ...provided,
    color : white,
    fontFamily : font,
    fontSize : fontSize,
    fontWeight : 700,
    textTransform : 'uppercase'
  }),
  option: (provided, state) => {
    return ({
      ...provided,
      color : !state.isSelected ? darkGrey : 'white',
      backgroundColor : !state.isSelected ? 'white' : tonic,
      '&:hover' : {
        cursor : 'pointer',
        backgroundColor : !state.isSelected ? nuanceTonic : tonic,
        color : 'white'
      }
    })
  },
  menu : (provided, state) => ({
    ...provided,
    zIndex:10
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingLeft:0
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color : darkGrey,
    paddingRight: 0,
    paddingLeft: 0,
    '&:hover': {
      borderColor : tonic
    }
  }),
  indicatorSeparator: () => ({ display : 'none' })
}

// control: (provided, state) => {
//   return ({
//     ...provided,
//     backgroundColor : "transparent",
//     borderRadius: 0,
//     borderSizing : "border-box",
//     border: "none",
//     borderTop: !state.selectProps.noBorderTop ? border : null,
//     borderBottom: border,
//     outline: "none",
//     boxShadow: 0,
//     cursor: 'pointer',
//     '&:hover': {
//       borderColor : darkGrey
//     }
//   })
// },
// placeholder: (provided, state) => ({
//   ...provided,
//   fontSize : fontSize,
//   color : darkGrey,
//   fontFamily : font
// }),
// valueContainer : (provided, state) => ({
//   ...provided,
//   paddingLeft:0
// }),
// singleValue : (provided, state) => ({
//   ...provided,
//   color : tonic,
//   fontFamily : font,
//   fontSize : fontSize
// }),
// multiValue : (provided, state) => ({
//   ...provided,
//   backgroundColor : 'transparent'
// }),
// multiValueLabel : (provided, state) => ({
//   ...provided,
//   color : tonic,
//   fontFamily : font,
//   fontSize : fontSize,
//   paddingLeft:0,
//   padding:0,
//   margin: 0
// }),
// multiValueRemove: (provided, state) => ({
//   ...provided,
//   paddingLeft:0,
//   '&:hover': {
//     backgroundColor : 'transparent',
//     color : tonic
//   }
// }),
// input: (provided, state) => ({
//   ...provided,
//   color : tonic,
//   fontFamily : font
// }),
// dropdownIndicator: (provided, state) => ({
//   ...provided,
//   color : darkGrey,
//   paddingRight: 0,
//   paddingLeft: 0,
//   '&:hover': {
//     borderColor : tonic
//   }
// }),
// option: (provided, state) => {
//   return ({
//     ...provided,
//     color : !state.isSelected ? darkGrey : 'white',
//     backgroundColor : !state.isSelected ? 'white' : tonic,
//     '&:hover' : {
//       cursor : 'pointer',
//       backgroundColor : !state.isSelected ? nuanceTonic : tonic,
//       color : 'white'
//     }
//   })
// },
// clearIndicator: (provided) => ({
//   ...provided,
//   paddingRight: 0,
//   color : darkGrey
// }),
// indicatorSeparator: () => ({ display : 'none' })

export default style
