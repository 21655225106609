import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import styles from './checkboxes.module.scss'
import imgCheckboxChecked from './checkbox-checked.svg'
import imgCheckbox from './checkbox.svg'


const Checkboxes = ({options, defaultValue, name },ref) => {
  const [ value, setValue ] = useState({})

  useImperativeHandle(ref,()=>({
    getValue : () => value,
    getObject : () => ({ [name] : value.value})
  }))

  useEffect(()=>{
    defaultValue && setValue(defaultValue)
  },[])

  return (
    <div className={styles["checkboxes"]}>
      {options.map((opt,i) => {
        return (
          <div
            key={`checkbox-${i}`}
            onClick={() => setValue(opt)}
            className={value.value === opt.value ? `${styles["container-checkbox"]} ${styles["selected"]}` : `${styles["container-checkbox"]}`}
          >
            <div className={styles["checkbox"]}>
              { value.value === opt.value ?
                  <img src={imgCheckboxChecked} alt={opt.label} /> :
                  <img src={imgCheckbox} alt={opt.label} />
              }
            </div>
            <label>{opt.label}</label>
          </div>
        )
      })}
    </div>
  )
}

export default forwardRef(Checkboxes)
