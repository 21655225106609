import React from 'react'
import { useDispatch } from 'react-redux'
import styles from './modal-sound.module.scss'
import { MdPlayArrow } from "react-icons/md";

import podcastActions from '../../actions/podcastActions'

import { useHistory } from "react-router-dom";


export default function ModalSound(){
  const history = useHistory()
  const dispatch = useDispatch();

  function playAllMedia(){
    let audiosElements = document.querySelectorAll("audio")
    let videosElements = document.querySelectorAll("video")

    if(audiosElements){
      audiosElements.forEach(audio=>{
        if(history.location.pathname === "/fantas-ia/intro"){
          audio.id !== "end" && audio.play()
        }else if(history.location.pathname === "/fantas-ia/end" ||
          history.location.pathname === "/fantas-ia/pages/resultat" ||
          history.location.pathname === "/fantas-ia/pages/sources" ||
          history.location.pathname === "/fantas-ia/pages/credits" ||
          history.location.pathname === "/fantas-ia/pages/navigation-annexes")
          audio.id !== "intro" && audio.play()
        else{
          if(audio.id !== "intro" && audio.id !== "end") audio.play()
        }
      })
    }

    if(videosElements){
      videosElements.forEach(video=>{
        video.play();
      })
    }

    dispatch(podcastActions.toggleModalSound(false))

  }

  return (
    <React.Fragment>
      <div className={styles["modal-sound"]} onClick={playAllMedia}>
        <MdPlayArrow/>
      </div>
      <div className={styles["modal-overlay"]}>
      </div>
    </React.Fragment>
  )
}
