import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styles from './aura.module.scss'
import video from './waveform_materialisation_voix_anna_20sec.webm'
import videomp4 from './video2.mp4'

const Aura = (props = {},ref) => {
  const videoRef = useRef()
  const [ isVisible, setIsVisible ] = useState(false);
  const [ canPlayWebm, setCanPlayWebm ] = useState(true);

  //HANDLE CAN PLAY WEBM
  useEffect(()=>{
    const video = document.createElement('video');
    let canPlayType = video.canPlayType('video/webm') !== ""
    setCanPlayWebm(canPlayType)
  },[])


  useEffect(() => {
    const { current: videoElement } = videoRef
    if(!videoElement){return}
    videoElement.setAttribute('muted', '')
  }, [])

  useImperativeHandle(ref,()=>({
    pause : () => videoRef.current && videoRef.current.pause(),
    play : () => videoRef.current && videoRef.current.play(),
    toggleAura : (bool) => {
      setIsVisible(bool)
    }
  }))

  return (
    <React.Fragment>
        { isVisible &&
          <div className={styles["aura"]} style={canPlayWebm ? { opacity : 1 } : { opacity : .5 }}>
            <video ref={videoRef} controls={false} autoPlay muted loop playsInline >
              <source src={video} type="video/webm" />
              <source src={videomp4} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        }
    </React.Fragment>
  )
}

export default forwardRef(Aura)
