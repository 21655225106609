export const traductionsTechnocast = {
  en : {
    "title" : "The Technocast approach",
    "label-1" : "The approach",
    "text-1" : `
      <p>
        Technocast is an initiative of the technology foresight program of armasuisse science and technology. It combines statistical study and playful experience to better identify the society's positioning with respect to the uses of various technological fields. The purpose of this approach is to minimize opinion biasis by projecting the listener into a contextualized decision scheme.
      </p>
      <p>
        The audio fiction alternates so-called "strategic" questions, those taken into account in the study and which create a fork in the story, with "atmospheric" questions which are there to maintain interactivity in the experience but which have no impact, either on the story or on the study.
      </p>
      <p>
        This approach is the fruit of work between actors in the fields of politics and statistics, foresight, computer science, innovation, writing and design.
      </p>
      <p>
        Study results are in <a href="https://deftech.ch/en/technocast" target="_blank">free access.</a></br>
        For more information on analytical methods, <a href="https://deftech.ch/en/technocast-analytics/" target="_blank">visit here.</a></br>
      </p>
    `,
    "label-2" : "The experience",
    "text-2" : `
      <p>
        FantasIA is an interactive and prospective sound experience, produced by the collective Le Coup d'Après, in which the listener plays his own role. The action takes place in the near and probable future. Within an amusement park and during a day rich in experience, the listener plays the human consciousness of an artificial intelligence - Anna - who manages the park's security. The listener is confronted with realistic dilemmas, and makes choices regarding the use of Anna's abilities.
      </p>
      <p>
        We hope you enjoy this experience and want to share it with others.
      </p>
    `,
    "contact" : "Contact",
    "text-contact" : `
      <p>
        Dr. Quentin Ladetto</br>
        Director of Prospective Technological Research</br>
      </p>
      <p>
        armasuisse</br>
        Science and Technology</br>
        Research Management and operations research</br>
        Feuerwerkerstrasse 39</br>
        CH-3602 Thun</br>
      </p>
      <p>
        Contact mail (https://deftech.ch/en/contact/)
      </p>
    `
  },
  fr : {
    "title" : "La démarche Technocast",
    "label-1" : "La démarche",
    "text-1" : `
      <p>
        Technocast est une initiative du programme de prospective technologique d’armasuisse sciences et technologies. Il combine étude statistique et expérience ludique, afin de mieux identifier le positionnement de la société face aux usages de différents domaines technologiques. L’objectif de la démarche est de minimiser les biais d’opinion, en projetant l’auditeur dans un schéma de décision contextualisé.
      </p>
      <p>
        La fiction audio alterne des questions dites “stratégiques”, celles prises en compte dans l’étude et qui créent un embranchement dans l’histoire, avec des questions “atmosphériques” qui sont là pour maintenir l’interactivité dans l’expérience mais qui n’ont aucun impact, ni sur l’histoire, ni sur l’étude.
      </p>
      <p>
        Cette démarche est le fruit d’un travail entre acteurs du domaine politique et de la statistique, de la prospective, des sciences informatiques, de l’innovation, de l’ écriture et du design.
      </p>
      <p>
        Les résultats de l’étude sont en <a href="https://deftech.ch/fr/technocast" target="_blank">libre accès.</a></br>
        Pour en savoir plus sur les méthodes d’analyse, <a href="https://deftech.ch/fr/technocast-analytics/" target="_blank">rendez-vous ici.</a></br>
      </p>
    `,
    "label-2" : "L’expérience",
    "text-2" : `
      <p>
        FantasIA est une expérience sonore interactive et prospective, produite par le collectif Le Coup d’Après, dans laquelle l’auditeur joue son propre rôle. L’action se passe dans un futur proche et probable. Au sein d’un parc d'attractions et au cours d’une journée riche en événements, l’auditeur exerce le métier de conscience humaine d’une intelligence artificielle - Anna - qui gère la sécurité du parc. L’auditeur est placé face à des dilemmes réalistes, et fait des choix par rapport à l’utilisation des capacités d’Anna.
      </p>
      <p>
        Nous espérons que cette expérience vous plaira et que vous aurez envie de la partager.
      </p>
    `,
    "contact" : "Contact",
    "text-contact" : `
      <p>
        Dr. Quentin Ladetto</br>
        Directeur de Recherche Prospective Technologique</br>
      </p>
      <p>
        armasuisse</br>
        Sciences et Technologies</br>
        Gestion de la recherche et de recherche opérationnelle</br>
        Feuerwerkerstrasse 39</br>
        CH-3602 Thoune</br>
      </p>
      <p>
        Contact mail (https://deftech.ch/fr/contact/)
      </p>
    `
  },
  it : {
    "title" : "L'approccio Technocast",
    "label-1" : "L'approccio",
    "text-1" : `
      <p>
        Technocast è un'iniziativa del programma di foresight tecnologico di armasuisse scienza e tecnologia. Unisce studio statistico ed esperienza ludica per meglio identificare il posizionamento dell'azienda in relazione agli utilizzi dei diversi settori tecnologici. L'obiettivo dell'approccio è quello di ridurre al minimo i pregiudizi di opinione, proiettando l'ascoltatore in uno schema decisionale contestualizzato.
      </p>
      <p>
        La fiction audio alterna le domande cosiddette "strategiche", quelle prese in considerazione nello studio e che creano una forchetta nella storia, con domande "atmosferiche" che servono a mantenere l'interattività dell'esperienza ma che non hanno alcun impatto, né sulla storia né sullo studio.
      </p>
      <p>
        Questo approccio è il risultato del lavoro tra attori della politica e della statistica, della previsione, dell'informatica, dell'innovazione, della scrittura e del design.
      </p>
      <p>
        I risultati dello studio sono in <a href="https://deftech.ch/it/technocast" target="_blank"> accesso libero.</a></br>
        Per ulteriori informazioni sui metodi analitici, <a href="https://deftech.ch/it/technocast-analytics/" target="_blank">vai qui.</a></br>
      </p>
    `,
    "label-2" : "L'esperienza",
    "text-2" : `
      <p>
        FantasIA è un'esperienza sonora interattiva e prospettica, prodotta dal collettivo Le Coup d'Après, in cui l'ascoltatore interpreta il proprio ruolo. L'azione si svolge nel prossimo e probabile futuro. All'interno di un parco divertimenti e durante una giornata ricca di eventi, l'ascoltatore esercita la coscienza umana di un'intelligenza artificiale - Anna - che gestisce la sicurezza del parco. L'ascoltatore si trova di fronte a dilemmi realistici e fa delle scelte sull'uso delle capacità di Anna.
      </p>
      <p>
        Ci auguriamo che questa esperienza vi piaccia e che vogliate condividerla con gli altri.
      </p>
    `,
    "contact" : "Contatto",
    "text-contact" : `
      <p>
        Dr. Quentin Ladetto</br>
        Direttore Technology Foresight</br>
      </p>
      <p>
        armasuisse</br>
        Scienza e Tecnologia</br>
        Gestione della ricerca e ricerca operativa</br>
        Feuerwerkerstrasse 39</br>
        CH-3602 Thun</br>
      </p>
      <p>
        Contatto (https://deftech.ch/it/contact/)
      </p>
    `
  },
  de : {
    "title" : "Der Technocast Ansatz",
    "label-1" : "Der Ansatz",
    "text-1" : `
      <p>
        Technocast ist eine Initiative des Technology Foresight Programms von armasuisse science and technology. Es kombiniert statistische Untersuchungen und spielerische Erfahrungen, um die Positionierung des Unternehmens in Bezug auf die Nutzung verschiedener technologischer Felder besser zu erkennen. Das Ziel des Ansatzes ist es, die Meinungsverzerrung zu minimieren, indem der Hörer in ein kontextualisiertes Entscheidungsschema projiziert wird.
      </p>
      <p>
        In der Audio-Fiction wechseln sich sogenannte "strategische" Fragen, die in der Studie berücksichtigt werden und eine Abzweigung in der Geschichte schaffen, mit "atmosphärischen" Fragen ab, die dazu da sind, die Interaktivität im Erlebnis aufrechtzuerhalten, die aber keine Auswirkungen haben, weder auf die Geschichte noch auf die Studie.
      </p>
      <p>
        Dieser Ansatz ist das Ergebnis der Zusammenarbeit von Akteuren aus den Bereichen Politik und Statistik, Foresight, Informatik, Innovation, Schreiben und Design.
      </p>
      <p>
        Die Ergebnisse der Studie sind im <a href="https://deftech.ch/de/technocast" target="_blank">kostenlosen Zugang.</a></br>
        Weitere Informationen zu Analysemethoden finden Sie <a href="https://deftech.ch/de/technocast-analytics/" target="_blank">hier.</a></br>
      </p>
    `,
    "label-2" : "Die Erfahrung",
    "text-2" : `
      <p>
        FantasIA ist ein interaktives und prospektives Klangerlebnis, produziert vom Kollektiv Le Coup d'Après, bei dem der Zuhörer seine eigene Rolle spielt. Die Handlung findet in der nahen und wahrscheinlichen Zukunft statt. Innerhalb eines Vergnügungsparks und während eines ereignisreichen Tages übernimmt der Hörer das menschliche Bewusstsein einer künstlichen Intelligenz - Anna -, die für die Sicherheit des Parks zuständig ist. Der Hörer wird mit realistischen Dilemmas konfrontiert und trifft Entscheidungen bezüglich der Nutzung von Annas Fähigkeiten.
      </p>
      <p>
        Wir hoffen, dass Sie diese Erfahrung genießen und sie mit anderen teilen möchten.
      </p>
    `,
    "contact" : "Kontakt",
    "text-contact" : `
      <p>
        Dr. Quentin Ladetto</br>
        Direktor für technologische Zukunftsforschung</br>
      </p>
      <p>
        armasuisse</br>
        Wissenschaft und Technik</br>
        Forschungsmanagement und Operations Research</br>
        Feuerwerkerstrasse 39</br>
        CH-3602 Thoune</br>
      </p>
      <p>
        Contact mail (https://deftech.ch/de/contact/)
      </p>
    `
  }
}
