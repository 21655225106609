import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import styles from './home.module.scss';
import logoTechnocast from '../../assets/images/technocast.png'
import defTech from '../../assets/images/def-tech.png'
import coupdapres from '../../assets/images/logo-le-coup-dapres.png'

import podcastActions from '../../actions/podcastActions'

import { traductionsHome } from '../../config/traductions-home'
import { traductions } from '../../config/traductions'

import StyledSelect from '../../components/StyledSelect'
import Button from '../../components/Button'

export default function Home(){
  const dispatch = useDispatch()
  const podcastReducer = useSelector(state => state.podcastReducer)
  const optionsLang = [
    { label: 'Français', value: 'fr' },
    { label: 'English', value: 'en' },
    { label: 'Italiano', value: 'it' },
    { label: 'Deutsch', value: 'de' }
  ]

  function handleChangeLang(lang){
    dispatch(podcastActions.selectLang(lang.value))
  }

  return (
    <div className={styles["home"]}>
      <div className={`${styles["container"]} container`}>
        <div>
          <div className={styles["logo"]}>
            <img src={logoTechnocast} alt="logo technocast" />
          </div>
          <span dangerouslySetInnerHTML={{__html : traductionsHome[podcastReducer.lang]["text"]}} />
          <StyledSelect options={optionsLang} onChange={handleChangeLang}/>
          <div className={styles["container-btn-discover"]}>
            {/*<Button to="/" text={traductionsHome[podcastReducer.lang]["button-date"]} />*/}
            <Button to="/fantas-ia/intro" text={traductionsHome[podcastReducer.lang]["discover"]} />
          </div>
          <Link className={styles["learn-more"]} to="/technocast">{traductionsHome[podcastReducer.lang]["learn-more"]}</Link>
          <div className={styles["deftech"]}>
            <a href="https://deftech.ch/" target="_blank"><img src={defTech} alt="deftech"  width="100"/></a>
            <a href="http://lecoupdapres.fr/" target="_blank"><img src={coupdapres} alt="le coup d'après"  width="100"/></a>
          </div>
        </div>
        <Link className={styles["general-conditions"]} to="/mention-legales">{traductions[podcastReducer.lang]["general-conditions"]}</Link>
      </div>

    </div>
  )
}
