import {
  TOGGLE_MODAL_SOUND,
  SELECT_LANG,
  SET_END_TIME,
  SET_START_TIME,
  INIT_ANSWERS,
  RESET_ANSWERS,
  SET_ANSWERS
} from '../actions/types.js'

const selectLang = (lang) => {
  return {
    type: SELECT_LANG,
    payload : lang
  }
}

const initAnswers = (obj) => {
  return {
    type: INIT_ANSWERS,
    payload : obj
  }
}

const setDateStart = (date) => {
  return {
    type: SET_START_TIME,
    payload : date
  }
}

const setDateEnd = (date) => {
  return {
    type: SET_END_TIME,
    payload : date
  }
}

const setAnswers = (obj) => {
  return {
    type: SET_ANSWERS,
    payload : obj
  }
}

const resetAnswers = () => {
  return {
    type: RESET_ANSWERS
  }
}

const toggleModalSound = (bool) => {
  return {
    type: TOGGLE_MODAL_SOUND,
    payload : bool
  }
}

const actions = {
  selectLang,
  initAnswers,
  setDateStart,
  setDateEnd,
  resetAnswers,
  setAnswers,
  toggleModalSound
}

export default actions
