import React, { useState, useEffect, useRef } from 'react'
import { withWindowSize } from 'react-fns';
import { useSelector, useDispatch } from 'react-redux'
import {
  useHistory,
  Redirect,
  Link,
  Switch,
  Route
} from "react-router-dom";

import podcastActions from '../../actions/podcastActions'

import logoWhite from '../../assets/images/logo-white.png'

import styles from './podcast.module.scss'

//PAGES
import Intro from './Intro'
import PausePodcast from './PausePodcast'
import Story from './Story'
import End from './End'
import PageAnnexes from './PageAnnexes'
import NavigationAnnexes from './NavigationAnnexes'

// COMPONENTS
import Audio from '../../components/Audio'
import ModalSound from '../../components/ModalSound'

const Podcast = ({width , height}) => {
  const dispatch = useDispatch()
  const podcastReducer = useSelector(state => state.podcastReducer)
  const audioIntroRef = useRef()
  const audioEndRef = useRef()
  const history = useHistory()
  const [ isMuted, setIsMuted ] = useState(false)

  const [ pausePodcast, setPausePodcast ] = useState(false)

  useEffect(()=>{

    let answers = localStorage.getItem('answers')
    let startTime = localStorage.getItem('start_time')
    let endTime = localStorage.getItem('end_time')

    if(answers){
      setPausePodcast(true)
      dispatch(
        podcastActions.initAnswers({answers : JSON.parse(answers), startTime, endTime })
      )
    }

    if(history.location.pathname === "/fantas-ia/intro"){
      audioIntroRef.current.play();
      audioEndRef.current.pause();
    }

    if(history.location.pathname === "/fantas-ia/end" ||
      history.location.pathname === "/fantas-ia/pages/resultat" ||
      history.location.pathname === "/fantas-ia/pages/sources" ||
      history.location.pathname === "/fantas-ia/pages/credits" ||
      history.location.pathname === "/fantas-ia/pages/navigation-annexes"
     ){
      audioEndRef.current && audioEndRef.current.play();
      audioIntroRef.current && audioIntroRef.current.pause();
    }

    if(history.location.pathname !== "/fantas-ia/story"){
      localStorage.removeItem('step')
    }

    history.listen((location)=>{
      if(location.pathname === "/fantas-ia/intro"){
        audioIntroRef.current && audioIntroRef.current.play();
      }else{
        audioIntroRef.current && audioIntroRef.current.fadeOut();
      }

      if(location.pathname === "/fantas-ia/end" ||
        location.pathname === "/fantas-ia/pages/resultat" ||
        location.pathname === "/fantas-ia/pages/sources" ||
        location.pathname === "/fantas-ia/pages/credits" ||
        location.pathname === "/fantas-ia/pages/navigation-annexes"
      ){
        audioEndRef.current && audioEndRef.current.play();
      }else{
        audioEndRef.current && audioEndRef.current.pause();
      }

      if(location.pathname !== "/fantas-ia/story"){
        // if we are not in story we remove step from localStorage
        localStorage.removeItem('step')
      }
    })
  },[])

  function play(){
    history.location.pathname === "/fantas-ia/intro" && audioIntroRef.current.play()
    if(history.location.pathname === "/fantas-ia/end" ||
      history.location.pathname === "/fantas-ia/pages/resultat" ||
      history.location.pathname === "/fantas-ia/pages/sources" ||
      history.location.pathname === "/fantas-ia/pages/credits" ||
      history.location.pathname === "/fantas-ia/pages/navigation-annexes"
    ){
      audioEndRef.current.play()
    }
    setIsMuted(false)
  }

  function pause(){
    history.location.pathname === "/fantas-ia/intro" && audioIntroRef.current.pause()
    if(history.location.pathname === "/fantas-ia/end" ||
      history.location.pathname === "/fantas-ia/pages/resultat" ||
      history.location.pathname === "/fantas-ia/pages/sources" ||
      history.location.pathname === "/fantas-ia/pages/credits" ||
      history.location.pathname === "/fantas-ia/pages/navigation-annexes"
    ){
      audioEndRef.current.pause()
    }
    setIsMuted(true)
  }

  return (
    <React.Fragment>

      { (
        (history.location.pathname !== "/fantas-ia/intro" &&
        history.location.pathname !== "/fantas-ia/pages/resultat" &&
        history.location.pathname !== "/fantas-ia/pages/sources" &&
        history.location.pathname !== "/fantas-ia/pages/credits") ||
        ((history.location.pathname === "/fantas-ia/pages/resultat" ||
        history.location.pathname === "/fantas-ia/pages/sources" ||
        history.location.pathname === "/fantas-ia/pages/credits" ) && width > 767)
      ) &&
        <Link className={styles["logo-header"]} to="/"><img  src={logoWhite} alt="fantas-ia" /></Link>
      }

      <Switch>

        {pausePodcast &&
         <Route exact path="/fantas-ia/*">
           <PausePodcast setPausePodcast={setPausePodcast} />
         </Route>
        }

        <Route exact path="/fantas-ia/intro">
          <Intro
            isMuted={isMuted}
            playSound={play}
            pauseSound={pause}
          />
        </Route>
        <Route exact path="/fantas-ia/story">
          <Story
            playSound={play}
            pauseSound={pause}
            endSound={audioEndRef.current}
          />
        </Route>
        <Route exact path="/fantas-ia/end">
          <End
            isMuted={isMuted}
            playSound={play}
            pauseSound={pause}
          />
        </Route>

        <Route exact path="/fantas-ia/pages/navigation-annexes">
          <NavigationAnnexes
            isMuted={isMuted}
            playSound={play}
            pauseSound={pause}
          />
        </Route>
        <Route exact path="/fantas-ia/pages/(resultat|credits|sources)">
          <PageAnnexes
            isMuted={isMuted}
            playSound={play}
            pauseSound={pause}
          />
        </Route>
        <Route exact path="*"><Redirect to="/fantas-ia/intro"/></Route>
      </Switch>

      {podcastReducer.modalSound && <ModalSound />}

      <Audio id="intro" ref={audioIntroRef} source={`${process.env.PUBLIC_URL}/audios/intro.mp3`} loop/>
      <Audio id="end" ref={audioEndRef} source={`${process.env.PUBLIC_URL}/audios/end.mp3`} loop/>

    </React.Fragment>
  );
}

export default withWindowSize(Podcast)
