import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from "react-router-dom";

import { Provider } from 'react-redux';
import { createStore } from 'redux'
import rootReducer from './reducers'

import Home from './pages/Home'
import Podcast from './pages/Podcast'
import GeneralConditions from './pages/GeneralConditions'
import Technocast from './pages/Technocast'

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/"><Home/></Route>
          <Route exact path="/mention-legales"><GeneralConditions/></Route>
          <Route exact path="/technocast"><Technocast/></Route>
          <Route exact path="/fantas-ia/:page/:subpage?"><Podcast/></Route>
          <Route exact path="*"><Redirect to="/"/></Route>
        </Switch>
      </Router>
    </div>
    </Provider>
  );
}

export default App;
