import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import usePrevious from '../../hooks/usePrevious'

import styles from "./input-text.module.scss";

const InputText = ({type, name, handleFormValidation, placeholder = "" }, ref) => {
  const [ value, setValue ] = useState("")
  const [ isValid, setIsValid ] = useState(false)
  const [ wasValid, setWasValid ] = useState(false)

  const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

  useImperativeHandle(ref,()=>({
    getIsValid : () => ({ [name] : emailReg.test(value)}),
    getValue : () => value,
    getObject : () => ({ [name] : value})
  }))

  useEffect(()=>{
    if(wasValid && handleFormValidation){
      handleFormValidation(name,isValid)
    }
  },[isValid])

  function handleChange(value){
    if(type === "email"){
      setIsValid(emailReg.test(value))
      if(emailReg.test(value)) setWasValid(true)
    }
    setValue(value)
  }

  function getClassName(){
    let errorClass = wasValid && isValid === false ? `${styles["error"]}` : ""
    return `${styles["input-text"]} ${errorClass}`
  }

  return (
    <input
      type={type}
      value={value}
      className={getClassName()}
      onChange={e => handleChange(e.target.value)}
      placeholder={placeholder}
    />
  )
}

export default forwardRef(InputText)
