import { useState, useRef, useEffect } from 'react';

export default function useKonamiCode(code,action){
  const [ currentKey, setCurrentKey ] = useState("")
  const currentCode = useRef("")
  const [ timeStamp, setTimeStamp ] = useState("")

  useEffect(()=>{
     window.addEventListener("keydown",getKey);
     return () => {
       window.removeEventListener("keydown",getKey);
     }
  },[]);

  useEffect(()=>{
    currentCode.current = currentCode.current+currentKey
  },[timeStamp])

  useEffect(()=>{

    let timer = setTimeout(()=>{
      currentCode.current = ""
    },1500)

    return () => {
      clearTimeout(timer)
    }

    //WE USE A TIMESTAMP AND NOT currentKey
    //TO TRIGGER THIS USEEFFECT EVEN IF
    //IF THE SAME KEY IS PRESSED SEVERAL TIME
    //IN A ROW
  },[timeStamp])

  function getKey(e){

    if(e.keyCode === 13){
      let reg = /step+\d{1,}/g
      if(reg.test(currentCode.current)){
        let int = currentCode.current.replace(code,'')
        action(parseInt(int))
        currentCode.current = ""
      }
    }

    if(
      (e.keyCode > 64 && e.keyCode < 90) ||
      (e.keyCode > 47 && e.keyCode < 58)
    ){
      setCurrentKey(e.key)
      setTimeStamp(Date.now())
    }
  }

}
